import { useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";

export function RedirectPage({ redirectPath }) {
  const location = useLocation();
  const { cat } = useParams();
  const navigate = useNavigate();
  const searchQuery = window.location.search;

  useEffect(() => {
    if (redirectPath && redirectPath === "dashboard") {
      console.log("redirecting to dashboard", location);
      if (location.pathname.toLowerCase() === "/editor/" || location.pathname.toLowerCase() === "/editor") {
        navigate(`/editor/all${searchQuery}`);
      } else if (location.pathname.toLowerCase() === "/playground/" || location.pathname.toLowerCase() === "/playground") {
        //  navigate(`/playground/fluxDev-maker${searchQuery}`);
         navigate(`/playground/flux1${searchQuery}`);
      } else {
        navigate(`/${searchQuery}`);
      }
    } else if (redirectPath && redirectPath === "editor") {
      // console.log("navigating to pathname redirection", `/editor/${cat}`, window.location.pathname);
      // if(cat==="ai"){
      //   navigate(`/editor/${cat}/`);
      // }
      navigate(`/editor/${cat}${searchQuery}`);
    }
  }, []);
  return null;
}
// export default RedirectPage;
