import { createSlice } from "@reduxjs/toolkit";
import {
  initAligningGuidelines,
  initCenteringGuidelines,
} from "../component/canvas/guidlines";
import { _onClipMouseDown } from "../component/ImagesTemplate/Ap_CropImage";
import {
  addRect2img,
  _adjustPositionV2,
  _onMousedown,
  _onScaling,
  _adjustPositionV2_sttencil,
} from "../component/ImagesTemplate/CropImage copy";
import { initializeCanvasEventListeners } from "../helper/canvasEventListeners";
import { AddFilterInImage } from "../helper/CanvasEventFactory";
import { SEARCH_BASE_URL, defaultThumb } from "../utils/AppConstant";
import { toast } from "react-toastify";
import { reload } from "firebase/auth";
import { fetchAiJsonData } from "../utils/factory";
import WebFont from "webfontloader";

let cccanvas;
const isMobile = window.innerWidth <= 991;
export const Canvas_Slice = createSlice({
  name: "_canvas",
  initialState: {
    // Define the initial state of the reducer
    value: 0,
    canvas_: null, // initial state of canvas (this value is used in whole project)
    fake_canvas_: null,
    rectHeight: null, // initial state of value of available height in browser for canvas
    rectWight: null, // initial state of value of available width in browser for canvas
    bg_width_client: null, // initial state of value of background width
    bg_height_client: null, // initial state of value of background height
    framebox: null, // initial state of framebox or boxframe
    after_zoom_detail: { zoom_: 1, html: null }, //initial state of zoom value
    // dragMode: false,
    undo_status_active: false,
    redostatus: false,
    layer_toolbar_status: false,
    // should_redo_empty: false,
    layer_render: false,
    background_type: { bg_type: null, bg_fill: null },
    color_picker_state: {
      fromwhichcomponent: null, // indicates from wheree colorpicker is called
      currentSlider: null, // indicates the current slider name
      current_color: null, // indicate which color should be selected when colorpicker is called
    },
  },

  // Define the reducer function
  reducers: {
    // update_dragMode: (state, action) => {
    //   // to change the dragmode this reducer function will be called
    //   return {
    //     ...state, // Spread the current state object
    //     dragMode: action.payload.value, // Update the drag value that comes in payload
    //   };
    // },
    update_fake_Canvas: (state, action) => {
      return {
        ...state,
        fake_canvas_: action.payload.value__,
      };
    },
    updateCanvas: (state, action) => {
      let value = action.payload.value__;
      let widthBox = action.payload.cat_dimension_width
        ? action.payload.cat_dimension_width
        : parseInt(action.payload.clientWidth) - 100;
      let heightBox = action.payload.cat_dimension_height
        ? action.payload.cat_dimension_height
        : parseInt(action.payload.clientHeight) - 100;
      console.log("updatingcanvas rendered--->>", widthBox, heightBox);

      $("body").addClass("designeEditorContainer");
      //positionOffsetText = 0;
      let rectFrame = new fabric.Rect({
        // left: 50,
        // top: 50,
        width: widthBox,
        height: heightBox,
        // backgroundColor: "red",
        dirty: false,
        // fill: "red",
        strokeWidth: 0,
        selectable: false,
        // hasControls: false,
        absolutePositioned: true,
        id: "viewport",
        scaleX: 1,
        scaleY: 1,
        mtype: "ViewPort",
        // type: "background"
        // inverted: true
      });
      let background_img;
      let Background = new fabric.Image.fromURL("", (img) => {
        img.set({
          // img.set({
          // left: 50,
          // top: 50,
          backgroundColor: "#ffffff",
          background_fill: "#ffffff",
          background_fill_copy: "#ffffff",
          width: widthBox,
          height: heightBox,
          // backgroundColor: "red",
          dirty: false,
          fill: "transparent",
          strokeWidth: 0,
          selectable: false,
          // hasControls: false,
          // hasControls: false,
          // lockScalingX: true,
          // lockScalingY: true,
          // lockRotation: true,
          // lockMovementX: true,
          // lockMovementY: true,
          lockScalingFlip: true,
          lockUniScaling: true,
          evented: true,
          // hasBorders: true,
          // absolutePositioned: true,
          id: "viewport",
          cropY: 0,
          cropX: 0,
          scaleX: 1,
          scaleY: 1,
          isEditing_: false,
          mtype: "ViewPort",
          type_: "background",
          imageAngle: undefined,
          // type: "background"
          // inverted: true
        });
        value.add(img);
        img.layer_id = getRandomID(36);
        let container = document.getElementsByClassName("layringdiv")[0];
        let new_div = document.createElement("div");
        let id = `${img.layer_id}_${img.type}_layer_div`;
        new_div.id = id;
        new_div.classList.add("layeringdiv_div");
        new_div.classList.add("background_layer");
        new_div.classList.add("ui-state-disabled");
        container.appendChild(new_div);

        let src = img.toDataURL();
        let img_ = document.createElement("img");
        img_.classList.add("layeringdiv_img");
        img_.alt = "background_layer";
        img_.id = `${img.layer_id}_${img.type}_layer_img`;
        // img.class
        img_.src = src;
        new_div.appendChild(img_);
        let bg_bottom = document.createElement("div");
        bg_bottom.classList.add("bg_bottom");
        bg_bottom.innerHTML = "Background";

        new_div.appendChild(bg_bottom);
        value.centerObject(img);
        value.renderAll();
        background_img = img;
      });
      let canvas = value;
      cccanvas = value;
      canvas.clipPath = rectFrame; // initialize clipPath of canvas
      canvas.crop_mode = false; // initialize crop_mode and starting value will be false
      canvas.centerObject(rectFrame); // centering background to the viewport of canvas
      canvas.pageId = getRandomID(36);
      canvas.preDimensions = {
        width: canvas.width,
        height: canvas.height,
      };
      background_img.canvas_page_id = canvas.pageId;

      canvas.preserveObjectStacking = true;
      canvas.p_vpt_4_btn = canvas.viewportTransform;
      canvas.stateProperties = [];
      canvas._pages = [];
      canvas.selectionColor = "rgba(44,125,250,0.1)";
      canvas.selectionBorderColor = "rgb(176,207,255)";

      let options = {
        canvas: canvas,
        at: "first",
      };
      zoomtofit(canvas, rectFrame);

      addnewpagediv(options); // Adding div to new page

      const STATE_IDLE = "idle";
      const STATE_PANNING = "panning";
      // initCenteringGuidelines(canvas, canvas.getZoom());
      // initAligningGuidelines(canvas, canvas.getZoom());

      //*******************************//dragging fn//*************************************//
      fabric.Canvas.prototype.toggleDragMode = function (dragMode) {
        if (dragMode) {
          this.dragMode = dragMode;
          this.discardActiveObject();
          this.defaultCursor = "pointer";
          this.forEachObject(function (object) {
            object.prevEvented = object.evented;
            object.prevSelectable = object.selectable;
            object.evented = false;
            object.selectable = false;
          });

          this.selection = false;
          this.isDragging = false;

          // this.on("touch:gesture", function (e) {
          //   // Handle touch gestures
          //   if (e.self.touches && e.self.touches.length === 1 && e.self.target) {
          //     const target = e.self.target;
          //     target.fire("mousedown", { e: e.e });
          //     // target.setCoords();
          //     this.requestRenderAll();
          //   }
          // });

          this.renderAll();
        } else {
          this.dragMode = dragMode;
          this.forEachObject(function (object) {
            object.evented =
              object.prevEvented !== undefined
                ? object.prevEvented
                : object.evented;
            object.selectable =
              object.prevSelectable !== undefined
                ? object.prevSelectable
                : object.selectable;
          });
          this.defaultCursor = "default";
          this.selection = true;
          this.off("touch:gesture");
          this.renderAll();
        }
      };

      //---------------------------//dragging fn end //---------------------------//

      //*******************************// canvas object selection UI //*************************************//
      fabric.Object.prototype.set({
        borderScaleFactor: 2,
        borderColor: "#4BD3FB",
        borderOpacityWhenMoving: 1,
        cornerRoundSize: 3,
        cornerStyle: "circle",
        transparentCorners: false,
        // controls.mtr.offsetY
        cornerSize: 12,
        cornerColor: "rgba(255,255,255,1)",
        cornerStrokeColor: "rgba(0,0,0, 0.15)",
        cornerHighlightColor: "#4BD3FB",
        cornerRoundSize: 3,

        // cornerStyleIcons: { bl: "circle", br: "rect" }
      });
      fabric.Object.prototype.controls.mtr.offsetY = 10;
      fabric.Textbox.prototype.set({
        // borderColor: '#4BD3FB',
        // borderOpacityWhenMoving: 1,
        cornerRoundSize: 3,
        transparentCorners: false,

        cornerSize: 12,
        // cornerStyleIcons: { bl: "circle", br: "rect" },
        borderScaleFactor: 2,
        cornerStyle: "circle",
      });

      const istate = {
        lastAngleRotation: null,
        currentAngle: null,
        isRotating: false,
        cursorPos: new fabric.Point(),
      };
      const imgIcon_rotate = document.createElement("img");

      imgIcon_rotate.src = "/rotate_icon.svg";

      function mouseRotateIcon(angle) {
        const relativeAngle = angle - 90;
        const pos = {
            "-90": "9.25 5.25",
            "-75": "9.972 3.863",
            "-60": "10.84 1.756",
            "-45": "11.972 -1.716",
            "-30": "18.83 0.17",
            "-15": "28.49 -9.49",
            15: "-7.985 46.77",
            30: "-0.415 27.57",
            45: "2.32 21.713",
            60: "3.916 18.243",
            75: "4.762 16.135",
            90: "5.25 14.75",
            105: "5.84 13.617",
            120: "6.084 12.666",
            135: "6.317 12.01",
            150: "6.754 11.325",
            165: "7.06 10.653",
            180: "7.25 10",
            195: "7.597 9.43",
            210: "7.825 8.672",
            225: "7.974 7.99",
            240: "8.383 7.332",
            255: "8.83 6.441",
          },
          defaultPos = "7.25 10";
        const transform =
          relativeAngle === 0
            ? "translate(9.5 3.5)"
            : `rotate(${relativeAngle} ${pos[relativeAngle] || defaultPos})`;
        const imgCursor = encodeURIComponent(`
                <svg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='24' height='24'>
                  <defs>
                    <filter id='a' width='266.7%' height='156.2%' x='-75%' y='-21.9%' filterUnits='objectBoundingBox'>
                      <feOffset dy='1' in='SourceAlpha' result='shadowOffsetOuter1'/>
                      <feGaussianBlur in='shadowOffsetOuter1' result='shadowBlurOuter1' stdDeviation='1'/>
                      <feColorMatrix in='shadowBlurOuter1' result='shadowMatrixOuter1' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0'/>
                      <feMerge>
                        <feMergeNode in='shadowMatrixOuter1'/>
                        <feMergeNode in='SourceGraphic'/>
                      </feMerge>
                    </filter>
                    <path id='b' d='M1.67 12.67a7.7 7.7 0 0 0 0-9.34L0 5V0h5L3.24 1.76a9.9 9.9 0 0 1 0 12.48L5 16H0v-5l1.67 1.67z'/>
                  </defs>
                  <g fill='none' fill-rule='evenodd'><path d='M0 24V0h24v24z'/>
                    <g fill-rule='nonzero' filter='url(#a)' transform='${transform}'>
                      <use fill='#000' fill-rule='evenodd' xlink:href='#b'/>
                      <path stroke='#FFF' d='M1.6 11.9a7.21 7.21 0 0 0 0-7.8L-.5 6.2V-.5h6.7L3.9 1.8a10.4 10.4 0 0 1 0 12.4l2.3 2.3H-.5V9.8l2.1 2.1z'/>
                    </g>
                  </g>
                </svg>`);
        return `url("data:image/svg+xml;charset=utf-8,${imgCursor}") 12 12, crosshair`;
      }
      function treatAngle(angle) {
        return angle - (angle % 15);
      }
      function rotationStyleHandler(eventData, control, fabricObject) {
        if (fabricObject.lockRotation) {
          return NOT_ALLOWED_CURSOR;
        }

        const angle = treatAngle(fabricObject.angle);
        istate.lastAngleRotation = angle;
        return mouseRotateIcon(angle);
      }
      canvas.on("object:rotating", function (e) {
        if (e.pointer) {
          const angle = treatAngle(e.target.angle);
          if (istate.lastAngleRotation !== angle) {
            canvas.setCursor(mouseRotateIcon(angle));
            istate.lastAngleRotation = angle;
          }
          istate.isRotating = e.pointer && true;
          istate.currentAngle = e.target.angle;
          istate &&
            istate.cursorPos &&
            (istate.cursorPos.x = e.pointer && e.pointer.x);
          istate &&
            istate.cursorPos &&
            (istate.cursorPos.y = e.pointer && e.pointer.y);
        }
      });

      // canvas.on("after:render", function (opt) {
      //   istate.isRotating && renderRotateLabel(opt.ctx, canvas);
      // });

      // function renderRotateLabel(ctx, canvas) {
      //   const angleText = `${istate.currentAngle.toFixed(0)}°`,
      //     borderRadius = 12,
      //     rectWidth = 38,
      //     rectHeight = 19,
      //     textWidth = 6.01 * angleText.length - 2.317;

      //   const { tl, br } = canvas.vptCoords;

      //   const pos = istate.cursorPos.add(new fabric.Point(20, -9));

      //   ctx.save();
      //   ctx.translate(
      //     Math.min(Math.max(pos.x, tl.x), br.x - rectWidth),
      //     Math.min(Math.max(pos.y, tl.y), br.y - rectHeight)
      //   );
      //   ctx.beginPath();
      //   ctx.fillStyle = "rgba(37,38,39,0.9)";
      //   ctx.roundRect(0, 0, rectWidth, rectHeight, borderRadius);
      //   ctx.fill();
      //   ctx.font = "400 12px Poppins";
      //   ctx.fillStyle = "hsla(0,0%, 100%, 0.9)";
      //   ctx.fillText(
      //     angleText,
      //     rectWidth / 2 - textWidth / 2,
      //     rectHeight / 2 + 4
      //   );
      //   ctx.restore();
      // }

      ////////////////////////////////////////////////////////////////
      fabric.Object.prototype.controls.mr = new fabric.Control({
        x: 0.5,
        y: 0,
        offsetY: 0,
        cursorStyle: "ew-resize",
        render: renderIconmr,
        cornerSize: 12,

        actionHandler: fabric.controlsUtils.scalingXOrSkewingY,
        getActionName: fabric.controlsUtils.scaleOrSkewActionName,
      });
      fabric.Object.prototype.controls.ml = new fabric.Control({
        x: -0.5,
        y: 0,
        offsetY: 0,
        cursorStyle: "ew-resize",
        render: renderIconml,
        cornerSize: 12,
        actionHandler: fabric.controlsUtils.scalingXOrSkewingY,
        getActionName: fabric.controlsUtils.scaleOrSkewActionName,
      });
      fabric.Object.prototype.controls.mt = new fabric.Control({
        x: 0,
        y: -0.5,
        offsetY: 0,
        cursorStyle: "ns-resize",
        render: renderIconmt,
        cornerSize: 12,
        actionHandler: fabric.controlsUtils.scalingYOrSkewingX,
        getActionName: fabric.controlsUtils.scaleOrSkewActionName,
      });
      fabric.Object.prototype.controls.mb = new fabric.Control({
        x: 0,
        y: 0.5,
        offsetY: 0,
        cursorStyle: "ns-resize",
        render: renderIconmb,
        cornerSize: 12,
        actionHandler: fabric.controlsUtils.scalingYOrSkewingX,
        // actionHandler: ((this && this._type && (this._type === "line"))) ? (fabric.controlsUtils.scalingYOrSkewingX) : (console.log("linnnnneeee")),
        getActionName: fabric.controlsUtils.scaleOrSkewActionName,
      });
      fabric.Object.prototype.controls.mtr = new fabric.Control({
        x: 0,
        y: -0.5,
        offsetX: 0,
        offsetY: -30,
        cursorStyleHandler: rotationStyleHandler,
        actionHandler: fabric.controlsUtils.rotationWithSnapping,
        actionName: "rotate",
        render: renderIcon,
        cornerSize: 38,
        withConnection: false,
      });
      fabric.Textbox.prototype.controls.mr = new fabric.Control({
        x: 0.5,
        y: 0,
        offsetY: 0,
        cursorStyle: "ew-resize",
        render: renderIconmr,
        cornerSize: 12,
        actionHandler: fabric.controlsUtils.changeWidth,
        actionName: "resizing",
      });
      fabric.Textbox.prototype.controls.ml = new fabric.Control({
        x: -0.5,
        y: 0,
        offsetY: 0,
        cursorStyle: "ew-resize",
        render: renderIconml,
        cornerSize: 12,
        actionHandler: fabric.controlsUtils.changeWidth,
        actionName: "resizing",
      });
      fabric.Textbox.prototype.controls.mt = new fabric.Control({
        x: 0,
        y: -0.5,
        offsetY: 0,
        cursorStyle: "ns-resize",
        render: renderIconmt,
        cornerSize: 12,
        actionHandler: fabric.controlsUtils.scalingYOrSkewingX,
        getActionName: fabric.controlsUtils.scaleOrSkewActionName,
      });
      fabric.Textbox.prototype.controls.mb = new fabric.Control({
        x: 0,
        y: 0.5,
        offsetY: 0,
        cursorStyle: "ns-resize",
        render: renderIconmb,
        cornerSize: 12,
        actionHandler: fabric.controlsUtils.scalingYOrSkewingX,
        getActionName: fabric.controlsUtils.scaleOrSkewActionName,
      });
      fabric.Textbox.prototype.controls.mtr = new fabric.Control({
        x: 0,
        y: -0.5,
        offsetX: 0,
        offsetY: -40,
        cursorStyleHandler: rotationStyleHandler,
        actionHandler: fabric.controlsUtils.rotationWithSnapping,
        actionName: "rotate",
        render: renderIcon,
        cornerSize: 38,
        withConnection: false,
      });

      // Defining how the rendering action will be
      function renderIcon(ctx, left, top, styleOverride, fabricObject) {
        var size = this.cornerSize;
        ctx.save();
        ctx.translate(left, top);
        ctx.rotate(fabric.util.degreesToRadians(fabricObject.angle));
        ctx.drawImage(imgIcon_rotate, -size / 2, -size / 2, size, size);
        ctx.restore();
      }
      function renderIconml(ctx, left, top, styleOverride, fabricObject) {
        var size = fabricObject.cornerSize;
        ctx.save();
        ctx.translate(left, top);
        ctx.rotate(fabric.util.degreesToRadians(fabricObject.angle));
        // ctx.fillRect(100 - size / 2, 100 - size / 2, size, size);
        var rect_icon = new fabric.Rect({
          left: -(size / 4 + 0.9),
          top: -(size - 2.5),
          fill: fabricObject.cornerColor,
          stroke: fabricObject.cornerStrokeColor,
          width: size / 2,
          height: size * 1.5,
          rx: 3,
          ry: 3,
        });
        rect_icon.render(ctx);
        // ctx.drawImage(mr_ml_img, -((size / 2) + 0.8), -size, size, size * 2);
        // ctx.drawImage(mr_ml_img, -size / 2, -size / 2, size, size);
        ctx.restore();
      }
      function renderIconmt(ctx, left, top, styleOverride, fabricObject) {
        var size = this.cornerSize;
        ctx.save();
        ctx.translate(left, top);
        ctx.rotate(fabric.util.degreesToRadians(fabricObject.angle));
        var rect_icon = new fabric.Rect({
          left: size / 2 + size * 0.25,
          top: -(size / 2 - 0.9),
          fill: fabricObject.cornerColor,
          angle: 90,
          stroke: fabricObject.cornerStrokeColor,
          width: size / 2,
          height: size * 1.5,
          rx: 3,
          ry: 3,
        });
        rect_icon.render(ctx);
        // ctx.drawImage(mb_mt_img, -size, -(size / 2 + 0.8), size * 2, size);
        ctx.restore();
      }
      function renderIconmb(ctx, left, top, styleOverride, fabricObject) {
        var size = this.cornerSize;
        ctx.save();
        ctx.translate(left, top);
        ctx.rotate(fabric.util.degreesToRadians(fabricObject.angle));
        var rect_icon = new fabric.Rect({
          left: size / 2 + size * 0.25,
          top: -(size / 4),
          fill: fabricObject.cornerColor,
          angle: 90,
          stroke: fabricObject.cornerStrokeColor,
          width: size / 2,
          height: size * 1.5,
          rx: 3,
          ry: 3,
        });
        rect_icon.render(ctx);
        //ctx.drawImage(mb_mt_img, -size, -(size / 2 - 0.8), size * 2, size);
        ctx.restore();
      }
      function renderIconmr(ctx, left, top, styleOverride, fabricObject) {
        var size = this.cornerSize;
        ctx.save();
        ctx.translate(left, top);
        ctx.rotate(fabric.util.degreesToRadians(fabricObject.angle));
        var rect_icon = new fabric.Rect({
          left: -(size / 4 - 0.9),
          top: -(size - 2.5),
          fill: fabricObject.cornerColor,
          stroke: fabricObject.cornerStrokeColor,
          width: size / 2,
          height: size * 1.5,
          rx: 3,
          ry: 3,
        });
        rect_icon.render(ctx);
        // ctx.drawImage(mr_ml_img, -((size / 2) - 0.5), -size, size, size * 2);
        ctx.restore();
      }

      //---------------------------// canvas object selection UI end //---------------------------//

      let allcorner = ["mt", "ml", "mb", "mr", "tl", "tr", "bl", "br"];
      let inactive_corner;
      canvas.on("mouse:down", (e) => {
        if (e.transform && e.transform.corner) {
          if (!canvas.crop_mode) {
            if (e.transform.corner === "mtr") {
              e.transform.target.setControlVisible(e.transform.corner, false);
              canvas.renderAll();
            }
          }
        }
        if (isMobile) {
          const allChildElements = document.querySelectorAll(".leftChild");
          allChildElements.forEach((ele) => {
            if (ele.style.display == "block") {
              const height = ele.offsetHeight;
              ele.style.display = "none";
              canvas.preDimensions = {
                width: canvas.width,
                height: canvas.height,
              };
              const canvasDimensions = {
                width: canvas.getWidth(),
                height: canvas.getHeight() + (height - 60),
              };

              canvas.setDimensions(canvasDimensions);
              fitinViewPort(canvas);

              canvas.renderAll();

              var icon = document.getElementById("handIcon");
              icon.style.bottom = "90px";
            }
          });

          $(".plusTemplate").css("display", "block");
          const toolbarIconsList = document.querySelector(".toolbaricons-list");
          if (toolbarIconsList.classList.contains("toolbaricons-list2")) {
            toolbarIconsList.classList.remove("toolbaricons-list2");
          }
        }
      });
      canvas.on("mouse:up", (e) => {
        istate.isRotating = false;
        let all_corner = [
          "tl",
          "tr",
          "bl",
          "br",
          "ml",
          "mt",
          "mr",
          "mb",
          "mtr",
        ];
        if (!canvas.crop_mode) {
          if (e.target) {
            if (!e.target.lock) {
              e.target.hasControls = true;
              e.target.cornerColor = "rgba(255,255,255,1)";
              e.target.cornerStrokeColor = "rgba(0,0,0,0.15)";
              e.target.borderDashArray = [3, 0];
              // e.target._controlsVisibility = inactive_corner
              // e.target.setControlVisible(presentcorner, true)
              canvas.renderAll();
              // }
              // }
              if (e.transform) {
                check_CornerOverlapping(e.transform.target, canvas);
                // if (e.transform && e.transform.target) {
                //     if (e.transform.target.type === "textbox") {
                //         let all_corner_ = ['tl', 'tr', 'bl', 'br', 'ml', 'mr', 'mtr']
                //         all_corner_.map(a => {
                //             e.transform.target.setControlVisible(a, true)
                //         })
                //     } else {
                //         all_corner.map(a => {
                //             e.transform.target.setControlVisible(a, true)
                //         })
                //     }
                // }
              }
            }
          }
        }
      });
      //*******************************// Bounding box hovering effect on object //*************************************//
      canvas.on("mouse:over", function (e) {
        if (e.target) {
          if (!canvas.crop_mode) {
            if (e.target != canvas.getActiveObject()) {
              e.target._renderControls(e.target.canvas.contextTop, {
                hasControls: false,
              });
            } else {
            }
          }
        }
      });

      let cornerobj = false;
      canvas.on("mouse:move", function (e) {
        let active_obj = canvas.getActiveObject();
        if (active_obj) {
          if (!canvas.crop_mode) {
            let all_corner = [
              "tl",
              "tr",
              "bl",
              "br",
              "ml",
              "mt",
              "mr",
              "mb",
              "mtr",
            ];
            if (active_obj.__corner != 0) {
              all_corner.map((f) => {
                if (f !== active_obj.__corner) {
                  active_obj.setControlVisible(f, false);
                }
              });
              e.target.cornerColor = "#4bd3fb";
              e.target.cornerStrokeColor = "rgba(255,255,255,0.15)";
              canvas.renderAll();
              cornerobj = true;
            } else if (cornerobj) {
              if (e.target) {
                check_CornerOverlapping(active_obj, canvas);
              }
              active_obj.cornerColor = "#ffffff";
              active_obj.cornerStrokeColor = "rgba(0,0,0,0.15)";
              canvas.renderAll();
              cornerobj = false;
            }
          }

          // let pre_color = active_obj.cornerColor
          // let pre_stroke_color = active_obj.cornerStrokeColor
        }

        // let pre_corner = active_obj.getControlsVisibility()
      });
      canvas.on("mouse:out", function (e) {
        if (!canvas.crop_mode) {
          if (e.target) {
            if (e.target.id != "_cropArea") {
              e.target.canvas.clearContext(
                e.target.canvas.contextTop && e.target.canvas.contextTop
              );
              canvas.renderAll();
            }
          }
        }
      });
      //---------------------------// Bounding box hovering effect on object end //---------------------------//

      canvas.on("selection:created", (e) => {
        check_CornerOverlapping(e.target, canvas);

        if (e.target.group) {
          canvas.selectionKey = null;
        } else {
          canvas.selectionKey = "shiftKey";
        }

        if (e.target.type === "activeSelection") {
          // console.log("selection:created", e.target.type, e.target)
          let mid = ["mr", "mt", "ml", "mb"];
          mid.map((m) => e.target.setControlVisible(m, false));
          for (let i = 0; i < e.target.getObjects().length; i++) {
            let f = e.target.getObjects()[i];
            if (f.type === "rect" && f.original_img) {
              if (f.id === f.original_img.id) {
                if (e.target.getObjects()[i + 1] != f.original_img) {
                  // console.log("inserting now at ", (i + 1), f.original_img)
                  e.target.insertAt(f.original_img, i + 1);
                  f.original_img.set({
                    top: f.top,
                    left: f.left,
                  });
                  canvas.renderAll();
                }
                // console.log("getiing present image", e.target.getObjects())
              }
            }
          }
        } else if (!e.target._type && e.target.type === "group") {
          let mid = ["mr", "mt", "ml", "mb"];
          mid.map((m) => e.target.setControlVisible(m, false));
          canvas.renderAll();
        }
      });
      canvas.on("selection:updated", (e) => {
        // let all_corner = ['tl', 'tr', 'bl', 'br', 'ml', 'mr', 'mt', 'mb']
        check_CornerOverlapping(e.target, canvas);

        if (e.target.group) {
          canvas.selectionKey = null;
        } else {
          canvas.selectionKey = "shiftKey";
        }
        if (!canvas.crop_mode) {
          // console.log("selection updateb after activeselection ", e)
          if (e.target.type === "activeSelection") {
            let mid = ["mr", "mt", "ml", "mb"];
            mid.map((m) => e.target.setControlVisible(m, false));

            for (let i = 0; i < e.target.getObjects().length; i++) {
              let f = e.target.getObjects()[i];

              if (f.type === "rect" && f.original_img) {
                if (f.id === f.original_img.id) {
                  if (e.target.getObjects()[i + 1] != f.original_img) {
                    e.target.insertAt(f.original_img, i + 1);

                    f.original_img.set({
                      top: f.top,
                      left: f.left,
                    });

                    canvas.renderAll();
                  }

                  // console.log("getiing present image", e.target.getObjects())
                }
              }
            }
            // j.forEach((m, i) => {

            // })
          } else if (!e.target._type && e.target.type === "group") {
            let mid = ["mr", "mt", "ml", "mb"];
            mid.map((m) => e.target.setControlVisible(m, false));
            canvas.renderAll();
          }
        }
      });
      canvas.on("selection:cleared", function () {
        canvas.selectionKey = "shiftKey";
      });

      fabric.Canvas.prototype.hideControl = function (e) {
        let a = canvas.getActiveObject();
        let corners_ = ["tl", "mt", "tr", "mr", "br", "mb", "bl", "ml"];
        if (
          e.transform.action === "scale" ||
          e.transform.action === "scaleX" ||
          e.transform.action === "scaleY"
        ) {
          if (e.target.scaleX < 0.3 || e.target.scaleY < 0.3) {
            e.target.cornerSize = 8;
            corners_.map((a) => {
              e.target.setControlVisible(a, false);
            });
            e.target.setControlVisible(e.transform.corner, true);
            if (e.transform.corner === "tl" || e.transform.corner === "bl") {
              e.target.setControlVisible("mr", true);
            } else if (
              e.transform.corner === "tr" ||
              e.transform.corner === "br"
            ) {
              e.target.setControlVisible("ml", true);
            }
          } else {
            // corners_.map(a => { e.target.setControlVisible(a, true); })
            e.target.cornerSize = 12;
          }
          canvas.renderAll();
        }
      };
      //*******************************// Saving State function for undo/redo //*************************************//

      fabric.Canvas.prototype.savestate = function (params) {
        console.log("saving state", this, params && params.isAdd);
        let jj = canvas.getObjects();
        jj.forEach((obj) => {
          if (!obj.id__N) {
            obj.id__N = Math.floor(Math.random() * 100000000000000);
          }

          // obj.prevEvented = true;
          // obj.prevSelectable = true;
          // obj.evented = true;
          // obj.selectable = true;
          // obj.saveState()
        });

        if (canvas.stateProperties.length < 20) {
          // let data = {
          //   isAdd: params && params.isAdd ? params.isAdd : false,
          //   data: canvas.toJSON(),
          // };

          // Create a deep copy of the current canvas state
          let newStateData = JSON.parse(JSON.stringify(canvas.toJSON()));

          let newState = {
            isAdd: params && params.isAdd ? params.isAdd : false,
            data: newStateData, // Use deep copy to avoid reference issues
          };

          if (params?.shouldFitInViewPort) {
            newState.shouldFitInViewPort = true;
          }
          canvas.stateProperties.push(newState);
        } else {
          canvas.stateProperties.shift();
          let data = {
            isAdd: params && params.isAdd ? params.isAdd : false,
            data: canvas.toJSON(),
          };
          if (params?.shouldFitInViewPort) {
            data.shouldFitInViewPort = true;
          }
          // canvas.stateProperties.push(data);
        }
        if (canvas.stateProperties.length >= 1) {
        }
        // console.log("save state called", canvas.toJSON());
        // console.log("save state called", canvas.stateProperties);
      };
      //---------------------------// Bounding box hovering effect on object end //---------------------------//
      // Save additional attributes in Serialization

      fabric.Object.prototype.toObject = (function (toObject) {
        return function (propertiesToInclude) {
          propertiesToInclude = (propertiesToInclude || []).concat([
            "editingClipScaleH",
          ]);
          propertiesToInclude = (propertiesToInclude || []).concat([
            "placeholder",
          ]);
          propertiesToInclude = (propertiesToInclude || []).concat([
            "preWidth",
          ]);
          propertiesToInclude = (propertiesToInclude || []).concat([
            "publishId",
          ]);
          propertiesToInclude = (propertiesToInclude || []).concat([
            "preHeight",
          ]);
          propertiesToInclude = (propertiesToInclude || []).concat(["preTop"]);
          propertiesToInclude = (propertiesToInclude || []).concat(["preLeft"]);
          propertiesToInclude = (propertiesToInclude || []).concat([
            "background_fill_copy",
          ]);
          propertiesToInclude = (propertiesToInclude || []).concat([
            "backgroundColor",
          ]);
          propertiesToInclude = (propertiesToInclude || []).concat([
            "dynamicColor",
          ]);
          propertiesToInclude = (propertiesToInclude || []).concat([
            "dynamicData",
          ]);
          propertiesToInclude = (propertiesToInclude || []).concat([
            "preDimensions",
          ]);
          propertiesToInclude = (propertiesToInclude || []).concat([
            "prevEvented",
          ]);
          propertiesToInclude = (propertiesToInclude || []).concat([
            "prevSelectable",
          ]);
          propertiesToInclude = (propertiesToInclude || []).concat([
            "ytLayoutId",
          ]);
          propertiesToInclude = (propertiesToInclude || []).concat([
            "nameType",
          ]);
          propertiesToInclude = (propertiesToInclude || []).concat([
            "toBechangeColor",
          ]);
          propertiesToInclude = (propertiesToInclude || []).concat(["jsonId"]);
          propertiesToInclude = (propertiesToInclude || []).concat([
            "textEffects",
          ]);
          propertiesToInclude = (propertiesToInclude || []).concat([
            "textEffectKey",
          ]);
          propertiesToInclude = (propertiesToInclude || []).concat([
            "highlighterRadius",
          ]);
          propertiesToInclude = (propertiesToInclude || []).concat(["shadows"]);
          propertiesToInclude = (propertiesToInclude || []).concat(["hgh"]);
          propertiesToInclude = (propertiesToInclude || []).concat([
            "imageLoading",
          ]);
          propertiesToInclude = (propertiesToInclude || []).concat([
            "canvas_page_id",
          ]);
          propertiesToInclude = (propertiesToInclude || []).concat([
            "layer_id",
          ]);
          propertiesToInclude = (propertiesToInclude || []).concat(["copyId"]);
          propertiesToInclude = (propertiesToInclude || []).concat([
            "isloading",
          ]);
          propertiesToInclude = (propertiesToInclude || []).concat([
            "editingClipScaleW",
          ]);
          propertiesToInclude = (propertiesToInclude || []).concat([
            "editingClipScaleX",
          ]);
          propertiesToInclude = (propertiesToInclude || []).concat([
            "editingClipScaleY",
          ]);
          propertiesToInclude = (propertiesToInclude || []).concat([
            "editingScale",
          ]);
          propertiesToInclude = (propertiesToInclude || []).concat([
            "isLocked",
          ]);
          propertiesToInclude = (propertiesToInclude || []).concat(["aCoords"]);
          propertiesToInclude = (propertiesToInclude || []).concat([
            "absolutePositioned",
          ]);
          propertiesToInclude = (propertiesToInclude || []).concat([
            "cacheHeight",
          ]);
          propertiesToInclude = (propertiesToInclude || []).concat([
            "cacheTranslationX",
          ]);
          propertiesToInclude = (propertiesToInclude || []).concat([
            "cacheTranslationY",
          ]);
          propertiesToInclude = (propertiesToInclude || []).concat(["dirty"]);
          propertiesToInclude = (propertiesToInclude || []).concat(["fill"]);
          propertiesToInclude = (propertiesToInclude || []).concat(["flipX"]);
          propertiesToInclude = (propertiesToInclude || []).concat(["flipY"]);
          propertiesToInclude = (propertiesToInclude || []).concat(["id"]);
          propertiesToInclude = (propertiesToInclude || []).concat([
            "lineCoords",
          ]);
          propertiesToInclude = (propertiesToInclude || []).concat(["mtype"]);
          propertiesToInclude = (propertiesToInclude || []).concat(["oCoords"]);
          propertiesToInclude = (propertiesToInclude || []).concat([
            "ownCaching",
          ]);
          propertiesToInclude = (propertiesToInclude || []).concat([
            "ownMatrixCache",
          ]);
          propertiesToInclude = (propertiesToInclude || []).concat(["scaleX"]);
          propertiesToInclude = (propertiesToInclude || []).concat(["scaleY"]);
          propertiesToInclude = (propertiesToInclude || []).concat([
            "selectable",
          ]);
          propertiesToInclude = (propertiesToInclude || []).concat(["shadow"]);
          propertiesToInclude = (propertiesToInclude || []).concat(["stroke"]);
          propertiesToInclude = (propertiesToInclude || []).concat([
            "strokeWidth",
          ]);
          propertiesToInclude = (propertiesToInclude || []).concat(["top"]);
          propertiesToInclude = (propertiesToInclude || []).concat([
            "cornerSize",
          ]);
          propertiesToInclude = (propertiesToInclude || []).concat([
            "cropMask",
          ]);
          propertiesToInclude = (propertiesToInclude || []).concat([
            "clipChild",
          ]);
          propertiesToInclude = (propertiesToInclude || []).concat([
            "cropArea",
          ]);
          propertiesToInclude = (propertiesToInclude || []).concat([
            "centerPoint",
          ]);
          propertiesToInclude = (propertiesToInclude || []).concat(["_type"]);
          propertiesToInclude = (propertiesToInclude || []).concat(["bndry"]);
          propertiesToInclude = (propertiesToInclude || []).concat([
            "image_id",
          ]);
          propertiesToInclude = (propertiesToInclude || []).concat([
            "stencil_id",
          ]);
          propertiesToInclude = (propertiesToInclude || []).concat(["status"]);
          propertiesToInclude = (propertiesToInclude || []).concat([
            "_oldImage",
          ]);
          propertiesToInclude = (propertiesToInclude || []).concat(["type_"]);
          propertiesToInclude = (propertiesToInclude || []).concat(["id__N"]);
          propertiesToInclude = (propertiesToInclude || []).concat(["typeis"]);
          propertiesToInclude = (propertiesToInclude || []).concat([
            "location",
          ]);
          propertiesToInclude = (propertiesToInclude || []).concat([
            "hasControls",
          ]);
          propertiesToInclude = (propertiesToInclude || []).concat([
            "lockMovementX",
          ]);
          propertiesToInclude = (propertiesToInclude || []).concat([
            "lockMovementY",
          ]);
          propertiesToInclude = (propertiesToInclude || []).concat(["cropX"]);
          propertiesToInclude = (propertiesToInclude || []).concat(["cropY"]);
          propertiesToInclude = (propertiesToInclude || []).concat([
            "isEditing_",
          ]);
          propertiesToInclude = (propertiesToInclude || []).concat([
            "inverted",
          ]);
          propertiesToInclude = (propertiesToInclude || []).concat(["padding"]);
          propertiesToInclude = (propertiesToInclude || []).concat(["rx"]);
          propertiesToInclude = (propertiesToInclude || []).concat(["ry"]);
          propertiesToInclude = (propertiesToInclude || []).concat([
            "perPixelTargetFind",
          ]);
          propertiesToInclude = (propertiesToInclude || []).concat([
            "strokeUniform",
          ]);
          propertiesToInclude = (propertiesToInclude || []).concat([
            "noScaleCache",
          ]);
          propertiesToInclude = (propertiesToInclude || []).concat([
            "lockUniScaling",
          ]);
          propertiesToInclude = (propertiesToInclude || []).concat([
            "cornerColor",
          ]);
          propertiesToInclude = (propertiesToInclude || []).concat([
            "_cacheContext",
          ]);
          propertiesToInclude = (propertiesToInclude || []).concat([
            "_cacheProperties",
          ]);
          propertiesToInclude = (propertiesToInclude || []).concat([
            "_transformDone",
          ]);
          propertiesToInclude = (propertiesToInclude || []).concat([
            "_stateProperties",
          ]);
          propertiesToInclude = (propertiesToInclude || []).concat([
            "borderColor",
          ]);
          propertiesToInclude = (propertiesToInclude || []).concat([
            "centeredRotation",
          ]);
          propertiesToInclude = (propertiesToInclude || []).concat([
            "cornerStyle",
          ]);
          propertiesToInclude = (propertiesToInclude || []).concat([
            "editingAngle",
          ]);
          propertiesToInclude = (propertiesToInclude || []).concat(["evented"]);
          propertiesToInclude = (propertiesToInclude || []).concat([
            "excludeFromExport",
          ]);
          propertiesToInclude = (propertiesToInclude || []).concat(["filters"]);
          propertiesToInclude = (propertiesToInclude || []).concat([
            "imageAngle",
          ]);
          propertiesToInclude = (propertiesToInclude || []).concat([
            "imageViewport",
          ]);
          propertiesToInclude = (propertiesToInclude || []).concat([
            "isCropped",
          ]);
          propertiesToInclude = (propertiesToInclude || []).concat([
            "lockScalingFlip",
          ]);
          propertiesToInclude = (propertiesToInclude || []).concat([
            "minScale",
          ]);
          propertiesToInclude = (propertiesToInclude || []).concat([
            "previousImageViewport",
          ]);
          propertiesToInclude = (propertiesToInclude || []).concat([
            "transparentCorners",
          ]);
          propertiesToInclude = (propertiesToInclude || []).concat([
            "__eventListeners",
          ]);
          propertiesToInclude = (propertiesToInclude || []).concat(["src"]);
          propertiesToInclude = (propertiesToInclude || []).concat(["_width"]);
          propertiesToInclude = (propertiesToInclude || []).concat(["_height"]);
          propertiesToInclude = (propertiesToInclude || []).concat(["id2"]);
          propertiesToInclude = (propertiesToInclude || []).concat([
            "vptwidth",
          ]);
          propertiesToInclude = (propertiesToInclude || []).concat([
            "vptheight",
          ]);
          propertiesToInclude = (propertiesToInclude || []).concat(["_widtha"]);
          propertiesToInclude = (propertiesToInclude || []).concat([
            "_heighta",
          ]);
          propertiesToInclude = (propertiesToInclude || []).concat([
            "pre_clip",
          ]);
          propertiesToInclude = (propertiesToInclude || []).concat([
            "toBeScaleX_",
          ]);
          propertiesToInclude = (propertiesToInclude || []).concat([
            "toBeScaleY_",
          ]);
          propertiesToInclude = (propertiesToInclude || []).concat([
            "originalText",
          ]);
          propertiesToInclude = (propertiesToInclude || []).concat([
            "uppercaseText",
          ]);
          propertiesToInclude = (propertiesToInclude || []).concat([
            "uppercase",
          ]);
          propertiesToInclude = (propertiesToInclude || []).concat(["lock"]);
          propertiesToInclude = (propertiesToInclude || []).concat([
            "templateView",
          ]);
          return toObject.apply(this, [propertiesToInclude]);
        };
      })(fabric.Object.prototype.toObject);

      canvas.on("object:modified", (e) => {
        // reset the offset
        const modifiedObject = e.target;

        if (modifiedObject.__type === "shape") {
          positionOffsetShape = 0;
          shapeCount = 0;
        } else if (modifiedObject.__type === "cutout") {
          positionOffsetCutout = 0;
          cutoutCount = 0;
        } else if (modifiedObject.__type === "stencil_frame") {
          positionOffsetStencil = 0;
          stencilCount = 0;
        } else if (modifiedObject.__type === "text") {
          positionOffsetText = 0;
          textCount = 0;
        } else if (modifiedObject.typeis === "image") {
          positionOffsetImg = 0;
          imageCount = 0;
        } else if (modifiedObject._type === "stencil_frame") {
          positionOffsetStencil = 0;
          stencilCount = 0;
          stencilstateupdate(modifiedObject);
        }
        // const objectmodifiedlistener = canvas.__eventListeners["object:modified"];
        let allListeners = [
          "object:added",
          "object:modified",
          "object:removed",
          "object:moving",
          "object:scaling",
          "object:rotating",
          "selection:created",
          "selection:updated",
          "selection:cleared",
          "mouse:up",
          "mouse:over",
          "mouse:out",
          "mouse:move",
          "mouse:down",
          "mouse:dblclick",
          "before:transform",
          "before:render",
          "after:render",
        ];
        let listenerLength = [];
        allListeners.map((listenerss) => {
          const listenerName = canvas.__eventListeners[listenerss];
          const functionNames =
            listenerName &&
            listenerName.length > 1 &&
            listenerName.map((listener) => listener.name);
          listenerLength.push({
            listenerName: listenerss,
            functionName: functionNames,
          });
        });
        console.log("event listener", listenerLength);
        // Get the names of functions used for 'selection:updated' event

        if (canvas.crop_mode) {
          console.log("cannot save state cropmode ison");
          return;
        } else {
          console.log("saving state--->", e.target);
          setTimeout(() => {
            canvas.savestate();
            canvas.renderAll();
          }, 50);
        }
      });

      ///////////////////////////FOR LAYERING //////////////////////////
      let from;
      const Dragging_towards_upward = (ui) => {
        let obj_index_in_canvas = canvas
          .getObjects()
          .indexOf(getObjectById(canvas, ui.item.attr("id").split("_")[0]));
        let obj_to_move = canvas.getObjects()[obj_index_in_canvas];

        if (obj_to_move.type == "image" && obj_to_move.imageRect) {
          const layerDiv = document.querySelector(".layringdiv");
          let j = layerDiv.children[ui.item.index()];
          let upper_of_j = layerDiv.children[ui.item.index() - 1];
          let style_upper_j = upper_of_j && upper_of_j.style.display;
          if (style_upper_j && style_upper_j == "none") {
            layerDiv.insertBefore(j, layerDiv.children[ui.item.index() - 1]);
          }
          //if element of (ui.item.index()-1) ka style.display = 'none' then element of ui.tem.index() ko move krdo (ui.item.index()-1) ki jagah pe
          obj_to_move.moveTo(
            $(".layeringdiv_div").length - 1 - ui.item.index()
          );
          obj_to_move.imageRect.moveTo(
            $(".layeringdiv_div").length - 1 - ui.item.index()
          );
          let img_rect_layer_id = obj_to_move.imageRect.layer_id;
          let img_rect_div = document.getElementById(
            `${img_rect_layer_id}_${obj_to_move.imageRect.type}_layer_div`
          );
          layerDiv.insertBefore(
            img_rect_div,
            layerDiv.children[ui.item.index()]
          );
        } else {
          const layerDiv = document.querySelector(".layringdiv");
          let j = layerDiv.children[ui.item.index()];
          let upper_of_j = layerDiv.children[ui.item.index() - 1];
          let style_upper_j = upper_of_j && upper_of_j.style.display;
          if (style_upper_j && style_upper_j == "none") {
            layerDiv.insertBefore(j, layerDiv.children[ui.item.index() - 1]);
          }

          obj_to_move.moveTo(
            $(".layeringdiv_div").length - 1 - ui.item.index()
          );
          canvas.renderAll();
        }
      };
      const Dragging_towards_downward = (ui) => {
        let obj_index_in_canvas = canvas
          .getObjects()
          .indexOf(getObjectById(canvas, ui.item.attr("id").split("_")[0]));
        let obj_to_move = canvas.getObjects()[obj_index_in_canvas];

        if (obj_to_move.type == "image" && obj_to_move.imageRect) {
          obj_to_move.moveTo(
            $(".layeringdiv_div").length - 1 - ui.item.index()
          );
          obj_to_move.imageRect.moveTo(
            $(".layeringdiv_div").length - 1 - ui.item.index() + 1
          );

          const layerDiv = document.querySelector(".layringdiv");
          let img_rect_layer_id = obj_to_move.imageRect.layer_id;

          let img_rect_div = document.getElementById(
            `${img_rect_layer_id}_${obj_to_move.imageRect.type}_layer_div`
          );
          layerDiv.insertBefore(
            img_rect_div,
            layerDiv.children[ui.item.index()]
          );
        } else {
          obj_to_move.moveTo(
            $(".layeringdiv_div").length - 1 - ui.item.index()
          );
          canvas.renderAll();
        }
      };

      const Dragging_layer_update = (ui) => {
        let to = ui.item.index();
        if (from < to) {
          // downward moving
          Dragging_towards_downward(ui);
        } else {
          // upward moving
          Dragging_towards_upward(ui);
        }
      };

      $(".layringdiv").sortable({
        axis: "y",
        items: ".layeringdiv_div:not(.ui-state-disabled)",
        cursor: "grabbing",
        tolerance: "pointer",
        start: function (e, ui) {
          from = ui.item.index();
        },
        stop: function (e, ui) {
          // console.log("stop sorting", e, ui)
        },
        update: function (e, ui) {
          // console.log("update sorting", e, ui)

          Dragging_layer_update(ui);
        },
      });

      if (window.innerWidth <= 991) {
      } else {
        $(".all_page_div").sortable({
          axis: "x",
          // items: ".all_page_div:not(.ui-state-disabled)",
          cursor: "grabbing",
          tolerance: "pointer",
          start: function (e, ui) {
            // from = ui.item.index()
          },
          stop: function (e, ui) {
            // console.log("stop sorting", e, ui)
          },
          update: function (e, ui) {
            let index = canvas._pages.findIndex(
              (obj) => obj.pageId === ui.item.attr("id").split("_")[0]
            ); // Find the index of the object with pageid equal to the div is up for sorted
            let obj = canvas._pages[index]; // Get the object
            canvas._pages.splice(index, 1); // Remove the object at index
            canvas._pages.splice(ui.item.index(), 0, obj); // Insert the object at index where div is placed after sorted
            ui.item[0].click(); // click the div to open the same page that is sorted
          },
        });
      }
      let closing_id = null;
      const hideingcode = (e) => {
        // for layer menu hiding
        if (document.getElementsByClassName("layer_menu_outer_div")[0]) {
          if (
            document
              .getElementsByClassName("layer_menu_outer_div")[0]
              .contains(e.target)
          ) {
            console.log("under hai ");
          } else {
            if (
              document
                .getElementById(
                  document.querySelector(
                    '[id^="' +
                      document
                        .getElementsByClassName("layer_menu_outer_div")[0]
                        .id.split("_")[0] +
                      '"]'
                  ).id
                )
                .parentElement.contains(e.target)
            ) {
              return;
            }

            console.log("bahar  hai ");
            let k = document.getElementsByClassName("layer_menu_outer_div")[0];
            k.style.display = "none";
            k.id = null;
            document.removeEventListener("mousedown", hideingcode, false);
          }
        }

        // for page menu hiding
      };
      const setActiveObjectinCanvas = function (e) {
        let handelPointerBtn = document.getElementById("handelPointerBtn");
        handelPointerBtn && handelPointerBtn.click();
        // console.log("clikednow", e.target.id.split("_")[0])
        let elem_id = e.target.id;
        let layer_id = elem_id && elem_id.split("_")[0];

        let todoActive_obj = getObjectById(canvas, layer_id);

        if (todoActive_obj && todoActive_obj.imageRect) {
          canvas.setActiveObject(todoActive_obj.imageRect);
        } else if (todoActive_obj && todoActive_obj.selectable) {
          canvas.setActiveObject(todoActive_obj);
        }
        canvas.renderAll();
      };
      const togglelayermenu = function (e) {
        let cur_id = e.target.id.split("_")[0];
        let layer_menu_div = document.getElementsByClassName(
          "layer_menu_outer_div"
        )[0];
        let r = document.getElementsByClassName("right-panel")[0];
        let top = e.clientY;
        let active_obj = getObjectById(canvas, cur_id); // canvas.getActiveObject();
        let BringtoFront = document.getElementById("BringtoFront");
        let BringForward = document.getElementById("BringForward");
        let SendBackward = document.getElementById("SendBackward");
        let SendToBack = document.getElementById("SendToBack");
        if (active_obj && active_obj.type == "image" && active_obj.imageRect) {
          if (
            (canvas.getObjects().indexOf(active_obj) == 2 &&
              canvas.getObjects().indexOf(active_obj.imageRect) == 1) ||
            (canvas.getObjects().indexOf(active_obj) == 1 &&
              canvas.getObjects().indexOf(active_obj.imageRect) == 2)
          ) {
            SendBackward.classList.add("disable");
            SendToBack.classList.add("disable");
          } else if (canvas.croping_active_img === active_obj) {
            SendBackward.classList.add("disable");
            SendToBack.classList.add("disable");
          } else {
            SendBackward.classList.remove("disable");
            SendToBack.classList.remove("disable");
          }

          if (
            (canvas.getObjects().indexOf(active_obj) ==
              canvas.getObjects().length - 1 &&
              canvas.getObjects().indexOf(active_obj.imageRect) ==
                canvas.getObjects().length - 2) ||
            (canvas.getObjects().indexOf(active_obj) ==
              canvas.getObjects().length - 2 &&
              canvas.getObjects().indexOf(active_obj.imageRect) ==
                canvas.getObjects().length - 1)
          ) {
            BringtoFront.classList.add("disable");
            BringForward.classList.add("disable");
          } else if (canvas.croping_active_img === active_obj) {
            BringtoFront.classList.add("disable");
            BringForward.classList.add("disable");
          } else {
            BringtoFront.classList.remove("disable");
            BringForward.classList.remove("disable");
          }
        } else if (
          active_obj &&
          active_obj.id &&
          active_obj.id === "_cropArea"
        ) {
          SendBackward.classList.add("disable");
          SendToBack.classList.add("disable");
          BringtoFront.classList.add("disable");
          BringForward.classList.add("disable");
        } else {
          if (canvas.getObjects().indexOf(active_obj) == 1) {
            SendBackward.classList.add("disable");
            SendToBack.classList.add("disable");

            // SendBackward.style.display = 'none';
            // SendToBack.style.display = 'none';
            // SendToBack.setAttribute("disabled", true);
            // SendBackward.setAttribute("disabled", true);
          } else {
            SendBackward.classList.remove("disable");
            SendToBack.classList.remove("disable");

            // SendToBack.setAttribute("disabled", false);
            // SendBackward.setAttribute("disabled", false);
            // SendToBack.disabl
          }
          if (
            canvas.getObjects().indexOf(active_obj) ==
            canvas.getObjects().length - 1
          ) {
            BringtoFront.classList.add("disable");
            BringForward.classList.add("disable");
          } else {
            BringtoFront.classList.remove("disable");
            BringForward.classList.remove("disable");
          }
        }

        if (layer_menu_div.id) {
          let iddddd = layer_menu_div.id.split("_")[0];
          if (iddddd == cur_id) {
            layer_menu_div.style.display = "none";
            layer_menu_div.id = null;
            // console.log("layer_menu_div.id", "samedivclicked")
          } else {
            // console.log("layer_menu_div.id", "samedivnoooooootclicked")
            layer_menu_div.style.display = "block";
            layer_menu_div.style.top = `${top - 10}px`;
            layer_menu_div.style.left = `${r.offsetLeft - 263}px`;
            layer_menu_div.id = `${cur_id}_layer_menu_outer_div`;

            document.addEventListener("mousedown", hideingcode, false);
          }
        } else {
          // console.log("layer_menu_div.id no id present")
          layer_menu_div.style.display = "block";
          layer_menu_div.style.top = `${top - 10}px`;
          layer_menu_div.style.left = `${r.offsetLeft - 263}px`;
          layer_menu_div.id = `${cur_id}_layer_menu_outer_div`;
          document.addEventListener("mousedown", hideingcode, false);
        }
      };

      canvas.on("object:added", (e) => {
        handleObjectAdded(e)
          .then(() => {
            // Handle success if needed
          })
          .catch((error) => {
            console.error("error adding layer", error);
            // Handle errors if needed
          });
      });
      function handleObjectAdded(e) {
        return new Promise((resolve, reject) => {
          let handelPointerBtn = document.getElementById("handelPointerBtn");
          handelPointerBtn && handelPointerBtn.click();
          AddFilterInImage(e, canvas);
          AddLayer(e);
          resolve();
        });
      }
      const AddLayer = (e) => {
        if (e.target.type == "loaderFrame") {
          canvas.loading = true;
        }
        // else if (e.target.mtype && e.target.mtype == 'ViewPort') {
        //
        //     return
        // }
        else {
          e.target.layer_id = getRandomID(36);
          let container = document.getElementsByClassName("layringdiv")[0];
          let new_div = document.createElement("div");
          new_div.id = `${e.target.layer_id}_${e.target.type}_layer_div`;
          new_div.classList.add("layeringdiv_div");
          let firstChild = container.firstChild;
          container.insertBefore(new_div, firstChild);
          let src = e.target.toDataURL();
          let img = document.createElement("img");
          img.classList.add("layeringdiv_img");
          img.id = `${e.target.layer_id}_${e.target.type}_layer_img`;
          img.alt = "layer_img";
          img.src = src;
          new_div.appendChild(img);
          ////////////
          let threedotdiv = document.createElement("div");

          threedotdiv.id = `${e.target.layer_id}_${e.target.type}_layer_div_threedot`;
          threedotdiv.classList.add("moreBTN_before");
          threedotdiv.innerHTML = `<div class="moreBTN" id= ${e.target.layer_id}_${e.target.type}_layer_div_moreBTN>
          <svg width=10px height=10px viewBox="0 0 1024 1024" id= ${e.target.layer_id}_${e.target.type}_layer_div_threedot_svg>
          <path id= ${e.target.layer_id}_${e.target.type}_layer_div_threedot_path fill="#fff" d="M249.6 512c0 70.4-57.6 128-128 128S0 582.4 0 512s57.6-128 128-128 121.6 57.6 121.6 128z m384 0c0 70.4-57.6 128-128 128S384 582.4 384 512s57.6-128 128-128 121.6 57.6 121.6 128z m384 0c0 70.4-57.6 128-128 128s-128-57.6-128-128 57.6-128 128-128 128 57.6 128 128z"></path>  </svg><div>`;

          new_div.appendChild(threedotdiv);

          //// adding event listener
          new_div.addEventListener("click", setActiveObjectinCanvas, false);
          threedotdiv.addEventListener("click", togglelayermenu, false);
          // threedotdiv
          if (
            e.target.type == "rect" &&
            e.target.typeis &&
            e.target.typeis == "image"
          ) {
            new_div.style.display = "none";
          }
          if (e.target.mtype && e.target.mtype == "ViewPort") {
            let bg_bottom = document.createElement("div");
            bg_bottom.classList.add("bg_bottom");
            bg_bottom.innerHTML = "Background1";
            new_div.classList.add("ui-state-disabled");
            new_div.classList.add("background_layer");
            new_div.appendChild(bg_bottom);
          }
        }

        if (!canvas.crop_mode) {
          if (e.target.id !== "_cropArea") {
            Update_Currentpage_View_Src_status(canvas); /////// updating src of pageview of canvas when object added /////////
          }
        }
      };
      canvas.on("object:modified", (e) => {
        handleObjectModified(e)
          .then(() => {
            // Handle success if needed
          })
          .catch((error) => {
            console.error("error adding layer", error);
            // Handle errors if needed
          });
      });
      const handleObjectModified = (e) => {
        return new Promise((resolve, reject) => {
          UpdateLayer(e);
          resolve();
        });
      };
      const UpdateLayer = (e) => {
        let id_div;
        let id_img;
        let modified_src;
        if (e.target.type == "loaderFrame") {
          return;
        } else if (e.target.type == "rect" && e.target.original_img) {
          id_div = `${e.target.original_img.layer_id}_${e.target.original_img.type}_layer_div`;
          id_img = `${e.target.original_img.layer_id}_${e.target.original_img.type}_layer_img`;
          modified_src = e.target.original_img.toDataURL();
        } else {
          id_div = `${e.target.layer_id}_${e.target.type}_layer_div`;
          id_img = `${e.target.layer_id}_${e.target.type}_layer_img`;
          modified_src = e.target.toDataURL();
        }
        let layer_img = document.getElementById(id_img);
        let layer_div = document.getElementById(id_div);
        layer_img && (layer_img.src = modified_src);
        if (!canvas.crop_mode) {
          Update_Currentpage_View_Src_status(canvas); /////// updating src of pageview of canvas when object modified /////////
        }
        check_CornerOverlapping(e.target, canvas);
      };
      canvas.on("object:removed", (e) => {
        if (e.target.type == "loaderFrame") {
          canvas.loading = false;
          // return
        }
        // else if (e.target.type == 'rect' && e.target.original_img) {
        //     let id = `${e.target.layer_id}_${e.target.type}_image_layer_div`
        //     let elem = document.getElementById(id)
        //     elem && elem.remove()
        //     // return
        // }
        // else if (e.target.mtype && e.target.mtype == 'ViewPort') {

        //     // return
        // }
        else {
          let id = `${e.target.layer_id}_${e.target.type}_layer_div`;
          let threedotdiv_id = `${e.target.layer_id}_${e.target.type}_layer_div_threedot`;
          let elem = document.getElementById(id);
          let threedotdiv_elem = document.getElementById(threedotdiv_id);

          elem &&
            elem.removeEventListener("click", setActiveObjectinCanvas, false);
          threedotdiv_elem &&
            threedotdiv_elem.removeEventListener(
              "click",
              togglelayermenu,
              false
            );
          elem && elem.remove();
          if (e.target.mtype && e.target.mtype == "ViewPort") {
            //     // return
          }

          // elem.
        }
        Update_Currentpage_View_Src_status(canvas); /////// updating src of pageview of canvas when object added /////////
      });
      canvas.on("selection:created", (e) => {
        if (e.target.type == "loaderFrame") {
          return;
        } else if (e.target.type == "rect" && e.target.original_img) {
          let id = `${e.target.original_img.layer_id}_${e.target.original_img.type}_layer_div`;
          let j = document.getElementById(id);
          document
            .querySelectorAll(".layeringdiv_div.active")
            .forEach(function (c) {
              c.classList.remove("active");
            });
          j && j.classList.add("active");
        } else if (e.target.type == "activeSelection") {
          document
            .querySelectorAll(".layeringdiv_div.active")
            .forEach(function (c) {
              c.classList.remove("active");
            });
          e.target.getObjects().forEach((f) => {
            if (f.layer_id) {
              let id = `${f.layer_id}_${f.type}_layer_div`;
              let j = document.getElementById(id);
              j && j.classList.add("active");
            }
          });
        } else if (e.target.group) {
          let id = `${e.target.group.layer_id}_${e.target.group.type}_layer_div`;
          let j = document.getElementById(id);
          document
            .querySelectorAll(".layeringdiv_div.active")
            .forEach(function (c) {
              c.classList.remove("active");
            });

          j && j.classList.add("active");
          return;
        } else {
          let id = `${e.target.layer_id}_${e.target.type}_layer_div`;
          let j = document.getElementById(id);
          document
            .querySelectorAll(".layeringdiv_div.active")
            .forEach(function (c) {
              c.classList.remove("active");
            });
          j && j.classList.add("active");
        }
        if (!isMobile) {
          let idss = [
            "FontColorPicker",
            "FontSize",
            "FontFormat",
            "Spacing",
            "FontFamily",
            "TetxEffect",
            "ImageFilters",
            "Image_Adjustment",
            "shape_Adjustment",
            "stroke_Adjustment",
            "line_Adjustment",
            "colorChange",
            "BackgroundCategories",
            "Flip_object",
            "transparency",
            "Align",
          ];
          idss.forEach((id) => {
            let htmlelement = document.getElementById(id);
            if (htmlelement?.style.display == "block") {
              htmlelement.style.display = "none";
              if (isMobile) {
                $(".plusTemplate").css("display", "block");
                $(".backDashboard").css("display", "none");
              }
            } else if (
              htmlelement?.style.display == "none" &&
              htmlelement?.classList.contains("on")
            ) {
              if (e.deselected[0].type === e.selected[0].type) {
              } else {
                htmlelement.classList.remove("on");
              }
            }
          });
        }
        const elemPage = document.getElementById("edp-icon");

        elemPage.classList.add("edp-icon-active");
      });
      canvas.on("selection:updated", (e) => {
        if (e.target.type == "loaderFrame") {
          return;
        } else if (e.target.group) {
          let id = `${e.target.group.layer_id}_${e.target.group.type}_layer_div`;
          let j = document.getElementById(id);
          document
            .querySelectorAll(".layeringdiv_div.active")
            .forEach(function (c) {
              c.classList.remove("active");
            });

          j && j.classList.add("active");
          return;
        } else if (
          e.target.type == "rect" &&
          e.target.original_img &&
          !e.target.group
        ) {
          let id = `${e.target.original_img.layer_id}_${e.target.original_img.type}_layer_div`;
          let j = document.getElementById(id);
          document
            .querySelectorAll(".layeringdiv_div.active")
            .forEach(function (c) {
              c.classList.remove("active");
            });
          j && j.classList.add("active");
        } else if (e.target.type == "activeSelection") {
          document
            .querySelectorAll(".layeringdiv_div.active")
            .forEach(function (c) {
              c.classList.remove("active");
            });
          e.target.getObjects().forEach((f) => {
            if (f.layer_id) {
              let id = `${f.layer_id}_${f.type}_layer_div`;
              let j = document.getElementById(id);
              j && j.classList.add("active");
            }
          });
        } else {
          let id = `${e.target.layer_id}_${e.target.type}_layer_div`;
          let j = document.getElementById(id);
          document
            .querySelectorAll(".layeringdiv_div.active")
            .forEach(function (c) {
              c.classList.remove("active");
            });

          j && j.classList.add("active");
        }
        if (!isMobile) {
          let idss = [
            "FontColorPicker",
            "FontSize",
            "FontFormat",
            "Spacing",
            "FontFamily",
            "TetxEffect",
            "ImageFilters",
            "Image_Adjustment",
            "shape_Adjustment",
            "stroke_Adjustment",
            "line_Adjustment",
            "colorChange",
            "BackgroundCategories",
            "Flip_object",
            "transparency",
            "Align",
          ];
          idss.forEach((id) => {
            let htmlelement = document.getElementById(id);
            if (htmlelement?.style.display == "block") {
              htmlelement.style.display = "none";
              if (isMobile) {
                $(".plusTemplate").css("display", "block");
                $(".backDashboard").css("display", "none");
              }
            } else if (
              htmlelement?.style.display == "none" &&
              htmlelement?.classList.contains("on")
            ) {
              if (e.deselected[0].type === e.selected[0].type) {
              } else {
                htmlelement.classList.remove("on");
              }
            }
          });
        }
        // updateLeftSectionScroll();
      });

      canvas.on("selection:cleared", function (e) {
        document
          .querySelectorAll(".layeringdiv_div.active")
          .forEach(function (c) {
            c.classList.remove("active");
          });
        if (!isMobile) {
          let idss = [
            "FontColorPicker",
            "FontSize",
            "FontFormat",
            "Spacing",
            "FontFamily",
            "TetxEffect",
            "ImageFilters",
            "Image_Adjustment",
            "shape_Adjustment",
            "stroke_Adjustment",
            "line_Adjustment",
            "colorChange",
            "BackgroundCategories",
            "Flip_object",
            "transparency",
            "Align",
          ];
          idss.forEach((id) => {
            let htmlelement = document.getElementById(id);
            if (htmlelement?.style.display == "block") {
              htmlelement.style.display = "none";
              if (isMobile) {
                $(".plusTemplate").css("display", "block");
                $(".backDashboard").css("display", "none");
              }
            } else if (
              htmlelement?.style.display == "none" &&
              htmlelement?.classList.contains("on")
            ) {
              htmlelement.classList.remove("on");
            }
          });
        }
        updateLeftSectionScroll();
      });

      fabric.Textbox = fabric.util.createClass(fabric.Textbox, {
        type: "textbox",
        // fromObject: function (object, callback) {
        //   return fabric.Object._fromObject('Textbox', object, callback, 'text');
        // },
        // Override the `_render` method to add multiple shadows
        _render: function (ctx) {
          // Render the text normally first with the default rendering
          this.callSuper("_render", ctx);

          // Iterate through each shadow and apply it
          if (this.shadows && this.shadows.length > 0) {
            // Since we've already rendered the text once, we now want to add shadows.
            // To do this, we'll save the current state, apply the shadow, and then restore for the next shadow.
            this.shadows.forEach((shadow) => {
              ctx.save(); // Save the current context state

              // Apply shadow only if the type is 'shadow'
              if (shadow.type === "shadow") {
                // Set shadow properties from the shadow object
                ctx.shadowColor = shadow.color || "black";
                ctx.shadowOffsetX = shadow.offsetX || 0;
                ctx.shadowOffsetY = shadow.offsetY || 0;
                ctx.shadowBlur = shadow.blur || 0;

                // Now, re-render the text with the shadow
                // We use the 'fillText' method instead of the default render method to avoid affecting the object state
                ctx.fillText(this.text, -this.width / 2, -this.height / 2); // Adjust the position if needed
              }

              ctx.restore(); // Restore the context to its state before the shadow was applied
            });
          }
        },

        _render: function (ctx) {
          this.callSuper("_render", ctx);

          // Iterate through each shadow and apply it
          if (this.shadows && this.shadows.length > 0) {
            for (let i = 0; i < this.shadows.length; i++) {
              ctx.save();
              if (this.shadows[i].type === "shadow") {
                const shadow = this.shadows[i];
                ctx.shadowColor = shadow.color || "black";
                ctx.shadowOffsetX = shadow.offsetX || 0;
                ctx.shadowOffsetY = shadow.offsetY || 0;
                ctx.shadowBlur = shadow.blur || 0;
                /*  ctx.fillText(this.text, 0,0); */
                /* ctx.strokeText(this.text, 0,0); */
                ctx.translate(0, 0);

                this.callSuper("_render", ctx);
              } else {
                const shadow = this.shadows[i];
                ctx.shadowColor = null;
                ctx.shadowOffsetX = 0;
                ctx.shadowOffsetY = 0;
                ctx.shadowBlur = 0;
                /*  ctx.fillText(this.text, 0,0); */
                /* ctx.strokeText(this.text, 0,0); */
                ctx.translate(0, 0);
                this.callSuper("_render", ctx);
              }
              ctx.restore();
            }
          } else {
            ctx.shadowColor = "rgba(0,0,0,0)";
          }
        },

        _renderTextLinesBackground: function (ctx) {
          if (
            !this.textBackgroundColor &&
            !this.styleHas("textBackgroundColor")
          ) {
            return;
          }
          var heightOfLine,
            lineLeftOffset,
            originalFill = ctx.fillStyle,
            line,
            lastColor,
            leftOffset = this._getLeftOffset(),
            lineTopOffset = this._getTopOffset(),
            boxStart = 0,
            boxWidth = 0,
            charBox,
            currentColor,
            path = this.path,
            drawStart;

          for (var i = 0, len = this._textLines.length; i < len; i++) {
            heightOfLine = this.getHeightOfLine(i);
            if (
              !this.textBackgroundColor &&
              !this.styleHas("textBackgroundColor", i)
            ) {
              lineTopOffset += heightOfLine;
              continue;
            }
            line = this._textLines[i];
            lineLeftOffset = this._getLineLeftOffset(i);
            boxWidth = 0;
            boxStart = 0;
            lastColor = this.getValueOfPropertyAt(i, 0, "textBackgroundColor");
            for (var j = 0, jlen = line.length; j < jlen; j++) {
              charBox = this.__charBounds[i][j];
              currentColor = this.getValueOfPropertyAt(
                i,
                j,
                "textBackgroundColor"
              );
              if (path) {
                ctx.save();
                ctx.translate(charBox.renderLeft, charBox.renderTop);
                ctx.rotate(charBox.angle);
                ctx.fillStyle = currentColor;
                currentColor &&
                  ctx.fillRect(
                    -charBox.width / 2,
                    (-heightOfLine / this.lineHeight) *
                      (1 - this._fontSizeFraction),
                    charBox.width,
                    heightOfLine / this.lineHeight
                  );
                ctx.restore();
              } else if (currentColor !== lastColor) {
                drawStart = leftOffset + lineLeftOffset + boxStart;
                if (this.direction === "rtl") {
                  drawStart = this.width - drawStart - boxWidth;
                }
                ctx.fillStyle = lastColor;
                lastColor &&
                  ctx.fillRect(
                    drawStart,
                    lineTopOffset,
                    boxWidth,
                    heightOfLine / this.lineHeight
                  );
                boxStart = charBox.left;
                boxWidth = charBox.width;
                lastColor = currentColor;
              } else {
                boxWidth += charBox.kernedWidth;
              }
            }
            if (currentColor && !path) {
              drawStart = leftOffset + lineLeftOffset + boxStart;
              if (this.direction === "rtl") {
                drawStart = this.width - drawStart - boxWidth;
              }
              ctx.fillStyle = currentColor;
              // ctx.fillRect(
              //   drawStart,
              //   lineTopOffset,
              //   boxWidth,
              //   heightOfLine / this.lineHeight
              // );
              ctx.beginPath();

              ctx.roundRect(
                drawStart -
                  (this.highlighterThickness
                    ? this.highlighterThickness / 2
                    : 0),
                lineTopOffset -
                  (this.highlighterThickness
                    ? this.highlighterThickness / 2
                    : 0),
                boxWidth +
                  (this.highlighterThickness ? this.highlighterThickness : 0),
                heightOfLine / this.lineHeight +
                  (this.highlighterThickness ? this.highlighterThickness : 0),
                [this.highlighterRadius]
              );
              ctx.fill();
            }
            lineTopOffset += heightOfLine;
          }
          ctx.fillStyle = originalFill;
          // if there is text background color no
          // other shadows should be casted
          this._removeShadow(ctx);
        },
        toObject: function (propertiesToInclude) {
          return this.callSuper(
            "toObject",
            ["minWidth", "splitByGrapheme"].concat(propertiesToInclude)
          );
        },
        fromObject: function (object, callback) {
          // Create a new instance of your custom Textbox class
          var textbox = new fabric.Textbox(object.text, object);

          // Call the callback with the created instance
          if (typeof callback === "function") {
            callback(textbox);
          }
        },
      });

      fabric.Textbox.fromObject = function (object, callback) {
        return fabric.Object._fromObject("Textbox", object, callback, "text");
      };
      fabric.util.object.extend(fabric.Textbox.prototype, {
        // curvedText: function () {
        //
        //   let canvas = this.canvas;
        //   function drawTextOnArc(
        //     text,
        //     radius,
        //     centerX,
        //     centerY,
        //     startAngle,
        //     endAngle
        //   ) {
        //     const angleStep = (endAngle - startAngle) / text.length;
        //     //const canvas = this.canvas//new fabric.Canvas('myCanvas');

        //     for (let i = 0; i < text.length; i++) {
        //       const angle = endAngle - i * angleStep; // Reverse the angle to start from second quadrant
        //       const x = centerX + radius * Math.cos(angle);
        //       const y = centerY + radius * Math.sin(angle);

        //       const textObject = new fabric.Text(text[i], {
        //         left: x,
        //         top: y,
        //         fontFamily: "Arial",
        //         fontSize: 20,
        //         angle: angle * (180 / Math.PI) + 90, // Convert radians to degrees and add 90 degrees to rotate the text correctly
        //       });

        //       canvas.add(textObject);
        //     }
        //   }
        //   const arcText = "Add a heading";
        //   //const canvas = this.canvas//document.getElementById('myCanvas');
        //   const centerX = canvas.width / 2;
        //   const centerY = canvas.height / 2;
        //   const radius = 150;
        //   const startAngle = -Math.PI / 4; // Start drawing from the second quadrant (45 degrees)
        //   const endAngle = (-3 * Math.PI) / 4;
        //   drawTextOnArc(
        //     arcText,
        //     radius,
        //     centerX,
        //     centerY,
        //     startAngle,
        //     endAngle
        //   );
        // },
        // initDimensions: function () {
        //   var e = this.getScaledHeight();
        //   if (!this.__skipDimension) {
        //     this.isEditing && this.initDelayedCursor(),
        //       this.clearContextTop(),
        //       this._clearCache(),
        //       (this.dynamicMinWidth = 0),
        //       (this._styleMap = this._generateStyleMap(this._splitText())),
        //       this.dynamicMinWidth > this.width && this.fontReady,
        //       -1 !== this.textAlign.indexOf("justify") && this.enlargeSpaces(),
        //       (this.height = this.calcTextHeight());
        //     var t = this.path;
        //     if (
        //       t &&
        //       this._textLines &&
        //       this._textLines.length &&
        //       t.segmentsInfo
        //     ) {
        //
        //       var n = this.getScaledWidth(), //a.width
        //         i =
        //           this.path.height * this.path.scaleY +
        //           this.findLargestCharHeight(); //* this.scaleY//a.height;
        //       (this.width = n), (this.height = i);
        //     }
        //     this.saveState({
        //       propertySet: "_dimensionAffectingProps",
        //     }),
        //       !t && e !== this.getScaledHeight() && this.group
        //         ? (this.group.childHeightChanged(
        //           this,
        //           this.getScaledHeight() - e
        //         ),
        //           this.canvas && this.canvas.requestRenderAll())
        //         : this.group && (this.group.group || ae(this.group)),
        //       this.isInit && (this.saveState(), (this.isInit = !1));
        //   }
        // },
        // findLargestCharHeight: function () {
        //   const array = this.__lineHeights;
        //   const sum = array.reduce(
        //     (accumulator, currentValue) => accumulator + currentValue,
        //     0
        //   );
        //   return sum;
        // },
        // getLargestCharHeight: function (textbox) {
        //   if (!textbox || !textbox.text) return 0;

        //   const ctx = textbox.ctx || textbox.canvas.getContext("2d");
        //   const font = `${textbox.fontWeight} ${textbox.fontSize}px ${textbox.fontFamily}`;
        //   ctx.font = font;

        //   const textLines = textbox.text.split("\n");
        //   let largestCharHeight = 0;

        //   textLines.forEach((line) => {
        //     const lineWidth = ctx.measureText(line).width;
        //     const lineHeight = textbox.lineHeight * textbox.fontSize;
        //     const charsInLine = line.length;
        //     const averageCharWidth = lineWidth / charsInLine;
        //     const estimatedCharHeight = lineHeight / averageCharWidth;

        //     if (estimatedCharHeight > largestCharHeight) {
        //       largestCharHeight = estimatedCharHeight;
        //     }
        //   });

        //   return largestCharHeight;
        // },
        change_lineat_gradient_direction: function (value) {
          if (typeof this.fill === typeof {}) {
            this.set(
              "fill",
              new fabric.Gradient({
                angle: value,
                type: "linear",
                gradientUnits: "pixels", // or 'percentage'
                // coords: { x1: l.fill.coords.x1, y1: l.fill.coords.y1, x2: l.fill.coords.x2, y2: l.fill.coords.y2, },
                coords: {
                  x1:
                    (Math.round(
                      50 + Math.sin(-parseInt(value, 10) * (Math.PI / 180)) * 50
                    ) *
                      this.width) /
                    100,
                  y1:
                    (Math.round(
                      50 + Math.cos(-parseInt(value, 10) * (Math.PI / 180)) * 50
                    ) *
                      this.height) /
                    100,
                  x2:
                    (Math.round(
                      50 +
                        Math.sin(
                          -parseInt(value, 10) * (Math.PI / 180) + Math.PI
                        ) *
                          50
                    ) *
                      this.width) /
                    100,
                  y2:
                    (Math.round(
                      50 +
                        Math.cos(
                          -parseInt(value, 10) * (Math.PI / 180) + Math.PI
                        ) *
                          50
                    ) *
                      this.height) /
                    100,
                },
                colorStops: [
                  {
                    offset: this.fill.colorStops[0].offset,
                    color: this.fill.colorStops[0].color,
                  },
                  {
                    offset: this.fill.colorStops[1].offset,
                    color: this.fill.colorStops[1].color,
                  },
                ],
              })
            );
          } else {
            return;
          }
        },
      });

      // fabric.Textbox.fromObject = function (object, callback) {
      //   return fabric.Object._fromObject('Textbox', object, callback, 'text');
      // };

      initializeCanvasEventListeners(canvas);
      let a = canvas.getObjects().filter((o) => o.type_ === "background")[0];
      // console.log("aheadd")
      // canvas.savestate();
      return {
        ...state,
        canvas_: action.payload.value__,
        rectHeight: action.payload.clientHeight,
        rectWight: action.payload.clientWidth,
        bg_height_client: heightBox,
        bg_width_client: widthBox,
        framebox: a,
        background_type: "color",
        background_type_: {
          ...state.background_type,
          bg_type: "color",
          bg_fill: "#ffffff",
        },
        // zoom_: canvas.getZoom()
      };
    },
    updateSize: (state, action) => {
      return {
        ...state,
        rectHeight: action.payload.clientHeight, // Update the client height that comes in payload
        rectWight: action.payload.clientWidth, // Update the client height that comes in payload
        // framebox: action.payload.framebox
      };
      // dispatch()
    },
    update_Bg_Size: (state, action) => {
      return {
        ...state,
        bg_height_client: action.payload.bg_height_client, //Update the background height that comes in payload
        bg_width_client: action.payload.bg_width_client, // Update the background width that comes in payload
        // framebox: action.payload.framebox
      };
      // dispatch()
    },
    updateZoom: (state, action) => {
      // this reducer is responsible for updating the zoom value to update the current zoom level for alinging the guidelines.

      return {
        ...state,
        zoom: {
          ...state.after_zoom_detail,
          zoom_: action.payload.zoom__,
          html: action.payload.value,
        },
      };
    },
    // delete function to delete the object from canvas
    delete1: (state, action) => {
      // this reducer is responsible for deleting the object from canvas

      let canvasO = state.canvas_; // define canvas
      if (!canvasO.crop_mode) {
        if (action.payload.i) {
          if (action.payload.i === "element") {
            let Active_obj = canvasO.getActiveObject();
            let activegrp = Active_obj.group && Active_obj.group;

            console.log(Active_obj, "ObjectToBeDelete");
            if (Active_obj.original_img) {
              // trying to check object is image
              let All_obj = activegrp.getObjects();

              for (let i = 0; i < All_obj.length; i++) {
                if (All_obj[i].id === Active_obj.original_img.id) {
                  // console.log("delete---rawat remove", Active_obj.original_img);
                  activegrp.removeWithUpdate(All_obj[i]);
                  activegrp.removeWithUpdate(Active_obj);
                  canvasO.setActiveObject(activegrp);

                  canvasO.renderAll();
                }
              }
            } else {
              activegrp.removeWithUpdate(Active_obj);
              // removeWithUpdate
              // activegrp.setCoords()
              canvasO.setActiveObject(activegrp);
              canvasO.renderAll();
            }

            if (canvasO.getActiveObject()) {
              if (
                !canvasO.getActiveObject()._type &&
                canvasO.getActiveObject().type === "group"
              ) {
                if (canvasO.getActiveObject().size() == 1) {
                  activegrp.getObjects().forEach((f) => {
                    f.off("mouseover");
                    f.off("mouseout");
                    f.off("mousedown");
                    f.off("mouseup");
                  });
                  let j = activegrp.toActiveSelection();
                  // canvasO.discardActiveObject()
                  canvasO.setActiveObject(j.getObjects()[0]);
                  canvasO.renderAll();
                } else if (canvasO.getActiveObject().size() == 2) {
                  canvasO.getActiveObject().forEachObject((f) => {
                    if (f.original_img || f.imageRect) {
                      activegrp.getObjects().forEach((f) => {
                        f.off("mouseover");
                        f.off("mouseout");
                        f.off("mousedown");
                        f.off("mouseup");
                      });
                      let j = activegrp.toActiveSelection();
                      // canvasO.discardActiveObject()
                      {
                        f.original_img
                          ? canvasO.setActiveObject(f)
                          : f.imageRect;
                      }
                      // canvasO.setActiveObject(j.getObjects()[0])
                      canvasO.renderAll();
                    }
                  });
                }
              }
            }
            // Active
          } else if (action.payload.i === "whole_group") {
            let Active_obj =
              canvasO.getActiveObject().group &&
              canvasO.getActiveObject().group;
            canvasO.remove(Active_obj);
            canvasO.discardActiveObject();
            canvasO.renderAll();
          }
        } else {
          let Active_obj = canvasO.getActiveObject(); // get the active object

          // console.log(Active_obj, Active_obj.type);
          // console.log("delete---rawat", canvasO.getActiveObject());

          // if objects are not in group i.e objects are single selected not grouped
          if (Active_obj && !Active_obj._objects) {
            // if(Active_obj.group){
            //     Active_obj.group.remove(Active_obj)
            // }
            // else{
            if (Active_obj.original_img) {
              // trying to check object is image
              let All_obj = canvasO.getObjects();

              for (let i = 0; i < All_obj.length; i++) {
                if (All_obj[i].id === Active_obj.original_img.id) {
                  // console.log("delete---rawat remove", Active_obj.original_img);
                  canvasO.remove(All_obj[i]);
                  canvasO.remove(Active_obj);
                  canvasO.renderAll();
                }
              }
            } else if (Active_obj.type === "textbox") {
              if (Active_obj.isEditing) {
                return;
              } else {
                // console.log("delete---", Active_obj.type)

                canvasO.remove(Active_obj);
                canvasO.renderAll();
              }
              // console.log("textbox");
            } else if (Active_obj._type === "stencil_frame") {
              // if (
              //   Active_obj.getSrc() ==
              //     "http://localhost:8012/stencil_placeholder_img_resized.jpg" ||
              //   Active_obj.getSrc() ==
              //     "https://designsdk.firebaseapp.com/stencil_placeholder_img_resized.jpg" ||
              //   Active_obj.getSrc() ==
              //     "http://172.16.15.103:8012/stencil_placeholder_img_resized.jpg"
              // )

              if (Active_obj.placeholder == 0) {
                // bsase64b = [];
                canvasO.remove(Active_obj);
                canvasO.renderAll();
              } else {
                // bsase64b = [];
                let desireHeight =
                  Active_obj.clipPath.width * Active_obj.clipPath.scaleX;
                let desireWidth =
                  Active_obj.clipPath.height * Active_obj.clipPath.scaleY;

                fabric.Image.fromURL(
                  "/stencil_placeholder_img_resized.jpg",
                  function (img123) {
                    img123.scaleToWidth(desireWidth < 320 ? 320 : desireWidth);
                    bsase64 = img123.toDataURL({
                      format: "png",
                      quality: 1,
                      multiplier: 1,
                    });
                  }.bind(this),
                  {
                    crossOrigin: "anonymous",
                  }
                );
                Active_obj.clipPath.set({ fill: "#000" });
                setTimeout(() => {
                  Active_obj.setSrc(
                    `${bsase64}`,
                    function (img) {
                      img.set({
                        _widtha: desireWidth,
                        _heighta: desireHeight,
                        cropX: desireWidth / 2 - Active_obj.width / 2,
                        width: desireWidth,
                        cropY: desireHeight / 2 - Active_obj.height / 2,
                        height: desireHeight,
                        placeholder: 0,
                      });
                      canvasO.renderAll();
                      // if (img.allImages[img.allImages.length - 1] != bsase64) {
                      //   img.allImages.push(bsase64);
                      // }
                    }.bind(this),
                    {
                      crossOrigin: "anonymous",
                    }
                  );
                }, 20);
              }
            } else if (
              Active_obj &&
              Active_obj._type &&
              Active_obj._type === "stencil_frame"
            ) {
              // if (Active_obj._objects.length >= 3) {
              //   Active_obj.remove(Active_obj._objects[2]);
              //   canvasO.discardActiveObject();
              //   canvasO.setActiveObject(Active_obj);
              //   canvasO.renderAll();
              // } else {
              //   canvasO.remove(Active_obj);
              //   canvasO.renderAll();
              // }
            } else {
              canvasO.remove(Active_obj);
              canvasO.renderAll();
              // }
            }
          } else if (Active_obj && Active_obj._objects) {
            // to delete stencil
            // if (
            //   Active_obj &&
            //   Active_obj._type &&
            //   Active_obj._type === "stencil_frame"
            // ) {
            //   // if (Active_obj._objects.length >= 3) {
            //   //   Active_obj.remove(Active_obj._objects[2]);
            //   //   canvasO.discardActiveObject();
            //   //   canvasO.setActiveObject(Active_obj);
            //   //   canvasO.renderAll();
            //   // } else {
            //   //   canvasO.remove(Active_obj);
            //   //   canvasO.renderAll();
            //   // }
            // }

            if (Active_obj && Active_obj.type === "group") {
              canvasO.remove(Active_obj);
              canvasO.discardActiveObject();
              canvasO.renderAll();
            } else if (Active_obj && Active_obj.type === "activeSelection") {
              Active_obj.getObjects().map((f) => {
                // if (f._type === "line") {
                //   f.getObjects().forEach((i) => {
                //     f.remove(i);
                //   });
                //   canvasO.remove(f);
                //   canvasO.discardActiveObject();
                // }

                // else {
                canvasO.remove(f);
                // }

                // canvasO.renderAll();
              });
              canvasO.discardActiveObject();
              canvasO.renderAll();
            }
          } else if (Active_obj && Active_obj._type === "line") {
            canvasO.remove(Active_obj);
            canvasO.renderAll();

            // console.log("textbox");
          }
        }
      }
      return {
        ...state,
        // canvas_: action.payload.value
      };
    },
    update_undo_status: (state, action) => {
      return {
        ...state,
        undo_status_active: action.payload,
      };
    },
    update_redo_status: (state, action) => {
      return {
        ...state,
        redostatus: action.payload,
      };
    },
    update_layer_toolbar_status: (state, action) => {
      return {
        ...state,
        layer_toolbar_status: action.payload,
      };
    },
    udpate_layer_render: (state, action) => {
      return {
        ...state,
        layer_render: action.payload,
      };
    },
    udpate_background_type: (state, action) => {
      // this reducer is responsible for updating the background fill type to update the toolbar i.e. solid color, image , linear gradient, radial gradient
      return {
        ...state,
        background_type_: {
          ...state.background_type,
          bg_type: action.payload.bg_type,
          bg_fill: action.payload.bg_fill,
        },
      };
    },
    update_color_picker_state: (state, action) => {
      // this reducer is responsible for updating the colorpicker state to render and to proveide the exact color values in fontcolorpicker.js
      return {
        ...state,
        color_picker_state: {
          ...state.color_picker_state,
          fromwhichcomponent: action.payload.fromwhichcomponent,
          currentSlider: action.payload.currentSlider,
          current_color: action.payload.current_color,
        },
      };
    },
  },
});
// exporting all the reducers to use further in this project
export const {
  updateCanvas,
  update_fake_Canvas,
  updateSize,
  delete1,
  updateZoom,
  update_dragMode,
  update_undo_status,
  update_redo_status,
  update_layer_toolbar_status,
  udpate_layer_render,
  udpate_background_type,
  update_Bg_Size,
  update_color_picker_state,
} = Canvas_Slice.actions;

export const getcolor = (current_slider) => {
  return rgb2hex($(`#${current_slider}`).css("background-color"));
};
export function rgb2hex(rgb) {
  if (rgb) {
    if (/^#[0-9A-F]{6}$/i.test(rgb)) return rgb;

    rgb = rgb.match(/^rgb\((\d+),\s*(\d+),\s*(\d+)\)$/);
    function hex(x) {
      return ("0" + parseInt(x).toString(16)).slice(-2);
    }
    return "#" + hex(rgb[1]) + hex(rgb[2]) + hex(rgb[3]);
  }
}
export const LoaderFrame = (url, boxframe, canvas) => {
  canvas.selection = false;
  let w;
  if (url.from === "openImage") {
    w = new fabric.Rect({
      left: url.w && url.w ? boxframe.left + url.w / 4 / 2 : 100,
      top: url.w && url.w ? boxframe.top + boxframe.height / 4 : 100,
      width: url.w && url.w ? url.w : 100,
      height: url.w && url.h ? url.h : 100,
      fill: "rgba(0,0,0,0.3)",
      scaleX: url.w ? (boxframe.width - url.w / 4) / url.w : 1,
      scaleY: url.w ? (boxframe.width - url.w / 4) / url.w : 1,
    });
    w.left =
      url.left && url.left ? url.left - (w.width * w.scaleX) / 2 : w.left;
    w.top = url.top && url.top ? url.top - (w.height * w.scaleY) / 2 : w.top;
  } else if (url.from === "bgremoving") {
    w = new fabric.Rect({
      left: url.act_o.left,
      top: url.act_o.top,
      width: url.act_o.width,
      height: url.act_o.height,
      clipPath: url.act_o.clipPath,
      fill: "rgba(0,0,0,0.3)",
      scaleX: url.act_o.scaleX,
      scaleY: url.act_o.scaleY,
    });
    url.act_o.set({
      // selectable: false,
      // hasControls: false,
      // evented: false,
      isloading: true,
    });
    canvas.renderAll();

    // console.log("isloding in", url.act_o.isloading)
  } else if (url.from === "background") {
    w = new fabric.Rect({
      left: url.left,
      top: url.top,
      width: url.w,
      height: url.h,
      fill: "rgba(0,0,0,0.3)",
      lockMovementX: true,
      lockMovementY: true,
      lockRotation: true,
      lockScalingFlip: true,
      lockScalingX: true,
      lockScalingY: true,
      hasControls: false,
      selectable: false,

      // scaleX: boxframe.scaleX,
      // scaleY: boxframe.scaleY,
    });
  }

  const a = (abc) => {
    w.animate("opacity", abc, {
      duration: 400,
      onChange: canvas.renderAll.bind(canvas),
    });
  };

  var feku = setInterval(function () {
    var dimmer = setInterval(function () {
      a(0.5);
      setTimeout(function () {
        a(1);
        clearInterval(dimmer);
      }, 600);
    }, 1000);
  }, 1400);

  var loader = new fabric.Circle({
    left: w.left + (w.width * w.scaleX) / 2,
    top: w.top + (w.height * w.scaleY) / 2,
    radius: 15,
    startAngle: 0,
    endAngle: Math.PI,
    stroke: "#4BD3FB",
    strokeWidth: 3,
    fill: "",
    originX: "center",
    originY: "center",
  });
  // var gr = new fabric.Group([p, loader], {
  // });
  var g = new fabric.Group([w, loader], {});

  g.set({
    type: "loaderFrame",
    initiated_id: canvas.pageId,
  });

  // Add the loader to the canvas
  canvas.add(g);
  if (url.from == "background") {
    g.set({
      lockMovementX: true,
      lockMovementY: true,
      lockRotation: true,
      lockScalingFlip: true,
      lockScalingX: true,
      lockScalingY: true,
      hasControls: false,
      selectable: false,
    });
    canvas.moveTo(g, 1);
  }

  canvas.renderAll();
  g.on("moving", (e) => {
    // console.log("llllll", e)
    if (url.from === "bgremoving") {
      url.act_o.set({
        left: e.transform.target.left,
        top: e.transform.target.top,
        angle: e.transform.target.angle,
      });
      if (url.act_o.type === "rect" && url.act_o.original_img) {
        url.act_o.original_img.set({
          left: e.transform.target.left,
          top: e.transform.target.top,
          angle: e.transform.target.angle,
        });
      }
      canvas.renderAll();
    }
  });
  g.on("scaling", (e) => {
    if (url.from === "bgremoving") {
      url.act_o.set({
        left: e.transform.target.left,
        top: e.transform.target.top,
        angle: e.transform.target.angle,
        scaleX: e.transform.target.scaleX,
        scaleY: e.transform.target.scaleY,
      });
      if (url.act_o.type === "rect" && url.act_o.original_img) {
        url.act_o.original_img.set({
          left: e.transform.target.left,
          top: e.transform.target.top,
          angle: e.transform.target.angle,
          scaleX: e.transform.target.scaleX,
          scaleY: e.transform.target.scaleY,
        });
      }
      canvas.renderAll();
    } else if (url.from === "openImage") {
    }
  });
  // Set up a rotation animation for the loader
  var angle = 0;
  let ci = setInterval(function () {
    // console.log("feku")
    angle += 5;
    loader.set({
      angle: angle,
    });
    canvas.renderAll();
  }, 10);
  canvas.discardActiveObject();
  if (url.from == "background") {
  } else {
    canvas.setActiveObject(g);
  }
  let k = { g: g, f: feku, c: ci, initiated_id: g.initiated_id };
  return k;
};
export const loadimgaedimension = (uri) => {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.onload = () => {
      resolve({ width: img.width, height: img.height });
    };
    img.onerror = reject;
    img.src = uri;
  });
};
export const loadImageAndGetDimensions = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = (event) => {
      const img = new Image();
      img.onload = () => {
        resolve({ width: img.width, height: img.height });
      };
      img.onerror = reject;
      img.src = event.target.result;
    };
    reader.onerror = reject;
    reader.readAsDataURL(file);
  });
};

let positionOffsetImg = 0;
let imageCount = 0;
export const open_image = async (url, canvas, boxframe, ID, pre_img) => {
  console.log(url, "openImage");
  let returnedData;

  if (url.w == null || url.h == null) {
    const { width, height } = await loadimgaedimension(url.url);
    url.w = width;
    url.h = height;
  }

  let Active_img = null;
  if (ID === undefined) {
    ID = "image_photo" + Date.now();
  }
  let j = false;

  new fabric.Image.fromURL(
    "",
    function (img) {
      console.log("iamge added", img);
      let i = (((+new Date() / 4) % 360) * Math.PI) / 180;

      const canvasCenterX = canvas.getWidth() / 2;
      const canvasCenterY = canvas.getHeight() / 2;
      let safeAreaW = canvas.getWidth() * 0.8;
      let safeAreaH = canvas.getHeight() * 0.8;

      img.set({
        selectable: false,
        isCropped: "original",
        _width: url.w, // img.width,
        _height: url.h, // img.height,
        width: url.w,
        height: url.h,
        id: ID,
        scaleX: (boxframe.width - (url.w * img.scaleX) / 4) / url.w,
        scaleY: (boxframe.width - (url.w * img.scaleX) / 4) / url.w,
        centeredRotation: !0,
        transparentCorners: false,
        lockScalingFlip: true,
        borderColor: "#60D2F4",
        cornerStyle: "circle",
        evented: false,
        minScale: 0,
        canvas_page_id: canvas.pageId,
        editingClipScaleX: 0,
        editingClipScaleY: 0,
        editingClipScaleH: 0,
        editingClipScaleW: 0,
        editingScale: 0,
        editingAngle: 0,
        imageViewport: {
          x: 0,
          y: 0,
          width: 1,
          height: 1,
        },
        imageLoading: !0,
        previousImageViewport: 0,
        imageAngle: undefined,
        flipX: false,
        flipY: false,
        __type: "image",
      });
      if (url.paramsToAdd) {
        Object.keys(url.paramsToAdd).forEach((key) => {
          if (!(key in img)) {
            img[key] = url.paramsToAdd[key];
          }
        });
      }
      let pre_render_fn = img.render;

      if (safeAreaW < safeAreaH) {
        img.scaleToWidth(safeAreaW);
      } else if (safeAreaW > safeAreaH) {
        img.scaleToWidth(safeAreaH);
      }
      ///*********** For Loader  ************///
      renderloaderFrame(img, ctx, pre_render_fn);

      ///***********  Loader end here  ************///
      if (
        img.width * img.scaleX > canvas._objects[0].width ||
        img.height * img.scaleY > canvas._objects[0].height
      ) {
        const widthScaleFactor = (canvas._objects[0].width - 30) / img.width;
        const heightScaleFactor = (canvas._objects[0].height - 30) / img.height;

        const scaleFactor = Math.min(widthScaleFactor, heightScaleFactor);

        img.scale(scaleFactor);
      }
      const newLeft = canvasCenterX - (img.width * img.scaleX) / 2;
      const newTop = canvasCenterY - (img.height * img.scaleY) / 2;

      img.left = newLeft + positionOffsetImg;
      img.top = newTop + positionOffsetImg;
      positionOffsetImg += 10;
      imageCount++;

      if (imageCount >= 10) {
        positionOffsetImg = 0;
        imageCount = 0;
      }

      let Photorect = addRect2img(canvas, boxframe, img);
      img.imageRect = Photorect;
      canvas.add(img);
      canvas.add(Photorect);
      canvas.setActiveObject(Photorect);

      canvas.requestRenderAll();
      canvas.renderAll();
      canvas.savestate();

      if (url.url) {
        fabric.util.loadImage(
          url.url,
          function (loadedImage) {
            loadedImage.id = img.id;
            if (img.canvas_page_id === canvas.pageId) {
              console.log("same page image added", loadedImage);

              canvas.getObjects("image").forEach((f) => {
                if (f.id === loadedImage.id) {
                  f.render = pre_render_fn;
                  f.setElement(loadedImage);
                  f.imageLoading = !1;
                }
              });
              canvas.stateProperties.forEach((f) => {
                f.data.objects.forEach((o) => {
                  if (o.type === "image" && o.id === img.id) {
                    o._element = loadedImage;
                    o._originalElement = loadedImage;
                    o.imageLoading = !1;
                    o.src = url.url;
                  }
                });
              });
            } else {
              console.log("other page image added", loadedImage);
              canvas._pages.forEach((f) => {
                if (img.canvas_page_id === f.pageId) {
                  f.data.objects.forEach((p) => {
                    if (p.type === "image" && p.id === img.id) {
                      p._element = loadedImage;
                      p._originalElement = loadedImage;
                      p.imageLoading = !1;
                      p.src = url.url;
                    }
                  });
                }
              });
            }

            if (
              canvas.getActiveObject() &&
              canvas.getActiveObject().id === Photorect.id &&
              canvas.getActiveObject().type === Photorect.type
            ) {
              let a = canvas.getActiveObject();
              canvas.discardActiveObject();
              canvas.setActiveObject(a);
            }

            loadedImage && loadedImage.remove();
            updateBackgroundLayer(img, canvas);
            canvas.renderAll();
          },

          canvas.getContext("2d"),
          {
            crossOrigin: "anonymous",
          }
        );
      } else {
        returnedData = { frameImg: img, pre_render_fn: pre_render_fn };
      }
      if (url.returnImage) {
        returnedData = img;
      }

      canvas.selection = true;
    }.bind(this),
    {
      crossOrigin: "anonymous",
    }
  );
  if (!url.url) {
    //
    return returnedData;
  } else if (url.returnImage) {
    return returnedData;
  } else {
    return j;
  }
};

export const restoreOriginalProperties = (canvas) => {
  console.log("cancelCroping");
  const activeObject = canvas.getActiveObject();

  if (activeObject) {
    const previousPoint = activeObject.previousPoint;

    if (previousPoint) {
      activeObject.set({
        left: previousPoint.left,
        top: previousPoint.top,
        width: previousPoint.width,
        height: previousPoint.height,
        scaleX: previousPoint.scaleX,
        scaleY: previousPoint.scaleY,
        cropX: previousPoint.cropX,
        cropY: previousPoint.cropY,
        angle: previousPoint.angle,
        // Add other properties as needed
      });
      // canvas.discardActiveObject();

      activeObject.imageRect.set({
        left: activeObject.cropArea.left,
        top: activeObject.cropArea.top,
        width: previousPoint.width,
        height: previousPoint.height,
        absolutePositioned: true,
        angle: activeObject.cropArea.angle,
      });

      canvas.remove(activeObject.cropArea);
      canvas.overlayImage = null;
      canvas.crop_mode = false;
      canvas.renderAll();
    }
  }
};

export const replaceImageUrl = async (canvas, url, img, pre_render_fn) => {
  let Photorect = img.imageRect;
  fabric.util.loadImage(
    url,
    function (loadedImage) {
      console.log(
        "replaceImageUrl textToImage",
        url,
        img.width,
        img.height,
        loadedImage.width,
        loadedImage.height
      );

      loadedImage.id = img.id;

      if (img.canvas_page_id === canvas.pageId) {
        console.log("same page image added", loadedImage);

        canvas.getObjects("image").forEach((f) => {
          if (f.id === loadedImage.id) {
            f.render = pre_render_fn;
            f.setElement(loadedImage);
            f.imageLoading = !1;
          }
        });
        canvas.stateProperties.forEach((f) => {
          f.data.objects.forEach((o) => {
            if (o.type === "image" && o.id === img.id) {
              o._element = loadedImage;
              o._originalElement = loadedImage;
              o.imageLoading = !1;
              o.src = url.url;
            }
          });
        });
      } else {
        console.log("other page image added", loadedImage);
        canvas._pages.forEach((f) => {
          if (img.canvas_page_id === f.pageId) {
            f.data.objects.forEach((p) => {
              if (p.type === "image" && p.id === img.id) {
                p._element = loadedImage;
                p._originalElement = loadedImage;
                p.imageLoading = !1;
                p.src = url.url;
              }
            });
          }
        });
      }

      if (
        canvas.getActiveObject() &&
        canvas.getActiveObject().id === Photorect.id &&
        canvas.getActiveObject().type === Photorect.type
      ) {
        let a = canvas.getActiveObject();
        canvas.discardActiveObject();
        canvas.setActiveObject(a);
      }

      loadedImage && loadedImage.remove();
      canvas.renderAll();
    },

    canvas.getContext("2d"),
    {
      crossOrigin: "anonymous",
    }
  );
};
export const open_image_template = async (
  img,
  canvas,
  boxframe,
  ID,
  pre_img
) => {
  let Active_img = null;
  if (ID === undefined) {
    ID = "image_photo" + Date.now();
  }
  let j = false;

  img.set({
    selectable: false,
    isCropped: "original",
    _width: img.width,
    _height: img.height,
    id: ID,
    centeredRotation: !0,
    transparentCorners: false,
    lockScalingFlip: true,
    cornerStyle: "circle",
    evented: false,
    minScale: 0,
    canvas_page_id: canvas.pageId,
    // typeis: "image",
    editingClipScaleX: 0,
    editingClipScaleY: 0,
    editingClipScaleH: 0,
    editingClipScaleW: 0,
    editingScale: 0,
    editingAngle: 0,
    imageViewport: {
      x: 0,
      y: 0,
      width: 1,
      height: 1,
    },
    imageLoading: !0,
    previousImageViewport: 0,
    imageAngle: undefined,
  });
  let Photorect = addRect2img(canvas, boxframe, img);
  img.imageRect = Photorect;
  canvas.add(img);

  canvas.add(Photorect);

  canvas.requestRenderAll();
  return j;
};
export const UnGroupobj = () => {
  let canvas = cccanvas;
  if (!active_obj) {
    return;
  }
  if (active_obj.type !== "group") {
    return;
  }
  active_obj.getObjects().forEach((f) => {
    f.off("mouseover");
    f.off("mouseout");
    f.off("mousedown");
    f.off("mouseup");
  });
  active_obj.toActiveSelection();
  // canvas.setActiveObject(canvas._activeObject);
  canvas.requestRenderAll();
  setGroup(false);
};

let positionOffsetText = 0;
let textCount = 0;

export const AddText = (Canvas, t) => {
  console.log(t, "addTextttttt");
  const canvasCenterX = Canvas.getWidth() / 2;
  const canvasCenterY = Canvas.getHeight() / 2;

  const Headingh1 = new fabric.Textbox(t.body, {
    //left: canvasCenterX - t.width / 2, // Adjust left position based on object width
    top: canvasCenterY + positionOffsetText,

    width: t.width,
    textAlign: "center",
    fontFamily: "Roboto",
    fontWeight:
      t.id === "Heading" || t.id === "Subheading"
        ? "bold"
        : t.id === "body"
        ? "normal"
        : "inherit",
    fill: customTextCol ? customTextCol : "#000000",
    originalText: t.body,
    uppercaseText: t.body.toUpperCase(),
    uppercase: false,
    isLocked: true,
    lineHeight: 1,
    editingBorderColor: "#4BD3FB",
    fontStyle: "normal",
    fontSize: t.id === "Heading" ? 78 : t.id === "Subheading" ? 40 : 30,
    selectionStart: 12,
    selectionEnd: 12,
    __type: "text",
    cursorColor: "#000",
  });

  if (
    Headingh1.width > Canvas._objects[0].width * Canvas._objects[0].scaleX ||
    Headingh1.height > Canvas._objects[0].height * Canvas._objects[0].scaleY
  ) {
    const widthScaleFactor =
      (Canvas._objects[0].width * Canvas._objects[0].scaleX - 30) /
      Headingh1.width;
    const heightScaleFactor =
      (Canvas._objects[0].height * Canvas._objects[0].scaleY - 30) /
      Headingh1.height;

    const scaleFactor = Math.min(widthScaleFactor, heightScaleFactor);
    Headingh1.scale(scaleFactor);
  }

  const newLeft = canvasCenterX - (Headingh1.width * Headingh1.scaleX) / 2; // Adjust left position based on object width & ScaleX

  Headingh1.left = newLeft;

  Canvas.add(Headingh1);
  Headingh1.fontSize = Headingh1.fontSize * Headingh1.scaleX;
  Headingh1.width = Headingh1.width * Headingh1.scaleX;
  Headingh1.scaleX = 1;
  Headingh1.scaleY = 1;
  Canvas.setActiveObject(Headingh1);
  Canvas.savestate();
  Canvas.renderAll();

  positionOffsetText += Headingh1.height * Headingh1.scaleY;
  // Check if the next text addition will exceed the clipPath height
  if (positionOffsetText + Headingh1.height > Canvas.clipPath.height / 2) {
    positionOffsetText = 0; // Reset to start from the top again
  }

  textCount++;

  if (Canvas._objects[0].height / 2 < 50) {
    if (positionOffsetText + 10 >= Canvas._objects[0].height / 2) {
      positionOffsetText = 0;
    }
  } else {
    if (
      positionOffsetText + 30 >= Canvas._objects[0].height / 2 ||
      Canvas._objects[0].height / 2 - (positionOffsetText + 30) < 0
    ) {
      positionOffsetText = 0;
    }
  }

  return Headingh1;
};

export const getImageBrightness = (imgPath, callback) => {
  var img = document.createElement("img");
  img.src = imgPath;
  img.crossOrigin = "Anonymous";
  img.style.display = "none";
  document.body.appendChild(img);
  var colorSum = 0;
  img.onload = function () {
    var ctx = canvas.getContext("2d");
    var imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
    var data = imageData.data;
    var r, g, b, avg;
    for (var x = 0, len = data.length; x < len; x += 4) {
      r = data[x];
      g = data[x + 1];
      b = data[x + 2];
      avg = Math.floor((r + g + b) / 3);
      colorSum += avg;
    }
    var brightness = Math.floor(colorSum / (canvas.width * canvas.height));
    console.log(brightness);
    callback(brightness);
  };
};

let customTextCol;
export const checkBackgroundColor = (img) => {
  if (img) {
    getImageBrightness(img, function (brightness) {
      if (brightness < 127.5) {
        customTextCol = "#ffffff";
      } else {
        customTextCol = "#000000";
      }
    });
  }
};

var r, g, bb, hsp;
export const checkColor = (bgColor) => {
  if (bgColor.match(/^rgb/)) {
    // If HEX --> store the red, green, blue values in separate variables
    bgColor = bgColor.match(
      /^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)$/
    );
    r = bgColor[1];
    g = bgColor[2];
    bb = bgColor[3];
  } else {
    // If RGB --> Convert it to HEX: http://gist.github.com/983661
    bgColor = +(
      "0x" + bgColor.slice(1).replace(bgColor.length < 5 && /./g, "$&$&")
    );

    r = bgColor >> 16;
    g = (bgColor >> 8) & 255;
    bb = bgColor & 255;
  }
  // HSP (Highly Sensitive Poo) equation from http://alienryderflex.com/hsp.html
  hsp = Math.sqrt(0.299 * (r * r) + 0.587 * (g * g) + 0.114 * (bb * bb));

  // Using the HSP value, determine whether the color is light or dark
  if (hsp > 127.5) {
    console.log("light");
    customTextCol = "#000000";
  } else {
    console.log("dark");
    customTextCol = "#ffffff";
  }
};

export const setbgImg = (url, canvas, bg, vptwidth, vptheight, boxframe) => {
  let url_ = {
    left: bg.left,
    top: bg.top,
    w: vptwidth,
    h: vptheight,
    from: "background",
  };
  // const LoaderFrame_ = LoaderFrame(url_, boxframe, Canvas);
  let pre_render_fn = bg.render;
  bg.imageLoading = true;
  renderloaderFrame(bg, canvas.getContext("2d"));
  canvas.renderAll();

  ////////////////////////////////////////////////////////////////////////
  if (!url) {
    let returnedData = { frameImg: bg, pre_render_fn: pre_render_fn };
    return returnedData;
  }
  bg &&
    fabric.util.loadImage(
      url,
      function (loadedimg) {
        loadedimg.id = bg.id;
        if (bg.canvas_page_id === canvas.pageId) {
          console.log("same page image added, background_image", loadedimg);
          let img = canvas.getObjects().filter((f) => f.id === loadedimg.id)[0];

          img.set({
            src: url,
            background_fill_copy: bg.toJSON(),
            background_fill: bg,
            _width: loadedimg.naturalWidth,
            _height: loadedimg.naturalHeight,
            vptheight: vptheight,
            vptwidth: vptwidth,
            scaleX: 1,
            scaleY: 1,
            cropX: 0,
            cropY: 0,
            // _originalElement: img.getElement()
          });
          img.render = pre_render_fn;
          img.setElement(loadedimg);
          img.imageLoading = !1;
          console.log("both loaded", img, loadedimg);

          // console.log("aa----===///,vpt width is greater >>>>>> than height")
          if (vptwidth >= vptheight) {
            if (img._width <= vptwidth) {
              // alert('hello img.width<=canvas.width');
              let newWidth1 = (img._width / img._height) * vptheight;
              let newscale1 = newWidth1 / img._width;
              let newheight2 = (img._height / img._width) * vptwidth;
              let newscale2 = newheight2 / img._height;
              if (newscale1 < newscale2) {
                img.scale(newscale2);
              } else {
                img.scale(newscale1);
              }
            } else if (img._width >= vptwidth) {
              // alert('hello img.width>=canvas.width');
              let newheight1 = (img._height / img._width) * vptwidth;
              let newscale1 = newheight1 / img._height;

              let newWidth1 = (img._width / img._height) * vptheight;
              let newscale2 = newWidth1 / img._width;
              if (newscale1 < newscale2) {
                img.scale(newscale2);
              } else {
                img.scale(newscale1);
              }
            }
            img.width = vptwidth / img.scaleX;
            img.height = vptheight / img.scaleY;
          } else if (vptwidth < vptheight) {
            // alert("vptwidth < vptheight")
            if (img._height <= vptheight) {
              // img.scale(vptheight / img._height);
              // alert("img._height <= vptheight")
              let newheight1 = (img._height / img._width) * vptwidth;
              let newscale1 = newheight1 / img._height;
              let newwidtht2 = (img._width / img._height) * vptheight;
              let newscale2 = newwidtht2 / img._width;
              if (newscale1 < newscale2) {
                img.scale(newscale2);
              } else {
                img.scale(newscale1);
              }
            } else if (img._height >= vptheight) {
              // alert("img._height >= vptheight")
              // img.scale(vptwidth / img._width);
              let newheight1 = (img._height / img._width) * vptwidth;
              let newscale1 = newheight1 / img._height;

              let newWidth1 = (img._width / img._height) * vptheight;
              let newscale2 = newWidth1 / img._width;
              if (newscale1 > newscale2) {
                img.scale(newscale1);
              } else {
                img.scale(newscale2);
              }
            } else {
              // alert("elseee")
              img.scale(vptheight / img._height);
            }
            img.width = vptwidth / img.scaleX;
            img.height = vptheight / img.scaleY;
          }
          canvas.stateProperties.forEach((f) => {
            f.data.objects.forEach((o) => {
              if (o.type === img.type && o.id === img.id && o.imageLoading) {
                o.imageLoading = !1;
                o._element = loadedimg;
                o._originalElement = loadedimg;
                o.src = url;
                o.background_fill = bg;
                o.background_fill_copy = bg.toJSON();
                o._width = loadedimg.naturalWidth;
                o._height = loadedimg.naturalHeight;
                o.vptheight = vptheight;
                o.vptwidth = vptwidth;
                o.scaleX = 1;
                o.scaleY = 1;
                o.cropX = 0;
                o.cropY = 0;
                o.scaleX = img.scaleX;
                o.scaleY = img.scaleY;
                o.width = img.width;
                o.height = img.height;
              }
            });
          });

          updateBackgroundLayer(img, canvas);
          let dispatchBgfn = document.getElementById("updatebgdispatch");
          dispatchBgfn && dispatchBgfn.click();
        } else {
          console.log("other page image added, background_image", loadedimg);
          canvas._pages.forEach((f) => {
            if (bg.canvas_page_id === f.pageId) {
              f.data.objects.forEach((p) => {
                if (
                  p.type === "image" &&
                  p.id === loadedimg.id &&
                  p.imageLoading
                ) {
                  p.imageLoading = !1;
                  p._element = loadedimg;
                  p._originalElement = loadedimg;
                  p.src = url;
                  p.background_fill_copy = bg.toJSON();
                  p.background_fill = bg;
                  p._width = loadedimg.naturalWidth;
                  p._height = loadedimg.naturalHeight;
                  p.vptheight = vptheight;
                  p.vptwidth = vptwidth;
                  p.scaleX = bg.scaleX;
                  p.scaleY = bg.scaleY;
                  p.cropX = 0;
                  p.cropY = 0;
                  p.width = bg.width;
                  p.height = bg.height;
                  if (vptwidth >= vptheight) {
                    if (p._width <= vptwidth) {
                      // alert('hello img.width<=canvas.width');
                      let newWidth1 = (p._width / p._height) * vptheight;
                      let newscale1 = newWidth1 / p._width;
                      let newheight2 = (p._height / p._width) * vptwidth;
                      let newscale2 = newheight2 / p._height;
                      if (newscale1 < newscale2) {
                        p.scaleX = newscale2;
                        p.scaleY = newscale2;
                      } else {
                        p.scaleX = newscale1;
                        p.scaleY = newscale1;
                      }
                    } else if (p._width >= vptwidth) {
                      // alert('hello img.width>=canvas.width');
                      let newheight1 = (p._height / p._width) * vptwidth;
                      let newscale1 = newheight1 / p._height;

                      let newWidth1 = (p._width / p._height) * vptheight;
                      let newscale2 = newWidth1 / p._width;
                      if (newscale1 < newscale2) {
                        p.scaleX = newscale2;
                        p.scaleY = newscale2;
                      } else {
                        p.scaleX = newscale1;
                        p.scaleY = newscale1;
                      }
                    }
                    p.width = vptwidth / p.scaleX;
                    p.height = vptheight / p.scaleY;
                  } else if (vptwidth < vptheight) {
                    // alert("vptwidth < vptheight")
                    if (p._height <= vptheight) {
                      // img.scale(vptheight / img._height);
                      // alert("img._height <= vptheight")
                      let newheight1 = (p._height / p._width) * vptwidth;
                      let newscale1 = newheight1 / p._height;
                      let newwidtht2 = (p._width / p._height) * vptheight;
                      let newscale2 = newwidtht2 / p._width;
                      if (newscale1 < newscale2) {
                        p.scaleX = newscale2;
                        p.scaleY = newscale2;
                      } else {
                        p.scaleX = newscale1;
                        p.scaleY = newscale1;
                      }
                    } else if (p._height >= vptheight) {
                      // alert("img._height >= vptheight")
                      // img.scale(vptwidth / img._width);
                      let newheight1 = (p._height / p._width) * vptwidth;
                      let newscale1 = newheight1 / p._height;

                      let newWidth1 = (p._width / p._height) * vptheight;
                      let newscale2 = newWidth1 / p._width;
                      if (newscale1 > newscale2) {
                        p.scale = newscale1;
                        p.scale = newscale1;
                      } else {
                        p.scaleX = newscale2;
                        p.scaleY = newscale2;
                      }
                    } else {
                      // alert("elseee")
                      p.scale(vptheight / p._height);
                    }
                    p.width = vptwidth / p.scaleX;
                    p.height = vptheight / p.scaleY;
                  }
                }
              });
            }
          });
        }
        // if (LoaderFrame_.initiated_id === canvas.pageId) {
        //   img.set({
        //     src: url,
        //     background_fill: bg,
        //     _width: img.width,
        //     _height: img.height,
        //     vptheight: vptheight,
        //     vptwidth: vptwidth,
        //     scaleX: 1,
        //     scaleY: 1,
        //     cropX: 0,
        //     cropY: 0,
        //   });

        //   // console.log("aa----===///,vpt width is greater >>>>>> than height")
        //   if (vptwidth >= vptheight) {
        //     if (img._width <= vptwidth) {
        //       // alert('hello img.width<=canvas.width');
        //       let newWidth1 = (img._width / img._height) * vptheight;
        //       let newscale1 = newWidth1 / img._width;
        //       let newheight2 = (img._height / img._width) * vptwidth;
        //       let newscale2 = newheight2 / img._height;
        //       if (newscale1 < newscale2) {
        //         img.scale(newscale2);
        //       } else {
        //         img.scale(newscale1);
        //       }
        //     } else if (img._width >= vptwidth) {
        //       // alert('hello img.width>=canvas.width');
        //       let newheight1 = (img._height / img._width) * vptwidth;
        //       let newscale1 = newheight1 / img._height;

        //       let newWidth1 = (img._width / img._height) * vptheight;
        //       let newscale2 = newWidth1 / img._width;
        //       if (newscale1 < newscale2) {
        //         img.scale(newscale2);
        //       } else {
        //         img.scale(newscale1);
        //       }
        //     }
        //     img.width = vptwidth / img.scaleX;
        //     img.height = vptheight / img.scaleY;
        //   } else if (vptwidth < vptheight) {
        //     // alert("vptwidth < vptheight")
        //     if (img._height <= vptheight) {
        //       // img.scale(vptheight / img._height);
        //       // alert("img._height <= vptheight")
        //       let newheight1 = (img._height / img._width) * vptwidth;
        //       let newscale1 = newheight1 / img._height;
        //       let newwidtht2 = (img._width / img._height) * vptheight;
        //       let newscale2 = newwidtht2 / img._width;
        //       if (newscale1 < newscale2) {
        //         img.scale(newscale2);
        //       } else {
        //         img.scale(newscale1);
        //       }
        //     } else if (img._height >= vptheight) {
        //       // alert("img._height >= vptheight")
        //       // img.scale(vptwidth / img._width);
        //       let newheight1 = (img._height / img._width) * vptwidth;
        //       let newscale1 = newheight1 / img._height;

        //       let newWidth1 = (img._width / img._height) * vptheight;
        //       let newscale2 = newWidth1 / img._width;
        //       if (newscale1 > newscale2) {
        //         img.scale(newscale1);
        //       } else {
        //         img.scale(newscale2);
        //       }
        //     } else {
        //       // alert("elseee")
        //       img.scale(vptheight / img._height);
        //     }
        //     img.width = vptwidth / img.scaleX;
        //     img.height = vptheight / img.scaleY;
        //   }
        //   // img.angle = 45
        //   canvas.renderAll();
        //   canvas.remove(LoaderFrame_.g);
        //   canvas.selection = true;
        //   clearInterval(LoaderFrame_.f);
        //   clearInterval(LoaderFrame_.c);
        // } else {
        //   let newcanvas = document.createElement("canvas");

        //   newcanvas.id = `${LoaderFrame_.initiated_id}_newcanvas`;
        //   let newfbcanvas = new fabric.canvas(newcanvas.id, {
        //     height: canvas.height,
        //     width: canvas.width,
        //     preserveObjectStacking: true,
        //     enableRetinaScaling: true,
        //     controlsAboveOverlay: true,
        //     crop_mode: false,
        //   });

        //   let pre_page = canvas._pages.filter(
        //     (p) => p.pageId === LoaderFrame_.initiated_id
        //   );
        //   if (pre_page.length >= 1 && pre_page[0] && pre_page[0].data) {
        //     newfbcanvas.loadFromJSON(pre_page[0].data, () => {
        //       newfbcanvas.renderAll.bind(newfbcanvas);
        //       let allOBJECTS = newfbcanvas.getObjects();
        //       for (let i = 0; i < allOBJECTS.length; i++) {
        //         if (allOBJECTS[i].type === "image") {
        //           for (let j = 0; j < allOBJECTS.length; j++) {
        //             if (
        //               allOBJECTS[i].id === allOBJECTS[j].id &&
        //               allOBJECTS[i].type != allOBJECTS[j].type
        //             ) {
        //               allOBJECTS[j].original_img = allOBJECTS[i];
        //               allOBJECTS[j].original_img.imageRect = allOBJECTS[j];
        //             }
        //           }
        //         }
        //       }
        //       bg = newfbcanvas
        //         .getObjects()
        //         .filter((o) => o.type_ === "background")[0];
        //       bg &&
        //         bg.setSrc(
        //           url,
        //           function (img) {
        //             img.set({
        //               src: url,
        //               background_fill: bg,
        //               _width: img.width,
        //               _height: img.height,
        //               vptheight: vptheight,
        //               vptwidth: vptwidth,
        //               scaleX: 1,
        //               scaleY: 1,
        //               cropX: 0,
        //               cropY: 0,
        //             });

        //             if (vptwidth >= vptheight) {
        //               if (img._width <= vptwidth) {
        //                 // alert('hello img.width<=canvas.width');
        //                 let newWidth1 = (img._width / img._height) * vptheight;
        //                 let newscale1 = newWidth1 / img._width;
        //                 let newheight2 = (img._height / img._width) * vptwidth;
        //                 let newscale2 = newheight2 / img._height;
        //                 if (newscale1 < newscale2) {
        //                   img.scale(newscale2);
        //                 } else {
        //                   img.scale(newscale1);
        //                 }
        //               } else if (img._width >= vptwidth) {
        //                 // alert('hello img.width>=canvas.width');
        //                 let newheight1 = (img._height / img._width) * vptwidth;
        //                 let newscale1 = newheight1 / img._height;

        //                 let newWidth1 = (img._width / img._height) * vptheight;
        //                 let newscale2 = newWidth1 / img._width;
        //                 if (newscale1 < newscale2) {
        //                   img.scale(newscale2);
        //                 } else {
        //                   img.scale(newscale1);
        //                 }
        //               }
        //               img.width = vptwidth / img.scaleX;
        //               img.height = vptheight / img.scaleY;
        //             } else if (vptwidth < vptheight) {
        //               // alert("vptwidth < vptheight")
        //               if (img._height <= vptheight) {
        //                 // img.scale(vptheight / img._height);
        //                 // alert("img._height <= vptheight")
        //                 let newheight1 = (img._height / img._width) * vptwidth;
        //                 let newscale1 = newheight1 / img._height;
        //                 let newwidtht2 = (img._width / img._height) * vptheight;
        //                 let newscale2 = newwidtht2 / img._width;
        //                 if (newscale1 < newscale2) {
        //                   img.scale(newscale2);
        //                 } else {
        //                   img.scale(newscale1);
        //                 }
        //               } else if (img._height >= vptheight) {
        //                 // alert("img._height >= vptheight")
        //                 // img.scale(vptwidth / img._width);
        //                 let newheight1 = (img._height / img._width) * vptwidth;
        //                 let newscale1 = newheight1 / img._height;

        //                 let newWidth1 = (img._width / img._height) * vptheight;
        //                 let newscale2 = newWidth1 / img._width;
        //                 if (newscale1 > newscale2) {
        //                   img.scale(newscale1);
        //                 } else {
        //                   img.scale(newscale2);
        //                 }
        //               } else {
        //                 // alert("elseee")
        //                 img.scale(vptheight / img._height);
        //               }
        //               img.width = vptwidth / img.scaleX;
        //               img.height = vptheight / img.scaleY;
        //             }
        //             // img.angle = 45
        //             newfbcanvas.renderAll();
        //             newfbcanvas.remove(LoaderFrame_.g);
        //             canvas.selection = true;
        //             newfbcanvas.selection = true;
        //             LoaderFrame_.f && clearInterval(LoaderFrame_.f);
        //             LoaderFrame_.c && clearInterval(LoaderFrame_.c);
        //             newfbcanvas.renderAll();
        //             let page_img = document.getElementById(
        //               `${LoaderFrame_.initiated_id}_page_img`
        //             );
        //
        //             let pvt = newfbcanvas.viewportTransform;
        //             newfbcanvas.viewportTransform = [1, 0, 0, 1, 0, 0];
        //             let src_canvas = newfbcanvas.toDataURL({
        //               left: newfbcanvas.clipPath.left,
        //               top: newfbcanvas.clipPath.top,
        //               width: newfbcanvas.clipPath.width,
        //               height: newfbcanvas.clipPath.height,
        //               quality: 0.3,
        //             });
        //             page_img && (page_img.src = src_canvas);
        //             newfbcanvas.viewportTransform = pvt;
        //             canvas._pages.map((p) => {
        //               if (p.pageId === LoaderFrame_.initiated_id) {
        //                 p.data = newfbcanvas.toJSON();
        //               }
        //             });
        //             newfbcanvas.dispose();
        //           }.bind(this),
        //           {
        //             crossOrigin: "anonymous",
        //           }
        //         );
        //     });
        //   } else {
        //     console.log("page deleted no data found");
        //     LoaderFrame_.g && newfbcanvas.remove(LoaderFrame_.g);
        //     canvas.selection = true;
        //     newfbcanvas.selection = true;
        //     LoaderFrame_.f && clearInterval(LoaderFrame_.f);
        //     LoaderFrame_.c && clearInterval(LoaderFrame_.c);
        //     newfbcanvas.dispose();
        //   }
        // }
      },
      canvas.getContext("2d"),
      {
        crossOrigin: "anonymous",
      }
    );
  Update_Currentpage_View_Src_status(canvas);
};

// global
let bsase64 = null;
let StnOrigHeight = 320;
let StnOrigWidth = 320;
let b;
let a;
let m;
let id__ = null;
let bndry;

export function cropStencils(e, img, Canvas) {
  console.log("NewCal stencildataon crop--->>>>>", e.target);

  e.target.pre_clip = e.target.clipPath;
  let clip_path = e.target.clipPath.path;
  console.log(
    "srenbcildata-->>>",
    e.target.scaleX,
    e.target.toBeScaleX_,
    "fuldata",
    e.target
  );

  let TobescaleX = e.target.toBeScaleX_;
  let TobescaleY = e.target.toBeScaleY_;

  e.target.width = e.target._widtha;
  e.target.height = e.target._heighta;

  e.target.clipPath = null;
  let imga = e.target;
  let ImgCropX = img.cropX;
  let ImgCropY = img.cropY;

  // imga.count = imga.count + 1;
  imga.cropX = null;
  imga.cropY = null;
  imga.left = imga.left - ImgCropX * img.scaleX;
  imga.top = imga.top - ImgCropY * img.scaleY;

  imga.setControlsVisibility({
    ml: false,
    mt: false,
    mr: false,
    mb: false,
    mtr: false,
  });
  // inner clip path

  // clip path for grey background
  b = new fabric.Path(e.target.pre_clip.path, {
    // left: e.target.left,
    left: imga.left + ImgCropX * img.scaleX,
    // top: e.target.top,
    top: imga.top + ImgCropY * img.scaleY,
    inverted: true,
    angle: imga.angle,
    absolutePositioned: true,
    fill: "rgba(255,0, 0, 0.5)",
    width: e.target.pre_clip.width,
    height: e.target.pre_clip.height,
    scaleX: TobescaleX ? TobescaleX : e.target.pre_clip.scaleX,
    scaleY: TobescaleY ? TobescaleY : e.target.pre_clip.scaleY,
  });

  m = new fabric.Rect({
    left: 0 - Canvas.viewportTransform[4] / Canvas.getZoom(),
    top: 0 - Canvas.viewportTransform[5] / Canvas.getZoom(),
    fill: "#CBCDCE",
    opacity: 0.8,
    width: Canvas.width / Canvas.getZoom(),
    height: Canvas.height / Canvas.getZoom(),
    clipPath: b,
    selectable: false,
    id: "masking",
  });
  // let new_c = {
  //   x: img.left - img_.cropX * img_.scaleX,
  //   y: img_.top - img_.cropY * img_.scaleY,
  // };
  // let vc = { x: e.target.left, y: e.target.top };
  // let q = fabric.util.rotatePoint(
  //   new fabric.Point(new_c.x, new_c.y),
  //   vc,
  //   fabric.util.degreesToRadians(v)
  // );

  // bounding box
  a = new fabric.Path(e.target.pre_clip.path, {
    left: imga.left + ImgCropX * img.scaleX,
    top: imga.top + ImgCropY * img.scaleY,
    angle: imga.angle,
    fill: "transparent",
    strokeWidth: 1,
    selectable: false,
    hasBorders: false,
    absolutePositioned: true,
    id: "_bndry",
    lockMovementX: true,
    lockMovementY: true,
    perPixelTargetFind: true,
    strokeUniform: true,
    noScaleCache: false,
    lockScalingFlip: true,
    cropMask: m,
    centeredRotation: true,
    width: e.target.pre_clip.width,
    height: e.target.pre_clip.height,
    scaleX: TobescaleX ? TobescaleX : e.target.pre_clip.scaleX,
    scaleY: TobescaleY ? TobescaleY : e.target.pre_clip.scaleY,
  });

  // if (img.flag == false) {
  a.scaleX = TobescaleX ? TobescaleX : (StnOrigWidth * img.scaleX) / a.width;
  a.scaleY = TobescaleY ? TobescaleY : (StnOrigHeight * img.scaleY) / a.height;

  b.scaleX = TobescaleX ? TobescaleX : (StnOrigWidth * img.scaleX) / a.width;
  b.scaleY = TobescaleY ? TobescaleY : (StnOrigHeight * img.scaleY) / a.height;

  console.log(
    "TobescaleX-->>",
    b.scaleX,
    TobescaleX,
    (StnOrigWidth * img.scaleX) / a.width
  );

  img.toBeScaleX_ = TobescaleX
    ? TobescaleX
    : (StnOrigWidth * img.scaleX) / a.width;
  img.toBeScaleY_ = TobescaleY
    ? TobescaleY
    : (StnOrigHeight * img.scaleY) / a.height;

  Canvas.overlayImage = m;

  // restricting the image movement
  let new_c = {
    x: imga.left - imga.cropX * imga.scaleX,
    y: imga.top - imga.cropY * imga.scaleY,
  };
  let vc = { x: a.left, y: a.top };
  let v = (imga.angle || 0) + (imga.imageAngle || 0);
  let q = fabric.util.rotatePoint(
    new fabric.Point(new_c.x, new_c.y),
    vc,
    fabric.util.degreesToRadians(v)
  );
  imga.set({
    top: q.y,
    left: q.x,
    bndry: a,
    status: "editor",
  });

  let shapeBoundingBox = a.getBoundingRect();
  imga.on("mousedown", (e) => {
    _on_Mousedown_new(e);
    console.log("aftermousedown", e.target.minScale);
    Canvas.renderAll();
  });

  imga.on("moving", (e) => {
    if (Canvas.crop_mode) {
      if (e.transform.target.type === "image" && e.transform.target.bndry) {
        _adjust_PositionV2(e);
      }
    }
  });

  imga.on("scaling", function (e) {
    if (Canvas.crop_mode) {
      let img_ = e.transform.target;

      console.log("img_scaledata--->>>", img_);

      var t = e.transform,
        a = t && t.corner,
        n = getOriginalRects(img_);
      if (img_.status === "editor" && e && e.transform && n) {
        if (img_.scaleX <= img_.minScale || img_.scaleY <= img_.minScale) {
          var i = img_.translateToOriginPoint(
            img_.getCenterPoint(),
            t.originX,
            t.originY
          );
          img_.scaleX = img_.minScale;
          img_.scaleY = img_.minScale;
          img_.setPositionByOrigin(i, t.originX, t.originY);
        } else img_.editingScale = img_.scaleX;
      } else {
      }
    }
  });

  Canvas.add(a);

  Canvas.bringToFront(a);
  Canvas.renderAll();
}

function _(e, t, a) {
  return fabric.util.capValue(e, t, a);
}
const _adjust_PositionV2 = (g) => {
  var e = getPreviousCenterPoint(g),
    t = fabric.util.rotatePoint(
      new fabric.Point(g.transform.target.left, g.transform.target.top),
      e,
      fabric.util.degreesToRadians(-g.transform.target.angle)
    ),
    a = t.x,
    n = t.y;
  g.transform.target.bndry.rotate(-g.transform.target.imageAngle);
  var i = g.transform.target.bndry.getBoundingRect(!0, !0);
  g.transform.target.bndry.rotate(g.transform.target.angle);
  g.transform.target.bndry.rotate(g.transform.target.angle);
  var r = i.left - (g.transform.target.getScaledWidth() - i.width),
    o = i.top - (g.transform.target.getScaledHeight() - i.height),
    c = _(r, a, i.left),
    l = _(o, n, i.top);
  if (c != a || l != n) {
    var u = fabric.util.rotatePoint(
      new fabric.Point(c, l),
      e,
      fabric.util.degreesToRadians(g.transform.target.angle)
    );
    g.transform.target.set("left", u.x), g.transform.target.set("top", u.y);

    // console.log("aman", u, r, a, i.left);
  }
};

const getPreviousCenterPoint = (g) => {
  // //console.log(g)
  return g.transform.target.bndry && g.transform.target.bndry.getCenterPoint();
};

const getOriginalRects = (e) => {
  if (e.status === "editor") {
    var t = e.getCenterPoint(),
      a = fabric.util.rotatePoint(
        new fabric.Point(e.left, e.top),
        t,
        fabric.util.degreesToRadians(-(e.angle || 0))
      );
    console.log("amandgfsdf", a);
    return {
      x: a.x,
      y: a.y,
      width: e._width,
      height: e._height,
      scaleX: e.scaleX,
      scaleY: e.scaleY,
    };
  }
};

export const _on_Mousedown_new = (e) => {
  let t = e.transform,
    a = t && t.corner,
    n;
  // , i = getPreviousCenterPoint(e);

  e.transform
    ? (n = getOriginalRects(t.target))
    : (n = getOriginalRects(e.target));
  console.log("inmousedown", e, n, e.transform);
  if (n && e && e.transform) {
    console.log("inmousedown in=-", t.target.bndry, t.target);
    // t.target.bndry.rotate(-t.target.imageAngle);
    let r = t.target.bndry.getBoundingRect(!0, !0);
    t.target.bndry.rotate(t.target.angle);
    let o = fabric.util.rotatePoint(
        new fabric.Point(t.target.left, t.target.top),
        t.target.bndry.getCenterPoint(),
        fabric.util.degreesToRadians(-t.target.angle)
      ),
      c = 0,
      l = 0,
      u = t.target.width,
      h = t.target.height,
      d = t.target.width * t.target.scaleX,
      p = t.target.height * t.target.scaleY,
      g = r.left - o.x,
      f = r.top - o.y;

    if ("tl" === t.corner) {
      c = (d - g) / u;
      l = (p - f) / h;
    } else if ("tr" === t.corner) {
      c = (g + r.width) / u;
      l = (p - f) / h;
    } else if ("br" === t.corner) {
      c = (g + r.width) / u;
      l = (f + r.height) / h;
    } else if ("bl" === t.corner) {
      c = (d - g) / u;
      l = (f + r.height) / h;
    }

    let z = Math.max(c, l);

    t.target.minScale = z;
    console.log("minScale====", z, t.target.minScale);
  } else {
    return;
  }
};

export const restoreStencil = (img, Canvas) => {
  // let clippath = Canvas.overlayImage.clipPath;
  // clippath.inverted = false;

  img.newClipPath = Canvas.overlayImage.clipPath;
  img.newClipPath.inverted = false;
  let n = {
    x: img.bndry.left,
    y: img.bndry.top,
  };
  let q = fabric.util.rotatePoint(
    new fabric.Point(n.x, n.y),
    img.bndry.getCenterPoint(),
    fabric.util.degreesToRadians(img.angle)
  );
  // img.left = clippath.left;
  // img.cropX = (clippath.left - img.left) / img.scaleX;
  // img.width = clippath.getScaledWidth() / img.scaleX;

  let cREct = getOriginalRect(img.bndry, img.bndry);
  let iRect = getOriginalRect(img, img.bndry);

  img.set({
    // top: cropArea.top,--
    // angle: cropArea.angle,
    // cropY: clippath.getScaledWidth() / img.scaleX,
    // clipPath: boxframe,
    // height: cropArea.getScaledHeight() / img_.scaleY,
    // isCropped: "cropped",
    // selectable: false,
    // evented: false,
    // stroke: null,
    // strokeWidth: null,
    // cropArea: null,
    // clipChild: null,
    // count: img.count + 1,
    flag: true,
    angle: img.bndry.angle,
    left: img.newClipPath.left,
    top: img.newClipPath.top,

    cropX: (cREct.x - iRect.x) / img.scaleX,
    cropY: (cREct.y - iRect.y) / img.scaleY,

    width: img.newClipPath.getScaledWidth() / img.scaleX,
    height: img.newClipPath.getScaledHeight() / img.scaleY,

    clipPath: new fabric.Path(
      // "M100,0 L125,50 L175,50 L135,85 L150,135 L100,105 L50,135 L65,85 L25,50 L75,50 Z",
      // url_.imgPath,
      img.newClipPath.path,
      {
        left: -2 - img.newClipPath.getScaledWidth() / img.scaleX / 2,
        top: -2 - img.newClipPath.getScaledHeight() / img.scaleY / 2,
      }
    ),
  });
  img.clipPath.scaleX = img.width / img.clipPath.width;
  img.clipPath.scaleY = img.height / img.clipPath.height;

  Canvas.overlayImage = null;
  Canvas.crop_mode = 0;

  // cropMode_stn = 0;
  console.log(img.bndry, "imageboundry");
  Canvas.remove(img.bndry);
  Canvas.renderAll();
  Canvas.savestate();

  console.log(img.count, "maggeeCount");
};

const getOriginalRect = (e, ca) => {
  if (e) {
    var t = ca.getCenterPoint(),
      a = fabric.util.rotatePoint(
        new fabric.Point(e.left, e.top),
        t,
        fabric.util.degreesToRadians(-(e.angle || 0))
      );
    return {
      x: a.x,
      y: a.y,
      width: e.width,
      height: e.height,
    };
  }
};

const removeImage = (stn, Canvas) => {
  let _imgid = stn.image_id;
  let _img;
  // stn.getObjects().forEach((o) => {
  //   if (o.id == _imgid) {
  //     _img = o;
  //   }
  // });
  console.log("stenciiiiiiiiiiiiiiiiiiiii", stn);

  if (_img && _img.status == "insideFrame") {
    if (stn._oldImage) {
      stn.set({
        image_id: stn._oldImage.id,
      });
      stn.remove(_img);
      stn.add(stn._oldImage);
      Canvas.remove(bndry);
      Canvas.renderAll();
    } else {
      stn.set({
        image_id: null,
      });
      stn.remove(_img);
      Canvas.remove(bndry);
      if (_img.bndry) {
        _img.bndry = null;
      }
      Canvas.renderAll();
    }
  }
};

export const deleteFromCanvas = (img, Canvas) => {
  if (Canvas) {
    let stencils = Canvas.getObjects().filter(
      (obj) => obj._type == "stencil_frame"
    );

    stencils.forEach((stn) => {
      if (stn.image_id) {
        if (img) {
          if (img.id) {
            if ((stn.image_id && stn.image_id) == (img && img.id && img.id)) {
              id__ = img.id;

              console.log("Hii, REMOVINGzzzzzzzzzz");

              console.log("removing", stn.image_id, img.id);
              Canvas.remove(img);
              Canvas.remove(bndry);
              // Canvas.setActiveObject(stn);
              if (img.bndry) {
                img.bndry = null;
              }
            }
          }
        }
      }
    });

    if (id__) {
      //console.log(id__)
      let i = Canvas.getObjects().filter((obj) => obj.id === id__);
      //console.log(i)
      Canvas.remove(...i);
      id__ = null;
    }
  }
};

var scaleDifferenceX;
var scaleDifferenceY;
var initialRatioX;
var initialRatioY;
export function NewCalStencil(e) {
  console.log(
    "finaldataafterscale onscale-->>>>>",
    e.target.toBeScaleX_,
    e.target.scaleX
  );

  //console.log("CropMode finaldataafterscale  onscale-->>>>>", e.target.canvas.crop_mode)

  if (!e.target.canvas.crop_mode) {
    if (e.target.toBeScaleX_) {
      scaleDifferenceX = e.target.scaleX - e.target.toBeScaleX_;
      scaleDifferenceY = e.target.scaleY - e.target.toBeScaleY_;

      initialRatioX = e.target.toBeScaleX_ / e.target.scaleX;
      initialRatioY = e.target.toBeScaleY_ / e.target.scaleY;

      console.log("firsttimedifference", e.target.scaleX, e.target.toBeScaleX_);
      e.target.set({
        NewCalScaleBefore: { x: e.target.scaleX, y: e.target.scaleY },
        NewCaltoBeScaleX_: e.target.toBeScaleX_,
        NewCaltoBeScaleY_: e.target.toBeScaleY_,
      });
      e.target.toBeScaleX_ = null;
      e.target.toBeScaleY_ = null;
    }
  }
  e.target.set({
    NewCalScaleAfter: { x: e.target.scaleX, y: e.target.scaleY },
  });
}

const stencilstateupdate = (e) => {
  //   console.log("NewCalDifferent",e)
  // return

  if (e.NewCaltoBeScaleX_ == null || e.NewCaltoBeScaleY_ == null) {
    return;
  }

  // Check for the presence of NewCalScaleBefore and NewCalScaleAfter
  if (!e?.NewCalScaleBefore || !e?.NewCalScaleAfter) {
    console.error("Missing NewCalScaleBefore or NewCalScaleAfter values.");
    return;
  }

  // Calculate the difference between the new and old scales
  const scaleXChange =
    (e.NewCalScaleAfter.x || 0) - (e.NewCalScaleBefore.x || 0);
  const scaleYChange =
    (e.NewCalScaleAfter.y || 0) - (e.NewCalScaleBefore.y || 0);

  if (!e.canvas.crop_mode) {
    console.log(
      "scaleXChangeNew",
      e.toBeScaleX_,
      e.scaleX,
      scaleXChange,
      "newScale",
      e.toBeScaleX_ + scaleXChange
    );
    const adjustmentFactor = 0.02;

    console.log("objdata-->>", e);

    console.log(
      "finaldataafterscale onscaleDone-->>>>>",
      e.scaleX,
      scaleXChange
    );

    console.log(
      "finaldataafterscale scaleDifference",
      scaleDifferenceX,
      scaleDifferenceY
    );

    const adjustedScaleXChange =
      scaleXChange +
      (e.width >= e.height ? adjustmentFactor : -adjustmentFactor);
    const adjustedScaleYChange =
      scaleYChange +
      (e.width >= e.height ? adjustmentFactor : -adjustmentFactor);

    // e.toBeScaleX_ = e.NewCaltoBeScaleX_ + adjustedScaleXChange;
    // e.toBeScaleY_ = e.NewCaltoBeScaleY_ + adjustedScaleYChange;

    console.log("firsttimedifference 2nd", e.scaleX, e.toBeScaleX_);

    // e.toBeScaleX_ = e.scaleX + scaleDifferenceX;
    // e.toBeScaleY_ = e.scaleY + scaleDifferenceY;

    console.log(
      "firsttimedifference ratio",
      initialRatioX,
      initialRatioY,
      scaleDifferenceX,
      scaleDifferenceY
    );
    e.set({
      toBeScaleX_: e.scaleX * initialRatioX,
      toBeScaleY_: e.scaleY * initialRatioY,
    });

    console.log("firsttimedifference 2nd", e.scaleX, e.toBeScaleX_);

    // Reset the scale before and after values to avoid using stale data
    e.NewCalScaleBefore.x = null;
    e.NewCalScaleBefore.y = null;
    e.NewCalScaleAfter.x = null;
    e.NewCalScaleAfter.y = null;
    e.NewCaltoBeScaleX_ = null;
    e.NewCaltoBeScaleY_ = null;
  }
};

export function handleStencilScaling(e) {
  if (e.target.flag === true) {
    console.log(
      "scalechange alltime",
      e.target.scaleX,
      e.target.toBeScaleX_,
      (StnOrigWidth * e.target.scaleX) / e.target.pre_clip.width
    );

    // Check if toBeScaleX_ and toBeScaleY_ are null or undefined
    if (e.target.toBeScaleX_ == null || e.target.toBeScaleX_ === undefined) {
      e.target.toBeScaleX_ =
        (StnOrigWidth * e.target.scaleX) / e.target.pre_clip.width;
    }

    if (e.target.toBeScaleY_ == null || e.target.toBeScaleY_ === undefined) {
      e.target.toBeScaleY_ =
        (StnOrigHeight * e.target.scaleY) / e.target.pre_clip.height;
    }
  }
}

// export function handleStencilScaling(e) {
//   if (e.target.flag == true) {
//     // console.log(e.target.scaleX, e.target.clipPath.width, "Mode scale");
//     e.target.toBeScaleX_ = (StnOrigWidth * e.target.scaleX) / e.target.pre_clip.width;
//     e.target.toBeScaleY_ = (StnOrigHeight * e.target.scaleY) / e.target.pre_clip.height;
//   }

//   // console.log(img, "Mode");
// }
// Define the handleObjectMoving function
// export function handleImageToStencilMovement(e) {
//   if (e.target.typeis && e.target.typeis === "image") {
//     let img;
//     let allObj = e.target.canvas.getObjects();
//     for (let i = 0; i < allObj.length; i++) {
//       if (allObj[i].id === e.target.id && allObj[i].type === "image") {
//         img = allObj[i];
//       }
//     }

//     if (img.status == "editor") {
//       return;
//     }
//     let stencils = e.target.canvas.getObjects().filter((obj) => obj._type == "stencil_frame");
//     let stencilsContainingImage = [];

//     stencils.forEach((stn, idx) => {
//       if (!stn.isloading) {
//         // let isInside = stn.containsPoint({ x: e.pointer.x, y: e.pointer.y });

//         let isInside = false;
//         if (
//           e.pointer.x >= stn.left &&
//           e.pointer.x <= stn.left + stn.width * stn.scaleX &&
//           e.pointer.y >= stn.top &&
//           e.pointer.y <= stn.top + stn.height * stn.scaleY
//         ) {
//           isInside = true;
//         } else {
//           isInside = false;
//         }
//         // const absoluteMouseX = e.pointer.x * e.target.canvas.getZoom();
//         // const absoluteMouseY = e.pointer.y * e.target.canvas.getZoom();

//         // let isInside = stn.containsPoint({ x: absoluteMouseX, y: absoluteMouseY }, null, false);

//         if (isInside) {
//           stencilsContainingImage.push(stn);
//         } else {
//           if (stn.image_id && stn.image_id == img.id) {
//             removeImage(stn, e.target.canvas);
//             img.set({
//               opacity: 1,
//               status: "normal",
//               stencil_id: null,
//             });
//             stn.set({
//               image_id: null,
//             });

//             // if (stn.allImages.length > 1) {
//             //   stn.setSrc(
//             //     stn.allImages[stn.allImages.length - 2],
//             //     function (img) {
//             //       img.set({
//             //         cropX: img.width / 2 - 160,
//             //         width: 321,
//             //         cropY: img.height / 2 - 160,
//             //         height: 321,
//             //       });
//             //     }.bind(this),
//             //     {
//             //       crossOrigin: "anonymous",
//             //     }
//             //   );
//             // } else {
//             stn.setSrc(
//               "/stencil_placeholder_img_resized.jpg",
//               function (img) {
//                 img.set({
//                   width: img.width,
//                   height: img.height,
//                   cropX: 0,
//                   cropY: 0,
//                   placeholder: 0,
//                 });
//               }.bind(this),
//               {
//                 crossOrigin: "anonymous",
//               }
//             );
//             // }
//           }
//         }
//       }
//     });

//     stencilsContainingImage.forEach((stn, idx) => {
//       if (idx == stencilsContainingImage.length - 1) {
//         if (img.status == "preview" && img.stencil_id == stn.id) {
//           return;
//         }
//         let url = img.toDataURL();
//         img.set({
//           opacity: 0.5,
//           status: "preview",
//           stencil_id: stn.id,
//         });
//         stn.set({
//           image_id: img.id,
//         });

//         fabric.Image.fromURL(
//           url,
//           function (img) {
//             if (img.width > img.height) {
//               img.scaleToHeight(320);
//             } else if (img.height > img.width) {
//               img.scaleToWidth(320);
//             } else {
//               img.scaleToWidth(320 + 1);
//             }
//             bsase64 = img.toDataURL();
//           }.bind(this),
//           {
//             crossOrigin: "anonymous",
//           }
//         );

//         setTimeout(() => {
//           stn.setSrc(
//             `${bsase64}`,
//             function (img) {
//               img.set({
//                 _widtha: img.width,
//                 _heighta: img.height,
//                 cropX: img.width / 2 - 160,
//                 width: 321,
//                 cropY: img.height / 2 - 160,
//                 height: 321,
//                 placeholder: 1,
//               });
//               e.target.canvas.renderAll();
//               // if (img.allImages[img.allImages.length - 1] != bsase64) {
//               //   img.allImages.push(bsase64);
//               // }
//             }.bind(this),
//             {
//               crossOrigin: "anonymous",
//             }
//           );
//         }, 10);
//       } else {
//         if (stn.image_id && stn.image_id == img.id) {
//           removeImage(stn, e.target.canvas);
//           img.set({
//             opacity: 1,
//             status: "normal",
//             stencil_id: null,
//           });
//           stn.set({
//             image_id: null,
//           });
//         }
//       }
//     });
//   }
// }

// Bhupi stencil pixalate issues fixed code
// export function handleImageToStencilMovement(e) {
//   if (e.target.typeis && e.target.typeis === "image") {
//     let img;
//     let allObj = e.target.canvas.getObjects();
//     for (let i = 0; i < allObj.length; i++) {
//       if (allObj[i].id === e.target.id && allObj[i].type === "image") {
//         img = allObj[i];
//       }
//     }

//     if (img.status === "editor") {
//       return;
//     }

//     let stencils = e.target.canvas.getObjects().filter((obj) => obj._type === "stencil_frame");
//     let canvas = e.target.canvas;
//     let stencilsContainingImage = [];

//     stencils.forEach((stn) => {
//       if (!stn.isloading) {
//         let isInside =
//           e.pointer.x >= stn.left &&
//           e.pointer.x <= stn.left + stn.width * stn.scaleX &&
//           e.pointer.y >= stn.top &&
//           e.pointer.y <= stn.top + stn.height * stn.scaleY;

//         if (isInside) {
//           stencilsContainingImage.push(stn);
//         }
//       }
//     });

//     if (stencilsContainingImage.length > 0) {
//       let stn = stencilsContainingImage[stencilsContainingImage.length - 1];

//       if (img.status === "preview" && img.stencil_id === stn.id) {
//         return;
//       }

//       // Store the original source and transformations of the stencil
//       let originalSrc = stn.getSrc();
//       let originalTransform = {
//         left: stn.left,
//         top: stn.top,
//         scaleX: stn.scaleX,
//         scaleY: stn.scaleY,
//         angle: stn.angle,
//         width: stn.width,
//         height: stn.height,
//         cropX: stn.cropX || 0,
//         cropY: stn.cropY || 0,
//         originX: stn.originX,
//         originY: stn.originY,
//         _widtha:stn._widtha|| "",
//         _heighta:stn._heighta|| "",
//       };

//       img.set({
//         opacity: 0.5,
//         status: "preview",
//         stencil_id: stn.id,
//       });
//       stn.set({
//         image_id: img.id,
//       });

//       // Use the original image URL or source for better quality
//       fabric.Image.fromURL(
//         img.getSrc(),
//         function (fabricImg) {
//           // Determine the scale to use

//           stn.setSrc(
//             fabricImg.getSrc(),
//             function (img) {

//               let newWidth, newHeight;
//               console.log("old imagedetails-->>>>", fabricImg)
//               console.log("old imagedetails-->>>>", fabricImg.height, "Width", fabricImg.width)

//               // if (fabricImg.width < fabricImg.height) {
//               //   // Calculate based on height
//               //   fabricImg.scaleToWidth(320); // Scale based on width
//               //   newWidth = fabricImg.getScaledWidth();
//               //   newHeight = fabricImg.getScaledHeight();

//               // } else if (fabricImg.height < fabricImg.width) {
//               //   // Calculate based on width
//               //   fabricImg.scaleToHeight(320); // Scale based on height
//               //   newWidth = fabricImg.getScaledWidth();
//               //   newHeight = fabricImg.getScaledHeight();
//               // }
//               // else {
//               //   fabricImg.scaleToWidth(321); // Scale based on width
//               //   newWidth = fabricImg.getScaledWidth();
//               //   newHeight = fabricImg.getScaledHeight();
//               // }

//               newWidth = fabricImg.width*fabricImg.scaleX
//               newHeight = fabricImg.height*fabricImg.scaleY
//               console.log("imagedata--->>>>",img.width,newWidth,"height",img.height,newHeight)
//               stn.width = stn.clipPath.width * stn.clipPath.scaleX
//               stn.height = stn.clipPath.height * stn.clipPath.scaleY
//               console.log("new imagedetails-->>>>", fabricImg.height, newHeight, "Width", fabricImg.width, newWidth)
//               img.set({
//                 _widtha: newWidth,
//                 _heighta: newHeight,
//                 cropX: newWidth / 2 - stn.width / 2,
//                 cropY: newHeight / 2 - stn.height / 2,
//                 placeholder: 1,
//               });
//               canvas.renderAll();
//             },
//             {
//               crossOrigin: "anonymous",
//             }

//           );
//       // fabric.Image.fromURL(img.getSrc(), function (fabricImg) {
//       //   const stencilWidth = stn.clipPath.width * stn.clipPath.scaleX;
//       //   const stencilHeight = stn.clipPath.height * stn.clipPath.scaleY;

//       //   // Calculate scale factor to fit the image width to the stencil width
//       //   const scaleFactor = stencilWidth / fabricImg.width;

//       //   // Scale image while maintaining aspect ratio
//       //   fabricImg.scale(scaleFactor);

//       //   // Calculate new width and height of the image after scaling
//       //   const newWidth = fabricImg.width * fabricImg.scaleX;
//       //   const newHeight = fabricImg.height * fabricImg.scaleY;

//       //   // Calculate cropping values to center the image within the stencil
//       //   const cropX = Math.max(0, (newWidth - stencilWidth) / 2);
//       //   const cropY = Math.max(0, (newHeight - stencilHeight) / 2);
//       //   // Set properties to fit the image within the stencil
//       //   stn.setSrc(fabricImg.getSrc(), function (img) {
//       //       img.set({
//       //           _widtha: fabricImg.width,
//       //           _heighta: fabricImg.height,
//       //           cropX: cropX,
//       //           cropY: cropY,
//       //           placeholder: 1,
//       //           left: stn.left,  // Align image left with stencil left
//       //           top: stn.top,  // Center image vertically
//       //           // scaleX: scaleFactor,  // Scale image width to fit stencil
//       //           // scaleY: scaleFactor,  // Maintain aspect ratio
//       //           originX: 'left',
//       //           originY: 'top',
//       //           // width:img.clipPath.getScaledWidth(),
//       //           // height:img.clipPath.getScaledHeight(),
//       //       //     stnPreWidth:stnPreWidth,
//       //       //     stnPreHeight:stnPreHeight,
//       //       //  stnPreSX:stnPreSX,
//       //       //     stnPreSY:stnPreSY
//       //       });
//       //       // canvas.getActiveObject().width=canvas.getActiveObject().clipPath.width*canvas.getActiveObject().clipPath.scaleX

//       //       canvas.renderAll();  // Render the canvas to reflect changes
//       //   }, {
//       //       crossOrigin: "anonymous",
//       //   });
//     // });

//           // Define the event listener function for mouse movement
//           function moveHandler(evt) {
//             let pointer = canvas.getPointer(evt.e);
//             let isInside =
//               pointer.x >= stn.left &&
//               pointer.x <= stn.left + stn.width * stn.scaleX &&
//               pointer.y >= stn.top &&
//               pointer.y <= stn.top + stn.height * stn.scaleY;

//             if (!isInside && img.status === "preview") {
//               // Image is outside the stencil, revert to original
//               console.log("NewCal---->>inside preview")
//               stn.setSrc(
//                 originalSrc,
//                 function () {
//                   stn.set({
//                     left: originalTransform.left,
//                     top: originalTransform.top,
//                     scaleX: originalTransform.scaleX,
//                     scaleY: originalTransform.scaleY,
//                     angle: originalTransform.angle,
//                     width: originalTransform.width,
//                     height: originalTransform.height,
//                     cropX: originalTransform.cropX,
//                     cropY: originalTransform.cropY,
//                     originX: originalTransform.originX,
//                     originY: originalTransform.originY,
//                     _widtha:originalTransform._widtha,
//                     _heighta:originalTransform._heighta,
//                     image_id: null,
//                   });
//                   canvas.renderAll();
//                 },
//                 {
//                   crossOrigin: "anonymous",
//                 }
//               );

//               img.set({
//                 opacity: 1,
//                 status: "normal",
//                 stencil_id: null,
//               });

//               // Remove the event listeners
//               canvas.off('mouse:move', moveHandler);
//               canvas.off('mouse:up', dropHandler);
//             }
//           }

//           // Define the event listener function for mouse up (drop)
//           function dropHandler(evt) {
//             console.log("NewCal---->>imageDrop")
//             if (stencilsContainingImage.includes(stn) && img.status === "preview") {
//               // Image was dropped, set the new image as the final source
//               img.set({
//                 opacity: 1,
//                 status: "normal",
//               });
//             } else {
//               // Image was not dropped, revert to the original source and transformations
//               stn.setSrc(
//                 originalSrc,
//                 function () {
//                   stn.set({
//                     left: originalTransform.left,
//                     top: originalTransform.top,
//                     scaleX: originalTransform.scaleX,
//                     scaleY: originalTransform.scaleY,
//                     angle: originalTransform.angle,
//                     width: originalTransform.width,
//                     height: originalTransform.height,
//                     cropX: originalTransform.cropX,
//                     cropY: originalTransform.cropY,
//                     originX: originalTransform.originX,
//                     originY: originalTransform.originY,
//                     image_id: null,
//                   });
//                   canvas.renderAll();
//                 },
//                 {
//                   crossOrigin: "anonymous",
//                 }
//               );
//             }
//             // Remove the event listeners
//             canvas.off('mouse:move', moveHandler);
//             canvas.off('mouse:up', dropHandler);
//           }

//           // Attach the event listeners
//           canvas.on('mouse:move', moveHandler);
//           canvas.on('mouse:up', dropHandler);
//         },
//         {
//           crossOrigin: "anonymous",
//         }
//       );
//     }
//   }
// }
export function handleImageToStencilMovement(e) {
  console.log("imageInside");
  if (
    e.target.typeis &&
    e.target.typeis === "image" &&
    e.target.original_img &&
    !e.target.original_img.imageLoading
  ) {
    let img;
    let allObj = e.target.canvas.getObjects();
    for (let i = 0; i < allObj.length; i++) {
      if (allObj[i].id === e.target.id && allObj[i].type === "image") {
        img = allObj[i];
      }
    }

    if (img.status === "editor") {
      return;
    }

    let stencils = e.target.canvas
      .getObjects()
      .filter((obj) => obj._type === "stencil_frame");
    let canvas = e.target.canvas;
    let stencilsContainingImage = [];

    stencils.forEach((stn) => {
      if (!stn.isloading) {
        const boundingRect = stn.getBoundingRect(true);
        // let minX=Math.min(stn.aCoords.bl.x,stn.aCoords.tl.x,stn.aCoords.tr.x,stn.aCoords.br.x);
        // let maxX=Math.max(stn.aCoords.bl.x,stn.aCoords.tl.x,stn.aCoords.tr.x,stn.aCoords.br.x);
        // let minY=Math.min(stn.aCoords.bl.y,stn.aCoords.tl.y,stn.aCoords.tr.y,stn.aCoords.br.y);
        // let maxY=Math.max(stn.aCoords.bl.y,stn.aCoords.tl.y,stn.aCoords.tr.y,stn.aCoords.br.y);
        let isInside =
          e.pointer.x >= stn.left &&
          e.pointer.x <= stn.left + stn.width * stn.scaleX &&
          e.pointer.y >= stn.top &&
          e.pointer.y <= stn.top + stn.height * stn.scaleY;
        // let isInside =
        //   e.pointer.x >= minX &&
        //   e.pointer.x <= maxX &&
        //   e.pointer.y >= minY &&
        //   e.pointer.y <= maxY;
        if (isInside) {
          stencilsContainingImage.push(stn);
        }
      }
    });

    if (stencilsContainingImage.length > 0) {
      let stn = stencilsContainingImage[stencilsContainingImage.length - 1];

      if (img.status === "preview" && img.stencil_id === stn.id) {
        return;
      }

      // Store the original source and transformations of the stencil
      let originalSrc = stn.getSrc();

      let originalTransform = {
        left: stn.left,
        top: stn.top,
        scaleX: stn.scaleX,
        scaleY: stn.scaleY,
        angle: stn.angle,
        width: stn.width,
        height: stn.height,
        cropX: stn.cropX || 0,
        cropY: stn.cropY || 0,
        originX: stn.originX,
        originY: stn.originY,
        _widtha: stn._widtha || "",
        _heighta: stn._heighta || "",
      };

      console.log("imageInside", originalTransform);

      img.set({
        opacity: 0.5,
        status: "preview",
        stencil_id: stn.id,
      });
      stn.set({
        image_id: img.id,
      });

      // Use the original image URL or source for better quality
      fabric.Image.fromURL(
        img.getSrc(),
        function (fabricImg) {
          // Determine the scale to use

          stn.setSrc(
            fabricImg.getSrc(),
            function (img) {
              let newWidth, newHeight;
              console.log("old imagedetails-->>>>", fabricImg);
              console.log(
                "old imagedetails-->>>>",
                fabricImg.height,
                "Width",
                fabricImg.width
              );

              const stencilWidth = stn.clipPath.width * stn.clipPath.scaleX;
              const stencilHeight = stn.clipPath.height * stn.clipPath.scaleY;

              // Calculate aspect ratios
              const imageAspectRatio = fabricImg.width / fabricImg.height;
              const stencilAspectRatio = stencilWidth / stencilHeight;

              newWidth = fabricImg.width * fabricImg.scaleX;
              newHeight = fabricImg.height * fabricImg.scaleY;

              stn.width = stn.clipPath.width * stn.clipPath.scaleX;
              stn.height = stn.clipPath.height * stn.clipPath.scaleY;
              console.log(
                "new imagedetails-->>>>",
                fabricImg.height,
                newHeight,
                "Width",
                fabricImg.width,
                newWidth
              );

              if (stencilWidth > newWidth || stencilHeight > newHeight) {
                console.log("base64innnn--->>>>>");

                if (imageAspectRatio > stencilAspectRatio) {
                  newHeight = stencilHeight;
                  newWidth = newHeight * imageAspectRatio;
                } else {
                  newWidth = stencilWidth;
                  newHeight = newWidth / imageAspectRatio;
                }

                // Scale the image to the desired size
                fabricImg.set({
                  scaleX: newWidth / fabricImg.width,
                  scaleY: newHeight / fabricImg.height,
                });

                // Convert the scaled image to a base64 data URL
                const base64Data = fabricImg.toDataURL({
                  format: "png", // Choose 'jpeg' or 'png' based on the desired format
                  quality: 1.0, // Quality between 0 and 1 if using 'jpeg'
                });

                // Set the stencil image source using base64
                stn.setSrc(
                  base64Data,
                  function (img) {
                    img.set({
                      _widtha: newWidth,
                      _heighta: newHeight,
                      cropX: newWidth / 2 - originalTransform.width / 2,
                      cropY: newHeight / 2 - originalTransform.height / 2,
                      placeholder: 1,
                    });

                    // Restore the stencil's original position
                    stn.set({
                      left: originalTransform.left,
                      top: originalTransform.top,
                      width: originalTransform.width,
                      height: originalTransform.height,
                      _widtha: newWidth,
                      _heighta: newHeight,
                    });

                    canvas.renderAll();
                  },
                  {
                    crossOrigin: "anonymous",
                  }
                );
              } else {
                img.set({
                  _widtha: newWidth,
                  _heighta: newHeight,
                  cropX: newWidth / 2 - stn.width / 2,
                  cropY: newHeight / 2 - stn.height / 2,
                  placeholder: 1,
                });
                canvas.renderAll();
              }
            },

            // if (fabricImg.width < fabricImg.height) {
            //   // Calculate based on height
            //   fabricImg.scaleToWidth(320); // Scale based on width
            //   newWidth = fabricImg.getScaledWidth();
            //   newHeight = fabricImg.getScaledHeight();

            // } else if (fabricImg.height < fabricImg.width) {
            //   // Calculate based on width
            //   fabricImg.scaleToHeight(320); // Scale based on height
            //   newWidth = fabricImg.getScaledWidth();
            //   newHeight = fabricImg.getScaledHeight();
            // }
            // else {
            //   fabricImg.scaleToWidth(321); // Scale based on width
            //   newWidth = fabricImg.getScaledWidth();
            //   newHeight = fabricImg.getScaledHeight();
            // }

            // console.log("imagedata--->>>>",img.width,newWidth,"height",img.height,newHeight)

            {
              crossOrigin: "anonymous",
            }
          );

          // Define the event listener function for mouse movement
          function moveHandler(evt) {
            let pointer = canvas.getPointer(evt.e);
            let isInside =
              pointer.x >= stn.left &&
              pointer.x <= stn.left + stn.width * stn.scaleX &&
              pointer.y >= stn.top &&
              pointer.y <= stn.top + stn.height * stn.scaleY;

            if (!isInside && img.status === "preview") {
              // Image is outside the stencil, revert to original
              console.log("NewCal---->>inside preview");
              stn.setSrc(
                originalSrc,
                function () {
                  stn.set({
                    left: originalTransform.left,
                    top: originalTransform.top,
                    scaleX: originalTransform.scaleX,
                    scaleY: originalTransform.scaleY,
                    angle: originalTransform.angle,
                    width: originalTransform.width,
                    height: originalTransform.height,
                    cropX: originalTransform.cropX,
                    cropY: originalTransform.cropY,
                    originX: originalTransform.originX,
                    originY: originalTransform.originY,
                    image_id: null,
                    _widtha: originalTransform._widtha,
                    _heighta: originalTransform._heighta,
                  });
                  canvas.renderAll();
                },
                {
                  crossOrigin: "anonymous",
                }
              );

              img.set({
                opacity: 1,
                status: "normal",
                stencil_id: null,
              });

              // Remove the event listeners
              canvas.off("mouse:move", moveHandler);
              canvas.off("mouse:up", dropHandler);
            }
          }

          // Define the event listener function for mouse up (drop)
          function dropHandler(evt) {
            console.log("NewCal---->>imageDrop");
            if (
              stencilsContainingImage.includes(stn) &&
              img.status === "preview"
            ) {
              // Image was dropped, set the new image as the final source
              img.set({
                opacity: 1,
                status: "normal",
              });
            } else {
              // Image was not dropped, revert to the original source and transformations
              stn.setSrc(
                originalSrc,
                function () {
                  stn.set({
                    left: originalTransform.left,
                    top: originalTransform.top,
                    scaleX: originalTransform.scaleX,
                    scaleY: originalTransform.scaleY,
                    angle: originalTransform.angle,
                    width: originalTransform.width,
                    height: originalTransform.height,
                    cropX: originalTransform.cropX,
                    cropY: originalTransform.cropY,
                    originX: originalTransform.originX,
                    originY: originalTransform.originY,
                    image_id: null,
                  });
                  canvas.renderAll();
                },
                {
                  crossOrigin: "anonymous",
                }
              );
            }
            // Remove the event listeners
            canvas.off("mouse:move", moveHandler);
            canvas.off("mouse:up", dropHandler);
          }

          // Attach the event listeners
          canvas.on("mouse:move", moveHandler);
          canvas.on("mouse:up", dropHandler);
        },
        {
          crossOrigin: "anonymous",
        }
      );
    }
  }
}

// Attach the handleObjectMoving function to the object:moving event

// amn
// let bsase64b = [];
let positionOffsetStencil = 0;
let stencilCount = 0;
export const AddStencil = (url_, Canvas, boxframe) => {
  let url = url_.url;

  let svg1;
  let cropMode_stn = Canvas && Canvas.crop_mode;

  const canvasCenterX = Canvas.getWidth() / 2;
  const canvasCenterY = Canvas.getHeight() / 2;

  let safeAreaW = Canvas._objects[0].width * 0.3;
  let safeAreaH = Canvas._objects[0].height * 0.3;

  fabric.Image.fromURL(
    "/stencil_placeholder_img_resized.jpg",

    function (img) {
      img.set({
        // left: canvasCenterX - (img.width * img.scaleX) / 2,
        // top: canvasCenterY - (img.height * img.scaleY) / 2,
        canvas_page_id: Canvas.pageId,
        imageAngle: undefined,
        width: img.width,
        height: img.height,
        editingAngle: 0,

        _type: "stencil_frame",
        lockScalingFlip: true,
        perPixelTargetFind: true,
        clipPath: new fabric.Path(
          // "M50,0 L150,0 L200,100 L125,200 L25,200 Z",
          url_.imgPath,
          {
            left: -img.width / 2,
            top: -img.height / 2,
          }
        ),
        allImages: [],
        flag: false,
        placeholder: 0,
        id: toString(Math.random()),
        toBeScaleX_: null,
        toBeScaleY_: null,
        count: 1,
      });
      img.clipPath.scaleX = img.width / img.clipPath.width;
      img.clipPath.scaleY = img.height / img.clipPath.height;

      // img.scaleToWidth(300);

      if (safeAreaW < safeAreaH) {
        img.scaleToWidth(safeAreaW);
        console.log("SafeWidth");
      } else if (safeAreaW > safeAreaH) {
        img.scaleToWidth(safeAreaH);
        console.log("SafeHeight");
      }
      // Canvas.setActiveObject(img);
      Canvas.add(img);
      Canvas.setActiveObject(img);

      // if (
      //   img.width * img.scaleX > Canvas._objects[0].width ||
      //   img.height * img.scaleY > Canvas._objects[0].height
      // ) {
      //   const widthScaleFactor = (Canvas._objects[0].width - 30) / img.width;
      //   const heightScaleFactor = (Canvas._objects[0].height - 30) / img.height;

      //   const scaleFactor = Math.min(widthScaleFactor, heightScaleFactor);

      //   img.scale(scaleFactor);
      // }

      const newLeft = canvasCenterX - (img.width * img.scaleX) / 2;
      const newTop = canvasCenterY - (img.height * img.scaleY) / 2;

      img.left = newLeft + positionOffsetStencil;
      img.top = newTop + positionOffsetStencil;

      positionOffsetStencil += 10;
      stencilCount++;

      if (stencilCount >= 10) {
        // Reset positionOffsetShape and the count
        positionOffsetStencil = 0;
        stencilCount = 0;
      }

      StnOrigWidth = img.width;
      StnOrigHeight = img.height;
      // console.log(StnOrigWidth, StnOrigHeight, "Mode Width");

      // img.on("mousedblclick", (e) => {
      //   if (img.placeholder == 0) {
      //   } else {
      //     if (!Canvas.crop_mode) {
      //       Canvas.discardActiveObject();
      //       Canvas.setActiveObject(img);
      //       Canvas.crop_mode = !0;
      //       cropStencils(e, img, Canvas);
      //       cropMode_stn = !0;
      //     } else if (Canvas.crop_mode) {
      //       restoreStencil(img, Canvas);
      //       img.count = img.count + 1;
      //     }
      //   }
      // });

      // Canvas.on("selection:cleared", function (event) {
      //   if (Canvas.crop_mode) {
      //     console.log("selection clearing now", event);
      //     if (event.deselected[0]._type == "stencil_frame") {
      //       restoreStencil(event.deselected[0], Canvas);
      //     }
      //   }
      // });

      // Canvas.on("selection:updated", function (event) {
      //   if (Canvas.crop_mode) {
      //     console.log("selection updating now", event);
      //     if (event.deselected[0]._type == "stencil_frame") {
      //       restoreStencil(event.deselected[0], Canvas);
      //     }
      //   }
      // });

      // img.on("scaling", function (e) {
      //   if (!Canvas.crop_mode) {
      //     if (img.flag == true) {
      //       console.log(img.scaleX, img.clipPath.width, "Mode scale");
      //       img.toBeScaleX_ = (StnOrigWidth * img.scaleX) / img.clipPath.width;
      //       img.toBeScaleY_ = (StnOrigHeight * img.scaleY) / img.clipPath.height;
      //     }
      //   }

      // });
      Canvas.savestate();
      Canvas.renderAll();
    }.bind(this),
    {
      crossOrigin: "anonymous",
    }
  );

  ////////////////////////////////////////////////////////////////

  // Canvas.on("mouse:up", (e) => {
  //   // console.log(e.target, Canvas, "Default");
  //   deleteFromCanvas(e.target, Canvas);
  // });

  // const deleteFromCanvas = (img) => {
  //   let stencils = Canvas.getObjects().filter((obj) => obj._type == "stencil_frame");

  //   stencils.forEach((stn) => {
  //     if (stn.image_id) {
  //       if (img) {
  //         if (img.id) {
  //           if ((stn.image_id && stn.image_id) == (img && img.id && img.id)) {
  //             id__ = img.id;
  //             console.log("removing", stn.image_id, img.id);
  //             Canvas.remove(img);
  //             Canvas.remove(bndry);
  //             if (img.bndry) {
  //               img.bndry = null;
  //             }
  //           }
  //         }
  //       }
  //     }
  //   });

  //   if (id__) {
  //     //console.log(id__)
  //     let i = Canvas.getObjects().filter((obj) => obj.id === id__);
  //     //console.log(i)
  //     Canvas.remove(...i);
  //     id__ = null;
  //   }
  // };

  // Canvas.on("object:moving", (e) => {
  //   // let prevLeft;
  //   if (e.target.typeis && e.target.typeis === "image") {
  //     let img;
  //     let allObj = Canvas.getObjects();
  //     for (let i = 0; i < allObj.length; i++) {
  //       if (allObj[i].id === e.target.id && allObj[i].type === "image") {
  //         img = allObj[i];
  //       }
  //     }

  //     if (img.status == "editor") {
  //       return;
  //     }
  //     let stencils = Canvas.getObjects().filter((obj) => obj._type == "stencil_frame");
  //     let stencilsContainingImage = [];
  //     stencils.forEach((stn, idx) => {
  //       if (!stn.isloading) {
  //         // console.log("isloding", stn.isloading);
  //         // let tr = absoluteToImageframe(e.pointer.x, e.pointer.y, stn);
  //         // let c = new fabric.Point(e.pointer.x, e.pointer.y);
  //         // console.log("cccccccc", c);
  //         console.log("TTTTTTTTTTTTT", stn);
  //         let isInside = stn.containsPoint({ x: e.pointer.x, y: e.pointer.y });
  //         // amn
  //         // c = point to be check (cursor's)

  //         console.log("isloding########################", isInside);
  //         if (isInside) {
  //           stencilsContainingImage.push(stn);
  //         } else {
  //           if (stn.image_id && stn.image_id == img.id) {
  //             console.log("notin", stn.image_id, stn);
  //             removeImage(stn);
  //             img.set({
  //               opacity: 1,
  //               status: "normal",
  //               stencil_id: null,
  //             });
  //             stn.set({
  //               image_id: null,
  //               // opacity: 1,
  //             });

  //             // bsase64b.length > 1
  //             //   ? bsase64b[bsase64b.length - 2]
  //             //   : "./stencil_placeholder_img_resized.jpg",

  //             // stn.setSrc(
  //             //   "./stencil_placeholder_img_resized.jpg",

  //             //   // url,
  //             //   function (img) {
  //             //     img.set({
  //             //       width: img.width,
  //             //       height: img.height,
  //             //       cropX: 0,
  //             //       cropY: 0,
  //             //       // left: img.left + 80,
  //             //       // left: img.left - prevLeft,
  //             //     });
  //             //     console.log(stn.allImages, "maggee");
  //             //   }.bind(this),
  //             //   {
  //             //     crossOrigin: "anonymous",
  //             //   }
  //             // );

  //             if (stn.allImages.length > 1) {
  //               stn.setSrc(
  //                 stn.allImages[stn.allImages.length - 2],
  //                 function (img) {
  //                   img.set({
  //                     cropX: img.width / 2 - 160,
  //                     width: 321,
  //                     cropY: img.height / 2 - 160,
  //                     height: 321,
  //                   });
  //                 }.bind(this),
  //                 {
  //                   crossOrigin: "anonymous",
  //                 }
  //               );
  //             } else {
  //               stn.setSrc(
  //                 "/stencil_placeholder_img_resized.jpg",

  //                 // url,
  //                 function (img) {
  //                   img.set({
  //                     width: img.width,
  //                     height: img.height,
  //                     cropX: 0,
  //                     cropY: 0,
  //                     placeholder: 0,
  //                     // left: img.left + 80,
  //                     // left: img.left - prevLeft,
  //                   });
  //                 }.bind(this),
  //                 {
  //                   crossOrigin: "anonymous",
  //                 }
  //               );
  //             }
  //             console.log(stn.allImages.length, "maggee");
  //           }
  //         }
  //       }
  //     });
  //     stencilsContainingImage.forEach((stn, idx) => {
  //       if (idx == stencilsContainingImage.length - 1) {
  //         if (img.status == "preview" && img.stencil_id == stn.id) {
  //           return;
  //         }
  //         img.set({
  //           opacity: 0.5,
  //           status: "preview",
  //           stencil_id: stn.id,
  //         });

  //         stn.set({
  //           image_id: img.id,
  //         });

  //         fabric.Image.fromURL(
  //           img._element.currentSrc,

  //           function (img) {
  //             if (img.width > img.height) {
  //               img.scaleToHeight(StnOrigHeight);
  //             } else if (img.height > img.width) {
  //               img.scaleToWidth(StnOrigWidth);
  //             } else {
  //               img.scaleToWidth(StnOrigWidth + 1);
  //             }

  //             // img.scaleToHeight(320);
  //             bsase64 = img.toDataURL();
  //             // if (bsase64b[bsase64b.length - 1] != img.toDataURL()) {
  //             //   bsase64b.push(img.toDataURL());
  //             // }

  //             // console.log(bsase64, "maggee");
  //           }.bind(this),
  //           {
  //             crossOrigin: "anonymous",
  //           }
  //         );

  //         setTimeout(() => {
  //           stn.setSrc(
  //             `${bsase64}`,

  //             function (img) {
  //               // let a = img.clipPath;
  //               img.set({
  //                 _widtha: img.width,
  //                 _heighta: img.height,

  //                 // width: 321,
  //                 // height: 321,
  //                 // cropX: img.width /2 - stn.width/2
  //                 cropX: img.width / 2 - 160,
  //                 width: 321,
  //                 cropY: img.height / 2 - 160,
  //                 height: 321,
  //                 placeholder: 1,
  //               });
  //               // console.log(img.width / 2 - 320 / 2, "leftdddd");
  //               // prevLeft = img.width / 2 - 320 / 2;
  //               // console.log(prevLeft, "leftdddd1");
  //               Canvas.renderAll();

  //               if (img.allImages[img.allImages.length - 1] != bsase64) {
  //                 img.allImages.push(bsase64);
  //               }
  //               // Canvas.on("mouse:up", (e) => {
  //               //   console.log("maggeeDropped");
  //               //   if (img.allImages[img.allImages.length - 1] != bsase64) {
  //               //     img.allImages.push(bsase64);
  //               //   }
  //               //   console.log(img.allImages.length, "maggee");
  //               // });
  //             }.bind(this),
  //             {
  //               crossOrigin: "anonymous",
  //             }
  //           );
  //         });
  //         // amn

  //         // if (vptwidth >= vptheight) {
  //         // if (img._width <= stn.width) {
  //         //   // alert('hello img.width<=canvas.width');
  //         //   let newWidth1 = (img.width / img.height) * stn.width;
  //         //   let newscale1 = newWidth1 / img.width;
  //         //   let newheight2 = (img.height / img.width) * stn.width;
  //         //   let newscale2 = newheight2 / img._height;
  //         //   if (newscale1 < newscale2) {
  //         //     console.log(img.scale(newscale2), "insta");
  //         //   } else {
  //         //     console.log(img.scale(newscale1), "insta");
  //         //   }
  //         // }

  //         // if (img.width >= stn.width) {
  //         //   // alert('hello img.width>=canvas.width');
  //         //   let newheight1 = (img.height / img.width) * stn.width;
  //         //   let newscale1 = newheight1 / img.height;

  //         //   let newWidth1 = (img.width / img.height) * stn.height;
  //         //   let newscale2 = newWidth1 / img._width;
  //         //   if (newscale1 < newscale2) {
  //         //     console.log(img.scale(newscale2), "insta222");
  //         //   } else {
  //         //     console.log(img.scale(newscale1), "insta222");
  //         //   }
  //         // }

  //         //             img.width =
  //         // img.height =
  //         // console.log(stn.width / img.scaleX, "checkingIfCondition");
  //         // console.log(stn.width / img.scaleY, "checkingIfCondition");
  //         // }
  //         // else if (vptwidth < vptheight) {
  //         //   // alert("vptwidth < vptheight")
  //         //   if (img._height <= vptheight) {
  //         //     // img.scale(vptheight / img._height);
  //         //     // alert("img._height <= vptheight")
  //         //     let newheight1 = (img._height / img._width) * vptwidth;
  //         //     let newscale1 = newheight1 / img._height;
  //         //     let newwidtht2 = (img._width / img._height) * vptheight;
  //         //     let newscale2 = newwidtht2 / img._width;
  //         //     if (newscale1 < newscale2) {
  //         //       img.scale(newscale2);
  //         //     } else {
  //         //       img.scale(newscale1);
  //         //     }
  //         //   } else if (img._height >= vptheight) {
  //         //     // alert("img._height >= vptheight")
  //         //     // img.scale(vptwidth / img._width);
  //         //     let newheight1 = (img._height / img._width) * vptwidth;
  //         //     let newscale1 = newheight1 / img._height;

  //         //     let newWidth1 = (img._width / img._height) * vptheight;
  //         //     let newscale2 = newWidth1 / img._width;
  //         //     if (newscale1 > newscale2) {
  //         //       img.scale(newscale1);
  //         //     } else {
  //         //       img.scale(newscale2);
  //         //     }
  //         //   } else {
  //         //     // alert("elseee")
  //         //     img.scale(vptheight / img._height);
  //         //   }
  //         //   img.width = vptwidth / img.scaleX;
  //         //   img.height = vptheight / img.scaleY;
  //         // }
  //         // amn

  //         console.log("amn______stn", stn);
  //         // });
  //       } else {
  //         if (stn.image_id && stn.image_id == img.id) {
  //           removeImage(stn, Canvas);
  //           img.set({
  //             opacity: 1,
  //             status: "normal",
  //             stencil_id: null,
  //           });

  //           stn.set({
  //             image_id: null,
  //           });
  //         }
  //       }
  //     });
  //   }
  // });

  // Canvas.on("object:moving", handleImageToStencilMovement);
  // const removeImage = (stn,Canvas) => {
  //   let _imgid = stn.image_id;
  //   let _img;
  //   // stn.getObjects().forEach((o) => {
  //   //   if (o.id == _imgid) {
  //   //     _img = o;
  //   //   }
  //   // });
  //   console.log("stenciiiiiiiiiiiiiiiiiiiii", stn);

  //   if (_img && _img.status == "insideFrame") {
  //     if (stn._oldImage) {
  //       stn.set({
  //         image_id: stn._oldImage.id,
  //       });
  //       stn.remove(_img);
  //       stn.add(stn._oldImage);
  //       Canvas.remove(bndry);
  //       Canvas.renderAll();
  //     } else {
  //       stn.set({
  //         image_id: null,
  //       });
  //       stn.remove(_img);
  //       Canvas.remove(bndry);
  //       if (_img.bndry) {
  //         _img.bndry = null;
  //       }
  //       Canvas.renderAll();
  //     }
  //   }
  // };

  // const handleImageScaling = (e) => {
  //   let transform = e.transform;
  //   let cr = transform.corner;
  //   let m = Canvas.overlayImage;
  //   let _img = e.transform.target;
  //   if (!m) {
  //     return;
  //   }

  //   let bx1 = m.clipPath.left;
  //   let bx2 = m.clipPath.left + m.clipPath.getScaledWidth();
  //   let by1 = m.clipPath.top;
  //   let by2 = m.clipPath.top + m.clipPath.getScaledHeight();

  //   let ix1 = _img.left;
  //   let ix2 = _img.left + _img.getScaledWidth();
  //   let iy1 = _img.top;
  //   let iy2 = _img.top + _img.getScaledHeight();

  //   let c1 = ix1 >= bx1;
  //   let c2 = ix2 <= bx2;
  //   let c3 = iy1 >= by1;
  //   let c4 = iy2 <= by2;

  //   if (cr == "tl") {
  //     // c1 or c3
  //     if (c1 && c3) {
  //       let dh = Math.abs(iy1 - by1);
  //       let dw = Math.abs(ix1 - bx1);
  //       let r = _img.height / _img.width;
  //       let dhh = r * dw;
  //       if (dhh <= dh) {
  //         c1 = false;
  //       } else {
  //         c3 = false;
  //       }
  //     }
  //   } else if (cr == "tr") {
  //     // c2 or c3
  //     if (c2 && c3) {
  //       let dh = Math.abs(iy1 - by1);
  //       let dw = Math.abs(ix2 - bx2);
  //       let r = _img.height / _img.width;
  //       let dhh = r * dw;
  //       if (dhh <= dh) {
  //         c2 = false;
  //       } else {
  //         c3 = false;
  //       }
  //     }
  //   } else if (cr == "bl") {
  //     // c1 or c4
  //     if (c1 && c4) {
  //       let dh = Math.abs(iy2 - by2);
  //       let dw = Math.abs(ix1 - bx1);
  //       let r = _img.height / _img.width;
  //       let dhh = r * dw;
  //       if (dhh <= dh) {
  //         c1 = false;
  //       } else {
  //         c4 = false;
  //       }
  //     }
  //   } else if (cr == "br") {
  //     // c2 or c4
  //     if (c2 && c4) {
  //       let dh = Math.abs(iy2 - by2);
  //       let dw = Math.abs(ix2 - bx2);
  //       let r = _img.height / _img.width;
  //       let dhh = r * dw;
  //       if (dhh <= dh) {
  //         c2 = false;
  //       } else {
  //         c4 = false;
  //       }
  //     }
  //   } else {
  //     //not expected here
  //   }

  //   if (c1) {
  //     //1
  //     let sx = (ix2 - bx1) / _img.width;
  //     if (cr == "tl") {
  //       _img.set({
  //         top: _img._top ? _img._top : _img.top,
  //         left: bx1,
  //         scaleX: sx,
  //         scaleY: sx,
  //         _top: _img._top ? _img._top : _img.top,
  //       });
  //     } else {
  //       _img.set({
  //         left: bx1,
  //         scaleX: sx,
  //         scaleY: sx,
  //       });
  //     }
  //   } else if (c2) {
  //     //2
  //     let sx = (bx2 - ix1) / _img.width;
  //     if (cr == "tr") {
  //       _img.set({
  //         top: _img._top ? _img._top : _img.top,
  //         _top: _img._top ? _img._top : _img.top,
  //         scaleY: sx,
  //         scaleX: sx,
  //       });
  //     } else {
  //       _img.set({
  //         scaleY: sx,
  //         scaleX: sx,
  //       });
  //     }
  //   } else if (c3) {
  //     //3
  //     let sx = (iy2 - by1) / _img.height;
  //     if (cr == "tl") {
  //       _img.set({
  //         top: by1,
  //         left: _img._left ? _img._left : _img.left,
  //         _left: _img._left ? _img._left : _img.left,
  //         scaleX: sx,
  //         scaleY: sx,
  //       });
  //     } else {
  //       _img.set({
  //         top: by1,
  //         scaleX: sx,
  //         scaleY: sx,
  //       });
  //     }
  //   } else if (c4) {
  //     //4
  //     let sy = (by2 - iy1) / _img.height;
  //     if (cr == "bl") {
  //       _img.set({
  //         left: _img._left ? _img._left : _img.left,
  //         _left: _img._left ? _img._left : _img.left,
  //         scaleY: sy,
  //         scaleX: sy,
  //       });
  //     } else {
  //       _img.set({
  //         scaleY: sy,
  //         scaleX: sy,
  //       });
  //     }
  //   } else {
  //     _img.isConstraint = null;
  //     _img._top = null;
  //     _img._left = null;
  //   }
  // };

  // const handle_m_scaling = (e, m, stn, img__) => {
  //   console.log("scalingboundryh", e);
  //   let new_bndry = new fabric.Polygon(stn.getObjects()[1].clipPath.points, {
  //     left: e.transform.target.left,
  //     top: e.transform.target.top,
  //     fill: "rgba(255,255,255,0)",
  //     opacity: 0.5,
  //     width: e.transform.target.width + 2 / e.transform.target.scaleX,
  //     height: e.transform.target.height + 2 / e.transform.target.scaleY,
  //     inverted: true,
  //     absolutePositioned: true,
  //     scaleX: e.transform.target.scaleX,
  //     scaleY: e.transform.target.scaleY,
  //     angle: e.transform.target.angle,
  //     lockScalingFlip: true,
  //     strokeWidth: 0,
  //   });
  //   m.clipPath = new_bndry;
  //   Canvas.renderAll();
  //   stn.set({
  //     left: new_bndry.left,
  //     top: new_bndry.top,
  //     angle: new_bndry.angle,
  //     width: e.transform.target.width + 2 / e.transform.target.scaleX,
  //     height: e.transform.target.height + 2 / e.transform.target.scaleY,
  //     scaleX: e.transform.target.scaleX,
  //     scaleY: e.transform.target.scaleY,
  //   });

  //   //stop bndry scaling//
  //   if (img__.bndry) {
  //     var t = img__.scaleX,
  //       a = img__.scaleY,
  //       n = img__.getCenterPoint(),
  //       i = img__.scaleX,
  //       r = img__.scaleY;
  //     img__.scaleX = i;
  //     img__.scaleY = r;
  //     img__.setPositionByOrigin(n, "center", "center");
  //     img__.setCoords();
  //     for (
  //       var o = e.transform, c = img__.bndry.getCoords(!0, !0), s = !0, l = 0;
  //       l < c.length;
  //       l++
  //     ) {
  //       var u = c[l];
  //       if (!img__.containsPoint(u, null, !0)) {
  //         //console.log("breakked")
  //         s = !1;
  //         break;
  //       }
  //     }

  //     if (
  //       !s &&
  //       img__.editingClipScaleX &&
  //       img__.editingClipScaleY &&
  //       img__.editingClipScaleH &&
  //       img__.editingClipScaleW
  //     ) {
  //       var h = img__.bndry.translateToOriginPoint(
  //         img__.bndry.getCenterPoint(),
  //         o.originX,
  //         o.originY
  //       );
  //       img__.bndry.scaleX = img__.editingClipScaleX;
  //       img__.bndry.scaleY = img__.editingClipScaleY;
  //       img__.bndry.width = img__.editingClipScaleW;
  //       img__.bndry.height = img__.editingClipScaleH;
  //       img__.bndry.setPositionByOrigin(h, o.originX, o.originY);
  //       new_bndry.scaleX = img__.editingClipScaleX;
  //       new_bndry.scaleY = img__.editingClipScaleY;
  //       new_bndry.width = img__.editingClipScaleW;
  //       new_bndry.height = img__.editingClipScaleH;
  //       new_bndry.setPositionByOrigin(h, o.originX, o.originY);
  //       stn.scaleX = img__.editingClipScaleX;
  //       stn.scaleY = img__.editingClipScaleY;
  //       stn.width = img__.editingClipScaleW;
  //       stn.height = img__.editingClipScaleH;
  //       stn.setPositionByOrigin(h, o.originX, o.originY);
  //       Canvas.setActiveObject(img__);
  //       //console.log("if", img__.bndry.width, img__.bndry.height)
  //     } else
  //       isPerpendicular(img__) ||
  //         ((img__.editingClipScaleX = img__.bndry.scaleX),
  //         (img__.editingClipScaleY = img__.bndry.scaleY),
  //         (img__.editingClipScaleW = img__.bndry.width),
  //         (img__.editingClipScaleH = img__.bndry.height));
  //     {
  //       img__.editingClipScaleX = img__.bndry.scaleX;
  //       img__.editingClipScaleY = img__.bndry.scaleY;
  //       img__.editingClipScaleH = img__.bndry.height;
  //       img__.editingClipScaleW = img__.bndry.width;

  //       img__.scaleX = t;
  //       img__.scaleY = a;
  //       img__.setPositionByOrigin(n, "center", "center");
  //       img__.setCoords();
  //       Canvas.setActiveObject(img__);
  //     }
  //   }
  // };
  // const isPerpendicular = (g) => {
  //   var e = (g.imageAngle || 0) % 90;
  //   return e <= 1 || 90 - e <= 1;
  // };
  // const _on_Mousedown_new = (e) => {
  //   let t = e.transform,
  //     a = t && t.corner,
  //     n;
  //   // , i = getPreviousCenterPoint(e);

  //   e.transform ? (n = getOriginalRects(t.target)) : (n = getOriginalRects(e.target));
  //   console.log("inmousedown", e, n, e.transform);
  //   if (n && e && e.transform) {
  //     console.log("inmousedown in=-", t.target.bndry, t.target);
  //     // t.target.bndry.rotate(-t.target.imageAngle);
  //     let r = t.target.bndry.getBoundingRect(!0, !0);
  //     t.target.bndry.rotate(t.target.angle);
  //     let o = fabric.util.rotatePoint(
  //         new fabric.Point(t.target.left, t.target.top),
  //         t.target.bndry.getCenterPoint(),
  //         fabric.util.degreesToRadians(-t.target.angle)
  //       ),
  //       c = 0,
  //       l = 0,
  //       u = t.target.width,
  //       h = t.target.height,
  //       d = t.target.width * t.target.scaleX,
  //       p = t.target.height * t.target.scaleY,
  //       g = r.left - o.x,
  //       f = r.top - o.y;

  //     if ("tl" === t.corner) {
  //       c = (d - g) / u;
  //       l = (p - f) / h;
  //     } else if ("tr" === t.corner) {
  //       c = (g + r.width) / u;
  //       l = (p - f) / h;
  //     } else if ("br" === t.corner) {
  //       c = (g + r.width) / u;
  //       l = (f + r.height) / h;
  //     } else if ("bl" === t.corner) {
  //       c = (d - g) / u;
  //       l = (f + r.height) / h;
  //     }

  //     let z = Math.max(c, l);

  //     t.target.minScale = z;
  //     console.log("minScale====", z, t.target.minScale);
  //   } else {
  //     return;
  //   }
  // };
  // const _on_Scaling = (e) => {
  //   let img_ = e.transform.target;
  //   // this.imageChanged = !0;
  //   // img.map((imag) => {
  //   //   if (imag.id == e.transform.target.id) {
  //   //     img_ = imag;
  //   //   }
  //   // });
  //   var t = e.transform,
  //     a = t && t.corner,
  //     n = getOriginalRects(img_);
  //   if (img_.status === "editor" && e && e.transform && n) {
  //     // //console.log(img_.cropArea.scaleX, img_.scaleX, cropArea.width, cropArea.getScaledWidth())
  //     if (img_.scaleX <= img_.minScale || img_.scaleY <= img_.minScale) {
  //       var i = img_.translateToOriginPoint(
  //         img_.getCenterPoint(),
  //         t.originX,
  //         t.originY
  //       );
  //       img_.scaleX = img_.minScale;
  //       img_.scaleY = img_.minScale;
  //       img_.setPositionByOrigin(i, t.originX, t.originY);
  //     } else img_.editingScale = img_.scaleX;
  //     // img_.minScale = img_.scaleX;
  //     // this._showOrHideGridlines(!0)
  //   } else {
  //   }
  //   // this._isChangeSizeCorner(a) && this._onSizeChanged(a)
  // };
  // const getOriginalRects_ = (e) => {
  //   var t = e.getCenterPoint(),
  //     a = fabric.util.rotatePoint(new fabric.Point(e.left, e.top), t, fabric.util.degreesToRadians(-(e.angle || 0)));

  //   return {
  //     x: a.x,
  //     y: a.y,
  //     width: e._width,
  //     height: e._height,
  //     scaleX: e.scaleX,
  //     scaleY: e.scaleY,
  //   };
  // };
  // const getOriginalRects = (e) => {
  //   if (e.status === "editor") {
  //     var t = e.getCenterPoint(),
  //       a = fabric.util.rotatePoint(new fabric.Point(e.left, e.top), t, fabric.util.degreesToRadians(-(e.angle || 0)));
  //     return {
  //       x: a.x,
  //       y: a.y,
  //       width: e._width,
  //       height: e._height,
  //       scaleX: e.scaleX,
  //       scaleY: e.scaleY,
  //     };
  //   }
  // };
  // const setImageToGrid = (img1) => {
  //   if (img1.stencil_id && img1.status == "preview") {
  //     img1.status = "insideFrame";
  //     let whichstencil = img1.stencil_id;
  //     let stn = Canvas.getObjects().filter((obj) => obj.id == whichstencil)[0];
  //     console.log(stn.scaleX, stn.scaleY);
  //     if (stn.getObjects().length == 3) {
  //       stn._oldImage = stn.getObjects()[2];
  //       stn.remove(stn.getObjects()[2]);
  //     }
  //     let _clipPath = "";
  //     let sImage = stn.getObjects()[1];
  //     sImage.clipPath.clone(function (cloned) {
  //       _clipPath = cloned;
  //     });
  //     sImage.clipPath.clone((cloned) => {
  //       bndry = cloned;
  //     });
  //     let t =
  //       (stn.scaleY * stn.height) / 2 +
  //       stn.top +
  //       stn.scaleY * sImage.top +
  //       (stn.scaleY * sImage.height * sImage.scaleY) / 2 +
  //       stn.scaleY * sImage.clipPath.top * sImage.scaleY;
  //     let l =
  //       (stn.scaleX * stn.width) / 2 +
  //       stn.left +
  //       stn.scaleX * sImage.left +
  //       (stn.scaleX * sImage.width * sImage.scaleX) / 2 +
  //       stn.scaleX * sImage.clipPath.left * sImage.scaleX;

  //     let clipWidth = _clipPath.width * sImage.scaleX * sImage.clipPath.scaleX * stn.scaleX;
  //     let clipHeight = _clipPath.height * sImage.scaleY * sImage.clipPath.scaleY * stn.scaleY;
  //     let x = clipWidth / img1.width;
  //     let y = clipHeight / img1.height;

  //     if (x > y) {
  //       img1.scaleToWidth(clipWidth);
  //       let offset = (img1.getScaledHeight() - clipHeight) / 2;
  //       img1.left = l;
  //       img1.top = t - offset;
  //       // img1.scale((stn.width * stn.scaleY) / img1.height)
  //       // console.log("clipwidthhhh", clipWidth, img1.scaleX, img1.scaleY)
  //     } else {
  //       img1.scaleToHeight(clipHeight);
  //       let offset = (img1.getScaledWidth() - clipWidth) / 2;
  //       img1.left = l - offset;
  //       img1.top = t;
  //     }
  //     bndry.set({
  //       absolutePositioned: true,
  //       top: t,
  //       left: l,
  //       angle: stn.angle,
  //       scaleX: sImage.scaleX * sImage.clipPath.scaleX * stn.scaleX,
  //       scaleY: sImage.scaleY * sImage.clipPath.scaleY * stn.scaleY,
  //       stroke: "blue",
  //       // strokeWidth: 1,
  //       fill: "transparent",
  //       selectable: false,
  //       perPixelTargetFind: true,
  //     });

  //     img1.set({
  //       status: "insideFrame",

  //       top: (img1.top - stn.top - (stn.height * stn.scaleY) / 2) / stn.scaleY,
  //       left: (img1.left - stn.left - (stn.width * stn.scaleX) / 2) / stn.scaleX,
  //       opacity: 1,
  //       clipPath: _clipPath.set({
  //         top: t / img1.scaleY - img1.top / img1.scaleY - img1.height / 2,
  //         left: l / img1.scaleX - img1.left / img1.scaleX - img1.width / 2,
  //         scaleX: (sImage.scaleX * sImage.clipPath.scaleX) / (img1.scaleX / stn.scaleX),
  //         scaleY: (sImage.scaleY * sImage.clipPath.scaleY) / (img1.scaleY / stn.scaleY),
  //       }),

  //       scaleX: img1.scaleX / stn.scaleX,
  //       scaleY: img1.scaleY / stn.scaleY,
  //     });
  //     console.log("clippathnow", img1.clipPath.top, img1.clipPath.left, img1.clipPath.points);
  //     Canvas.remove(img1);
  //     stn.add(img1);
  //     Canvas.add(bndry);
  //     Canvas.renderAll();
  //   } else if (img1.stencil_id && img1.status == "editor") {
  //     img1.status = "insideFrame";
  //     let whichstencil = img1.stencil_id;
  //     let stn = Canvas.getObjects().filter((obj) => obj.id == whichstencil)[0];
  //     if (stn.getObjects().length == 3) {
  //       stn.remove(stn.getObjects()[2]);
  //     }
  //     let _clipPath = "";
  //     let sImage = stn.getObjects()[1];
  //     sImage.clipPath.clone(function (cloned) {
  //       _clipPath = cloned;
  //     });

  //     // let ab = { x: stn.left, y: stn.top }
  //     // let b = fabric.util.rotatePoint(new fabric.Point(((img1.left - stn.left - (stn.width * stn.scaleX) / 2) / stn.scaleX), ((img1.top - stn.top - (stn.height * stn.scaleY) / 2) / stn.scaleY)), ab, fabric.util.degreesToRadians(stn.angle));
  //     // let cv = fabric.util.rotatePoint(new fabric.Point((l / img1.scaleX - img1.left / img1.scaleX - img1.width / 2), (t / img1.scaleY - img1.top / img1.scaleY - img1.height / 2)), stn.getCenterPoint(), fabric.util.degreesToRadians(-stn.angle))
  //     //console.log("editor--->>>", img1, stn)

  //     //////
  //     let cREct = getOriginalRect(stn, img1.bndry);
  //     let iRect = getOriginalRect(img1, img1.bndry);
  //     //////
  //     let t =
  //       (stn.scaleY * stn.height) / 2 +
  //       cREct.y +
  //       stn.scaleY * sImage.top +
  //       (stn.scaleY * sImage.height * sImage.scaleY) / 2 +
  //       stn.scaleY * sImage.clipPath.top * sImage.scaleY;
  //     let l =
  //       (stn.scaleX * stn.width) / 2 +
  //       cREct.x +
  //       stn.scaleX * sImage.left +
  //       (stn.scaleX * sImage.width * sImage.scaleX) / 2 +
  //       stn.scaleX * sImage.clipPath.left * sImage.scaleX;
  //     // console.log("before", img1.top, stn.top, iRect.y, cREct.y, stn.height, stn.scaleY, stn)
  //     img1.set({
  //       status: "insideFrame",
  //       angle: 0,
  //       top: (iRect.y - cREct.y - (stn.height * stn.scaleY) / 2) / stn.scaleY,
  //       left: (iRect.x - cREct.x - (stn.width * stn.scaleX) / 2) / stn.scaleX,
  //       opacity: 1,
  //       clipPath: _clipPath.set({
  //         top: t / img1.scaleY - iRect.y / img1.scaleY - img1.height / 2,
  //         left: l / img1.scaleX - iRect.x / img1.scaleX - img1.width / 2,
  //         scaleX: (sImage.scaleX * sImage.clipPath.scaleX) / (img1.scaleX / stn.scaleX),
  //         scaleY: (sImage.scaleY * sImage.clipPath.scaleY) / (img1.scaleY / stn.scaleY),
  //       }),
  //       scaleX: img1.scaleX / stn.scaleX,
  //       scaleY: img1.scaleY / stn.scaleY,
  //       bndry: null,
  //     });
  //     // console.log("after", img1.top, stn.top, stn.height, stn.scaleY, stn)
  //     stn.image_id = img1.id;
  //     stn.selectable = true;
  //     Canvas.overlayImage = null;
  //     Canvas.remove(img1);
  //     Canvas.remove(bndry);
  //     stn.add(img1);
  //     Canvas.setActiveObject(stn);
  //     Canvas.renderAll();
  //     cropMode_stn = !1;
  //     Canvas.crop_mode = !1;
  //   } else {
  //   }
  // };

  // const getOriginalRect = (e, ca) => {
  //   if (e) {
  //     var t = ca.getCenterPoint(),
  //       a = fabric.util.rotatePoint(new fabric.Point(e.left, e.top), t, fabric.util.degreesToRadians(-(e.angle || 0)));
  //     return {
  //       x: a.x,
  //       y: a.y,
  //       width: e.width,
  //       height: e.height,
  //     };
  //   }
  // };
  // const _adjust_PositionV2 = (g) => {
  //   var e = getPreviousCenterPoint(g),
  //     t = fabric.util.rotatePoint(
  //       new fabric.Point(g.transform.target.left, g.transform.target.top),
  //       e,
  //       fabric.util.degreesToRadians(-g.transform.target.angle)
  //     ),
  //     a = t.x,
  //     n = t.y;
  //   g.transform.target.bndry.rotate(-g.transform.target.imageAngle);
  //   var i = g.transform.target.bndry.getBoundingRect(!0, !0);
  //   g.transform.target.bndry.rotate(g.transform.target.angle);
  //   g.transform.target.bndry.rotate(g.transform.target.angle);
  //   var r = i.left - (g.transform.target.getScaledWidth() - i.width),
  //     o = i.top - (g.transform.target.getScaledHeight() - i.height),
  //     c = _(r, a, i.left),
  //     l = _(o, n, i.top);
  //   if (c != a || l != n) {
  //     var u = fabric.util.rotatePoint(
  //       new fabric.Point(c, l),
  //       e,
  //       fabric.util.degreesToRadians(g.transform.target.angle)
  //     );
  //     g.transform.target.set("left", u.x), g.transform.target.set("top", u.y);
  //   }
  //   //console.log(g)
  // };
  /////////////////////////////////////////////////////////////////
  // const getPreviousCenterPoint = (g) => {
  //   // //console.log(g)
  //   return (
  //     g.transform.target.bndry && g.transform.target.bndry.getCenterPoint()
  //   );
  // };
  // function _(e, t, a) {
  //   return fabric.util.capValue(e, t, a);
  // }
  // Canvas.on("selection:cleared", (e) => {
  //   // console.log("selection Update", e)
  //   if (Canvas.crop_mode) {
  //     if (e.deselected) {
  //       console.log("selection cleared", e.deselected[0].stencil_id, svg1.id);
  //       // let o = (e.deselected[0]);

  //       if (e.deselected[0].id === "_bndry") {
  //         // console.log("selection cleared from stencil");
  //         setImageToGrid(e.deselected[0].pimg);
  //       } else if (e.deselected[0].stencil_id === svg1.id) {
  //         setImageToGrid(e.deselected[0]);
  //       }
  //     }
  //   }
  // });

  // to be done amn

  // Canvas.on("selection:updated", (e) => {
  //   // console.log("selection Update", e)
  //   if (Canvas.crop_mode) {
  //     if (e.deselected) {
  //       // console.log("selection cleared", e.deselected[0].stencil_id, svg1.id);
  //       // let o = (e.deselected[0]);

  //       if (
  //         e.deselected[0].id === "_bndry" &&
  //         (e.selected[0].stencil_id && e.selected[0].stencil_id) === svg1.id
  //       ) {
  //         return;
  //       } else if (
  //         // e.deselected[0].id === Active_img && Active_img.id &&
  //         // e.selected[0].id === "_cropArea"
  //         e.deselected &&
  //         e.deselected.length >= 1 &&
  //         (e.deselected[0].stencil_id && e.deselected[0].stencil_id) ===
  //           svg1.id &&
  //         e.selected &&
  //         e.selected.length >= 1 &&
  //         e.selected[0].id === "_bndry"
  //       ) {
  //         // console.log("canvas.crop_mode, from IMageApi upper", canvas.croping_active_img.id, e.deselected[0].id, e.selected[0].id)
  //         return;
  //         // console.log("selection cleared from stencil");
  //       } else if (
  //         (e.deselected[0].stencil_id && e.deselected[0].stencil_id) ===
  //           svg1.id &&
  //         e.selected &&
  //         e.selected.length >= 1 &&
  //         e.selected[0].id != "_bndry"
  //       ) {
  //         setImageToGrid(e.deselected[0]);
  //       } else if (
  //         e.deselected[0].id === "_bndry" &&
  //         e.selected &&
  //         e.selected.length >= 1 &&
  //         (e.selected[0].stencil_id && e.selected[0].stencil_id) != svg1.id
  //       ) {
  //         setImageToGrid(e.deselected[0].pimg);
  //       }
  //       // setImageToGrid(e.deselected[0].pimg)
  //     }
  //   }
  // });
  // Canvas.on("selection:updated", (e) => {
  //   if (cropMode_stn) {
  //     console.log("selection:update", e);
  //     if (
  //       e.deselected[0].id === "_bndry" &&
  //       e.selected[0].stencil_id === svg1.id
  //     ) {
  //       return;
  //     } else if (
  //       e.deselected[0].stencil_id === svg1.id &&
  //       e.selected[0].id === "_bndry"
  //     ) {
  //       return;
  //     } else if (e.deselected[0].id === "_bndry") {
  //       if (e.selected[0].id !== svg1.id) {
  //         setImageToGrid(e.deselected[0].pimg);
  //       }

  //       console.log("selection Updated, from stencilapi");
  //     }
  //   }
  // });
};

// feku's code

// lines and arrow
let positionOffsetLine = 0;
let LineCount = 0;
function lineCallBack(objects, Canvas, options) {
  let safeAreaW = Canvas._objects[0].width * 0.4;
  let safeAreaH = Canvas._objects[0].height * 0.4;
  const canvasCenterX = Canvas.getWidth() / 2;
  const canvasCenterY = Canvas.getHeight() / 2;
  console.log(
    "objects in linecallback",
    objects,
    typeof objects,
    objects.length,
    objects.type
  );
  if (objects.type === "line") {
    objects = [objects];
  }
  if (objects.type === "line") {
    objects = [objects];
  }
  let loadedObject = fabric.util.groupSVGElements(objects, options);

  if (loadedObject.type === "line") {
    var group = new fabric.Group([], {
      /* group options */
    });
    group.addWithUpdate(loadedObject);
    loadedObject = group;
  }
  objects.forEach((element) => {
    console.log(element, "elementsss");
    if (element.id.includes("_")) {
      element.differINwidth = Number(element.id.trim().split("_")[1]);
      element.id = element.id.trim().split("_")[0];
    }
    if (element.id === "right") {
      loadedObject.set({
        pointright: true,
      });
      element.set({
        originX: "right",
        originY: "center",
      });
      element.setPositionByOrigin(
        new fabric.Point(element.left, element.top),
        "left",
        "top"
      );
      if (element.strokeMiterLimit <= 10) {
        element.width =
          element.width + (element.strokeMiterLimit / 3) * element.scaleX;
        element.height =
          element.height + (element.strokeMiterLimit / 3) * element.scaleY;
      }
    } else if (element.id === "left") {
      loadedObject.set({
        pointleft: true,
      });
      element.set({
        originX: "left",
        originY: "center",
      });
      element.setPositionByOrigin(
        new fabric.Point(element.left, element.top),
        "left",
        "top"
      );
      if (element.strokeMiterLimit <= 10) {
        element.width =
          element.width + (element.strokeMiterLimit / 3) * element.scaleX;
        element.height =
          element.height + (element.strokeMiterLimit / 3) * element.scaleY;
      }
    } else if (element.id === "line") {
      element.set({
        originX: "left",
        originY: "center",
        // strokeLineJoin: "round"
      });
      element.setPositionByOrigin(
        new fabric.Point(element.left, element.top),
        "left",
        "top"
      );
    }
    element._type = element.id;
  });

  loadedObject.set({
    // left: 100,
    // top: 100,
    left: canvasCenterX + positionOffsetLine,
    top: canvasCenterY + positionOffsetLine,
    lockScalingFlip: true,
    strokeUniform: true,
    minScaleLimit: 0.2,
    minWidth: 10,
    _type: "line",
    dirty: false,
    changeRotationWhenScaleX: true,
    originX: "center",
    originY: "center",
  });

  if (safeAreaW < safeAreaH) {
    loadedObject.scaleToWidth(safeAreaW);
  } else if (safeAreaW > safeAreaH) {
    loadedObject.scaleToWidth(safeAreaH);
  }

  // if (
  //   loadedObject.width > Canvas._objects[0].width ||
  //   loadedObject.height > Canvas._objects[0].height
  // ) {
  //   const widthScaleFactor =
  //     (Canvas._objects[0].width - 20) / loadedObject.width;
  //   const heightScaleFactor =
  //     (Canvas._objects[0].height - 20) / loadedObject.height;

  //   const scaleFactor = Math.min(widthScaleFactor, heightScaleFactor);
  //   loadedObject.scale(scaleFactor);
  // }

  // loadedObject.setControlVisible("br", false);
  Canvas.add(loadedObject);
  // Canvas.centerObject(loadedObject);
  Canvas.setActiveObject(loadedObject);
  // Canvas.setActiveObject(loadedObject);
  // if (typeof objects != "object") {
  //   Canvas.centerObject(loadedObject);
  //   Canvas.setActiveObject(loadedObject);

  // }
  Canvas.renderAll();

  positionOffsetLine += 16;
  LineCount++;

  if (shapeCount >= 10) {
    // Reset positionOffsetShape and the count
    positionOffsetLine = 0;
    LineCount = 0;
  }

  loadedObject.on("scaling", (e) => {
    // if (e.target) {
    //   e.target.strokeWidth = 4
    //   Canvas.renderAll()
    //   e.target.setCoords()
    // }
    if (e.transform && e.transform.target._type === "line") {
      // scaleObject(e.pointer.x, e.pointer.y, "x")
      // try {
      //   const x = e.pointer.x;
      //   const y = e.pointer.y;
      //   const t = e.transform;
      //   const target = e.transform.target;

      //   if (target.lockRotation) {
      //     return false;
      //   }
      //   const lastAngle = Math.atan2(t.ey - target.top, t.ex - target.left);
      //   const curAngle = Math.atan2(y - target.top, x - target.left);
      //   const angle = (curAngle - lastAngle + t.theta) / (Math.PI / 180);
      //   const hasRotated = true;

      //   if (e.transform.corner === 'mr') {
      //     if (target.originX === 'right') {
      //       target.originX = 'left';
      //       target.setPositionByOrigin(
      //         new fabric.Point(target.left, target.top),
      //         'right',
      //         'top'
      //       );
      //     }
      //     target.angle = angle;
      //   } else if (e.transform.corner === 'ml') {
      //     if (target.originX === 'left') {
      //       target.originX = 'right';
      //       target.setPositionByOrigin(
      //         new fabric.Point(target.left, target.top),
      //         'left',
      //         'top'
      //       );
      //     }
      //     target.angle = angle;

      //     Canvas.renderAll();
      //   }
      // } catch (error) {
      //   console.log('linescaling', error);
      // }
      ///////////////////
      let activeObj = e.transform.target;
      setscale(e, Canvas);
      let p = e.transform.target.translateToOriginPoint(
        e.transform.target.getCenterPoint(),
        Canvas._currentTransform.originX,
        "center"
      );
      rotateObject(e.pointer.x, e.pointer.y, "left", Canvas);
      e.transform.target.setPositionByOrigin(
        p,
        Canvas._currentTransform.originX,
        "center"
      );
      activeObj.setCoords();
      Canvas.renderAll();
    }
  });
  loadedObject.on("mousedown", (e) => {
    if (e.transform && e.transform.action === "scaleX") {
      e.transform.target.getObjects().forEach((f) => {
        f.oldScaleX = f.scaleX;
        console.log("sclaaaaaeX,", f.id, f.scaleX, f.oldScaleX);
      });
    }
  });
  loadedObject.on("mouseup", (e) => {
    if (e.transform && e.transform.action === "scaleX") {
      let obj = e.transform.target;
      obj.width = obj.width * obj.scaleX;
      obj.scaleX = 1;
      obj.getObjects().forEach((f) => {
        f.scaleX = f.oldScaleX;
        if (f.id === "left") {
          f.set({
            left: -(obj.width / 2),
          });
        } else if (f.id === "right") {
          f.set({
            left: obj.width / 2,
          });
        } else if (f.id === "line") {
          f.set({
            left:
              -(obj.width / 2) +
              (obj.pointleft
                ? get1obj("left", obj).differINwidth
                  ? get1obj("left", obj).width *
                      get1obj("left", obj).oldScaleX -
                    get1obj("left", obj).differINwidth *
                      get1obj("left", obj).oldScaleX
                  : get1obj("left", obj).width * get1obj("left", obj).oldScaleX
                : 0),
          });
          f.width =
            (obj.width -
              (obj.pointleft
                ? get1obj("left", obj).differINwidth
                  ? get1obj("left", obj).width *
                      get1obj("left", obj).oldScaleX -
                    get1obj("left", obj).differINwidth *
                      get1obj("left", obj).oldScaleX
                  : get1obj("left", obj).width * get1obj("left", obj).oldScaleX
                : 0) -
              (obj.pointright
                ? get1obj("right", obj).differINwidth
                  ? get1obj("right", obj).width *
                      get1obj("right", obj).oldScaleX -
                    get1obj("right", obj).differINwidth *
                      get1obj("right", obj).oldScaleX
                  : get1obj("right", obj).width *
                    get1obj("right", obj).oldScaleX
                : 0)) /
            f.scaleX;
        }
      });
      Canvas.renderAll();
      obj.setCoords();
    }
  });

  Canvas.savestate();
}

export const addSpecificshape = (result, Canvas, boxframe) => {
  console.log(typeof result, "typeOF");
  if (typeof result == "string") {
    fabric.loadSVGFromURL(result, (objects, options) => {
      console.log("getting options", options);
      lineCallBack(objects, Canvas, options);
    });
  } else if (typeof result == "object") {
    lineCallBack(result, Canvas);
  }
};

export const get1obj = (id, grp) => {
  return grp._objects.filter((f) => {
    return f.id === id;
  })[0];
};

export const setscale = (e, Canvas) => {
  let activeObj = e.transform.target;
  let scaleX = activeObj.scaleX;
  let scaleY = activeObj.scaleY;
  let width = activeObj.width * scaleX;
  activeObj.getObjects().forEach((f) => {
    if (f.id === "left") {
      f.set({
        // left:-width/2
        scaleX: f.oldScaleX / scaleX,
      });
    } else if (f.id === "right") {
      f.set({
        // left:-width/2
        scaleX: f.oldScaleX / scaleX,
      });
    } else if (f.id === "line") {
      f.set({
        left:
          -(activeObj.width / 2) +
          (activeObj.pointleft
            ? get1obj("left", activeObj).differINwidth
              ? get1obj("left", activeObj).width *
                  get1obj("left", activeObj).scaleX -
                get1obj("left", activeObj).differINwidth *
                  get1obj("left", activeObj).scaleX
              : get1obj("left", activeObj).width *
                get1obj("left", activeObj).scaleX
            : 0),
        scaleX: f.oldScaleX / scaleX,
      });
      f.width =
        (activeObj.width -
          (activeObj.pointleft
            ? get1obj("left", activeObj).differINwidth
              ? get1obj("left", activeObj).width *
                  get1obj("left", activeObj).scaleX -
                get1obj("left", activeObj).differINwidth *
                  get1obj("left", activeObj).scaleX
              : get1obj("left", activeObj).width *
                get1obj("left", activeObj).scaleX
            : 0) -
          (activeObj.pointright
            ? get1obj("right", activeObj).differINwidth
              ? get1obj("right", activeObj).width *
                  get1obj("right", activeObj).scaleX -
                get1obj("right", activeObj).differINwidth *
                  get1obj("right", activeObj).scaleX
              : get1obj("right", activeObj).width *
                get1obj("right", activeObj).scaleX
            : 0)) /
        f.scaleX;
      // f.left = -(width / 2)
      activeObj.setCoords();
      Canvas.renderAll();
      // f.width = f.width * f.scaleX
      // console.log(
      //   "scalingggg, line",
      //   activeObj.width,
      //   f.width,
      //   f.left,
      //   activeObj.pointleft
      //     ? get1obj("left", activeObj).differINwidth
      //       ? get1obj("left", activeObj).width *
      //       get1obj("left", activeObj).scaleX -
      //       get1obj("left", activeObj).differINwidth *
      //       get1obj("left", activeObj).scaleX
      //       : get1obj("left", activeObj).width *
      //       get1obj("left", activeObj).scaleX
      //     : 0,
      //   activeObj.pointright
      //     ? get1obj("right", activeObj).differINwidth
      //       ? get1obj("right", activeObj).width *
      //       get1obj("right", activeObj).scaleX -
      //       get1obj("right", activeObj).differINwidth *
      //       get1obj("right", activeObj).scaleX
      //       : get1obj("right", activeObj).width *
      //       get1obj("right", activeObj).scaleX
      //     : 0
      // );
    }
    // console.log("sclaaaaaeX  inn", f.id, f.scaleX, f.left);
  });
};

export const isInRange = function (e, t) {
  return Math.abs(e - t) < 3;
};
export const rotateObject = (e, t, a, Canvas) => {
  var n = Canvas,
    i = Canvas._currentTransform,
    r = i.target,
    o = r.translateToOriginPoint(r.getCenterPoint(), i.originX, i.originY);
  if (r.lockRotation) return !1;
  i.hasOwnProperty("lastAngle") ||
    (i.lastAngle = Math.atan2(i.ey - o.y, i.ex - o.x));
  var c = Math.atan2(t - o.y, e - o.x),
    l = !0,
    u = fabric.util.radiansToDegrees(c - i.lastAngle + i.theta);

  if (r.snapAngle > 0) {
    var h = r.snapAngle,
      d = r.snapThreshold || h,
      p = Math.ceil(u / h) * h,
      g = Math.floor(u / h) * h;
    Math.abs(u - g) < d ? (u = g) : Math.abs(u - p) < d && (u = p);
  }
  return (
    u < 0 && (u = 360 + u),
    (u %= 360),
    [0, 45, 90, 135, 180, 225, 270].map(function (e) {
      u = isInRange(u, e) ? e : u;
    }),
    r.angle === u
      ? (l = !1)
      : r.rotateWithOrigin
      ? r.rotateWithOrigin(a, "center", u)
      : ((r.angle = u), r.setPositionByOrigin(o, i.originX, i.originY)),
    l
  );
};
export const AddHead = (url, Canvas) => {
  url = url.replace("png", "svg");
  let activeObj = Canvas.getActiveObject();
  if (activeObj) {
    fabric.loadSVGFromURL(url, (objects, options) => {
      let grp = new fabric.Group([...objects], {});
      grp._objects.forEach((f) => {
        if (f.id.includes("_")) {
          f.differINwidth = Number(f.id.trim().split("_")[1]);
          f.id = f.id.trim().split("_")[0];
        }
        if (f.id === "left") {
          grp.leftpresent = true;
          f.set({
            originX: "left",
            originY: "center",
          });
          if (f.strokeMiterLimit <= 10) {
            f.width = f.width + (f.strokeMiterLimit / 3) * f.scaleX;
            f.height = f.height + (f.strokeMiterLimit / 3) * f.scaleY;
          }
        } else if (f.id === "right") {
          grp.rightpresent = true;
          f.set({
            originX: "right",
            originY: "center",
          });
          if (f.strokeMiterLimit <= 10) {
            f.width = f.width + (f.strokeMiterLimit / 3) * f.scaleX;
            f.height = f.height + (f.strokeMiterLimit / 3) * f.scaleY;
          }
          console.log("righttt", f);
        }
      });
      if (grp.leftpresent && grp.leftpresent) {
        if (activeObj.pointleft) {
          let a = grp._objects.filter((f) => {
            return f.id === "left";
          })[0];
          let b = activeObj._objects.filter((f) => {
            return f._type === "left";
          })[0];
          let active_obj_line = activeObj._objects.filter((f) => {
            return f._type === "line";
          })[0];
          console.log("loadedObjecct need to replace leftttttttttt ", a, b);
          a.set({
            // left: b.left,
            // top: b.top,
            left: -(activeObj.width / 2),
            top: 0, //-((a.height / 2) + (a.strokeWidth / 2)),
            _type: b._type,
            type: b.type,
            stroke: b.stroke,
            fill: a.fill !== "" && b.stroke,
            scaleX: b.scaleX,
            scaleY: b.scaleX,
            // fill: f.fill,
          });
          if (activeObj.pointright) {
            let p_right = activeObj._objects.filter((f) => {
              return f._type === "right";
            })[0];
            active_obj_line.left =
              a.left +
              (a.width * a.scaleX -
                (a.differINwidth ? a.differINwidth * a.scaleX : 0));
            // active_obj_line.width = ((active_obj_line.width) - a.width)
            // active_obj_line.x2 = ((active_obj_line.x2) - a.width)
          } else {
            active_obj_line.left =
              a.left +
              (a.width * a.scaleX -
                (a.differINwidth ? a.differINwidth * a.scaleX : 0));
            active_obj_line.width =
              (activeObj.width -
                (a.width * a.scaleX -
                  (a.differINwidth ? a.differINwidth * a.scaleX : 0))) /
              active_obj_line.scaleX;
            active_obj_line.x2 =
              (active_obj_line.x2 -
                (a.width * a.scaleX -
                  (a.differINwidth ? a.differINwidth * a.scaleX : 0))) /
              active_obj_line.scaleX;
          }
          activeObj.height = a.height * a.scaleY + a.strokeWidth * a.scaleY;
          activeObj.remove(b);
          activeObj.add(a);
        } else {
          let a = grp._objects.filter((f) => {
            return f.id === "left";
          })[0];
          let active_obj_line = activeObj._objects.filter((f) => {
            return f._type === "line";
          })[0];

          a.set({
            left: -(activeObj.width / 2),
            top: 0, //-((a.height / 2) + (a.strokeWidth / 2)),
            _type: a.id,
            type: "polygon",
            stroke: active_obj_line.stroke,
            fill: a.fill !== "" && active_obj_line.stroke,
            scaleX: Number(active_obj_line.strokeWidth / 5),
            scaleY: Number(active_obj_line.strokeWidth / 5),
            // fill: f.fill,
            // stroke: b.stroke
          });

          activeObj.height = a.height * a.scaleY + a.strokeWidth * a.scaleY;
          activeObj.add(a);
          activeObj.pointleft = true;
          console.log("loadedObjecct need to add leftttttttttt ");
          if (activeObj.pointright) {
            let p_right = activeObj._objects.filter((f) => {
              return f._type === "right";
            })[0];
            active_obj_line.left =
              a.left +
              (a.width * a.scaleX -
                (a.differINwidth ? a.differINwidth * a.scaleX : 0));
            // active_obj_line.width = ((active_obj_line.width) - a.width)
            // active_obj_line.x2 = ((active_obj_line.x2) - a.width)
          } else {
            active_obj_line.left =
              a.left +
              (a.width * a.scaleX -
                (a.differINwidth ? a.differINwidth * a.scaleX : 0));
            active_obj_line.width =
              (active_obj_line.width * active_obj_line.scaleX -
                (a.width * a.scaleX -
                  (a.differINwidth ? a.differINwidth * a.scaleX : 0))) /
              active_obj_line.scaleX;
            active_obj_line.x2 =
              (active_obj_line.x2 -
                (a.width * a.scaleX -
                  (a.differINwidth ? a.differINwidth * a.scaleX : 0))) /
              active_obj_line.scaleX;
          }
        }
      } else {
        if (activeObj.pointleft) {
          let b = activeObj._objects.filter((f) => {
            return f._type === "left";
          })[0];
          let active_obj_line = activeObj._objects.filter((f) => {
            return f._type === "line";
          })[0];

          if (activeObj.pointright) {
            let p_right = activeObj._objects.filter((f) => {
              return f._type === "right";
            })[0];
            active_obj_line.left = -(activeObj.width / 2);
            active_obj_line.width =
              (activeObj.width - b.width * b.scaleX) / active_obj_line.scaleX;
            // active_obj_line.x2 = ()
          } else {
            active_obj_line.left = -(activeObj.width / 2);
            active_obj_line.width = activeObj.width / active_obj_line.scaleX;
            // active_obj_line.x2 = ((active_obj_line.x2) - a.width)
          }
          activeObj.remove(b);
          activeObj.pointleft = false;
          console.log("loadedObjecct need to remove leftttttttttt ");
        } else {
          console.log("loadedObjecct need to do nothing  leftttttttttt ");
        }
      }
      if (grp.rightpresent && grp.rightpresent) {
        if (activeObj.pointright) {
          console.log("loadedObjecct need to replace righhhhhhhhht ");
          let a = grp._objects.filter((f) => {
            return f.id === "right";
          })[0];
          let b = activeObj._objects.filter((f) => {
            return f._type === "right";
          })[0];
          let active_obj_line = activeObj._objects.filter((f) => {
            return f._type === "line";
          })[0];

          a.set({
            left: activeObj.width / 2, //((b.left + b.width) - a.width) + (b.strokeWidth - a.strokeWidth),
            top: 0, //-((a.height / 2) + (a.strokeWidth / 2)),
            // left: (active_obj_line.left + active_obj_line.width) - (a.width * a.scaleX) - a.strokeWidth,
            // top: -((a.height / 2) + (a.strokeWidth / 2)),
            _type: b._type,
            type: b.type,
            scaleX: b.scaleX,
            scaleY: b.scaleX,
            // fill: f.fill,
            stroke: b.stroke,
            fill: a.fill !== "" && b.stroke,
          });

          activeObj.height = a.height * a.scaleY + a.strokeWidth * a.scaleY;
          activeObj.remove(b);
          activeObj.add(a);
          if (activeObj.pointleft) {
            let p_left = activeObj._objects.filter((f) => {
              return f._type === "left";
            })[0];
            active_obj_line.x2 =
              (activeObj.width -
                (p_left.width * p_left.scaleX -
                  (p_left.differINwidth
                    ? p_left.differINwidth * p_left.scaleX
                    : 0)) -
                (a.width * a.scaleX -
                  (a.differINwidth ? a.differINwidth * a.scaleX : 0))) /
              active_obj_line.scaleX; //((activeObj.width) - (p_left.width * p_left.scaleX) - ((a.width * a.scaleX) - (a.differINwidth ? (a.differINwidth * a.scaleX) : 0)))
            active_obj_line.width =
              (activeObj.width -
                (p_left.width * p_left.scaleX -
                  (p_left.differINwidth
                    ? p_left.differINwidth * p_left.scaleX
                    : 0)) -
                (a.width * a.scaleX -
                  (a.differINwidth ? a.differINwidth * a.scaleX : 0))) /
              active_obj_line.scaleX;
          } else {
            active_obj_line.x2 =
              (activeObj.width -
                (a.width * a.scaleX -
                  (a.differINwidth ? a.differINwidth * a.scaleX : 0))) /
              active_obj_line.scaleX;
            active_obj_line.width =
              (activeObj.width -
                (a.width * a.scaleX -
                  (a.differINwidth ? a.differINwidth * a.scaleX : 0))) /
              active_obj_line.scaleX;
          }
        } else {
          let a = grp._objects.filter((f) => {
            return f.id === "right";
          })[0];
          let active_obj_line = activeObj._objects.filter((f) => {
            return f._type === "line";
          })[0];
          a.set({
            left: activeObj.width / 2, //(active_obj_line.left + active_obj_line.width) - (a.width * a.scaleX) - a.strokeWidth,
            top: 0, //-((a.height / 2) + (a.strokeWidth / 2)),
            _type: a.id,
            type: "polygon",
            stroke: active_obj_line.stroke,
            fill: a.fill !== "" && active_obj_line.stroke,
            scaleX: Number(active_obj_line.strokeWidth / 5),
            scaleY: Number(active_obj_line.strokeWidth / 5),
            // scaleX:
            // scaleX:
            // fill: f.fill,
            // stroke: activeObj.stroke
          });

          if (activeObj.pointleft) {
            let p_left = activeObj._objects.filter((f) => {
              return f._type === "left";
            })[0];
            active_obj_line.x2 =
              (activeObj.width -
                (p_left.width * p_left.scaleX -
                  (p_left.differINwidth
                    ? p_left.differINwidth * p_left.scaleX
                    : 0)) -
                (a.width * a.scaleX -
                  (a.differINwidth ? a.differINwidth * a.scaleX : 0))) /
              active_obj_line.scaleX;
            active_obj_line.width =
              (activeObj.width -
                (p_left.width * p_left.scaleX -
                  (p_left.differINwidth
                    ? p_left.differINwidth * p_left.scaleX
                    : 0)) -
                (a.width * a.scaleX -
                  (a.differINwidth ? a.differINwidth * a.scaleX : 0))) /
              active_obj_line.scaleX;
          } else {
            active_obj_line.x2 =
              (activeObj.width -
                (a.width * a.scaleX -
                  (a.differINwidth ? a.differINwidth * a.scaleX : 0))) /
              active_obj_line.scaleX;
            active_obj_line.width =
              (activeObj.width -
                (a.width * a.scaleX -
                  (a.differINwidth ? a.differINwidth * a.scaleX : 0))) /
              active_obj_line.scaleX;
          }
          activeObj.height = a.height * a.scaleY + a.strokeWidth * a.scaleY;
          activeObj.add(a);
          activeObj.pointright = true;
          console.log("loadedObjecct need to add righhhhhhhhht ");
        }
      } else {
        if (activeObj.pointright) {
          let b = activeObj._objects.filter((f) => {
            return f._type === "right";
          })[0];
          let active_obj_line = activeObj._objects.filter((f) => {
            return f._type === "line";
          })[0];
          if (activeObj.pointleft) {
            let p_left = activeObj._objects.filter((f) => {
              return f._type === "left";
            })[0];
            active_obj_line.x2 =
              (activeObj.width -
                (p_left.width * p_left.scaleX -
                  (p_left.differINwidth
                    ? p_left.differINwidth * p_left.scaleX
                    : 0))) /
              active_obj_line.scaleX;
            active_obj_line.width =
              (activeObj.width -
                (p_left.width * p_left.scaleX -
                  (p_left.differINwidth
                    ? p_left.differINwidth * p_left.scaleX
                    : 0))) /
              active_obj_line.scaleX;
          } else {
            active_obj_line.x2 = activeObj.width / active_obj_line.scaleX;
            active_obj_line.width = activeObj.width / active_obj_line.scaleX;
          }
          activeObj.remove(b);
          activeObj.pointright = false;
          console.log("loadedObjecct need to remove righhhhhhhhht ");
        } else {
          console.log("loadedObjecct need to do nothing  righhhhhhhhht ");
        }
      }
      Canvas.renderAll();
    });
  }
};

// arrow ends

// shape
let positionOffsetShape = 0;
let shapeCount = 0;
export const addShape = (url, Canvas, name_type) => {
  const canvasCenterX = Canvas.getWidth() / 2;
  const canvasCenterY = Canvas.getHeight() / 2;

  let safeAreaW = Canvas._objects[0].width * 0.3;
  let safeAreaH = Canvas._objects[0].height * 0.3;

  fabric.loadSVGFromURL(url, function (objects, options) {
    const svg = fabric.util.groupSVGElements(objects, options);

    svg.set({
      // left: canvasCenterX,
      // top: canvasCenterY,
      left: canvasCenterX + positionOffsetShape,
      top: canvasCenterY + positionOffsetShape,
      type_: "basicShape",
      // toolbarStrokeShow: data.isStrokeToolbar && data.isStrokeToolbar == true ? true : false,
      // type: "path",
      originX: "center",
      originY: "center",
      __type: "shape",
      nameType: name_type,

      // selectable: false,
    });
    if (safeAreaW < safeAreaH) {
      svg.scaleToWidth(safeAreaW);
    } else if (safeAreaW > safeAreaH) {
      svg.scaleToWidth(safeAreaH);
    }

    // Add the SVG object to the canvas
    Canvas.add(svg);
    Canvas.setActiveObject(svg);
    Canvas.savestate();
    Canvas.renderAll();
    positionOffsetShape += 16;
    shapeCount++;

    if (shapeCount >= 10) {
      // Reset positionOffsetShape and the count
      positionOffsetShape = 0;
      shapeCount = 0;
    }
  });

  // Canvas.on("object:modified", function (options) {
  //   const modifiedObject = options.target;

  //   if (modifiedObject.__type === "shape") {
  //     positionOffsetShape = 0;
  //     shapeCount = 0;
  //   }
  // });

  // Canvas.savestate();
};

let positionOffsetCutout = 0;
let cutoutCount = 0;

export const loadCutout = (url, Canvas) => {
  const canvasCenterX = Canvas.getWidth() / 2;
  const canvasCenterY = Canvas.getHeight() / 2;

  fabric.Image.fromURL(
    url,
    function (img) {
      img.set({
        left: canvasCenterX + positionOffsetCutout,
        top: canvasCenterY + positionOffsetCutout,
        originX: "center",
        originY: "center",
        __type: "cutout",
      });

      let safeAreaW = Canvas._objects[0].width * 0.3;
      let safeAreaH = Canvas._objects[0].height * 0.3;

      if (safeAreaW < safeAreaH) {
        img.scaleToWidth(safeAreaW);
      } else if (safeAreaW > safeAreaH) {
        img.scaleToWidth(safeAreaH);
      }

      img.filters = [
        new fabric.Image.filters.Brightness({ brightness: 0 }),
        new fabric.Image.filters.Contrast({ contrast: 0 }),
        new fabric.Image.filters.Saturation({ saturation: 0 }),
        new fabric.Image.filters.Blur({ blur: 0 }),
      ];

      // Apply filters
      img.applyFilters();
      Canvas.add(img);
      Canvas.setActiveObject(img);
      Canvas.savestate();
      Canvas.renderAll();
      positionOffsetCutout += 16;
      cutoutCount++;

      if (cutoutCount >= 10) {
        // Reset positionOffsetCutout and the count
        positionOffsetCutout = 0;
        cutoutCount = 0;
      }
    },
    {
      crossOrigin: "anonymous",
    }
  );
};

// shape ends  1738 2304

export const lockObject = (canvas) => {
  var activeObject = canvas.getActiveObject();
  if (activeObject.type === "activeSelection") {
    activeObject._objects.forEach(function (item) {
      item.lockMovementX = true;
      item.lockMovementY = true;
      item.hasControls = false;
      item.editable = false;
      item.selectable = true;
      item.lock = true; // Set lock for individual objects in the selection
    });

    canvas.discardActiveObject().renderAll();
  } else {
    activeObject.lockMovementX = true;
    activeObject.lockMovementY = true;
    activeObject.hasControls = false;
    activeObject.editable = false;
    activeObject.selectable = true;
    activeObject.lock = true;

    canvas.discardActiveObject().renderAll();
    canvas.setActiveObject(activeObject);

    canvas.savestate();
    //dispatch(update_undo_status(true));
  }
};

export const unLockObject = (canvas) => {
  var activeObject = canvas.getActiveObject();

  if (activeObject.type === "activeSelection") {
    activeObject._objects.forEach(function (item) {
      item.lockMovementX = false;
      item.lockMovementY = false;
      item.hasControls = true;
      item.editable = true;
      item.lock = false;
      item.selectable = false;
    });
  } else {
    activeObject.lockMovementX = false;
    activeObject.lockMovementY = false;
    activeObject.hasControls = true;
    activeObject.editable = true;
    activeObject.lock = false;
  }

  let actObj = activeObject;

  canvas.discardActiveObject().renderAll();

  setTimeout(() => {
    canvas.setActiveObject(actObj);
  }, 10);
  canvas.savestate();
};

export const getRandomID = (length) => {
  let text = "";
  const possible =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

  for (let i = 0; i < length; i++) {
    text += possible.charAt(Math.floor(Math.random() * possible.length));
  }
  return text;
};
export const getObjectById = (canvas, id) => {
  let obj;
  canvas.getObjects().forEach((f) => {
    if (f.layer_id) {
      if (f.layer_id == id) {
        obj = f;
      }
    }
  });
  return obj;
};

let timeoutId;
export const Update_Currentpage_View_Src_status = function (canvas) {
  clearTimeout(timeoutId); // Clear any existing timeout

  // Set a new timeout of 5 seconds
  timeoutId = setTimeout(() => {
    let pageid = canvas.pageId;
    if (!canvas.crop_mode) {
      if (pageid) {
        let img_div = document.getElementById(`${pageid}_page_img`);
        if (img_div) {
          let pvt = canvas.viewportTransform;
          canvas.viewportTransform = [1, 0, 0, 1, 0, 0];
          let src_canvas = canvas.toDataURL({
            left: canvas.clipPath.left,
            top: canvas.clipPath.top,
            width: canvas.clipPath.width,
            height: canvas.clipPath.height,
            quality: 0.3,
          });
          img_div && (img_div.src = src_canvas);
          canvas.viewportTransform = pvt;
          canvas.renderAll();
          canvas._pages.map((p) => {
            if (p.pageId == pageid) {
              p.data = canvas.toJSON();
            }
          });
        }
      }
    }
  }, 2000);

  // let pageid = canvas.pageId;
  // if (!canvas.crop_mode) {
  //   if (pageid) {
  //     let img_div = document.getElementById(`${pageid}_page_img`);
  //     if (img_div) {
  //       let pvt = canvas.viewportTransform;
  //       canvas.viewportTransform = [1, 0, 0, 1, 0, 0];
  //       let src_canvas = canvas.toDataURL({
  //         left: canvas.clipPath.left,
  //         top: canvas.clipPath.top,
  //         width: canvas.clipPath.width,
  //         height: canvas.clipPath.height,
  //         quality: 0.3,
  //       });
  //       img_div && (img_div.src = src_canvas);
  //       canvas.viewportTransform = pvt;
  //       canvas.renderAll();
  //       canvas._pages.map((p) => {
  //         if (p.pageId == pageid) {
  //           p.data = canvas.toJSON();
  //         }
  //       });
  //     }
  //   }
  // }
};
export const Select_Page_onclick = function () {
  let handelPointerBtn = document.getElementById("handelPointerBtn");
  handelPointerBtn && handelPointerBtn.click();
  let canvas = cccanvas;
  // if (!canvas.loading) {
  let pageid = this.id.split("_")[0];

  if (pageid != canvas.pageId) {
    canvas.discardActiveObject();
    canvas._pages.map((p) => {
      if (p.pageId == canvas.pageId) {
        // p.undo_redo_data = canvas.stateProperties;
        p.data = canvas.toJSON();
      }
    });
    if (canvas.loading) {
      let l = canvas.getObjects().filter((f) => f.type === "loaderFrame");
      canvas.remove(...l);
      document
        .querySelectorAll(".current_page_div.active")
        .forEach(function (c) {
          c.classList.remove("active");
        });
      this && this.classList.add("active");
      canvas.pageId = pageid;
      debugger;
      let { jsondata, index } = getPageData(canvas);
      loadfromjson(canvas, jsondata.data);
      let pageNameBarDiv = document.getElementById("pageNameOnBar");
      pageNameBarDiv &&
        (pageNameBarDiv.innerHTML = p.pageName
          ? p.pageName
          : `Page -${index + 1}`);
      // canvas.stateProperties = jsondata.undo_redo_data;
      canvas.renderAll();
    } else {
      document
        .querySelectorAll(".current_page_div.active")
        .forEach(function (c) {
          c.classList.remove("active");
        });
      this && this.classList.add("active");
      canvas.pageId = pageid;
      let { jsondata, index } = getPageData(canvas);
      console.log("gettingjsondata", jsondata);
      if (
        jsondata.data.objects[0] &&
        jsondata.data.objects[0].type_ &&
        jsondata.data.objects[0].type_ == "background"
      ) {
        loadfromjson(canvas, jsondata.data);
        let pageNameBarDiv = document.getElementById("pageNameOnBar");
        pageNameBarDiv &&
          (pageNameBarDiv.innerHTML = jsondata.pageName
            ? jsondata.pageName
            : `Page -${index + 1}`);
      } else {
        loadLegacyProject(canvas, jsondata);
      }

      // canvas.stateProperties = jsondata.undo_redo_data;
      canvas.renderAll();
    }
  }
  // if(window.innerWidth <= 991) {
  //   const element = document.getElementById("codeeditoreditor");
  //   const element2 = document.getElementById("left-menu-expanded");
  //    //const element3 = document.getElementsByClassName("cd-mobilevewdiv");

  //     //console.log("element----<<>>>", element);
  //   if (element && element2 && element.classList.contains("active")) {
  //       element.classList.remove('active');
  //       element2.classList.remove("active");
  //       //element3.classList.remove("border-none");
  //       //prompt()
  //     } else {
  //       element.classList.add('active');
  //       element2.classList.add("active");
  //      // element3.classList.add("border-1");
  //     }
  // }
};

export const togglePageMenu = function (e) {
  console.log("inside this ", e.target.id, e.target);
  let canvas = cccanvas;
  let cur_id = this.id.split("_")[0];
  let page_menu_div = document.getElementsByClassName("page_menu_outer_div")[0];
  let top = e.clientY;
  // let delete_page_div =
  if (canvas._pages.length === 1) {
    document.getElementById("delete_page").classList.add("disable");
  } else {
    document.getElementById("delete_page").classList.remove("disable");
  }
  if (canvas._pages.length === 20) {
    document.getElementById("add_page").classList.add("disable");
    document.getElementById("duplicate_page").classList.add("disable");
  } else {
    document.getElementById("add_page").classList.remove("disable");
    document.getElementById("duplicate_page").classList.remove("disable");
  }
  canvas._pages.map((p, i) => {
    if (p.pageId == cur_id) {
      let menu_page_name_div = document.getElementById("menu_page_name_div");
      let menu_page_name_input = document.getElementById(
        "menu_page_name_input"
      );
      menu_page_name_div.innerHTML = p.pageName ? p.pageName : `Page -${i + 1}`;
      menu_page_name_input.value = p.pageName ? p.pageName : `Page -${i + 1}`;
      let pageNameBarDiv = document.getElementById("pageNameOnBar");
      pageNameBarDiv &&
        (pageNameBarDiv.innerHTML = p.pageName ? p.pageName : `Page -${i + 1}`);
    }
  });
  const hidepagemenu = (e) => {
    if (
      document
        .getElementsByClassName("page_menu_outer_div")[0]
        .contains(e.target)
    ) {
      // console.log("under hai 1st condition ke page menu");
    } else {
      if (
        document.getElementById(e.target.id) ==
          document.getElementById(
            `${
              document
                .getElementsByClassName("page_menu_outer_div")[0]
                .id.split("_")[0]
            }_page_img_threedot`
          ) ||
        document.getElementById(e.target.id) ==
          document.getElementById(
            `${
              document
                .getElementsByClassName("page_menu_outer_div")[0]
                .id.split("_")[0]
            }_page_div_moreBTN`
          ) ||
        document.getElementById(e.target.id) ==
          document.getElementById(
            `${
              document
                .getElementsByClassName("page_menu_outer_div")[0]
                .id.split("_")[0]
            }_page_div_threedot_svg`
          ) ||
        document.getElementById(e.target.id) ==
          document.getElementById(
            `${
              document
                .getElementsByClassName("page_menu_outer_div")[0]
                .id.split("_")[0]
            }_page_div_threedot_path`
          )
      ) {
        return;
      }
      console.log(
        "under hai band kr rhe hai page menu ",
        e.target.id,
        canvas.pageId
      );
      let k = document.getElementsByClassName("page_menu_outer_div")[0];
      k.style.display = "none";
      k.id = null;
      let menu_page_name_div = document.getElementById("menu_page_name_div");
      let menu_page_name_input = document.getElementById(
        "menu_page_name_input"
      );
      menu_page_name_div.style.display = "block";
      menu_page_name_input.style.display = "none";
      document.removeEventListener("mousedown", hidepagemenu, false);
    }
  };

  if (page_menu_div.id) {
    let iddddd = page_menu_div.id.split("_")[0];
    if (iddddd == cur_id) {
      page_menu_div.style.display = "none";
      page_menu_div.id = null;
    } else {
      canvas.discardActiveObject();
      page_menu_div.style.display = "block";

      if (window.window.innerWidth <= 991) {
        page_menu_div.style.top = "unset";
        page_menu_div.style.left = "unset";
      } else {
        page_menu_div.style.top = `${
          top -
          document.getElementsByClassName("page_menu_div")[0].offsetHeight -
          15
        }px`;
        page_menu_div.style.left = `${e.clientX - 263 / 2}px`;
      }

      page_menu_div.id = `${cur_id}_page_menu_outer_div`;

      document.addEventListener("mousedown", hidepagemenu, false);
    }
  } else {
    canvas.discardActiveObject();
    // console.log("page_menu_div.id no id present")
    page_menu_div.style.display = "block";
    if (window.window.innerWidth <= 991) {
      page_menu_div.style.top = "unset";
      page_menu_div.style.left = "unset";
    } else {
      page_menu_div.style.top = `${
        top -
        document.getElementsByClassName("page_menu_div")[0].offsetHeight -
        15
      }px`;
      page_menu_div.style.left = `${e.clientX - 263 / 2}px`;
    }
    page_menu_div.id = `${cur_id}_page_menu_outer_div`;
    document.addEventListener("mousedown", hidepagemenu, false);
  }
  canvas.renderAll();
};
export const addnewpagediv = function ({ canvas, at, previous_page_name }) {
  let canvas_page_div = document.getElementsByClassName("all_page_div")[0];
  let pvt = canvas.viewportTransform;
  canvas.viewportTransform = [1, 0, 0, 1, 0, 0];
  positionOffsetText = 0;
  let src_canvas = canvas.toDataURL({
    left: canvas.clipPath.left,
    top: canvas.clipPath.top,
    width: canvas.clipPath.width,
    height: canvas.clipPath.height,
  });
  let newdiv = document.createElement("div");
  newdiv.id = `${canvas.pageId}_page_div`;
  newdiv.classList.add("current_page_div");
  document.querySelectorAll(".current_page_div.active").forEach(function (c) {
    c.classList.remove("active");
  });
  newdiv.classList.add("active");
  let pageimg = document.createElement("img");
  pageimg.id = `${canvas.pageId}_page_img`;
  pageimg.alt = "page_view";
  pageimg && (pageimg.src = src_canvas);
  canvas.viewportTransform = pvt;
  canvas.renderAll();
  newdiv.appendChild(pageimg);
  let threedotdiv = document.createElement("div");
  threedotdiv.id = `${canvas.pageId}_page_img_threedot`;
  threedotdiv.classList.add("moreBTN_page_before");
  threedotdiv.innerHTML = `<div class="moreBTN_page" id= ${canvas.pageId}_page_div_moreBTN>
<svg width=10px height=10px viewBox="0 0 1024 1024" id= ${canvas.pageId}_page_div_threedot_svg>
<path id= ${canvas.pageId}_page_div_threedot_path fill="#fff" d="M249.6 512c0 70.4-57.6 128-128 128S0 582.4 0 512s57.6-128 128-128 121.6 57.6 121.6 128z m384 0c0 70.4-57.6 128-128 128S384 582.4 384 512s57.6-128 128-128 121.6 57.6 121.6 128z m384 0c0 70.4-57.6 128-128 128s-128-57.6-128-128 57.6-128 128-128 128 57.6 128 128z"></path></svg><div>`;
  newdiv.appendChild(threedotdiv);
  newdiv.addEventListener("click", Select_Page_onclick, false);
  threedotdiv.addEventListener("click", togglePageMenu, false);
  let current_pageId = canvas.pageId;
  if (at === "first" || at === "last") {
    canvas_page_div.appendChild(newdiv);

    let p = {
      pageId: current_pageId,
      data: canvas.toJSON(),
      //pageName: `Page -${canvas._pages.length + 1}`,
    };
    let pageNameBarDiv = document.getElementById("pageNameOnBar");
    pageNameBarDiv &&
      (pageNameBarDiv.innerHTML = `Page -${canvas._pages.length + 1}`);
    canvas._pages.push(p);
  } else {
    canvas_page_div.insertBefore(newdiv, canvas_page_div.children[at]);
    let p = {
      pageId: current_pageId,
      data: canvas.toJSON(),
      //pageName: previous_page_name ? previous_page_name : `Page -${at + 1}`,
    };

    canvas._pages.splice(at, 0, p);
    let pageNameBarDiv = document.getElementById("pageNameOnBar");
    pageNameBarDiv &&
      (pageNameBarDiv.innerHTML = previous_page_name
        ? previous_page_name
        : `Page -${at + 1}`);
    // arr.splice(2, 0, { id: 50 });
  }
};
export const getPageData = function (canvas) {
  let data;
  canvas._pages.map((p, i) => {
    if (p.pageId == canvas.pageId) {
      data = { jsondata: p, index: i };
    }
  });
  return data;
};
export const loadfromjson = function (canvas, json_data) {
  return new Promise((resolve, reject) => {
    canvas.loadFromJSON(
      json_data,
      () => {
        canvas.renderAll.bind(canvas);
        let allOBJECTS = canvas.getObjects();
        for (let i = 0; i < allOBJECTS.length; i++) {
          if (allOBJECTS[i].type === "image") {
            for (let j = 0; j < allOBJECTS.length; j++) {
              if (
                allOBJECTS[i].id === allOBJECTS[j].id &&
                allOBJECTS[i].type != allOBJECTS[j].type
              ) {
                allOBJECTS[j].original_img = allOBJECTS[i];
                allOBJECTS[j].original_img.imageRect = allOBJECTS[j];
              }
            }
            if (allOBJECTS[i].imageLoading && allOBJECTS[i].imageLoading) {
              console.log("still loading", allOBJECTS[i]);
              let ctx = canvas.getContext("2d");
              renderloaderFrame(allOBJECTS[i], ctx);
            }
          }
          if (allOBJECTS[i].type_ && allOBJECTS[i].type_ === "background") {
            // if (allOBJECTS[i].getSrc() !== "") {
            //   allOBJECTS[i].set({
            //     background_fill: allOBJECTS[i].toJSON(),
            //   });
            // } else if (typeof allOBJECTS[i].backgroundColor === typeof "") {
            //   allOBJECTS[i].set({
            //     background_fill: allOBJECTS[i].backgroundColor,
            //   });
            // }
            if (typeof allOBJECTS[i].background_fill_copy === typeof {}) {
              if (
                allOBJECTS[i].background_fill_copy.type == "linear" ||
                allOBJECTS[i].background_fill_copy.type == "radial"
              ) {
                let gradient = new fabric.Gradient(
                  allOBJECTS[i].background_fill_copy
                );
                allOBJECTS[i].set({
                  backgroundColor: gradient.toLive(canvas.contextContainer),
                  background_fill: gradient,
                  background_fill_copy: gradient,
                });
              } else if (allOBJECTS[i].background_fill_copy.type == "image") {
                allOBJECTS[i].set({
                  background_fill: allOBJECTS[i],
                  background_fill_copy: allOBJECTS[i].toJSON(),
                });
              }
            } else if (
              typeof allOBJECTS[i].backgroundColor === typeof "" ||
              typeof allOBJECTS[i].background_fill_copy === typeof ""
            ) {
              allOBJECTS[i].set({
                background_fill: allOBJECTS[i].backgroundColor,
                background_fill_copy: allOBJECTS[i].backgroundColor,
                crossOrigin: "anonymous",
              });
            }
          }
        }
        canvas.setViewportTransform([1, 0, 0, 1, 1, 1]);
        fitinViewPort(canvas);
        let dispatchBgfn = document.getElementById("updatebgdispatch");
        dispatchBgfn && dispatchBgfn.click();
        resolve();
      },
      null,
      (error) => {
        console.error("Error loading JSON", error);
        reject(error);
      }
    );
  });
};
export const loadfromjson_to_newcanvas = function (
  newcanvas,
  json_data,
  loadeframe
) {
  newcanvas.loadFromJSON(
    json_data,
    () => {
      newcanvas.renderAll.bind(newcanvas);
      // let allOBJECTS = newcanvas.getObjects()
      // for (let i = 0; i < allOBJECTS.length; i++) {
      //     if (allOBJECTS[i].type === "image") {
      //         for (let j = 0; j < allOBJECTS.length; j++) {
      //             if (allOBJECTS[i].id === allOBJECTS[j].id && allOBJECTS[i].type != allOBJECTS[j].type) {
      //                 // allOBJECTS[i]
      //                 allOBJECTS[j].original_img = allOBJECTS[i]
      //                 allOBJECTS[j].original_img.imageRect = allOBJECTS[j]
      //                 // console.log("rgsg", allOBJECTS[j])
      //             }
      //         }
      //     }
      // }
    },
    (i) => {
      console.log("fromladingnow", i);
    }
  );
};
export const objectmove_key = function (canvas, key, value, to) {
  let active_object = canvas.getActiveObject();
  if (active_object) {
    if (active_object.type === "rect" && active_object.original_img) {
      if (to === "plus") {
        active_object.set(key, active_object.get(key) + value);
        active_object.original_img.set(
          key,
          active_object.original_img.get(key) + value
        );
      } else if (to === "minus") {
        active_object.set(key, active_object.get(key) - value);
        active_object.original_img.set(
          key,
          active_object.original_img.get(key) - value
        );
      }
    } else {
      if (to === "plus") {
        active_object.set(key, active_object.get(key) + value);
      } else if (to === "minus") {
        active_object.set(key, active_object.get(key) - value);
      }
    }
    canvas.renderAll();
  }
};
export const Previous_div_selection_key = (
  current_layer_div_index,
  layer_outer_div
) => {
  if (current_layer_div_index >= 0) {
    let to_be_selected = layer_outer_div.children[current_layer_div_index - 1];
    if (to_be_selected && to_be_selected.style.display == "none") {
      current_layer_div_index = Array.prototype.indexOf.call(
        layer_outer_div.children,
        to_be_selected
      );
      Previous_div_selection_key(current_layer_div_index, layer_outer_div);
    } else {
      to_be_selected && to_be_selected.click();
    }
  }
};
export const Next_div_selection_key = (
  current_layer_div_index,
  layer_outer_div
) => {
  if (current_layer_div_index <= layer_outer_div.children.length) {
    let to_be_selected = layer_outer_div.children[current_layer_div_index + 1];
    if (to_be_selected && to_be_selected.style.display == "none") {
      current_layer_div_index = Array.prototype.indexOf.call(
        layer_outer_div.children,
        to_be_selected
      );
      Next_div_selection_key(current_layer_div_index, layer_outer_div);
    } else {
      to_be_selected && to_be_selected.click();
    }
  }
};
export const handleKeyDown = function (e) {
  // if (document.activeElement.tagName === "INPUT" || document.activeElement.tagName === "TEXTAREA") {
  //   return;
  // }
  let canvas = cccanvas;
  // Handle ctrlKey or command key + any key from here
  // console.log("keyboard pressed", e);
  if (e.ctrlKey || e.metaKey) {
    //Undo Redo "Ctrl + Z"/"Ctrl + Shift + Z"
    if ((e.ctrlKey || e.metaKey) && e.keyCode === 90) {
      // Handle the "Ctrl + Z" || "command + Z" shortcut here (⌘ Z)
      if ((e.ctrlKey || e.metaKey) && e.shiftKey && e.keyCode === 90) {
        // Handle the "Ctrl + Shift + Z" || "command + Shif + Z" shortcut here shortcut here (⇧ ⌘ Z)
        let redo_button = document.getElementById("redo_button");
        redo_button.click();
      } else {
        let undo_button = document.getElementById("undo_button");
        undo_button.click();
      }
    }
    //Download "Shift + Ctrl + E" /"⇧ ⌘ E"
    // else if ((e.shiftKey && e.metaKey && e.keyCode === 69) || (e.shiftKey && e.ctrlKey && e.keyCode === 69)) {
    //     // Handle the "⇧ ⌘ E" or "Shift + Ctrl + E" shortcut here
    //     e.preventDefault()
    //     //////////////////Will add download key shortcut here/////////////
    //     alert("currently download is not working ")
    // }
    // Clone Object "⌘ + D" / "ctrl+D"
    else if ((e.ctrlKey || e.metaKey) && e.keyCode === 68) {
      if (canvas.getActiveObject()) {
        e.preventDefault();
      }
      let clone_button = document.getElementById("clone_object");
      clone_button && clone_button.click();
    } else if (
      ((e.ctrlKey || e.metaKey) && e.keyCode === 187 && !e.shiftKey) ||
      ((e.ctrlKey || e.metaKey) && e.keyCode === 107)
    ) {
      // ctrl +
      console.log("keyboard pressed", e);
      e.preventDefault();
      let zoom_increase = document.getElementById("zoom+");
      zoom_increase && zoom_increase.click();
    } else if (
      ((e.ctrlKey || e.metaKey) && e.keyCode === 189 && !e.shiftKey) ||
      ((e.ctrlKey || e.metaKey) && e.keyCode === 109)
    ) {
      // ctrl -
      e.preventDefault();
      let zoom_decrease = document.getElementById("zoom-");
      zoom_decrease && zoom_decrease.click();
    } else if ((e.ctrlKey || e.metaKey) && e.keyCode === 48) {
      // ctrl
      e.preventDefault();
      let Zoom_fit = document.getElementById("zoomfit");
      Zoom_fit && Zoom_fit.click();
    } else if ((e.ctrlKey || e.metaKey) && e.keyCode === 37) {
      // ctrl arrow left
      e.preventDefault();
      let page_div = document.getElementById(`${canvas.pageId}_page_div`);
      let all_page_div = document.getElementsByClassName("all_page_div")[0];
      let current_index = Array.prototype.indexOf.call(
        all_page_div.children,
        page_div
      );
      if (current_index >= 1) {
        let previous_page_div = all_page_div.children[current_index - 1];
        previous_page_div && previous_page_div.click();
      }
    } else if ((e.ctrlKey || e.metaKey) && e.keyCode === 39) {
      // ctrl arrow right
      let page_div = document.getElementById(`${canvas.pageId}_page_div`);
      let all_page_div = document.getElementsByClassName("all_page_div")[0];
      let current_index = Array.prototype.indexOf.call(
        all_page_div.children,
        page_div
      );
      if (current_index < all_page_div.children.length - 1) {
        let next_page_div = all_page_div.children[current_index + 1];
        next_page_div && next_page_div.click();
      }
    } else if (e.altKey && (e.ctrlKey || e.metaKey) && e.keyCode === 221) {
      //⌥ ⌘ ] or Alt Ctrl ] for Bring to front
      e.preventDefault();
      document.getElementById("BringtoFront__") &&
        document.getElementById("BringtoFront__").click();
    } else if (!e.altKey && (e.ctrlKey || e.metaKey) && e.keyCode === 221) {
      //⌘ ] or Ctrl ] for Bring to forwARD
      e.preventDefault();
      document.getElementById("BringForward__") &&
        document.getElementById("BringForward__").click();
    } else if (!e.altKey && (e.ctrlKey || e.metaKey) && e.keyCode === 219) {
      //⌘ [ or Ctrl [ for Bring to forwARD
      e.preventDefault();
      document.getElementById("SendBackward__") &&
        document.getElementById("SendBackward__").click();
    } else if (e.altKey && (e.ctrlKey || e.metaKey) && e.keyCode === 219) {
      //⌥ ⌘ ] or Alt Ctrl ] for Bring to front
      e.preventDefault();
      document.getElementById("SendToBack__") &&
        document.getElementById("SendToBack__").click();
    } else if ((e.metaKey || e.ctrlKey) && e.keyCode === 65 && !e.shiftKey) {
      //⌘ A or ctrl a  for select all objects

      if (
        document.activeElement.tagName === "INPUT" ||
        document.activeElement.tagName === "TEXTAREA"
      ) {
        return;
      }
      e.preventDefault();

      let allObjects = []; //= canvas.getObjects().filter(o => { o.type_ !== "background" })
      canvas.getObjects().forEach((o) => {
        if (o.type_ && o.type_ == "background") {
        } else if (o.selectable == false) {
        } else if (o.type == "image" && o.imageRect) {
        } else {
          allObjects.push(o);
        }
      });

      if (allObjects.length > 1) {
        canvas.discardActiveObject();
        canvas.setActiveObject(
          new fabric.ActiveSelection(allObjects, {
            canvas: canvas,
          })
        );
      }
      canvas.requestRenderAll();
    } else if ((e.metaKey || e.ctrlKey) && e.keyCode === 65 && e.shiftKey) {
      // ⇧ ⌘ A or shift ctrl a  for deselect all objects
      canvas.discardActiveObject().renderAll();
    } else if ((e.metaKey || e.ctrlKey) && e.keyCode === 71 && !e.shiftKey) {
      //⌘ G or ctrl G for grouping the selected object
      e.preventDefault();
      document.getElementById("grouped") &&
        document.getElementById("grouped").click();
    } else if ((e.metaKey || e.ctrlKey) && e.keyCode === 71 && e.shiftKey) {
      //⇧ ⌘ G or shift ctrl G for UnGrouping the group object
      e.preventDefault();
      document.getElementById("ungrouped") &&
        document.getElementById("ungrouped").click();
    } else if ((e.metaKey || e.ctrlKey) && e.keyCode === 66) {
      //⌘ B or ctrl B for make the text bold
      if (canvas.getActiveObject()) {
        e.preventDefault();
        document.getElementById("text_bold") &&
          document.getElementById("text_bold").click();
      }
    } else if ((e.metaKey || e.ctrlKey) && e.keyCode === 73) {
      //⌘ I or ctrl I for make the text italic
      if (canvas.getActiveObject()) {
        e.preventDefault();
        document.getElementById("text_italic") &&
          document.getElementById("text_italic").click();
      }
    } else if ((e.metaKey || e.ctrlKey) && e.keyCode === 85) {
      //⌘ U or ctrl U for make the text underline
      if (canvas.getActiveObject()) {
        e.preventDefault();
        document.getElementById("text_underline") &&
          document.getElementById("text_underline").click();
      }
    } else if ((e.metaKey || e.ctrlKey) && e.keyCode === 76 && e.shiftKey) {
      //⇧ ⌘ L or shift ctrl L for text align left
      let active_object = canvas.getActiveObject();
      if (active_object && active_object.type === "textbox") {
        e.preventDefault();
        active_object.set({ textAlign: "left" });
      }
      canvas.renderAll();
    } else if ((e.metaKey || e.ctrlKey) && e.keyCode === 67 && e.shiftKey) {
      //⇧ ⌘ C or ctrl U for text align center
      let active_object = canvas.getActiveObject();
      if (active_object && active_object.type === "textbox") {
        e.preventDefault();
        active_object.set({ textAlign: "center" });
      }
      canvas.renderAll();
    } else if ((e.metaKey || e.ctrlKey) && e.keyCode === 82 && e.shiftKey) {
      //⇧ ⌘ R or shift ctrl R for align text right
      let active_object = canvas.getActiveObject();
      if (active_object && active_object.type === "textbox") {
        e.preventDefault();
        active_object.set({ textAlign: "right" });
      }
      canvas.renderAll();
    } else if ((e.metaKey || e.ctrlKey) && e.keyCode === 190 && e.shiftKey) {
      //⇧ ⌘ > or ctrl shift > for increase text font size
      e.preventDefault();
      document.getElementById("text_font_increase") &&
        document.getElementById("text_font_increase").click();
    } else if ((e.metaKey || e.ctrlKey) && e.keyCode === 188 && e.shiftKey) {
      // ⇧ ⌘ < or ctrl shift < for decrease text font size
      e.preventDefault();
      document.getElementById("text_font_decrease") &&
        document.getElementById("text_font_decrease").click();
    }
    // else if ((e.metaKey || e.ctrlKey) && (e.keyCode === 86) && !e.shiftKey) {// ⌘ V or ctrl V for paste

    //     navigator.clipboard.read()

    //         .then(text => {
    //
    //         })
    //         .catch(err => {
    //             console.error('Failed to read clipboard contents: ', err);
    //         });

    // }
  } else if (
    e.keyCode == 37 ||
    e.keyCode === 38 ||
    e.keyCode === 39 ||
    e.keyCode === 40
  ) {
    if (e.shiftKey && e.keyCode == 37) {
      objectmove_key(canvas, "left", 8, "minus");
    } else if (e.shiftKey && e.keyCode == 38) {
      objectmove_key(canvas, "top", 8, "minus");
    } else if (e.shiftKey && e.keyCode == 39) {
      objectmove_key(canvas, "left", 8, "plus");
    } else if (e.shiftKey && e.keyCode == 40) {
      objectmove_key(canvas, "top", 8, "plus");
    } else if (e.keyCode == 37) {
      objectmove_key(canvas, "left", 1, "minus");
    } else if (e.keyCode == 38) {
      objectmove_key(canvas, "top", 1, "minus");
    } else if (e.keyCode == 39) {
      objectmove_key(canvas, "left", 1, "plus");
    } else if (e.keyCode == 40) {
      objectmove_key(canvas, "top", 1, "plus");
    }
  } else if (e.shiftKey) {
    if (e.shiftKey && e.keyCode == 72) {
      // H handle flip horizontal
      let active_object = canvas.getActiveObject();
      if (active_object) {
        if (active_object.original_img) {
          active_object.original_img.flipX = !active_object.original_img.flipX;
        }
        if (
          (active_object.type == "textbox" || active_object.type == "text") &&
          active_object.isEditing
        ) {
          return;
        }

        active_object.flipX = !active_object.flipX;
        canvas.renderAll();
      }
    } else if (e.shiftKey && e.keyCode == 86 && !(e.metaKey || e.ctrlKey)) {
      //V handle flip vertical
      let active_object = canvas.getActiveObject();
      if (active_object) {
        if (active_object.original_img) {
          active_object.original_img.flipY = !active_object.original_img.flipY;
        }
        if (
          (active_object.type == "textbox" || active_object.type == "text") &&
          active_object.isEditing
        ) {
          return;
        }
        active_object.flipY = !active_object.flipY;
        canvas.renderAll();
      }
    } else if (e.shiftKey && e.keyCode === 9) {
      // (⇧ Tab) to handle the selection of upper layer
      e.preventDefault();
      let active_object = canvas.getActiveObject();
      if (active_object) {
        if (active_object.original_img) {
          let layer_div = document.getElementById(
            `${active_object.original_img.layer_id}_${active_object.original_img.type}_layer_div`
          );
          let layer_outer_div =
            document.getElementsByClassName("layringdiv")[0];
          let current_layer_div_index = Array.prototype.indexOf.call(
            layer_outer_div.children,
            layer_div
          );
          Previous_div_selection_key(current_layer_div_index, layer_outer_div);
        } else {
          let layer_div = document.getElementById(
            `${active_object.layer_id}_${active_object.type}_layer_div`
          );
          let layer_outer_div =
            document.getElementsByClassName("layringdiv")[0];
          let current_layer_div_index = Array.prototype.indexOf.call(
            layer_outer_div.children,
            layer_div
          );
          Previous_div_selection_key(current_layer_div_index, layer_outer_div);
        }
      }
    }
  } else if (e.altKey) {
    if (e.altKey && e.keyCode === 87) {
      // ⌥ W or alt W
      e.preventDefault();
      document.getElementById("Align_top") &&
        document.getElementById("Align_top").click();
    } else if (e.altKey && e.keyCode == 83) {
      // ⌥ S or alt S
      e.preventDefault();
      document.getElementById("Align_Bottom") &&
        document.getElementById("Align_Bottom").click();
    } else if (e.altKey && e.keyCode == 65) {
      // ⌥ A or alt A
      e.preventDefault();
      document.getElementById("Align_left") &&
        document.getElementById("Align_left").click();
    } else if (e.altKey && e.keyCode == 68) {
      // ⌥ D or alt D
      e.preventDefault();
      document.getElementById("Align_Right") &&
        document.getElementById("Align_Right").click();
    } else if (e.altKey && e.keyCode == 86) {
      // ⌥ V or alt V
      e.preventDefault();
      document.getElementById("Align_Middle") &&
        document.getElementById("Align_Middle").click();
    } else if (e.altKey && e.keyCode == 72) {
      // ⌥ H or alt H
      e.preventDefault();
      document.getElementById("Align_Center") &&
        document.getElementById("Align_Center").click();
    }
  } else if (e.keyCode === 9 && !e.shiftKey) {
    // only tab keys are pressed (Handling the selection of lower layer)
    let active_object = canvas.getActiveObject();
    if (active_object) {
      e.preventDefault();
      if (active_object.original_img) {
        let layer_div = document.getElementById(
          `${active_object.original_img.layer_id}_${active_object.original_img.type}_layer_div`
        );
        let layer_outer_div = document.getElementsByClassName("layringdiv")[0];
        let current_layer_div_index = Array.prototype.indexOf.call(
          layer_outer_div.children,
          layer_div
        );
        Next_div_selection_key(current_layer_div_index, layer_outer_div);
      } else {
        let layer_div = document.getElementById(
          `${active_object.layer_id}_${active_object.type}_layer_div`
        );
        let layer_outer_div = document.getElementsByClassName("layringdiv")[0];
        let current_layer_div_index = Array.prototype.indexOf.call(
          layer_outer_div.children,
          layer_div
        );
        Next_div_selection_key(current_layer_div_index, layer_outer_div);
      }
    }
  } else {
    switch (e.keyCode) {
      case 27: // escape key
        if (canvas.getActiveObject()) {
          // e.preventDefault()
          canvas.discardActiveObject();
          canvas.renderAll();
        }
        break;
      case 84: //t key
        // checking if text is in editing mode then do nothing and if is not in editing mode then Adding Text
        if (
          !(
            canvas.getActiveObject() &&
            canvas.getActiveObject().type === "textbox" &&
            canvas.getActiveObject().isEditing === true
          ) &&
          e.target.tagName.toLowerCase() !== "input" &&
          e.target.tagName.toLowerCase() !== "textarea"
        ) {
          let t = {
            id: "body",
            body: "Add text",
            font_size: "12px",
            width: 600,
          };
          AddText(canvas, t);
        }

        break;
      case 76: //l key
        // checking if text is in editing mode then do nothing and if is not in editing mode then Adding Line
        if (
          !(
            canvas.getActiveObject() &&
            canvas.getActiveObject().type === "textbox" &&
            canvas.getActiveObject().isEditing === true
          ) &&
          e.target.tagName.toLowerCase() !== "input" &&
          e.target.tagName.toLowerCase() !== "textarea"
        ) {
          alert("Adding a Line function is not available yet");
        }
        break;
      case 82: // "r" key
        // checking if text is in editing mode then do nothing and if is not in editing mode then Adding Rectangle
        if (
          !(
            canvas.getActiveObject() &&
            canvas.getActiveObject().type === "textbox" &&
            canvas.getActiveObject().isEditing === true
          ) &&
          e.target.tagName.toLowerCase() !== "input" &&
          e.target.tagName.toLowerCase() !== "textarea"
        ) {
          alert("Adding a Rectangle function is not available yet");
        }
        break;
      case 85: // U key
        // checking if text is in editing mode then do nothing and if is not in editing mode then Adding Rounded Rectangle
        if (
          !(
            canvas.getActiveObject() &&
            canvas.getActiveObject().type === "textbox" &&
            canvas.getActiveObject().isEditing === true
          ) &&
          e.target.tagName.toLowerCase() !== "input" &&
          e.target.tagName.toLowerCase() !== "textarea"
        ) {
          alert("Adding a Rounded Rectangle  function is not available yet");
        }
        break;
      case 79: // O key
        // checking if text is in editing mode then do nothing and if is not in editing mode then Adding Circle
        if (
          !(
            canvas.getActiveObject() &&
            canvas.getActiveObject().type === "textbox" &&
            canvas.getActiveObject().isEditing === true
          ) &&
          e.target.tagName.toLowerCase() !== "input" &&
          e.target.tagName.toLowerCase() !== "textarea"
        ) {
          alert("Adding a Circle  function is not available yet");
        }
        break;
      default:
        // Handle other keys

        break;
    }
  }
};
export const pasteItem = (e) => {
  if (
    document.activeElement.tagName === "INPUT" ||
    document.activeElement.tagName === "TEXTAREA"
  ) {
    return;
  }
  let canvas = cccanvas;
  let boxframe = canvas.getObjects().filter((o) => o.type_ === "background")[0];
  let items = e.clipboardData.items; //getting all items that are copied in clipboard
  if (
    !(
      canvas.getActiveObject() &&
      canvas.getActiveObject().type === "textbox" &&
      canvas.getActiveObject().isEditing === true
    )
  ) {
    e.preventDefault();
    e.stopPropagation();

    for (let i = 0; i < items.length; i++) {
      if (items[i].kind === "file") {
        let typee = items[i].type.split("/")[0];
        if (typee === "image") {
          let file = items[i];
          let imageData = file.getAsFile();
          let URLobj = window.URL || window.webkitURL;
          let img = new Image();
          img.src = URLobj.createObjectURL(imageData);

          img.onload = function () {
            let r = {
              url: URLobj.createObjectURL(imageData),
              w: img.naturalWidth,
              h: img.naturalHeight,
            };

            open_image(r, canvas, boxframe);
            img.remove();
          };
        }
      } else if (items[i].type === "text/plain") {
        let text = e.clipboardData.getData("text/plain");

        let data = { id: "body", body: text, font_size: "12px", width: 600 };
        AddText(canvas, data);
        return;
      } else if (items[i].type === "text/html") {
        let html = e.clipboardData.getData("text/html");

        let div = document.createElement("div");
        // div.innerHTML = html;
        // let jsonData = div.querySelector('DesignSDK').textContent;
        // let myObject = JSON.parse(jsonData);

        canvas.copiedItem.clone(function (clonedObj) {
          canvas.discardActiveObject();
          clonedObj.set({
            left: clonedObj.left + 10,
            top: clonedObj.top + 10,
            // evented: true,
          });
          if (clonedObj.type === "rect" && clonedObj.typeis === "image") {
            clonedObj.original_img = canvas.copiedItem.original_img;
            let new_id = "image_photo" + Date.now();
            canvas.copiedItem.original_img.clone(function (c) {
              c.set({
                left: clonedObj.left,
                top: clonedObj.top,
                id: new_id,
                // evented: true,
              });
              clonedObj.id = new_id;
              clonedObj.original_img = c;
              c.imageRect = clonedObj;
              canvas.add(c);
            });
            canvas.add(clonedObj);
          } else if (clonedObj.type === "activeSelection") {
            // active selection needs a reference to the canvas.
            clonedObj.canvas = canvas;
            clonedObj.forEachObject(function (obj) {
              if (obj.type === "rect" && obj.typeis && obj.typeis == "image") {
                obj.group.getObjects().forEach((f) => {
                  if (f.id === obj.id && f.type === "image") {
                    obj.original_img = f;
                    f.imageRect = obj;
                  }
                });
                let new_id = "image_photo" + Date.now();
                obj.id = new_id;
                obj.original_img.id = new_id;
                obj.original_img.imageRect = obj;
                canvas.add(obj);
              } else {
                canvas.add(obj);
              }
            });
            // this should solve the unselectability
            clonedObj.setCoords();
          } else {
            canvas.add(clonedObj);
          }
          canvas.copiedItem.top += 10;
          canvas.copiedItem.left += 10;
          canvas.setActiveObject(clonedObj);
          canvas.requestRenderAll();
        });
      }

      // canvas.renderAll();
    }
  }
};

export const copyItem = function (e) {
  let canvas = cccanvas;
  let text = window.getSelection().toString();
  let active_object = canvas.getActiveObject() && canvas.getActiveObject();
  const colorPattern = /^#([0-9A-Fa-f]{3,6})$/;
  if (colorPattern.test(text)) {
    return;
  } else if (
    !(
      active_object &&
      active_object.type === "textbox" &&
      active_object.isEditing === true
    )
  ) {
    if (active_object) {
      e.preventDefault();
      canvas.copiedItem = [];
      if (active_object.type === "rect" && active_object.original_img) {
        active_object.clone(function (cloned) {
          cloned.original_img = active_object.original_img;
          canvas.copiedItem = cloned;
        });
      } else {
        canvas.getActiveObject().clone(function (cloned) {
          canvas.copiedItem = cloned;
        });
      }
      let dataObject = canvas.copiedItem;
      let jsonData = JSON.stringify(dataObject);
      // Create a new div element to hold the HTML
      let htmlData = document.createElement("div");
      htmlData.innerHTML = "<DesignSDK>" + jsonData + "</DesignSDK>";

      // Create a new ClipboardItem object with text and HTML data
      let item = new ClipboardItem({
        "text/html": new Blob([htmlData.innerHTML], { type: "text/html" }),
      });
      // Write the ClipboardItem to the clipboard
      navigator.clipboard
        .write([item])
        .then(() => console.log("JSON data copied to clipboard"))
        .catch((err) => console.error("Could not copy JSON data: ", err));
    }
  } // Group of Objects (all groups require async clone)
};

export const check_CornerOverlapping = (obj, canvas) => {
  const rotatePoint = (point) =>
    fabric.util.rotatePoint(
      point,
      obj.getCenterPoint(),
      fabric.util.degreesToRadians(-obj.angle)
    );

  const tl_ = rotatePoint(new fabric.Point(obj.oCoords.tl.x, obj.oCoords.tl.y));
  const ml_ = rotatePoint(new fabric.Point(obj.oCoords.ml.x, obj.oCoords.ml.y));
  const mt_ = rotatePoint(new fabric.Point(obj.oCoords.mt.x, obj.oCoords.mt.y));

  const cornerVisibility = {
    tl: true,
    tr: true,
    bl: true,
    br: true,
    ml: true,
    mr: true,
    mt: true,
    mb: true,
    mtr: true,
  };

  if (!canvas.crop_mode) {
    if (obj.type === "textbox") {
      ["tl", "tr", "bl", "br", "ml", "mr", "mtr"].forEach((a) => {
        obj.setControlVisible(a, true);
      });
    }

    if (
      parseInt(ml_.y - tl_.y) <= obj.cornerSize + obj.cornerSize / 4 ||
      parseInt(mt_.x - tl_.x) <= obj.cornerSize + obj.cornerSize / 4
    ) {
      Object.keys(cornerVisibility).forEach((a) => {
        obj.setControlVisible(a, false);
      });

      obj.setControlVisible("mtr", true);
      obj.setControlVisible("br", true);
      obj.setControlVisible("ml", true);
      canvas.renderAll();
    } else {
      Object.keys(cornerVisibility).forEach((a) => {
        obj.setControlVisible(a, true);
      });

      if (obj.type === "textbox" || obj.type === "text") {
        obj.setControlVisible("mt", false);
        obj.setControlVisible("mb", false);
      }
    }

    if (obj._type === "line") {
      Object.keys(cornerVisibility).forEach((ele) => {
        obj.setControlVisible(ele, false);
      });
      obj.setControlVisible("ml", true);
      obj.setControlVisible("mr", true);
    }
  }
};

// export const renderloaderFrame = (obj, ctx, pre_render_fn, Canvas) => {
//   console.log(obj, "renderring loadder frame");
//   obj.render = function (ctx) {
//     var t = this.width,
//       a = this.height,
//       n = (((+new Date() / 2) % 360) * Math.PI) / 180,
//       i = (((+new Date() / 4) % 360) * Math.PI) / 180,
//       z = (this.canvas && this.canvas.getZoom()) || 1,
//       o = Math.max(this.scaleX || 1, this.scaleY || 1),
//       // c = 6 / o / z,
//       c = 12,
//       s = 0.5 / o / z,
//       l = this.left,
//       t = this.top;
//     // ctx.rotate(this.angle);
//     ctx.fillStyle = "rgba(210, 210, 210, " + (0.2 + 0.4 * Math.sin(i / 2)) + ")";
//     ctx.fillRect(l, t, this.width * this.scaleX, this.height * this.scaleY);

//     ctx.beginPath();
//     ctx.arc(this.getCenterPoint().x, this.getCenterPoint().y, c, n, n + Math.PI);
//     ctx.strokeStyle = "#4BD3FB";
//     ctx.fillStyle = "#4BD3FB";

//     ctx.fill();
//     ctx.closePath();

//     // console.log(Canvas, "anananan");

//     if (Canvas) {
//       Canvas.requestRenderAll();
//     } else {
//       this.canvas.requestRenderAll();
//     }
//   };
// };
export const renderloaderFrame = (obj, ctx, pre_render_fn, Canvas) => {
  console.log(obj, "renderring loadder frame");
  obj.render = function (ctx) {
    var t = this.width,
      a = this.height,
      n = (((+new Date() / 2) % 360) * Math.PI) / 180,
      i = (((+new Date() / 4) % 360) * Math.PI) / 180,
      z = (this.canvas && this.canvas.getZoom()) || 1,
      o = Math.max(this.scaleX || 1, this.scaleY || 1),
      // c = 6 / o / z,
      c = 12,
      s = 0.5 / o / z,
      l = this.left,
      t = this.top;

    ctx.fillStyle =
      "rgba(210, 210, 210, " + (0.2 + 0.4 * Math.sin(i / 2)) + ")";
    ctx.fillRect(l, t, this.width * this.scaleX, this.height * this.scaleY);
    // let b = fabric.util.rotatePoint(new fabric.Point(obj.left, obj.top), obj.getCenterPoint(), fabric.util.degreesToRadians(-obj.angle));
    // ctx.fillRect(b.x, b.y, this.width * this.scaleX, this.height * this.scaleY);
    ctx.beginPath();
    ctx.arc(
      this.getCenterPoint().x,
      this.getCenterPoint().y,
      c,
      n,
      n + Math.PI
    );
    ctx.strokeStyle = "#4BD3FB";
    ctx.fillStyle = "#4BD3FB";

    ctx.fill();
    ctx.closePath();

    // console.log(Canvas, "anananan");

    if (Canvas) {
      Canvas.requestRenderAll();
    } else {
      this.canvas.requestRenderAll();
    }
  };
};

// export const r

export const add_element = (data, canvas) => {
  if (data.type === "stencils") {
    console.log("urlllll", data.img_url);
    fabric.Image.fromURL(data.img_url, function (img) {
      img.set({
        left: 500,
        top: 300,
        width: img.width,
        height: img.height,
        clipPath: new fabric.Path(
          // "M50,0 L150,0 L200,100 L125,200 L25,200 Z",
          data.img_path,
          {
            left: -259,
            top: -259,
          }
        ),
      });

      canvas.add(img);
      img.clipPath.scaleX = img.width / img.clipPath.width;
      img.clipPath.scaleY = img.height / img.clipPath.height;
      img.scaleToWidth(300);
    });
  }
};

export const convertToRgb = (color) => {
  // Check if the color is in RGBA format
  if (/rgba?/i.test(color)) {
    return color;
  }

  // Check if the color is in hexadecimal format
  if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(color)) {
    let hex = color.replace("#", "");

    if (hex.length === 3) {
      hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
    }

    const r = parseInt(hex.substring(0, 2), 16);
    const g = parseInt(hex.substring(2, 4), 16);
    const b = parseInt(hex.substring(4, 6), 16);

    return `rgba(${r}, ${g}, ${b}, 1)`;
  }

  // If the color is not in RGBA or hexadecimal format, return null or handle it as needed
  return color;
};
export function dataURLtoBlob(dataURL) {
  var arr = dataURL.split(",");
  var mime = arr[0].match(/:(.*?);/)[1];
  var bstr = atob(arr[1]);
  var n = bstr.length;
  var u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new Blob([u8arr], { type: mime });
}
export const zoomtofit = (canvas, boxframe) => {
  const scaleX = canvas.width / (boxframe.width + 200);
  const scaleY = canvas.height / (boxframe.height + 200);
  const scale = Math.min(scaleX, scaleY);
  canvas.zoomToPoint(
    { x: canvas.getCenter().left, y: canvas.getCenter().top },
    scale
  );
  canvas.fire("mouse:wheel"); // fire mousewheel event to change the zoom value in html
  canvas.renderAll();
};
export const AutoCompleteApi = async (e) => {
  return new Promise((resolve, reject) => {
    fetch(
      `${SEARCH_BASE_URL}/getAutoCompleteText/?apiversion=2020-06-30&search=${e}&suggesterName=test`
    )
      .then((response) => response.json())
      .then((data) => {
        console.log("get autocomplete text", data);
        resolve(data);
      })
      .catch((error) => {
        console.error(error);
        reject(error);
      });
  });
};
export const spellCheckApi = (text) => {
  console.log("spellCheckApi", text);
  return new Promise((resolve, reject) => {
    fetch(`${SEARCH_BASE_URL}/getSpellCheckText/?text=${text}`)
      .then((response) => response.json())
      .then((data) => {
        resolve(data);
      })
      .catch((error) => {
        console.error(error);
        reject(error);
      });
  });
};
export const callSearchApi = (search_val, category_name, search_no) => {
  console.log("spellCheckApi callsearchapi", search_val);
  return new Promise((resolve, reject) => {
    if (!category_name || category_name === "all") {
      fetch(
        `${SEARCH_BASE_URL}/getSearchTemplate/?search=${search_val}&no=${
          search_no ? search_no : 50
        }`
      )
        .then((response) => response.json())
        .then((data) => {
          resolve(data);
        })
        .catch((error) => {
          console.error(error);
          reject(error);
        });
    } else {
      fetch(
        `${SEARCH_BASE_URL}/getSearchTemplate/?search=${search_val}&category=${category_name}&no=${
          search_no ? search_no : 50
        }`
      )
        .then((response) => response.json())
        .then((data) => {
          resolve(data);
        })
        .catch((error) => {
          console.error(error);
          reject(error);
        });
    }
  });
};

/**
 * Calculate the size of a data URL in megabytes.
 * @param {string} dataUrl - The data URL to calculate size for.
 * @returns {number} - The size of the data URL in megabytes.
 */
export function calculateDataUrlSize(dataUrl) {
  // Remove the "data:" prefix to isolate the base64 data
  const base64EncodedData = dataUrl.split(",")[1];

  // Decode the base64-encoded data
  const rawBytes = atob(base64EncodedData);

  // Calculate the size in bytes and convert to megabytes
  const sizeInBytes = rawBytes.length;
  const sizeInMB = sizeInBytes / (1024 * 1024);

  return sizeInMB.toFixed(2); // Return the size rounded to 2 decimal places
}

/**
 * Get a data URL representation of the canvas.
 * @param {fabric.Canvas} canvas - The Fabric.js canvas instance.
 * @param {number} multiplier - Multiplier for data URL generation.
 * @returns {string|null} - The data URL of the canvas or null if an error occurs.
 */
export const canvasDataURL = (canvas, multiplier) => {
  try {
    if (!(canvas instanceof fabric.Canvas)) {
      throw new Error("Invalid canvas instance");
    }

    if (canvas._pages.length > 1) {
      const firstPageId = canvas._pages[0].pageId;
      const imgElement = document.getElementById(`${firstPageId}_page_img`);

      if (imgElement) {
        return imgElement.src;
      } else {
        console.warn(`Image element not found for page ${firstPageId}`);
        return null; // Indicate error gracefully
      }
    } else {
      const originalViewportTransform = canvas.viewportTransform.slice();

      canvas.viewportTransform = [1, 0, 0, 1, 0, 0];

      const dataUrl = canvas.toDataURL({
        left: canvas.clipPath.left,
        top: canvas.clipPath.top,
        width: canvas.clipPath.width,
        height: canvas.clipPath.height,
        multiplier: multiplier,
      });

      canvas.viewportTransform = originalViewportTransform;

      return dataUrl;
    }
  } catch (error) {
    console.error("An error occurred creating data url:", error.message);
    return null; // Indicate error gracefully
  }
};

/**
 * Get canvas JSON data for multiple pages.
 * @param {fabric.Canvas} canvas - The Fabric.js canvas instance.
 * @returns {Array} - Array of canvas data for each page.
 */
export const getMultiCanvasData = (canvas) => {
  // Ensure canvas is a valid instance
  if (!(canvas instanceof fabric.Canvas)) {
    throw new Error("Invalid canvas instance");
  }
  const allPageCanvasData = [];

  // Loop through each page
  for (const page of canvas._pages) {
    // Create a data object for each page
    const pageData = {
      pageId: page.pageId,
      canvasData: null,
    };

    // Check if the current page is the active page
    if (page.pageId === canvas.pageId) {
      pageData.canvasData = canvas.toJSON();
    } else {
      pageData.canvasData = page.data;
    }

    allPageCanvasData.push(pageData);
  }

  return allPageCanvasData;
};

/**
 * Load User Project (Single/Multi Canvas) into a canvas
 * @param {fabric.Canvas} canvas - The canvas to load the project into.
 * @param {Array} canvasData - An array of objects containing canvas data for each page.
 * @param {Array} thumbnailLinks - An array of thumbnail links corresponding to each page (optional).
 */

export const loadUserProjectInCanvas = async function (
  canvas,
  projectResponse
) {
  try {
    // Check if canvas is an instance of fabric.Canvas
    if (!(canvas instanceof fabric.Canvas)) {
      throw new Error("Invalid canvas instance");
    }
    let canvasData = JSON.parse(projectResponse.data);
    let thumbnailLink = projectResponse.DownloadData;
    let workAreaJSON = canvas.getObjects()[0].toJSON();
    console.log("workarea json here", workAreaJSON, canvasData);
    // clear canvas and related properties
    if (projectResponse && !projectResponse.from) {
      // FOR DESIGNCLOUDPROJECT
      canvasData = migrateDesignToNewEditor(canvasData, projectResponse);
      removePreLoadedCanvas(canvas, canvasData);
      // workAreaJSON.canvas_page_id = canvasData[0].pageId;
      canvasData[0].canvasData.objects.unshift(workAreaJSON);
      let wArea;
      canvas.loadFromJSON(
        canvasData[0].canvasData,
        (obj) => {
          console.log("after loading adding all", canvas, obj);
          canvas.clipPath.set({
            height: wArea.height,
            width: wArea.width,
            left: wArea.left,
            top: wArea.top,
          });
          canvas.getObjects().forEach((object) => {
            // canvas.forEachObject(function (object) {
            object.evented =
              object.prevEvented !== undefined
                ? object.prevEvented
                : object.evented;
            object.selectable =
              object.prevSelectable !== undefined
                ? object.prevSelectable
                : object.selectable;
            // });
            if (object.type == "image" && object.id != "viewport") {
              object.canvas_page_id = canvasData[0].pageId;
              let imageRect = addRect2img(canvas, {}, object);

              object.imageRect = imageRect;
              canvas.add(imageRect);
              canvas.moveTo(imageRect, canvas.getObjects().indexOf(object) + 1);
            } else if (object.type == "text") {
              let textId = `text_${getRandomID(4)}`;
              let textBox = new fabric.Textbox(object.toObject().text, {
                left: object.left,
                top: object.top,
                width: parseInt(object.width * object.get("scaleX")) + 1,
                fontSize: parseInt(object.fontSize * object.get("scaleX")),
                fontFamily: object.fontFamily
                  .replace(/["']/g, "")
                  .split(",")[0],
                id: textId,
                fill: object.fill,
                breakWords: false,
                originX: "center",
                originY: "center",
              });
              textBox.set({
                scaleX: 1,
                scaleY: 1,
                // width: object.width * object.get("scaleX")
              });
              canvas.remove(object);
              canvas.add(textBox);
              textBox.setPositionByOrigin(
                textBox.getCenterPoint(),
                "left",
                "top"
              );
              WebFont.load({
                loading: function () {
                  // console.log("webfont, loading")
                },
                fontactive: function () {
                  textBox.set({
                    fontFamily: textBox.fontFamily,
                  });
                  canvas.renderAll();
                },
                fontinactive: function () {
                  console.log("webfont, loading failed");
                },
                google: {
                  families: [textBox.fontFamily],
                },
              });
            } else if (object.type_ && object.type_ === "background") {
              object.set({
                width: wArea.width,
                height: wArea.height,
                canvas_page_id: canvasData[0].pageId,
              });
              if (canvasData[0].canvasData.backgroundImage) {
                // open_image({ url: canvasData[0].canvasData.backgroundImage.src }, canvas, canvas.getObjects()[0]);
                object.set({
                  background_fill: { type: "image" },
                  background_fill_copy: { type: "image" },
                });
                setbgImg(
                  canvasData[0].canvasData.backgroundImage.src,
                  canvas,
                  object,
                  canvas.clipPath.width,
                  canvas.clipPath.height
                );
                canvas.backgroundImage = null;
                let dispatchBgfn = document.getElementById("updatebgdispatch");
                dispatchBgfn && dispatchBgfn.click();
              } else if (
                (canvasData[0].canvasData.background &&
                  canvasData[0].canvasData.background.type == "linear") ||
                (canvasData[0].canvasData.background &&
                  canvasData[0].canvasData.background.type == "radial")
              ) {
                let gradient = new fabric.Gradient(
                  canvasData[0].canvasData.background
                );
                object.set({
                  backgroundColor: gradient.toLive(canvas.contextContainer),
                  background_fill: gradient,
                  background_fill_copy: gradient,
                });
                let dispatchBgfn = document.getElementById("updatebgdispatch");
                dispatchBgfn && dispatchBgfn.click();
              } else if (
                canvasData[0].canvasData.background &&
                canvasData[0].canvasData.background.type == "pattern"
              ) {
                object.set({
                  backgroundColor: "#ffffff",
                  background_fill: "#ffffff",
                  background_fill_copy: "#ffffff",
                });
                let dispatchBgfn = document.getElementById("updatebgdispatch");
                dispatchBgfn && dispatchBgfn.click();
                toast.error(
                  "Failed to apply pattern as pattern is not supported in this version"
                );
              } else {
                let dispatchBgfn = document.getElementById("updatebgdispatch");
                dispatchBgfn && dispatchBgfn.click();
              }
            } else if (object.type === "group") {
              object.getObjects().forEach((obj) => {
                if (obj.type === "image") {
                  obj.canvas_page_id = canvasData[0].pageId;
                  let imageRect = addRect2img(canvas, {}, obj);
                  obj.imageRect = imageRect;
                  object.add(imageRect);
                  //object.moveTo(imageRect, object.getObjects().indexOf(obj) + 1);
                }
              });
            }
          });

          if (!canvas.selection) {
            canvas.selection = true;
          }
          canvas.requestRenderAll();
          canvas.backgroundColor = null;
          canvas.setViewportTransform([1, 0, 0, 1, 1, 1]);
          fitinViewPort(canvas);
          canvas.renderAll.bind(canvas);
          toggleProjectLoader(false);
          canvas.savestate();
          document.getElementById("projectLoader") &&
            (document.getElementById("projectLoader").style.display = "none");
        },
        function (o, object) {
          if (object) {
            if (object.type_ && object.type_ === "background") {
              object.set({
                backgroundColor: canvasData[0].canvasData.background
                  ? canvasData[0].canvasData.background
                  : "#ffffff",
                background_fill: canvasData[0].canvasData.background
                  ? canvasData[0].canvasData.background
                  : "#ffffff",
                background_fill_copy: canvasData[0].canvasData.background
                  ? canvasData[0].canvasData.background
                  : "#ffffff",
                width: 890,
                height: parseInt(projectResponse.canvaHeight),
                // vptheight: parseInt(projectResponse.canvaHeight),
                // vptwidth: vptwidth,
              });
              if (canvasData[0].canvasData.backgroundImage) {
                // fabric.util.loadImage(
                //   canvasData[0].canvasData.backgroundImage.src,
                //   function (loadedimg) {
                //     object.set({
                //       src: canvasData[0].canvasData.backgroundImage.src,
                //       // background_fill: object,
                //       _width: loadedimg.naturalWidth,
                //       _height: loadedimg.naturalHeight,
                //       width: 890,
                //       //angle:canvasData[0].canvasData.backgroundImage.angle,
                //       cropX: object.cropX - canvasData[0].canvasData.backgroundImage.left,
                //       cropY: object.cropY - canvasData[0].canvasData.backgroundImage.top,
                //       height: parseInt(projectResponse.canvaHeight),
                //     });
                //     object.setElement(loadedimg);
                //     let currentBg = canvas && canvas.getObjects()[0] && canvas.getObjects()[0];
                //     currentBg &&
                //       currentBg.set({
                //         width: wArea.width,
                //         height: wArea.height,
                //         //angle:canvasData[0].canvasData.backgroundImage.angle,
                //         cropX: object.cropX - canvasData[0].canvasData.backgroundImage.left,
                //         cropY: object.cropY - canvasData[0].canvasData.backgroundImage.top,
                //         background_fill: currentBg,
                //         vptheight: canvas.clipPath.height,
                //         vptwidth: canvas.clipPath.width,
                //       });
                //     canvas.backgroundImage = null;
                //     let dispatchBgfn = document.getElementById("updatebgdispatch");
                //     dispatchBgfn && dispatchBgfn.click();
                //     canvas.renderAll();
                //   },
                //   canvas.getContext("2d"),
                //   {
                //     crossOrigin: "anonymous",
                //   }
                // );
              }
              canvas.centerObject(object);
              wArea = {
                height: parseInt(projectResponse.canvaHeight), //object.height,
                width: 890, //object.width,
                left: object.left,
                top: object.top,
              };
            } else if (!object.type_ && object.type == "image") {
              let id = `img_${getRandomID(4)}`;

              object.set({
                left: wArea.left + object.left,
                top: wArea.top + object.top,
                typeis: "image",
                evented: false,
                selectable: false,
                isCropped: "original",
                centeredRotation: true,
                transparentCorners: false,
                id: id,
                id2: id,
                lockScalingFlip: true,
                _width: object.width,
                _height: object.height,
              });
              if (object.originX == "center" && object.originY == "center") {
                object.originX = "left";
                object.originY = "top";
                object.setPositionByOrigin(
                  new fabric.Point(object.left, object.top),
                  "center",
                  "center"
                );
                object.setCoords();
              }
            } else if (object.type === "group") {
              object.set({
                left: wArea.left + object.left,
                top: wArea.top + object.top,
              });
              object._objects.forEach((obj) => {
                if (obj.type === "image") {
                  let id = `img_${getRandomID(4)}`;
                  obj.set({
                    // left: wArea.left + object.left,
                    // top: wArea.top + object.top,
                    typeis: "image",
                    evented: false,
                    selectable: false,
                    isCropped: "original",
                    centeredRotation: true,
                    transparentCorners: false,
                    id: id,
                    id2: id,
                    lockScalingFlip: true,
                    _width: obj.width,
                    _height: obj.height,
                  });
                  if (obj.originX == "center" && obj.originY == "center") {
                    obj.originX = "left";
                    obj.originY = "top";
                    obj.setPositionByOrigin(
                      new fabric.Point(obj.left, obj.top),
                      "center",
                      "center"
                    );
                    obj.setCoords();
                  }
                }
              });
            } else {
              console.log("object loaded one by one,", object.type);
              object.set({
                left: wArea.left + object.left,
                top: wArea.top + object.top,
              });
            }
          }
          console.log("adding one by one from previous editor", o, object);

          // if (object.type == "text" || object.type == "textbox") {
          //   object.set({
          //     fontFamily: object.fontFamily
          //       .replace(/["']/g, "")
          //       .split(",")[0],
          //   });
          //   canvas.renderAll();
          //   let newObj = { ...object, breakWords: false, };
          //   object = new fabric.Textbox(object.toObject().text, newObj);
          //   canvas.add(object);
          //   var objWidth = object.get("width") * object.scaleX;
          //   object.set("fontSize", parseInt(object.get("fontSize") * object.get("scaleX")));
          //   object.set("scaleX", 1);
          //   object.set("scaleY", 1);
          //   if (objWidth != undefined) {
          //     object.set("width", objWidth * object.get("scaleX"));
          //   }
          //   canvas.renderAll();
          // } else if (object.type === "image") {
          //   // let id = `image_${i + 1}`;
          //   object.id = `image_${getRandomID(8)}`;
          //   let boxframe = {};
          //   let rect = addRect2img(canvas, boxframe, object);
          //   object.set({
          //     selectable: false,
          //     isCropped: "original",
          //     centeredRotation: true,
          //     transparentCorners: false,
          //     id2: object.id,
          //     lockScalingFlip: true,
          //     imageRect: rect,
          //     borderColor: "#60D2F4",
          //     cornerStyle: "circle",
          //     typeis: "image",
          //     evented: false,
          //     _width: object.width,
          //     _height: object.height,
          //     location: "template",
          //   });
          //   rect.hasControls = true;
          //   rect.selectable = true

          //   canvas.add(object);
          //   canvas.add(rect)
          //   // object.hasControls = true;
          //   canvas.renderAll()
          // }
        },
        canvas.savestate()
      );

      if (canvasData.length > 1) {
        console.log("multi canvas legacy project is loading", canvasData);
        for (let i = 1; i < canvasData.length; i++) {
          let newPageJson = {
            pageId: canvasData[i].pageId,
            data: canvasData[i].canvasData,
          };
          canvas._pages.splice(i, 0, newPageJson);

          addNewPageDivOnReloading(
            canvas,
            i,
            newPageJson,
            thumbnailLink &&
              thumbnailLink.length > 0 &&
              thumbnailLink[i] &&
              thumbnailLink[i]
          );
          console.log(`${i}page added`);
        }
      }
      console.log("this project is from previous design Editor");
    } else {
      // RELOAD, DASHBOARDEDIT
      removePreLoadedCanvas(canvas, canvasData);
      canvas.loadFromJSON(
        canvasData[0].canvasData,
        (obj) => {
          console.log("after loading adding all", canvas.pageId);
          console.log("loadingaterundo", obj);
          canvas.renderAll.bind(canvas);
          let allOBJECTS = canvas.getObjects();

          // Toggle Drag Mode - Passing true to enable drag mode
          canvas.toggleDragMode(false);

          for (let i = 0; i < allOBJECTS.length; i++) {
            allOBJECTS[i].evented =
              allOBJECTS[i].prevEvented !== undefined
                ? allOBJECTS[i].prevEvented
                : allOBJECTS[i].evented;
            allOBJECTS[i].selectable =
              allOBJECTS[i].prevSelectable !== undefined
                ? allOBJECTS[i].prevSelectable
                : allOBJECTS[i].selectable;
            if (allOBJECTS[i].type === "image") {
              if (
                allOBJECTS[i].type === "image" &&
                allOBJECTS[i].filters.length > 0
              ) {
                allOBJECTS[i].applyFilters();
                // allOBJECTS[i].applyFilters(function () {
                //     allOBJECTS[i].canvas.renderAll();
                // });
              }

              for (let j = 0; j < allOBJECTS.length; j++) {
                if (
                  allOBJECTS[i].id === allOBJECTS[j].id &&
                  allOBJECTS[i].type != allOBJECTS[j].type
                ) {
                  allOBJECTS[j].original_img = allOBJECTS[i];
                  allOBJECTS[j].original_img.imageRect = allOBJECTS[j];
                }
              }
              // if (allOBJECTS[i].imageLoading && allOBJECTS[i].imageLoading) {
              //   console.log("still loading", allOBJECTS[i]);
              //   renderloaderFrame(allOBJECTS[i], canvas.getContext("2d"));
              // }
              if (allOBJECTS[i].type_ && allOBJECTS[i].type_ === "background") {
                if (typeof allOBJECTS[i].background_fill_copy === typeof {}) {
                  if (
                    allOBJECTS[i].background_fill_copy.type == "linear" ||
                    allOBJECTS[i].background_fill_copy.type == "radial"
                  ) {
                    let gradient = new fabric.Gradient(
                      allOBJECTS[i].background_fill_copy
                    );
                    allOBJECTS[i].set({
                      backgroundColor: gradient.toLive(canvas.contextContainer),
                      background_fill: gradient,
                      background_fill_copy: gradient,
                    });
                  } else if (
                    allOBJECTS[i].background_fill_copy.type == "image"
                  ) {
                    allOBJECTS[i].set({
                      background_fill: allOBJECTS[i],
                      background_fill_copy: allOBJECTS[i].toJSON(),
                    });
                  }
                }
              } else if (
                typeof allOBJECTS[i].backgroundColor === typeof "" ||
                typeof allOBJECTS[i].background_fill_copy === typeof ""
              ) {
                allOBJECTS[i].set({
                  background_fill: allOBJECTS[i].backgroundColor,
                  background_fill_copy: allOBJECTS[i].backgroundColor,
                });
              }
            }
            canvas.renderAll();
          }
          canvas.setViewportTransform([1, 0, 0, 1, 1, 1]);

          fitinViewPort(canvas);
          let dispatchBgfn = document.getElementById("updatebgdispatch");
          dispatchBgfn && dispatchBgfn.click();
          toggleProjectLoader(false);
          canvas.savestate();
        },
        function (o, object) {
          console.log("adding one by one", o, object);
        }
      );

      if (canvasData.length > 1) {
        console.log("multi canvas is loading", canvasData);

        for (let i = 1; i < canvasData.length; i++) {
          let newPageJson = {
            pageId: canvasData[i].pageId,
            data: canvasData[i].canvasData,
          };
          canvas._pages.splice(i, 0, newPageJson);
          addNewPageDivOnReloading(canvas, i, newPageJson, thumbnailLink[i]);
          console.log(`${i}page added`);
        }
      }
    }
  } catch (error) {
    console.error("Error in loadUserProjectInCanvas:", error);
    toggleProjectLoader(false);
    // Handle the error or rethrow it for higher-level handling.
  }
};

const addNewPageDivOnReloading = (
  canvas,
  at,
  pageDataJson,
  thumbnailLink = defaultThumb
) => {
  let canvas_page_div = document.getElementsByClassName("all_page_div")[0];
  // let pvt = canvas.viewportTransform;
  // canvas.viewportTransform = [1, 0, 0, 1, 0, 0];
  // let src_canvas = canvas.toDataURL({
  //   left: canvas.clipPath.left,
  //   top: canvas.clipPath.top,
  //   width: canvas.clipPath.width,
  //   height: canvas.clipPath.height,
  // });
  let src_canvas = thumbnailLink;
  let newdiv = document.createElement("div");
  newdiv.id = `${pageDataJson.pageId}_page_div`;
  newdiv.classList.add("current_page_div");
  // document.querySelectorAll(".current_page_div.active").forEach(function (c) {
  //   c.classList.remove("active");
  // });
  // newdiv.classList.add("active");
  let pageimg = document.createElement("img");
  pageimg.id = `${pageDataJson.pageId}_page_img`;
  pageimg.alt = "page_view";
  pageimg && (pageimg.src = src_canvas);
  // canvas.viewportTransform = pvt;
  // canvas.renderAll();
  newdiv.appendChild(pageimg);
  let threedotdiv = document.createElement("div");
  threedotdiv.id = `${pageDataJson.pageId}_page_img_threedot`;
  threedotdiv.classList.add("moreBTN_page_before");
  threedotdiv.innerHTML = `<div class="moreBTN_page" id= ${pageDataJson.pageId}_page_div_moreBTN>
<svg width=10px height=10px viewBox="0 0 1024 1024" id= ${pageDataJson.pageId}_page_div_threedot_svg>
<path id= ${pageDataJson.pageId}_page_div_threedot_path fill="#fff" d="M249.6 512c0 70.4-57.6 128-128 128S0 582.4 0 512s57.6-128 128-128 121.6 57.6 121.6 128z m384 0c0 70.4-57.6 128-128 128S384 582.4 384 512s57.6-128 128-128 121.6 57.6 121.6 128z m384 0c0 70.4-57.6 128-128 128s-128-57.6-128-128 57.6-128 128-128 128 57.6 128 128z"></path></svg><div>`;
  newdiv.appendChild(threedotdiv);
  newdiv.addEventListener("click", Select_Page_onclick, false);
  threedotdiv.addEventListener("click", togglePageMenu, false);
  let current_pageId = pageDataJson.pageId;
  if (at === "first" || at === "last") {
    // canvas_page_div.appendChild(newdiv);
    // let p = {
    //   pageId: current_pageId,
    //   data: canvas.toJSON(),
    // };
    // canvas._pages.push(p);
  } else {
    canvas_page_div.insertBefore(newdiv, canvas_page_div.children[at]);
    // let p = {
    //   pageId: current_pageId,
    //   data: canvas.toJSON(),

    //   pageName: previous_page_name ? previous_page_name : "Add Page Title",
    // };
    // canvas._pages.splice(at, 0, p);
    // arr.splice(2, 0, { id: 50 });
  }
};

/**
 * Get an array of dataURL of all pages of canvas
 * @param {Object}  canvas - fabricjs canvas that contain all canvas data
 * @param {string} purpose - The purpose for which getMultiCanvasDataUrl function called (e.g., "autosave").
 * @param {string} thumbLink - First page dataurl to include in the array (optional).
 * @returns {Array} - An array of data URLs.
 */
export const getMultiCanvasDataUrl = (canvas, purpose, thumbLink) => {
  try {
    if (purpose === "autosave") {
      const dataUrlArray = thumbLink ? [thumbLink] : [];

      for (const page of canvas._pages) {
        if (page.pageId !== canvas._pages[0].pageId) {
          const imageElement = document.getElementById(
            `${page.pageId}_page_img`
          );
          const imageUrl = imageElement && imageElement.src;

          if (imageUrl) {
            dataUrlArray.push(imageUrl);
          }
        }
      }

      return dataUrlArray;
    }
  } catch (error) {
    console.error("Error in getMultiCanvasDataUrl:", error);
    return []; // Return an empty array in case of an error.
  }
};

export const removePreLoadedCanvas = (canvas, canvasData) => {
  canvas.clear();
  const currentPageId = canvas.pageId;
  const pageDiv = document.getElementById(`${canvas.pageId}_page_div`);
  if (pageDiv) {
    pageDiv.remove();
  }
  canvas._pages = canvas._pages.filter((obj) => obj.pageId !== currentPageId);
  canvas.pageId = canvasData[0].pageId;
  let options = {
    canvas: canvas,
    at: "first",
  };

  addnewpagediv(options);
};
export const getRandomColor = (colors) => {
  return colors[Math.floor(Math.random() * colors.length)];
};

export function formatDate(date) {
  const day = date.getDate();
  const month = date.toLocaleString("default", { month: "long" });
  const time = date.toLocaleTimeString("en-US", {
    hour: "2-digit",
    minute: "2-digit",
  });

  return `${day} ${month} - ${time}`;
}
export const migrateDesignToNewEditor = (canvasData, projectResponse) => {
  const updatedData = canvasData.map((data) => {
    data.objects.forEach((obj) => {
      if (obj.type == "group" && obj._type && obj._type == "stencil") {
        obj.objects.forEach((stencilImg) => {
          if (stencilImg.type == "image") {
            stencilImg.crossOrigin = "anonymous";
          }
        });
      }
    });
    if (data.overlayImage) {
      delete data.overlayImage; // Remove overlayImage property if it exists
    }
    if (data.overlay) {
      delete data.overlay; // Remove overlayImage property if it exists
    }

    return {
      canvasData: data,
      pageId: getRandomID(36),
    };
  });

  return updatedData;
};
export const loadLegacyProject = (canvas, JsonData, projectResponse) => {
  let canvaHeight = projectResponse ? projectResponse : canvas.clipPath.height;
  let workAreaJSON = canvas.getObjects()[0].toJSON();
  workAreaJSON.src = null;
  workAreaJSON.background_fill_copy = "#ffffff";
  workAreaJSON.scaleX = 1;
  workAreaJSON.scaleY = 1;
  JsonData.data.objects.unshift(workAreaJSON);

  let wArea = canvas.getObjects()[0];
  canvas.clear();
  canvas.loadFromJSON(
    JsonData.data,
    (obj) => {
      console.log("after loading adding all", canvas, obj);
      canvas.clipPath.set({
        height: wArea.height,
        width: wArea.width,
        left: wArea.left,
        top: wArea.top,
      });
      canvas.getObjects().forEach((object) => {
        if (object.type == "image" && object.id != "viewport") {
          object.canvas_page_id = JsonData.pageId;
          let imageRect = addRect2img(canvas, {}, object);
          imageRect.rotate(object.angle);
          object.imageRect = imageRect;
          canvas.add(imageRect);
          canvas.moveTo(imageRect, canvas.getObjects().indexOf(object) + 1);
        } else if (object.type == "text") {
          let textId = `text_${getRandomID(4)}`;
          let textBox = new fabric.Textbox(object.toObject().text, {
            left: object.left,
            top: object.top,
            width: parseInt(object.width * object.get("scaleX")) + 1,
            fontSize: parseInt(object.fontSize * object.get("scaleX")),
            fontFamily: object.fontFamily.replace(/["']/g, "").split(",")[0],
            id: textId,
            fill: object.fill,
            breakWords: false,
            originX: "center",
            originY: "center",
          });
          textBox.set({
            scaleX: 1,
            scaleY: 1,
            // width: object.width * object.get("scaleX")
          });
          canvas.remove(object);
          canvas.add(textBox);
          textBox.setPositionByOrigin(textBox.getCenterPoint(), "left", "top");
          WebFont.load({
            loading: function () {
              // console.log("webfont, loading")
            },
            fontactive: function () {
              textBox.set({
                fontFamily: textBox.fontFamily,
              });
              canvas.renderAll();
            },
            fontinactive: function () {
              console.log("webfont, loading failed");
            },
            google: {
              families: [textBox.fontFamily],
            },
          });
        } else if (object.type_ && object.type_ === "background") {
          object.set({
            width: wArea.width,
            height: wArea.height,
            canvas_page_id: JsonData.pageId,
          });
          if (JsonData.data.backgroundImage) {
            // open_image({ url: canvasData[0].canvasData.backgroundImage.src }, canvas, canvas.getObjects()[0]);
            object.set({
              background_fill: { type: "image" },
              background_fill_copy: { type: "image" },
            });
            setbgImg(
              JsonData.data.backgroundImage.src,
              canvas,
              object,
              canvas.clipPath.width,
              canvas.clipPath.height
            );
            canvas.backgroundImage = null;
            let dispatchBgfn = document.getElementById("updatebgdispatch");
            dispatchBgfn && dispatchBgfn.click();
          } else if (
            (JsonData.data.background &&
              JsonData.data.background.type == "linear") ||
            (JsonData.data.background &&
              JsonData.data.background.type == "radial")
          ) {
            let gradient = new fabric.Gradient(JsonData.data.background);
            object.set({
              backgroundColor: gradient.toLive(canvas.contextContainer),
              background_fill: gradient,
              background_fill_copy: gradient,
            });
            let dispatchBgfn = document.getElementById("updatebgdispatch");
            dispatchBgfn && dispatchBgfn.click();
          } else if (
            JsonData.data.background &&
            JsonData.data.background.type == "pattern"
          ) {
            object.set({
              backgroundColor: "#ffffff",
              background_fill: "#ffffff",
              background_fill_copy: "#ffffff",
            });
            let dispatchBgfn = document.getElementById("updatebgdispatch");
            dispatchBgfn && dispatchBgfn.click();
            toast.error(
              "Failed to apply pattern as pattern is not supported in this version"
            );
          } else {
            let dispatchBgfn = document.getElementById("updatebgdispatch");
            dispatchBgfn && dispatchBgfn.click();
          }
        } else if (object.type === "group") {
          object.getObjects().forEach((obj) => {
            if (obj.type === "image") {
              obj.canvas_page_id = JsonData.pageId;
              let imageRect = addRect2img(canvas, {}, obj);
              obj.imageRect = imageRect;
              object.add(imageRect);
              //object.moveTo(imageRect, object.getObjects().indexOf(obj) + 1);
            }
          });
        }
      });
      canvas.requestRenderAll();
      canvas.backgroundColor = null;
      canvas.renderAll.bind(canvas);
    },
    function (o, object) {
      if (object) {
        if (object.type_ && object.type_ === "background") {
          object.set({
            backgroundColor: JsonData.data.background
              ? JsonData.data.background
              : "#ffffff",
            background_fill: JsonData.data.background
              ? JsonData.data.background
              : "#ffffff",
            background_fill_copy: JsonData.data.background
              ? JsonData.data.background
              : "#ffffff",
            width: 890,
            height: parseInt(canvaHeight),
            // vptheight: parseInt(canvaHeight),
            // vptwidth: vptwidth,
          });
          // if (JsonData.backgroundImage) {
          //   fabric.util.loadImage(
          //     JsonData.backgroundImage.src,
          //     function (loadedimg) {
          //       object.set({
          //         src: JsonData.backgroundImage.src,
          //         // background_fill: object,
          //         _width: loadedimg.naturalWidth,
          //         _height: loadedimg.naturalHeight,
          //         width: 890,
          //         height: parseInt(canvaHeight),
          //       });
          //       object.setElement(loadedimg);
          //     },
          //     canvas.getContext("2d"),
          //     {
          //       crossOrigin: "anonymous",
          //     }
          //   );
          // }
          canvas.centerObject(object);
          wArea = {
            height: parseInt(canvaHeight), //object.height,
            width: 890, //object.width,
            left: object.left,
            top: object.top,
          };
        } else if (!object.type_ && object.type == "image") {
          let id = `img_${getRandomID(4)}`;
          object.set({
            left: wArea.left + object.left,
            top: wArea.top + object.top,
            typeis: "image",
            evented: false,
            selectable: false,
            isCropped: "original",
            centeredRotation: true,
            transparentCorners: false,
            id: id,
            id2: id,
            lockScalingFlip: true,
            _width: object.width,
            _height: object.height,
          });
          if (object.originX == "center" && object.originY == "center") {
            object.originX = "left";
            object.originY = "top";
            object.setPositionByOrigin(
              new fabric.Point(object.left, object.top),
              "center",
              "center"
            );
            object.setCoords();
          }
        } else if (object.type === "group") {
          object.set({
            left: wArea.left + object.left,
            top: wArea.top + object.top,
          });
          object._objects.forEach((obj) => {
            if (obj.type === "image") {
              let id = `img_${getRandomID(4)}`;
              obj.set({
                // left: wArea.left + object.left,
                // top: wArea.top + object.top,
                typeis: "image",
                evented: false,
                selectable: false,
                isCropped: "original",
                centeredRotation: true,
                transparentCorners: false,
                id: id,
                id2: id,
                lockScalingFlip: true,
                _width: obj.width,
                _height: obj.height,
              });
            }
            if (obj.originX == "center" && obj.originY == "center") {
              obj.originX = "left";
              obj.originY = "top";
              obj.setPositionByOrigin(
                new fabric.Point(obj.left, obj.top),
                "center",
                "center"
              );
              obj.setCoords();
            }
          });
        } else {
          console.log("object loaded one by one,", object.type);
          object.set({
            left: wArea.left + object.left,
            top: wArea.top + object.top,
          });
        }
      }
      console.log("adding one by one from previous editor", o, object);
    }
  );
};

export const addTextInCanvas = (canvas, textBody, dimension) => {
  const textWidth = getTextWidth(textBody, "normal 40px Roboto");
  console.log("adding text in canvas", textWidth);
  let Text = new fabric.Textbox(textBody, {
    left: dimension.left + dimension.getScaledWidth() / 2,
    top: dimension.top + dimension.getScaledHeight() + 20,
    textAlign: "center",
    fontFamily: "Roboto",
    originX: "center",
    originY: "center",
    fontWeight: "normal",
    width: textWidth,
  });
  Text.set({
    left: Text.left - Text.getScaledWidth() / 2,
  });
  let bg = canvas.getObjects()[0];
  if (Text.top + Text.getScaledHeight() > bg.top + bg.getScaledHeight()) {
    console.log("its down now");
    Text.set({
      top: bg.top + bg.getScaledHeight() - Text.getScaledHeight(),
    });
  }
  if (Text.left + Text.getScaledWidth() > bg.left + bg.getScaledWidth()) {
    console.log("its outside now");
    Text.set({
      left: bg.left + bg.getScaledWidth() / 2 - Text.getScaledWidth() / 2,
    });
  }
  Text.setPositionByOrigin(
    new fabric.Point(Text.left, Text.top),
    "left",
    "top"
  );
  dimension && canvas.add(Text);
  canvas.renderAll();
};

export const getTextWidth = (text, font) => {
  const canvas = document.createElement("canvas");
  const context = canvas.getContext("2d");
  context.font = font;
  const width = context.measureText(text).width;
  return width;
};

export const findPropertyvalue = (array, id) => {
  let a = array.find(function (obj) {
    return obj.id === id;
  });
  return a;
};

export const AppplyEffectStyle = (style, activeObj, Canvas) => {
  activeObj.textEffectKey = style.key;
  if (style.key === "e0") {
    // handle for none style
    RemoveEffectStyle(activeObj, Canvas);
    activeObj.dirty = true;
    activeObj.textEffects = {
      key: style.key,
    };
    activeObj.setCoords();
    Canvas.renderAll();
  } else if (style.key === "e1") {
  } else if (style.key === "e2") {
    // handle for e1 (shadow) effect style
    RemoveEffectStyle(activeObj);
    activeObj.dirty = true;
    let offset = findPropertyvalue(style.innerProps, "shadow_offset").value;
    let angle = findPropertyvalue(style.innerProps, "shadow_direction").value;
    activeObj.set({
      shadow: {
        color: findPropertyvalue(style.innerProps, "shadow_color").color[0]
          .shadow_color,
        offsetX: Math.cos((angle * Math.PI) / 180) * offset, //findPropertyvalue(style.innerProps, "shadow_offset",).offsetX,
        offsetY: Math.sin((angle * Math.PI) / 180) * offset, //findPropertyvalue(style.innerProps, "shadow_offset",).offsetY,
        blur: findPropertyvalue(style.innerProps, "shadow_blur").blur,
      },
    });
    activeObj.textEffects = {
      key: style.key,
      color: activeObj.shadow.color,
      offset: offset,
      direction: angle,
      blur: activeObj.shadow.blur,
      transparency: parseFloat(activeObj.shadow.color.split(",")[3]) * 100,
    };
    activeObj.setCoords();
    Canvas.renderAll();
  } else if (style.key === "e3") {
    // for stroke
    RemoveEffectStyle(activeObj, Canvas);
    activeObj.set({
      paintFirst: "stroke",
      strokeMiterLimit: 0,
      strokeLineJoin:
        findPropertyvalue(style.innerProps, "stroke_corner").value === true
          ? "round"
          : "square",
      stroke: activeObj.fill,
      strokeWidth:
        findPropertyvalue(style.innerProps, "stroke_thickness").value / 2,
    });
    activeObj.fill = "transparent";
    activeObj.setCoords();
    activeObj.textEffects = {
      key: style.key,
      stroke_color: activeObj.stroke,
      stroke_thickness: findPropertyvalue(style.innerProps, "stroke_thickness")
        .value,
      stroke_rounded_corner: findPropertyvalue(
        style.innerProps,
        "stroke_corner"
      ).value,
    };
    Canvas.renderAll();
  } else if (style.key === "e4") {
    // for stroke and shadow both
    RemoveEffectStyle(activeObj, Canvas);
    let offset = findPropertyvalue(style.innerProps, "shadow_offset").value;
    let angle = findPropertyvalue(style.innerProps, "shadow_direction").value;
    activeObj.set({
      paintFirst: "stroke",
      strokeMiterLimit: 0,
      strokeLineJoin:
        findPropertyvalue(style.innerProps, "stroke_corner").value === true
          ? "round"
          : "square",
      stroke: activeObj.fill,
      //findPropertyvalue(style.innerProps, "stroke_color",).color,
      strokeWidth:
        findPropertyvalue(style.innerProps, "stroke_thickness").value / 2,
      //fill: convertToRgb(activeObj.fill).replace(/[\d\.]+\)$/, 0.1 + ')'),//"transparent",
      shadow: {
        type: "shadow",
        color: findPropertyvalue(style.innerProps, "shadow_color").color[0]
          .shadow_color,
        offsetX: Math.cos((angle * Math.PI) / 180) * offset, //findPropertyvalue(style.innerProps, "shadow_offset",).offsetX,
        offsetY: Math.sin((angle * Math.PI) / 180) * offset, //findPropertyvalue(style.innerProps, "shadow_offset",).offsetY,
        blur: findPropertyvalue(style.innerProps, "shadow_blur").blur,
      },
    });
    activeObj.textEffects = {
      key: style.key,
      shadow_color: activeObj.shadow.color,
      stroke_color: activeObj.stroke,
      stroke_thickness: findPropertyvalue(style.innerProps, "stroke_thickness")
        .value,
      offset: offset,
      direction: angle,
      blur: activeObj.shadow.blur,
      transparency: parseFloat(activeObj.shadow.color.split(",")[3]) * 100,
      stroke_rounded_corner: findPropertyvalue(
        style.innerProps,
        "stroke_corner"
      ).value,
    };
    Canvas.renderAll();
  } else if (style.key === "e5") {
    // for double shadow
    RemoveEffectStyle(activeObj, Canvas);
    let offset = findPropertyvalue(style.innerProps, "shadow_offset").value;
    let offset2 = 20;
    let angle = findPropertyvalue(style.innerProps, "shadow_direction").value;
    activeObj.dirty = true;
    activeObj.set({
      shadows: [
        {
          type: "shadow",
          color: findPropertyvalue(style.innerProps, "shadow_color").color[0]
            .shadow_color,
          offsetX: Math.cos((angle * Math.PI) / 180) * offset, //findPropertyvalue(style.innerProps, "shadow_offset",).
          offsetY: Math.sin((angle * Math.PI) / 180) * offset,
          blur: 1,
        },
        {
          type: "shadow",
          color: findPropertyvalue(
            style.innerProps,
            "shadow_color"
          ).color[0].shadow_color.replace(/(\d+(\.\d+)?\s*\))$/, "0.4)"),
          offsetX: Math.cos((angle * Math.PI) / 180) * offset2, //findPropertyvalue(style.innerProps, "shadow_offset",).
          offsetY: Math.sin((angle * Math.PI) / 180) * offset2,
          blur: 1,
        },
      ],
    });
    activeObj.textEffects = {
      key: style.key,
      color1: activeObj.shadows[0].color,
      color2: activeObj.shadows[1].color,
      offset1: offset, //activeObj.shadows[0].offset,
      offset2: offset2, //activeObj.shadows[1].offset2,
      direction: angle,
    };
    activeObj.setCoords();
  } else if (style.key === "e6") {
    // handle for glitch
    RemoveEffectStyle(activeObj, Canvas);
    let offset = findPropertyvalue(style.innerProps, "shadow_offset").value;
    let offset2 = -findPropertyvalue(style.innerProps, "shadow_offset").value;
    let angle = findPropertyvalue(style.innerProps, "shadow_direction").value;
    activeObj.dirty = true;
    activeObj.set({
      shadows: [
        {
          type: "shadow",
          color: findPropertyvalue(style.innerProps, "shadow_color").color[0]
            .shadow_color,
          offsetX: Math.cos((angle * Math.PI) / 180) * offset,
          offsetY: Math.cos((angle * Math.PI) / 180) * offset,
          blur: 1,
        },
        {
          type: "shadow",
          color: findPropertyvalue(style.innerProps, "shadow_color").color[1]
            .shadow_color,
          offsetX: Math.cos((angle * Math.PI) / 180) * offset2,
          offsetY: Math.cos((angle * Math.PI) / 180) * offset2,
          blur: 1,
        },
      ],
    });
    activeObj.textEffects = {
      key: style.key,
      color1: activeObj.shadows[0].color,
      color2: activeObj.shadows[1].color,
      offset1: offset, //activeObj.shadows[0].offset,
      offset2: offset2, //activeObj.shadows[1].offset2,
      direction: angle,
    };
    activeObj.setCoords();
  } else if (style.key === "e7") {
    // for Neon
  } else if (style.key === "e8") {
    // for highlighter
    RemoveEffectStyle(activeObj, Canvas);
    activeObj.set({
      textBackgroundColor: findPropertyvalue(style.innerProps, "stroke_color")
        .color[0].shadow_color,
      highlighterRadius: findPropertyvalue(
        style.innerProps,
        "highlighter_roundness"
      ).value,
      highlighterThickness: findPropertyvalue(
        style.innerProps,
        "highlighter_thickness"
      ).value,
    });
    activeObj.textEffects = {
      key: style.key,
      textBackgroundColor: activeObj.textBackgroundColor,
      highlighterRadius: activeObj.highlighterRadius,
      highlighterThickness: activeObj.highlighterThickness,
      highlighterOpacity:
        parseFloat(activeObj.textBackgroundColor.split(",")[3]) * 100,
    };
  } else if (style.key === "e9") {
    RemoveEffectStyle(activeObj, Canvas);
    let p = {
      color1: findPropertyvalue(style.innerProps, "gradient_color").color[0]
        .shadow_color,
      color2: findPropertyvalue(style.innerProps, "gradient_color").color[1]
        .shadow_color,
    };
    activeObj.set(
      "fill",
      new fabric.Gradient({
        //gradient options

        angle: findPropertyvalue(style.innerProps, "gradient_direction").value,
        type: "linear",
        gradientUnits: "pixels", // or 'percentage'
        coords: {
          x1:
            (Math.round(
              50 + Math.sin(-parseInt(90, 10) * (Math.PI / 180)) * 50
            ) *
              (activeObj.width * activeObj.scaleX)) /
            100,
          y1:
            (Math.round(
              50 + Math.cos(-parseInt(90, 10) * (Math.PI / 180)) * 50
            ) *
              (activeObj.height * activeObj.scaleY)) /
            100,
          x2:
            (Math.round(
              50 + Math.sin(-parseInt(90, 10) * (Math.PI / 180) + Math.PI) * 50
            ) *
              (activeObj.width * activeObj.scaleX)) /
            100,
          y2:
            (Math.round(
              50 + Math.cos(-parseInt(90, 10) * (Math.PI / 180) + Math.PI) * 50
            ) *
              (activeObj.height * activeObj.scaleY)) /
            100,
        },
        colorStops: [
          { offset: 0, color: p.color1 },
          { offset: 1, color: p.color2 },
        ],
      })
    );
    $("#font____").css(
      "background",
      `linear-gradient(to right, ${p.color1}, ${p.color2})`
    );
    activeObj.textEffects = {
      key: style.key,
      gradient_direction: findPropertyvalue(
        style.innerProps,
        "gradient_direction"
      ).value,
      gradient_color: [p.color1, p.color2],
    };
    Canvas.renderAll();
  } else if (style.key === "e10") {
    // for gradient 2 (radial)
    RemoveEffectStyle(activeObj, Canvas);
    let p = {
      color1: findPropertyvalue(style.innerProps, "gradient_color").color[0]
        .shadow_color,
      color2: findPropertyvalue(style.innerProps, "gradient_color").color[1]
        .shadow_color,
    };

    activeObj.set(
      "fill",
      new fabric.Gradient({
        //gradient options
        type: "radial",
        gradientUnits: "pixels", // or 'percentage'
        coords: {
          x1: activeObj.width / 2,
          y1: activeObj.height / 2,
          x2: activeObj.width / 2,
          y2: activeObj.height / 2,
          r1: activeObj.height / 2 + activeObj.width / 4,
          r2: activeObj.width * 0.05,
        },
        colorStops: [
          { offset: 0, color: p.color1 },
          { offset: 1, color: p.color2 },
        ],
      })
    );
    $("#font____").css(
      "background",
      `radial-gradient(${p.color1}, ${p.color2})`
    );
    activeObj.textEffects = {
      key: style.key,
      gradient_color: [p.color1, p.color2],
    };
  } else if (style.key === "e11") {
    // for gradient 3
    RemoveEffectStyle(activeObj, Canvas);
    let p = {
      color1: findPropertyvalue(style.innerProps, "gradient_color").color[0]
        .shadow_color,
      color2: findPropertyvalue(style.innerProps, "gradient_color").color[1]
        .shadow_color,
    };
    activeObj.set(
      "fill",
      new fabric.Gradient({
        //gradient options

        angle: findPropertyvalue(style.innerProps, "gradient_direction").value,
        type: "linear",
        gradientUnits: "pixels", // or 'percentage'
        coords: {
          x1: activeObj.getScaledWidth(),
          y1: activeObj.getScaledHeight(),
          x2: activeObj.getScaledWidth(),
          y2: 0,
        },
        colorStops: [
          { offset: 0.39, color: p.color1 },
          { offset: 0.48, color: p.color2 },
        ],
      })
    );
    $("#font____").css(
      "background",
      `linear-gradient(to right, ${p.color1}, ${p.color2})`
    );
    let offset = findPropertyvalue(
      style.innerProps,
      "gradient_shadow_offset"
    ).value;
    let angle = findPropertyvalue(
      style.innerProps,
      "gradient_shadow_direction"
    ).value;
    activeObj.set({
      shadow: {
        color: findPropertyvalue(style.innerProps, "shadow_color").color[0]
          .shadow_color,
        offsetX: Math.cos((angle * Math.PI) / 180) * offset, //findPropertyvalue(style.innerProps, "shadow_offset",).offsetX,
        offsetY: Math.sin((angle * Math.PI) / 180) * offset, //findPropertyvalue(style.innerProps, "shadow_offset",).offsetY,
        blur: findPropertyvalue(style.innerProps, "gradient_shadow_blur").blur,
      },
      paintFirst: "stroke",
      strokeMiterLimit: 0,
      strokeLineJoin:
        findPropertyvalue(style.innerProps, "gradient_stroke_corner").value ===
        true
          ? "round"
          : "square",
      stroke: findPropertyvalue(style.innerProps, "stroke_color").color[0]
        .shadow_color,
      strokeWidth:
        findPropertyvalue(style.innerProps, "gradient_stroke_thickness").value /
        2,
    });
    activeObj.textEffects = {
      key: style.key,
      gradient_direction: findPropertyvalue(
        style.innerProps,
        "gradient_direction"
      ).value,
      shadow_color: activeObj.shadow.color,
      stroke_color: activeObj.stroke,
      gradient_color: [p.color1, p.color2],
      shadow_offset: offset,
      shadow_direction: angle,
      shadow_blur: activeObj.shadow.blur,
      stroke_thickness: activeObj.strokeWidth,
      stroke_rounded_corner: findPropertyvalue(
        style.innerProps,
        "gradient_stroke_corner"
      ).value,
    };
  }
  Canvas.renderAll();
};
export const RemoveEffectStyle = (activeObj, Canvas) => {
  activeObj.set({
    shadow: null, // for e2
    stroke: null, // foe e3
    fill: "rgba(0,0,0,1)",
    strokeWidth: 0, // for e3
    shadows: [], // for glitch and double shadows
    textBackgroundColor: null, // for highlighter
    highlighterRadius: null, // for highlighter
    highlighterThickness: null, // for highlighter
  });
  activeObj.dirty = true;
  //activeObj.setCoords()
  //Canvas.requestRenderAll()
  Canvas.renderAll();
};

export const refineLayoutJson = (layout, obj, bg, canvas) => {
  let retrunedData = { left: 0, top: 0, angle: 0 };
  //for all left
  // retrunedData.left=(bg.left + bg.getScaledWidth()) / 2 - obj.getScaledWidth() / 2

  if (layout.dimension.left == "0%") {
    retrunedData.left = bg.left;
  } else if (layout.dimension.left == "10%") {
    retrunedData.left = bg.left + bg.getScaledWidth() / 10 / 2;
  } else if (layout.dimension.left == "50%") {
    retrunedData.left =
      bg.left + bg.getScaledWidth() / 2 - obj.getScaledWidth() / 2;
  } else if (layout.dimension.left == "90%") {
    retrunedData.left =
      bg.left +
      bg.getScaledWidth() -
      (obj.getScaledWidth() + bg.getScaledWidth() / 10 / 2);
  } else if (layout.dimension.left == "100%") {
    retrunedData.left = bg.left + bg.getScaledWidth() - obj.getScaledWidth();
  }
  //for all top
  if (layout.dimension.top == "0%") {
    retrunedData.top = bg.top;
  } else if (layout.dimension.top == "10%") {
    retrunedData.top = bg.top + bg.getScaledHeight() / 10 / 2;
  } else if (layout.dimension.top == "40%") {
    retrunedData.top = (bg.top + bg.getScaledHeight()) * 0.4;
    // retrunedData.top; //(bg.top + bg.getScaledHeight()) / 2 - obj.getScaledHeight() / 2;
  } else if (layout.dimension.top == "50%") {
    retrunedData.top =
      (bg.top + bg.getScaledHeight()) / 2 - obj.getScaledHeight() / 2;
  } else if (layout.dimension.top == "60%") {
    retrunedData.top = (bg.top + bg.getScaledHeight()) * 0.6;
  } else if (layout.dimension.top == "70%") {
    retrunedData.top = (bg.top + bg.getScaledHeight()) * 0.7;
  } else if (layout.dimension.top == "90%") {
    retrunedData.top =
      bg.top +
      bg.getScaledHeight() -
      (obj.getScaledHeight() + bg.getScaledHeight() / 10 / 2);
  } else if (layout.dimension.top == "100%") {
    retrunedData.top = bg.top + bg.getScaledHeight() - obj.getScaledHeight();
  }

  return retrunedData;
};

export const aIYTcanvasDataURL = (canvas, multiplier) => {
  const originalViewportTransform = canvas.viewportTransform.slice();

  canvas.viewportTransform = [1, 0, 0, 1, 0, 0];

  const dataUrl = canvas.toDataURL({
    left: canvas.clipPath.left,
    top: canvas.clipPath.top,
    width: canvas.clipPath.width,
    height: canvas.clipPath.height,
    multiplier: multiplier,
  });

  canvas.viewportTransform = originalViewportTransform;

  return dataUrl;
};

export const openLayoutImage = async (
  currentLayout,
  canvas,
  boxframe,
  E4Styles,
  fontlist,
  imgData,
  Title,
  Description
) => {
  try {
    canvas.discardActiveObject();
    if (currentLayout) {
      // let refinedLayout = refineLayoutJson(currentLayout)
      const bg = canvas.getObjects()[0];
      // let RefTitleText;
      currentLayout.map(async (layout) => {
        // if (!layout.canvasVisibility) {
        //   return;
        // }
        if (layout.id == "Heroimage") {
          imgData.returnImage = true;
          let image = await open_image(imgData, canvas, boxframe);
          canvas.discardActiveObject();
          let layoutDimension = refineLayoutJson(layout, image, bg, canvas);
          image.set({
            ytLayoutId: "aiYtHeroImage",
            left: layoutDimension.left,
            angle: layoutDimension.angle,
            visible: layout.canvasVisibility,
          });
          image.imageRect.set({
            left: layoutDimension.left,
            angle: layoutDimension.angle,
            visible: layout.canvasVisibility,
          });
          canvas.moveTo(image, 1);
          canvas.moveTo(image.imageRect, 2);
          const layerDiv = document.querySelector(".layringdiv");
          const img_rect_div = document.getElementById(
            `${image.imageRect.layer_id}_${image.imageRect.type}_layer_div`
          );
          layerDiv.insertBefore(
            img_rect_div,
            layerDiv.children[layerDiv.children.length - 1]
          );
          const img_div = document.getElementById(
            `${image.layer_id}_${image.type}_layer_div`
          );
          layerDiv.insertBefore(
            img_div,
            layerDiv.children[layerDiv.children.length - 1]
          );
          if (!layout.canvasVisibility) {
            let layer_div = document.getElementById(
              `${image.layer_id}_${image.type}_layer_div`
            );
            if (layer_div.style.display !== "none") {
              layer_div.style.display = "none";
            }
          }
        } else if (layout.id == "TitleText") {
          let titleText = AddText(canvas, {
            id: "Heading",
            body: Title,
            width: 600,
          });
          AppplyEffectStyle(E4Styles, titleText, canvas);
          titleText.set({
            ytLayoutId: "aiYtTitleText",
            fontFamily:
              fontlist.fontList[
                Math.floor(Math.random() * fontlist.fontList.length)
              ].family,
            fontWeight: "bold",
            fontSize: 80,
            angle: layout.dimension.angle,
            stroke: "rgba(255,255,255)",
            visible: layout.canvasVisibility,
          });
          let layoutDimension = refineLayoutJson(layout, titleText, bg, canvas);
          titleText.set({
            top: layoutDimension.top,
            left: layoutDimension.left,
          });
          titleText.setPositionByOrigin(
            new fabric.Point(titleText.left, titleText.top),
            "left",
            "top"
          );
          // titleText.rotate(layout.dimension.angle);

          // if (layout.textRectVisibility) {
          let titleRect = new fabric.Rect({
            ytLayoutId: "aiYtTitleRect",
            top: titleText.top - 10,
            left: layout.dimension.rectWidth ? bg.left : titleText.left - 10,
            width: layout.dimension.rectWidth
              ? bg.getScaledWidth()
              : titleText.getScaledWidth() + 20,
            height: titleText.getScaledHeight() + 20,
            fill: "rgb(98, 102, 105)",
            angle: titleText.angle,
            originX: "center",
            originy: "center",
            type_: "basicShape",
            __type: "shape",
            visible: layout.canvasVisibility && layout.textRectVisibility,
          });

          titleRect.setPositionByOrigin(
            new fabric.Point(titleText.left, titleText.top),
            "center",
            "center"
          );
          // titleRect.rotate(RefTitleText.angle);
          canvas.add(titleRect);
          canvas.sendBackwards(titleRect);
          // }
          // if (layout.arrowVisibility) {
          let arrowSVG = `<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
            viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;" xml:space="preserve">
         <polygon points="512,393.952 310.056,192 425.24,76.84 0,2.856 73.984,428 194.84,307.176 396.808,509.144 "/>
         </svg>`;
          let fabricArrow = fabric.loadSVGFromString(
            arrowSVG,
            function (objects, options) {
              const svg = fabric.util.groupSVGElements(objects, options);
              let layoutDimension = refineLayoutJson(layout, svg, bg, canvas);
              svg.set({
                ytLayoutId: "aiYtTitleArrow",
                left: titleText.left + titleText.getScaledWidth() / 2,
                top: titleText.top,
                type_: "basicShape",
                __type: "shape",
                scaleX: 0.25,
                scaleY: 0.25,
                originX: "center",
                originy: "center",
                visible: layout.canvasVisibility && layout.arrowVisibility,
              });
              svg.setPositionByOrigin(
                new fabric.Point(titleRect.left, titleRect.top),
                "center",
                "center"
              );
              canvas.add(svg);

              if (!layout.arrowVisibility) {
                let layer_div = document.getElementById(
                  `${svg.layer_id}_${svg.type}_layer_div`
                );
                if (layer_div && layer_div.style.display !== "none") {
                  layer_div.style.display = "none";
                }
              }
            }
          );
          // }
          // RefTitleText = titleText;
          if (!layout.canvasVisibility) {
            let layer_div = document.getElementById(
              `${titleText.layer_id}_${titleText.type}_layer_div`
            );
            if (layer_div && layer_div.style.display !== "none") {
              layer_div.style.display = "none";
            }
          }
          if (!layout.textRectVisibility) {
            let layer_div = document.getElementById(
              `${titleRect.layer_id}_${titleRect.type}_layer_div`
            );
            if (layer_div && layer_div.style.display !== "none") {
              layer_div.style.display = "none";
            }
          }
        } else if (layout.id == "DescText") {
          let DescText = AddText(canvas, {
            id: "body",
            body: Description,
            width: 600,
          });
          AppplyEffectStyle(E4Styles, DescText, canvas);
          let layoutDimension = refineLayoutJson(layout, DescText, bg, canvas);
          DescText.set({
            ytLayoutId: "aiYtDescText",
            top: layoutDimension.top,
            left: layoutDimension.left,
            fontFamily:
              fontlist.fontList[
                Math.floor(Math.random() * fontlist.fontList.length)
              ].family,
            stroke: "rgba(255,255,255)",

            visible: layout.canvasVisibility,
          });
          DescText.setPositionByOrigin(
            new fabric.Point(DescText.left, DescText.top),
            "left",
            "top"
          );
          DescText.rotate(layout.dimension.angle);

          // if (layout.textRectVisibility) {
          let descRect = new fabric.Rect({
            ytLayoutId: "aiYtDescRect",
            top: DescText.top - 10,
            left: layout.dimension.rectWidth ? bg.left : DescText.left - 10,
            width: layout.dimension.rectWidth
              ? bg.getScaledWidth()
              : DescText.getScaledWidth() + 20,
            height: DescText.getScaledHeight() + 20,
            fill: "rgb(98, 102, 105)",
            angle: DescText.angle,
            type_: "basicShape",
            __type: "shape",
            originX: "center",
            originy: "center",

            visible: layout.canvasVisibility && layout.textRectVisibility,
          });
          descRect.setPositionByOrigin(
            new fabric.Point(descRect.left, descRect.top),
            "left",
            "top"
          );
          // titleRect.rotate(RefTitleText.angle);
          canvas.add(descRect);
          canvas.sendBackwards(descRect);
          // }

          if (!layout.canvasVisibility) {
            let layer_div = document.getElementById(
              `${DescText.layer_id}_${DescText.type}_layer_div`
            );
            if (layer_div && layer_div.style.display !== "none") {
              layer_div.style.display = "none";
            }
          }
          if (!layout.textRectVisibility) {
            let layer_div = document.getElementById(
              `${descRect.layer_id}_${descRect.type}_layer_div`
            );
            if (layer_div && layer_div.style.display !== "none") {
              layer_div.style.display = "none";
            }
          }
        } else if (layout.id == "arrow") {
        }
      });
      canvas.discardActiveObject();
      canvas.renderAll();
    }
  } catch (error) {
    console.log("Error in openLayoutImage", error);
    throw error;
  }
};

let offsetHeight = 0;
export const updateNewLeft_Toolbar = (id, canvas) => {
  const triggerbtn = document.getElementById("left-panel-trigger-btn");
  const leftasidemenu = document.getElementById("leftasidemenu");
  const allChildElements = document.querySelectorAll(".leftChild");

  if (
    window.innerWidth >= 1280 &&
    leftasidemenu.classList.contains("activeMenu") &&
    triggerbtn
  ) {
    triggerbtn.click();
  }

  allChildElements.forEach((element) => {
    if (element.id === id) {
      const isVisible = element.style.display === "block";
      element.style.display = isVisible ? "none" : "block";

      if (isMobile) {
        $(".plusTemplate").css("display", isVisible ? "block" : "none");
      }

      document.getElementById("");
      if (!isVisible) {
        setTimeout(() => {
          if (id == "TetxEffect") {
            offsetHeight = 280;
          } else {
            offsetHeight = element.offsetHeight;
          }
        }, 2);
      }

      ///////
      ///////
      ///////Adjusting canvas according to the toolbar popup height.

      setTimeout(() => {
        if (isMobile) {
          //if (offsetHeight >= 180) {
          canvas.preDimensions = {
            width: canvas.width,
            height: canvas.height,
          };
          const canvasDimensions = {
            width: canvas.getWidth(),
            height:
              canvas.getHeight() -
              (isVisible ? -(offsetHeight - 60) : offsetHeight - 60),
          };
          canvas.setDimensions(canvasDimensions);

          fitinViewPort(canvas);
          // canvas.centerObject(canvas._objects[0]);
          // canvas.setCoords();
          canvas.renderAll();

          var icon = document.getElementById("handIcon"); //To adjust hand tool according to the popup height.
          icon.style.bottom = isVisible
            ? "90px"
            : id == "TetxEffect"
            ? element.offsetHeight + 15 + "px"
            : offsetHeight + 15 + "px";
          //}
        }
      }, 3);
    } else {
      element.style.display = "none";
    }
  });
};

export const closePop = (id, canvas) => {
  if (document.getElementById(id)) {
    document.getElementById(id).click();
  } else {
    const allChildElements = document.querySelectorAll(".leftChild");
    allChildElements.forEach((ele) => {
      if (ele.style.display == "block") {
        const height = ele.offsetHeight;
        ele.style.display = "none";
        cccanvas.preDimensions = {
          width: cccanvas.width,
          height: cccanvas.height,
        };
        const canvasDimensions = {
          width: cccanvas.getWidth(),
          height: cccanvas.getHeight() + (height - 60),
        };

        cccanvas.setDimensions(canvasDimensions);
        fitinViewPort(cccanvas);

        cccanvas.renderAll();

        var icon = document.getElementById("handIcon");
        icon.style.bottom = "90px";
      }
    });
    $(".plusTemplate").css("display", "block");
    const toolbarIconsList = document.querySelector(".toolbaricons-list");
    if (toolbarIconsList.classList.contains("toolbaricons-list2")) {
      toolbarIconsList.classList.remove("toolbaricons-list2");
    }
  }
};

// export const fitinViewPort = (canvas) => {
//   let boxframe = canvas.getObjects()[0];

//   const scaleX = canvas.width / (boxframe.width + 200);
//   const scaleY = canvas.height / (boxframe.height + 200);
//   const scale = Math.min(scaleX, scaleY);

//   canvas.preDimensions && (canvas.viewportTransform[4] = canvas.viewportTransform[4] + (canvas.width - canvas.preDimensions.width) / 2);
//   canvas.preDimensions && (canvas.viewportTransform[5] = canvas.viewportTransform[5] + (canvas.height - canvas.preDimensions.height) / 2);
//   canvas.zoomToPoint({ x: canvas.getCenter().left, y: canvas.getCenter().top }, scale);

//   canvas.fire("mouse:wheel"); // fire mousewheel event to change the zoom value in html
//   canvas.renderAll();
// };

export const fitinViewPort = (canvas, template) => {
  if (window.innerWidth <= 1280) {
    const hasTemplateObject = canvas._objects.some(
      (obj) => obj.templateView === "template"
    );
    console.log("vp", hasTemplateObject);
    if (hasTemplateObject) {
      let boxframe = canvas.getObjects()[0];

      const scaleX = canvas.width / (boxframe.width + 200);
      const scaleY = canvas.height / (boxframe.height + 200);
      const scale = Math.min(scaleX, scaleY);

      canvas.preDimensions &&
        (canvas.viewportTransform[4] =
          canvas.viewportTransform[4] +
          (canvas.width - canvas.preDimensions.width) / 2);
      canvas.preDimensions &&
        (canvas.viewportTransform[5] =
          canvas.viewportTransform[5] +
          (canvas.height - canvas.preDimensions.height) / 2);
      canvas.zoomToPoint(
        { x: canvas.getCenter().left, y: canvas.getCenter().top },
        scale
      );

      canvas.fire("mouse:wheel"); // fire mousewheel event to change the zoom value in html
      canvas.renderAll();
    } else {
      fitinViewPortMobile(canvas, template);
    }
  } else {
    let boxframe = canvas.getObjects()[0];
    const scaleX = canvas.width / (boxframe.width * boxframe.scaleX + 200);
    const scaleY = canvas.height / (boxframe.height * boxframe.scaleY + 200);
    const scale = Math.min(scaleX, scaleY);

    canvas.preDimensions &&
      (canvas.viewportTransform[4] =
        canvas.viewportTransform[4] +
        (canvas.width - canvas.preDimensions.width) / 2);
    canvas.preDimensions &&
      (canvas.viewportTransform[5] =
        canvas.viewportTransform[5] +
        (canvas.height - canvas.preDimensions.height) / 2);
    canvas.zoomToPoint(
      { x: canvas.getCenter().left, y: canvas.getCenter().top },
      scale
    );

    canvas.fire("mouse:wheel"); // fire mousewheel event to change the zoom value in html``
    canvas.renderAll();
  }
};

export const fitinViewPortMobile = (canvas, template) => {
  const boxframe = canvas.getObjects()[0];

  // Determine scale based on whether template is true or false

  let scaleX, scaleY;
  if (template) {
    // If template is true, ignore boxframe scaling
    scaleY = canvas.height / (boxframe.height + 200);
    scaleX = canvas.width / (boxframe.width + 200);
  } else {
    // If template is false, consider boxframe scaling
    scaleY = canvas.height / (boxframe.height * boxframe.scaleY + 200);
    scaleX = canvas.width / (boxframe.width * boxframe.scaleX + 200);
  }

  // Choose the smaller scale to fit within the viewport
  const scale = Math.min(scaleX, scaleY);

  // Save current canvas center coordinates
  const currentCenter = {
    x: canvas.getCenter().left,
    y: canvas.getCenter().top,
  };

  // Set new zoom level to fit the canvas
  canvas.zoomToPoint(currentCenter, scale);

  // Calculate the new center after applying the zoom
  const newCenter = {
    x: currentCenter.x * scale + canvas.viewportTransform[4],
    y: currentCenter.y * scale + canvas.viewportTransform[5],
  };

  // Adjust viewportTransform to center the zoomed canvas
  canvas.viewportTransform[4] = (canvas.width - canvas.width * scale) / 2;
  canvas.viewportTransform[5] = (canvas.height - canvas.height * scale) / 2;

  // Reset boxframe scaling if template is true
  if (template) {
    boxframe.set({ scaleX: 1, scaleY: 1 });
  }

  // Render the updated canvas
  // boxframe.setCoords();

  canvas._objects.forEach((ele) => {
    ele.setCoords();
  });
  canvas.renderAll();
};

export const updateBackgroundLayer = (bg, canvas) => {
  let layer_img = document.getElementById(
    `${bg.layer_id}_${bg.type}_layer_img`
  );
  layer_img && (layer_img.src = bg.toDataURL());
};
export const updateLeftSectionScroll = () => {
  const bodyMainItems = document.querySelectorAll(
    ".bodyMain .leftsidetoolbarpage"
  );
  const anyVisible = Array.from(bodyMainItems).some(
    (item) => item.style.display === "block"
  );

  // Assuming .leftsideTemp is the container you want to control scrolling on
  const leftSection = document.querySelector(".leftsideTemp");
  if (anyVisible) {
    leftSection.classList.add("no-scroll");
  } else {
    const leftSectionClasss = document.querySelector(".no-scroll");
    if (leftSectionClasss) {
      leftSection.classList.remove("no-scroll");
    }
  }
};
export const toggleProjectLoader = (boolean) => {
  console.log("Project loading loader", boolean);
  if (boolean === true) {
    document.getElementById("projectLoader") &&
      (document.getElementById("projectLoader").style.display = "flex");
      document.getElementById("edp-icon").style.pointerEvents = "none";
  } else {
    document.getElementById("projectLoader") &&
      (document.getElementById("projectLoader").style.display = "none");
      document.getElementById("edp-icon").style.pointerEvents = "auto";
  }
};

export const openImagefromSearchQuery = async (r, canvas, dispatch) => {
  let boxframe = canvas.getObjects()[0];
  await open_image(r, canvas, boxframe);
  //canvas.savestate();
  dispatch(update_undo_status(true));
  canvas.redo_state && (canvas.redo_state.length = 0);
  dispatch(update_redo_status(false));
  removeUrlParam("img");
};
export const removeUrlParam = (paramName) => {
  const url = new URL(window.location.href);
  url.searchParams.delete(paramName);
  window.history.replaceState({}, document.title, url.toString());
};

export const openTemplateFromAiJson = async ({
  canvas,
  url,
  jsonDataParams,
}) => {
  const jsonData = jsonDataParams ? jsonDataParams : await fetchAiJsonData(url);
  if (!jsonData.status) {
    alert("Error fetching Json Data");
    return;
  }
  if (jsonData?.data?.bg_url) {
    let bg = canvas.getObjects()[0];
    canvas.clipPath.set({
      height: jsonData?.data?.height,
      width: jsonData?.data?.width,
    });
    bg.set({
      width: canvas.clipPath.width,
      height: canvas.clipPath.height,
    });
    setbgImg(
      jsonData?.data?.bg_url,
      canvas,
      bg,
      canvas.clipPath.width,
      canvas.clipPath.height,
      bg
    );
    canvas.setViewportTransform([1, 0, 0, 1, 1, 1]);
    fitinViewPort(canvas);
    canvas.centerObject(bg);
    canvas.centerObject(canvas.clipPath);
    canvas.renderAll();
    let dispatchBgfn = document.getElementById("updatebgdispatch");
    dispatchBgfn && dispatchBgfn.click();
    if (jsonData?.data?.overlay_texts) {
      // addTextInCanvas(canvas, overlay_texts[0].text,  bg)
      jsonData?.data.overlay_texts.forEach((t) => {
        let fontFamily = t.fontfamily.split("-")[0];
        let fontWeight = t.fontfamily.split("-")[1].replace(".ttf", "");
        WebFont.load({
          google: {
            families: [fontFamily], // Add your fonts here
          },
          loading: function () {
            // console.log("webfont, loading")
          },
          fontactive: function () {
            const textWidth = getTextWidth(
              t.text,
              `normal ${t.fontSize}px ${fontFamily || "Roboto"}`
            );
            console.log(
              "adding text in canvas",
              textWidth,
              fontFamily,
              fontWeight
            );
            let Text = new fabric.Textbox(t.text.trimEnd(), {
              left: bg.left + t.left,
              top: bg.top + t.top,
              textAlign: "center",
              fontFamily: fontFamily || "Roboto",
              originX: "center",
              originY: "center",
              fontWeight: "normal",
              fontSize: t.fontSize,
              fontWeight: fontWeight,
              width: textWidth,
              fill: `rgb(${t.colour[0]},${t.colour[1]},${t.colour[2]})`,
            });
            Text.setPositionByOrigin(
              new fabric.Point(Text.left, Text.top),
              "left",
              "top"
            );
            canvas.add(Text);
            canvas.renderAll();
          },
          fontinactive: function () {
            const textWidth = getTextWidth(
              t.text,
              `normal ${t.fontSize}px ${fontFamily || "Roboto"}`
            );
            console.log(
              "adding text in canvas",
              textWidth,
              fontFamily,
              fontWeight
            );

            let Text = new fabric.Textbox(t.text, {
              left: bg.left + t.left,
              top: bg.top + t.top,
              textAlign: "center",
              fontFamily: fontFamily || "Roboto",
              originX: "center",
              originY: "center",
              fontWeight: "normal",
              fontSize: t.fontSize,
              fontWeight: fontWeight,
              width: textWidth,
              fill: `rgb(${t.colour[0]},${t.colour[1]},${t.colour[2]})`,
            });
            Text.setPositionByOrigin(
              new fabric.Point(Text.left, Text.top),
              "left",
              "top"
            );
            canvas.add(Text);
            canvas.renderAll();
          },
        });
      });
    }
  }
  removeUrlParam("aitempjson");
};

export const openAdTemplateAiJson = async ({ canvas, url, jsonDataParams }) => {
  const jsonData = jsonDataParams ? jsonDataParams : await fetchAiJsonData(url);
  if (!jsonData.status) {
    alert("Error fetching Json Data");
    return;
  }

  // jsonData.data.ad_images.forEach(ad=>{

  // })
};
export const calculateLightness = (r, g, b) => {
  return 0.2126 * r + 0.7152 * g + 0.0722 * b;
};

export const averageLightness = (colors) => {
  const totalLightness = colors.reduce(
    (sum, [r, g, b]) => sum + calculateLightness(r, g, b),
    0
  );
  return totalLightness / colors.length;
};
export const calculateAvgLightness = (dominant_colors) => {
  const lightnessThreshold = 128;
  const avgLightness = averageLightness(dominant_colors);
  let isDark;

  if (avgLightness < lightnessThreshold) {
    console.log("The image is dark.", avgLightness);
    isDark = true;
  } else {
    console.log("The image is light.");
    isDark = false;
  }

  return isDark;
};
export function blendColors(color1, color2, percentage) {
  const r = Math.round(color1[0] * (1 - percentage) + color2[0] * percentage);
  const g = Math.round(color1[1] * (1 - percentage) + color2[1] * percentage);
  const b = Math.round(color1[2] * (1 - percentage) + color2[2] * percentage);
  return [r, g, b];
}
function rgbToHex([r, g, b]) {
  return `#${((1 << 24) + (r << 16) + (g << 8) + b)
    .toString(16)
    .slice(1)
    .toUpperCase()}`;
}
export function selectBackgroundColor(dominant_colors, sortedColors) {
  let selectedColor;
  const imageIsDark = calculateAvgLightness(dominant_colors);
  if (imageIsDark) {
    // Pick a light color but not the lightest
    selectedColor =
      sortedColors[sortedColors.length - 2] ||
      sortedColors[sortedColors.length - 1];
  } else {
    // Pick a dark color but not the darkest
    selectedColor = sortedColors[1] || sortedColors[0];
  }

  // Optionally blend the selected color with a nearby one for a more complex background
  const blendedColor = blendColors(
    selectedColor,
    sortedColors[sortedColors.length - 1],
    0.2
  );

  return blendedColor;
}
function luminance([r, g, b]) {
  const a = [r, g, b].map((v) => {
    v /= 255;
    return v <= 0.03928 ? v / 12.92 : Math.pow((v + 0.055) / 1.055, 2.4);
  });
  return 0.2126 * a[0] + 0.7152 * a[1] + 0.0722 * a[2];
}

// Function to calculate contrast ratio between two RGB colors
function contrastRatio(color1, color2) {
  const lum1 = luminance(color1) + 0.05;
  const lum2 = luminance(color2) + 0.05;
  return lum1 > lum2 ? lum1 / lum2 : lum2 / lum1;
}
export function selectTextColor(backgroundColor, dominantColors) {
  const lightnessThreshold = 4.5; // For normal text

  let bestColor = null;
  let bestContrast = 0;
  let highestContrastColor = null;

  dominantColors.forEach((color) => {
    const contrast = contrastRatio(backgroundColor, color);
    if (contrast >= lightnessThreshold && contrast > bestContrast) {
      bestContrast = contrast;
      bestColor = color;
    }
    if (contrast > bestContrast) {
      highestContrastColor = color;
    }
  });

  return bestColor || highestContrastColor;
}
function selectElementColors(backgroundColor, textColor, dominantColors) {
  const lightnessThreshold = 4.5; // For normal text
  const elementColors = [];

  dominantColors.forEach((color) => {
    const contrastWithBackground = contrastRatio(backgroundColor, color);
    const contrastWithText = contrastRatio(textColor, color);

    // Ensure good contrast with both background and text
    if (
      contrastWithBackground >= lightnessThreshold &&
      contrastWithText >= lightnessThreshold
    ) {
      elementColors.push(color);
    }
  });

  // If no colors meet the threshold, select the highest contrast ones
  if (elementColors.length === 0) {
    let highestContrastWithBackground = null;
    let highestContrastWithText = null;
    let highestBackgroundContrast = 0;
    let highestTextContrast = 0;

    dominantColors.forEach((color) => {
      const contrastWithBackground = contrastRatio(backgroundColor, color);
      const contrastWithText = contrastRatio(textColor, color);

      if (contrastWithBackground > highestBackgroundContrast) {
        highestBackgroundContrast = contrastWithBackground;
        highestContrastWithBackground = color;
      }
      if (contrastWithText > highestTextContrast) {
        highestTextContrast = contrastWithText;
        highestContrastWithText = color;
      }
    });

    elementColors.push(
      highestContrastWithBackground || highestContrastWithText
    );
  }

  return elementColors;
}
export const getAllColorScheme = (dominant_colors, sortedColors) => {
  const bgColor = selectBackgroundColor(dominant_colors, sortedColors);
  const textColor = selectTextColor(bgColor, dominant_colors);
  const elementColors = selectElementColors(
    bgColor,
    textColor,
    dominant_colors
  );
  return {
    bgColor: rgbToHex(bgColor),
    headingColor: rgbToHex(textColor),
    elementColors: rgbToHex(elementColors[0]),
  };
};
export const readBanner = (jsonData) => {
  let bannerDetails = { bgColor: null, titleTextColor: null, descColor: null };
  jsonData.objects.forEach((o) => {
    if (o.dynamicData?.status) {
      const dynamicData = o.dynamicData.data[0];
      if (dynamicData?.type?.toLowerCase() === "Background".toLowerCase()) {
        console.log("picked banner", o);
        if (typeof o.background_fill_copy === typeof {}) {
          bannerDetails.bgColor = {
            type: o.background_fill_copy.type,
            angle: o.background_fill_copy.angle,
            color1: o.background_fill_copy.colorStops[0].color,
            color2: o.background_fill_copy.colorStops[1].color,
          };
        } else {
          bannerDetails.bgColor = o.background_fill_copy || o.backgroundColor;
        }
      }
      if (dynamicData?.type?.toLowerCase() === "TitleText".toLowerCase()) {
        if (typeof o.fill === typeof {}) {
          bannerDetails.titleTextColor = {
            type: o.fill.type,
            angle: o.fill.angle,
            color1: o.fill.colorStops[0].color,
            color2: o.fill.colorStops[1].color,
          };
        } else {
          bannerDetails.titleTextColor = o.fill;
        }
      }
      if (dynamicData?.type?.toLowerCase() === "SubheadingText".toLowerCase()) {
        if (typeof o.fill === typeof {}) {
          bannerDetails.SubHeadingColor = {
            type: o.fill.type,
            angle: o.fill.angle,
            color1: o.fill.colorStops[0].color,
            color2: o.fill.colorStops[1].color,
          };
        } else {
          bannerDetails.SubHeadingColor = o.fill;
        }
      }
      if (dynamicData?.type?.toLowerCase() === "ParagraphText".toLowerCase()) {
        if (typeof o.fill === typeof {}) {
          bannerDetails.ParagraphColor = {
            type: o.fill.type,
            angle: o.fill.angle,
            color1: o.fill.colorStops[0].color,
            color2: o.fill.colorStops[1].color,
          };
        } else {
          bannerDetails.ParagraphColor = o.fill;
        }
      }
      if (dynamicData?.type?.toLowerCase() === "CTAText".toLowerCase()) {
        if (typeof o.fill === typeof {}) {
          bannerDetails.CTAColor = {
            type: o.fill.type,
            angle: o.fill.angle,
            color1: o.fill.colorStops[0].color,
            color2: o.fill.colorStops[1].color,
          };
        } else {
          bannerDetails.CTAColor = o.fill;
        }
      }
      if (
        dynamicData?.type?.toLowerCase() === "Shapes_Element_1".toLowerCase()
      ) {
        if (typeof o.fill === typeof {}) {
          bannerDetails.Shapes_Element_1 = {
            type: o.fill.type,
            angle: o.fill.angle,
            color1: o.fill.colorStops[0].color,
            color2: o.fill.colorStops[1].color,
          };
        } else {
          bannerDetails.Shapes_Element_1 = o.fill;
        }
      }
      if (
        dynamicData?.type?.toLowerCase() === "Shapes_Element_2".toLowerCase()
      ) {
        if (typeof o.fill === typeof {}) {
          bannerDetails.Shapes_Element_2 = {
            type: o.fill.type,
            angle: o.fill.angle,
            color1: o.fill.colorStops[0].color,
            color2: o.fill.colorStops[1].color,
          };
        } else {
          bannerDetails.Shapes_Element_2 = o.fill;
        }
      }
      if (
        dynamicData?.type?.toLowerCase() === "Shapes_Element_3".toLowerCase()
      ) {
        if (typeof o.fill === typeof {}) {
          bannerDetails.Shapes_Element_3 = {
            type: o.fill.type,
            angle: o.fill.angle,
            color1: o.fill.colorStops[0].color,
            color2: o.fill.colorStops[1].color,
          };
        } else {
          bannerDetails.Shapes_Element_3 = o.fill;
        }
      }
      // if (dynamicData?.type?.toLowerCase() === "AddressText".toLowerCase()) {
      //   addressValue && (o.text = addressValue);
      // }
      // if (dynamicData?.type?.toLowerCase() === "Shapes_Element".toLowerCase()) {
      //   o.fill = allColor?.elementColors;
      // }
    }
  });
  return bannerDetails;
};
