import axios from "axios";
import {
  API_URL,
  FUNCTION_URL,
  Python_FUNCTION_URL,
  SAVEPAYMENTDETAILS_API_URL,
} from "./AppConstant";
import { createJwtToken, verifyJwtToken } from "./middleware";
import { convertHeicToJpeg, getProjectById } from "../helper/sdk_factory";
import { getCookies } from "./Cookies";
import { toast } from "react-toastify";
import { getAuth } from "firebase/auth";
import firebaseServices from "../firebase";
import { doc, setDoc } from 'firebase/firestore';
const { db } = firebaseServices;
/**
 * Get user details using user email.
 *
 * @param {string} userEmail - The email of the user.
 * @returns {Promise<object|string>} - The user details or 'error' if an error occurs.
 */

const auth = getAuth();

export async function getUserDetails(userEmail) {
  try {
    const endpoint = "user/auth"; // API endpoint for user authentication
    console.log("getUserDetails userEmail", userEmail);

    // Fetch user details using Axios
    const res = await fetchData(endpoint, { userEmail }, "getUserDetails");
    const { payload } = await verifyJwtToken(res); // Assuming verifyJwtToken returns payload
    console.log("getUserDetails response payload", payload);
    return payload;
  } catch (error) {
    console.error("Error in getUserDetails:", error);
    throw error;
  }
}

export const addDataToFirestore = async (jsonData) => {
  try {
    console.log("addDataToFirestore ", jsonData);
    
    // Get the user ID from cookies and convert it to a string
    const userId = String(getCookies("userId"));
    // Ensure userId is a valid value before proceeding
    if (!userId) {
      throw new Error("Invalid userId: userId is empty or undefined");
    }

    // Set the document reference and path
    const docRef = doc(db, `userData/${userId}/AiStudio/${jsonData.docId}`);
    // Insert data into Firestore
    await setDoc(docRef, jsonData);
    console.log('Document successfully written!');
  } catch (error) {
    console.error('Error writing document: ', error);
  }
};

/**
 * Fetch data from the API using Axios with JWT authorization.
 *
 * @param {string} endpoint - The API endpoint.
 * @param {object} data - Data to be sent in the request.
 * @returns {Promise<object|string>} - The fetched JSON data or 'error' if an error occurs.
 */
async function fetchData(endpoint, data, from) {
  try {
    // let API_URL = "http://localhost:8090/appydesignstaging/us-central1/widgets";
    const jwtToken = await createJwtToken(data);
    console.log("jwt token created tokennnnn");
    console.log("apiurl---->>>>", API_URL);
    const headers = {
      "Content-Type": "application/json",
      Authorization: jwtToken,
    };

    let response;
    console.log("getUserDetails response sending payload", data);
    if (from && (from === "getUserDetails" || from === "project-size")) {
      // response = await axios.get(`${SAVEPAYMENTDETAILS_API_URL}/${endpoint}`, { headers });
      response = await axios.get(`${SAVEPAYMENTDETAILS_API_URL}/${endpoint}`, {
        headers,
      });
    } else if (from && from === "appydesign") {
      response = await axios.get(
        `https://us-central1-appydesigne-24e6c.cloudfunctions.net/widgetsnew/${endpoint}`,
        { headers }
      );
    } else {
      response = await axios.get(`${API_URL}/${endpoint}`, { headers });
    }

    // Check if response status is not successful
    if (response.status !== 200) {
      // return error;
      throw new Error(`Request failed with response: ${response}`);
    }
    // console.log("in fetchData", response.data)
    return response.data;
  } catch (error) {
    console.error("Error in fetchData:", error);
    // return new Error('Error in fetchData:', error);
    throw error;
  }
}
/**
 * Checks if the user's device is mobile.
 * @returns {boolean} - True if the device is mobile, false if not.
 */
const checkDevice = () => {
  var isMobile = false; //initiate as false
  // device detection
  if (
    /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|ipad|iris|kindle|Android|Silk|lge |maemo|midp|mmp|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i.test(
      navigator.userAgent
    ) ||
    /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
      navigator.userAgent.substr(0, 4)
    )
  ) {
    return (isMobile = true);
  }
  return isMobile;
};

export const generateImageAWSTitan = async (raw,token,uniqueImageIds,imagegroupid) => {
  try {
    console.log(raw, " generateImageAWSTitan raw");
    
    const response = await fetch("https://us-central1-appydesigne-24e6c.cloudfunctions.net/python_widget/TitanGenerateImage", {
      method: "POST",
      headers: { "Content-Type": "application/json","Authorization": `Bearer ${token}` },
      body:  JSON.stringify(raw),
      redirect: "follow",
    });
    const result = await response;
    console.log(result.error ,"generateImageAWSTitan",result);
    
    if (result.error != true) {
      showToast(result.error);
      uniqueImageIds.forEach((id) => {
        console.log("idofimagegenerationdata--->>>>", id);
        dispatch(removeGeneratedImage({ docId: id }));
      });
      dispatch(
        updateSDXLGenerations({
          isGenerating: false,
          error: { errorInGeneration: true, errorText: result },
        })
      );
    }
    console.log("inputfielddata", result, imagegroupid, uniqueImageIds);
  } catch (error) {
    console.error("Error initiating image generation:", error);
    showToast("An error occurred while generating images.");
    uniqueImageIds.forEach((id) => {
      console.log("idofimagegenerationdata--->>>>", id);
      dispatch(removeGeneratedImage({ docId: id }));
    });
    dispatch(
      updateSDXLGenerations({
        isGenerating: false,
        error: { errorInGeneration: true, errorText: error.message },
      })
    );
  }

};

/**
 * Sends a POST request to the API with provided payload and headers.
 * @param {string} queryString - The API endpoint to send the request to.
 * @param {string} access_token - The access token used for authorization.
 * @param {Object} payloadData - The data to send in the request body.
 * @returns {Promise<Object|string>} - A promise that resolves with the response data or 'error' on failure.
 */
export async function getPostData(
  queryString,
  access_token,
  payloadData = {},
  from
) {
  console.log("queryString", queryString);

  try {
    console.log("creating jwt tokennnn", payloadData);
    const jwtToken = await createJwtToken(payloadData);
    let url;
    if (from && from === "updateUserProjectData") {
      url = SAVEPAYMENTDETAILS_API_URL;
    } else if (from && from === "appydesign") {
      url =
        "https://us-central1-appydesigne-24e6c.cloudfunctions.net/widgetsnew";
    } else {
      url = API_URL;
    }

    const response = await fetch(`${url}/${queryString}`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: jwtToken,
      },
      body: JSON.stringify(access_token),
    });

    // console.log("getting post request", access_token, payloadData, response);
    const json = await response.json();

    return json;
  } catch (error) {
    console.error("Error sending POST request:", error);
    throw error; // Return a consistent error value
  }
}

/**
 * Updates or creates a user's data with provided payload.
 * @param {Object} payload - Data to be updated or created.
 * @param {string} access_token - Access token for authentication.
 * @returns {Promise<Object>} - User details.
 */

export async function calculateAspectRatio(width, height) {
  // Define common aspect ratios
  const aspectRatios = {
    "1:1": 1 / 1,
    "16:9": 16 / 9,
    "21:9": 21 / 9,
    "3:2": 3 / 2,
    "2:3": 2 / 3,
    "4:5": 4 / 5,
    "5:4": 5 / 4,
    "3:4": 3 / 4,
    "4:3": 4 / 3,
    "9:16": 9 / 16,
    "9:21": 9 / 21
  };

  // Calculate the aspect ratio for the given width and height
  const ratio = width / height;

  // Find the closest matching aspect ratio
  let closestRatio = null;
  let smallestDifference = Infinity;

  for (const [key, value] of Object.entries(aspectRatios)) {
    const difference = Math.abs(ratio - value);
    if (difference < smallestDifference) {
      smallestDifference = difference;
      closestRatio = key;
    }
  }

  // Return the closest matching aspect ratio
  return closestRatio || "Unknown aspect ratio";
}

const gcd = (a, b) => {
  return b === 0 ? a : gcd(b, a % b);
};

// Function to get aspect ratio in X:Y format
export async function getAspectRatio (width, height)  {
  const divisor = gcd(width, height);
  const aspectWidth = width / divisor;
  const aspectHeight = height / divisor;
  return `${aspectWidth}:${aspectHeight}`;
};

export async function updateOrCreateUser(payload = {}, access_token = "") {
  const url = "user/register";

  // Determine device type
  payload.deviceUsed = checkDevice() ? "mobile" : "web";

  try {
    const userDetails = await getPostData(
      url,
      { access_token },
      { ...payload }
    );
    // console.log("userDetails:", userDetails);

    return userDetails;
  } catch (error) {
    console.error("Error updating or creating user:", error);
    throw error; // Rethrow the error for higher-level handling if needed
  }
}
/**
 * Update user project data.
 * @async
 * @function setUserProjectUpdated
 * @param {Object} projectData - The data to update.
 * @returns {Promise<any>} - The result of the update.
 */
export async function setUserProjectUpdated(projectData) {
  try {
    const updatedData = await getPostData(
      "setUserProjectUpdated",
      projectData,
      {},
      "updateUserProjectData"
    );
    return updatedData;
  } catch (error) {
    console.error("An error occurred while updating user project:", error);
    return error;
    // throw error; // Rethrow the error for better error handling at higher levels
  }
}
/**
 * Remove an item from session storage.
 * @param {string} itemName - The name of the item to remove.
 * @returns {void}
 */
export const removeSessionStorageItem = (itemName) => {
  try {
    sessionStorage.removeItem(itemName);
  } catch (error) {
    // Handle any potential errors gracefully (though for sessionStorage, this is unlikely)
    console.error(
      `An error occurred while removing ${itemName} from session storage:`,
      error
    );
  }
};

/**
 * Retrieves a user project based on the projectId and provided payload.
 * @param {Object} payload - The payload containing project details.
 * @param {string} payload.projectId - The ID of the project to fetch.
 * @param {string} payload.myProject - The type of project/collection name.
 * @returns {Object} - User project data.
 */
export async function getUserProject(payload) {
  try {
    const { projectId, myProject } = payload;

    // console.log('getUserProject', payload, projectId, myProject);

    const userProject = await getProjectById(projectId, myProject);

    // console.log('getUserProject Result:', userProject);

    return userProject;
  } catch (error) {
    console.error("Error in getUserProject:", error.message);
    throw error;
    // throw error;
  }
}
export async function getPaymentStatus(userId) {
  const url = `payment/status`;
  // console.log("fetching payment status", userId)
  const resData = await fetchData(url, { userId });

  const { payload } = await verifyJwtToken(resData);
  console.log("fetching payment status after", payload);
  return payload;
}
export async function getMemoryConsumed(userId) {
  const url = `project-size`;
  const resData = await fetchData(url, { userId }, "project-size");
  const resPayload = verifyJwtToken(resData);
  return resPayload;
}
export async function AiGeneratorApi(endpoint, payload, others) {
  console.log("TexttoImageconvert--", payload);
  const TexttoImageconvert = await getAIPostData(endpoint, payload, {}, others);
  return TexttoImageconvert;
}

export async function getAIPostData(
  queryString,
  payloadData,
  headerData = {},
  others
) {
  const jwtToken = createJwtToken(headerData);
  // let url = "https://us-central1-appydesigne-24e6c.cloudfunctions.net/widgets";
  let url =
    "https://us-central1-appydesigne-24e6c.cloudfunctions.net/widgetsnew";
  if (others && others == "python_widgets") {
    url =
      "https://us-central1-appydesigne-24e6c.cloudfunctions.net/python_widget";
  }
  return await fetch(`${url}/${queryString}`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: jwtToken,
    },
    body: JSON.stringify(payloadData),
  })
    .then((response) => response.json())
    .then((json) => {
      return json;
    })
    .catch((error) => {
      console.error(error);
      return "error";
    });
}

export async function getSampleAiAvatarData() {
  const url = `getavtartemplate`;
  const userId = getCookies("userId");
  const resData = await fetchData(url, { userId }, "appydesign");
  return resData;
}

export const generateAIYTThumbnail2 = async (promptValue) => {
  try {
    const titlePrompt = `${promptValue} make a short title of my content in 1-2 words, max 15 characters`;
    const descriptionPrompt = `${promptValue} make a short description, max 6-8 words`;
    const imagePrompt = `${promptValue} make a prompt to generate a background image from given content`;

    const [title, description, imagePromptResponse] = await Promise.all([
      openAIAPI(titlePrompt),
      openAIAPI(descriptionPrompt),
      openAIAPI(imagePrompt),
    ]);

    if (title.error || description.error || imagePromptResponse.error) {
      throw new Error("Failed to generate content from OpenAI API");
    }

    const payloadData = {
      userId: getCookies("userId"),
      prompt: imagePromptResponse,
      numberofimage: 1,
      imageRatio: "1344x768",
      imageHeight: 768,
      imageWidth: 1344,
    };
    console.time("YT Generation openAi");
    const thumbImage = await AiGeneratorApi("youtubeGenerater", payloadData);
    console.timeEnd("YT Generation openAi");
    if (
      thumbImage.error ||
      thumbImage.data.message ||
      thumbImage.errorType ||
      thumbImage.errorMessage
    ) {
      throw new Error("Failed to generate YouTube thumbnail background image");
    }

    return {
      status: "success",
      data: {
        Title: title,
        Description: description,
        BackgroundImage: thumbImage.data[0],
      },
    };
  } catch (error) {
    console.error("Error generating YTThumbnail:", error.message || error);
    throw error;
  }
};
export const generateAIYTThumbnail = async (promptValue, inputImage) => {
  try {
    const titlePrompt = `"${promptValue}" make a short title of my content in 1-2 words, max 15 characters`;
    const descriptionPrompt = `${promptValue} make a short description, max 6-8 words`;
    const imagePrompt = `${promptValue} make a prompt to generate a background image from given content`;

    // const [title, description, imagePromptResponse] = await Promise.all([
    //   openAIAPI(titlePrompt),
    //   openAIAPI(descriptionPrompt),
    //   openAIAPI(imagePrompt),
    // ]);
    const title = await openAIAPI(titlePrompt);
    const description = await openAIAPI(descriptionPrompt);
    const imagePromptResponse = await openAIAPI(imagePrompt);

    if (title.error || description.error || imagePromptResponse.error) {
      throw new Error("Failed to generate content from OpenAI API");
    }
    const userId = getCookies("userId");
    const payloadData = {
      userId: userId,
      prompt: imagePromptResponse,
      numberofimage: 1,
      imageRatio: "1344x768",
      imageHeight: 768,
      imageWidth: 1344,
    };
    console.time("YT Generation openAi");
    const [thumbImage, personImage] = await Promise.all([
      AiGeneratorApi("youtubeGenerater", payloadData),
      !inputImage &&
        AiGeneratorApi("dallimagegenerate", {
          userId: userId,
          textContent: `Generate a "human individual", with some "hand gestures" for ${title} and ${description}`,
          numberofimage: 1,
          imageRatio: "1024x1024",
        }),
    ]);

    // const thumbImage = await AiGeneratorApi("youtubeGenerater", payloadData);
    console.timeEnd("YT Generation openAi");
    if (
      thumbImage.error ||
      thumbImage.data.message ||
      thumbImage.errorType ||
      thumbImage.errorMessage ||
      (personImage && personImage.error)
    ) {
      throw new Error("Failed to generate YouTube thumbnail background image");
    }

    // let personURL = inputImage ? await BGRemover(inputImage) : await BGRemover(personImage[0].url);

    return {
      status: "success",
      data: {
        Title: title,
        Description: description,
        BackgroundImage: thumbImage.data[0].url,
        PersonImage: inputImage ? inputImage : personImage[0].url,
        // PersonImage: inputImage ? personURL && personURL.data : personURL,
      },
    };
  } catch (error) {
    console.error("Error generating YTThumbnail:", error.message || error);
    throw error;
  }
};

export const generateAiAds = async (promptValue) => {
  try {
    const compositePrompt = `
    "${promptValue}"
    1. Make a short title of my content in 1-2 words, max 15 characters for flex banner purpose.
    2. Make a short description of my content in 4-7 words, max 30 characters for flex banner purpose.
    3. Based on the prompt, identify five captivating background colors suitable for this banner, ranging from dark to light shades of the same color. The colors should enhance the banner's appeal, be visually attractive, and resonate with the themes of the prompt. Please provide five specific color recommendations in color codes, in an array, from darkest to lightest: ['dark(original)', 'less darkshade1', 'less darkshade2', 'morelessdarkshade1', 'light'].
    4. Considering the background colors, recommend five hexadecimal color codes for the text that ensure maximum visibility and comply with the best contrast ratio standards for readability. The text colors should be different shades of the same color, achieving the highest contrast for easy legibility. Please provide the five text colors in hexadecimal format, ranging from darkest to lightest.
    5. Make a prompt to generate a product/hero image from given content.`;

    const aiResponse = await openAIAPI(compositePrompt);

    const [
      title,
      description,
      bgColorText,
      themeColorText,
      imagePromptResponse,
    ] = aiResponse.split("\n").map((item) => item.trim());
    // const bgColor = /#([0-9A-Fa-f]{6})/.exec(`${bgColorText}`)?.[0];
    // const themeColor = themeColorText.match(/#([0-9A-Fa-f]{6})/g).pop();
    const bgColor = JSON.parse(
      bgColorText.match(/\[(.*?)\]/)[0]?.replace(/'/g, '"')
    );
    const themeColor = JSON.parse(
      themeColorText.match(/\[(.*?)\]/)[0]?.replace(/'/g, '"')
    );

    if (!title || !description) {
      throw new Error("Failed to generate content from OpenAI API");
    }
    const newTitle = title.match(/"([^"]+)"/)[1];
    const newDesc = description.match(/"([^"]+)"/)[1];
    const userId = getCookies("userId");
    let productImage = await AiGeneratorApi("dallimagegenerate", {
      userId: userId,
      textContent: `Generate a "product/hero" image, for ${title} and ${description} without any text content`,
      numberofimage: 1,
      imageRatio: "1024x1024",
    });

    if (productImage && productImage.error) {
      throw new Error("Failed to generate AiAds product image");
    }

    return {
      status: "success",
      data: {
        Title: newTitle || title,
        Description: newDesc || description,
        BackgroundColor: bgColor ? bgColor : null,
        textColor: themeColor ? themeColor : null,
        // mixedColor: mixColors(bgColor, themeColor),
        productImage: productImage ? productImage[0] : null,
      },
    };
  } catch (error) {
    console.error("Error generating AiAds:", error.message || error);
    throw error;
  }
};
// export const generateAiAds = async (promptValue) => {
//   try {
//     const compositePrompt = `
//     "${promptValue}"
//     1. Make a short title of my content in 1-2 words, max 15 characters for flex banner purpose.
//     2. Make a short description of my content in 4-7 words, max 30 characters for flex banner purpose.
//     3. Based on the prompt, identify a single, captivating background color suitable for this banner. The color should enhance the banner's appeal, be visually attractive, and resonate with the themes of the prompt. Please provide one specific color recommendation in color code.
//     4. Considering the background color, recommend a hexadecimal color code for the text that ensures maximum visibility and complies with the best contrast ratio standards for readability. The aim is to achieve the highest contrast for easy legibility. Please provide the text color in hexadecimal format.
//     5. Make a prompt to generate a product/hero image from given content.
//     `;

//     const aiResponse = await openAIAPI(compositePrompt);

//     const [title, description, bgColorText, themeColorText, imagePromptResponse] = aiResponse.split('\n').map(item => item.trim());

//     const bgColor = /#([0-9A-Fa-f]{6})/.exec(`${bgColorText}`)?.[0];
//     const themeColor = themeColorText.match(/#([0-9A-Fa-f]{6})/g).pop();

//     if (!title || !description) {
//       throw new Error("Failed to generate content from OpenAI API");
//     }
//     const userId = getCookies("userId");
//     let productImage = await AiGeneratorApi("dallimagegenerate", {
//       userId: userId,
//       textContent: `Generate a "product/hero" image, for ${title} and ${description} without any text content`,
//       numberofimage: 1,
//       imageRatio: "1024x1024",
//     });

//     if (productImage && productImage.error) {
//       throw new Error("Failed to generate AiAds product image");
//     }

//     return {
//       status: "success",
//       data: {
//         Title: title,
//         Description: description,
//         BackgroundColor: bgColor ? bgColor : null,
//         textColor: themeColor ? themeColor : null,
//         mixedColor: mixColors(bgColor, themeColor),
//         productImage: productImage ? productImage[0] : null,
//       },
//     };
//   } catch (error) {
//     console.error("Error generating AiAds:", error.message || error);
//     throw error;
//   }
// };
function mixColors(color1, color2) {
  // Convert hex to RGB
  function hexToRgb(hex) {
    const r = parseInt(hex.slice(1, 3), 16);
    const g = parseInt(hex.slice(3, 5), 16);
    const b = parseInt(hex.slice(5, 7), 16);
    return [r, g, b];
  }

  // Convert RGB to hex
  function rgbToHex(r, g, b) {
    return (
      "#" +
      [r, g, b]
        .map((x) => {
          const hex = x.toString(16);
          return hex.length === 1 ? "0" + hex : hex;
        })
        .join("")
    );
  }

  // Get RGB components of both colors
  const rgb1 = hexToRgb(color1);
  const rgb2 = hexToRgb(color2);

  // Calculate the average of each component
  const mixedRgb = rgb1.map((component, i) =>
    Math.floor((component + rgb2[i]) / 2)
  );

  // Convert the mixed RGB back to hex
  return rgbToHex(...mixedRgb);
}
export const getColorPallete = async (colorDetails, user_prompt) => {
  // const compositePrompt = `
  // These are the colors of my design
  // Background color: ${colorDetails.bgColor}
  // Heading color: ${colorDetails.titleTextColor}
  // Paragraph Color: ${colorDetails.descColor}

  // These color look good together as per the color theory. I wish to change the look on my template so please suggest me a new color palette`
  const compositePrompt = `
  You are an expert graphic designer with over 20 years of experience in design and color combinations. My current design uses the following colors:
Background color: ${colorDetails.bgColor?typeof colorDetails.bgColor=== typeof ""?colorDetails.bgColor: `${colorDetails.bgColor.type}-gradient(${colorDetails.bgColor.color1}, ${colorDetails.bgColor.color2}) `:""}
Heading color: ${colorDetails.titleTextColor?typeof colorDetails.titleTextColor=== typeof ""?colorDetails.titleTextColor: `${colorDetails.titleTextColor.type}-gradient(${colorDetails.titleTextColor.color1}, ${colorDetails.titleTextColor.color2}) `:""}
SubHeading color:${colorDetails.SubHeadingColor?typeof colorDetails.SubHeadingColor=== typeof ""?colorDetails.SubHeadingColor: `${colorDetails.SubHeadingColor.type}-gradient(${colorDetails.SubHeadingColor.color1}, ${colorDetails.SubHeadingColor.color2}) `:""}
Paragraph color:${colorDetails.ParagraphColor?typeof colorDetails.ParagraphColor=== typeof ""?colorDetails.ParagraphColor: `${colorDetails.ParagraphColor.type}-gradient(${colorDetails.ParagraphColor.color1}, ${colorDetails.ParagraphColor.color2}) `:""}
CTA color:${colorDetails.CTAColor?typeof colorDetails.CTAColor=== typeof ""?colorDetails.CTAColor: `${colorDetails.CTAColor.type}-gradient(${colorDetails.CTAColor.color1}, ${colorDetails.CTAColor.color2}) `:""}
Decorative Elements color 1:${ colorDetails.Shapes_Element_1?typeof colorDetails.Shapes_Element_1=== typeof ""?colorDetails.Shapes_Element_1: `${colorDetails.Shapes_Element_1.type}-gradient(${colorDetails.Shapes_Element_1.color1}, ${colorDetails.Shapes_Element_1.color2}) `:""}
Decorative Elements color 2:${ colorDetails.Shapes_Element_2?typeof colorDetails.Shapes_Element_2=== typeof ""?colorDetails.Shapes_Element_2: `${colorDetails.Shapes_Element_2.type}-gradient(${colorDetails.Shapes_Element_2.color1}, ${colorDetails.Shapes_Element_2.color2}) `:""}
Decorative Elements color 3:${colorDetails.Shapes_Element_3?typeof colorDetails.Shapes_Element_3=== typeof ""?colorDetails.Shapes_Element_3: `${colorDetails.Shapes_Element_3.type}-gradient(${colorDetails.Shapes_Element_3.color1}, ${colorDetails.Shapes_Element_3.color2}) `:""}
Please suggest a new color palette, listed in the sequence (Background color, Heading color, SubHeading color, Paragraph color, CTA color, Decorative Elements color 1, Decorative Elements color 2, Decorative Elements color 3), Keep the number of colors in the palette same. inspired by the following prompt: '${user_prompt}'.` 

  const aiResponse = await openAIAPI(compositePrompt);
  console.log("airesponse on openai", aiResponse);
  return extractColorsFromResponse(aiResponse);
};
export const openAIAPI = async (promptValue) => {
  const apiUrl = `https://us-central1-appydesigne-24e6c.cloudfunctions.net/openAi/api`;
  // const apiUrl = `http://localhost:9000/appydesigne-24e6c/us-central1/OpenAi/api`;
  if (!promptValue) {
    throw new Error("Prompt is required.");
  }
  try {
    const token = await auth.currentUser?.getIdToken();

    if (!token) {
      throw new Error("Authentication failed. Please log in again.");
    }
    const response = await axios.post(apiUrl, { prompt: promptValue }, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    if (!response.data || !response.data.status) {
      throw new Error("Invalid response structure from the API.");
    }

    return response.data.message;

  } catch (error) {
    if (error.code === "ECONNABORTED") {
      throw new Error("Request timed out. Please try again.");
    }
    if (!error.response) {
      throw new Error("Network error. Please check your internet connection.");
    }
    if (error.response.status >= 400 && error.response.status < 500) {
      throw new Error(error.response.data.error || "Client error occurred.");
    }

    if (error.response.status >= 500) {
      throw new Error("Server error. Please try again later.");
    }
    throw new Error("An unexpected error occurred. Please try again.");
  }
};
// function extractColorsFromResponse(response) {
//   // Define a regular expression to match hex color codes
//   const colorRegex = /#([0-9a-fA-F]{6})/g;

//   // Extract all color codes from the response
//   const colors = response.match(colorRegex);

//   // Handle edge cases: Check if any colors were found
//   if (!colors || colors.length < 3) {
//       console.warn("Not enough colors found in the response.");
//       return {
//         backgroundColor: "#FFFFFF", // Default color
//         headingColor: "#000000", // Default color
//         SubHeadingColor: "#888888", // Default color
//         ParagraphColor: "#888888", // Default color
//         CTAColor: "#888888", // Default color
//         Shapes_Element_1: "#888888", // Default color
//         Shapes_Element_2: "#888888", // Default color
//         Shapes_Element_3: "#888888", // Default color
//       };
//   }
//   // Return an object with the extracted colors
//   return {
//       backgroundColor: colors[0] || "#FFFFFF", // Default color
//       headingColor: colors[1] || "#000000",    // Default color
//       SubHeadingColor: colors[2] || "#888888",  // Default color
//       ParagraphColor: colors[3] || "#888888",  // Default color
//       CTAColor: colors[4] || "#888888",  // Default color
//       Shapes_Element_1: colors[5] || "#888888", // Default color
//       Shapes_Element_2: colors[6] || "#888888", // Default color
//       Shapes_Element_3: colors[7] || "#888888"  // Default color
//   };
// }
function extractColorsFromResponse(response) {
  const colorLineRegex =
    /^(Background color|Heading color|SubHeading color|Paragraph color|CTA color|Decorative Elements color \d+):\s*(#[0-9a-fA-F]{6}|(?:linear-gradient|radial-gradient)\((?:#[0-9a-fA-F]{6}\s*,\s*){1,}#[0-9a-fA-F]{6}\))/gm;

  const extractedLines = [];
  let match;

  while ((match = colorLineRegex.exec(response)) !== null) {
    const label = match[1].trim();
    const colorValue = match[2].trim();

    // Format the result
    let formattedValue;
    if (
      colorValue.startsWith("linear-gradient") ||
      colorValue.startsWith("radial-gradient")
    ) {
      formattedValue = `"${colorValue}"`;
    } else {
      formattedValue = colorValue;
    }

    extractedLines.push(`${label}: ${formattedValue}`);
  }

  console.log(extractedLines);
  const colorsObject = extractedLines.reduce((acc, item) => {
    const [key, value] = item.split(": ");
    acc[key] = value.replace(/"/g, ""); // Remove any remaining quotes
    return acc;
  }, {});
  return {
    backgroundColor: colorsObject["Background color"] || "#FFFFFF",
    headingColor: colorsObject["Heading color"] || "#000000",
    SubHeadingColor: colorsObject["SubHeading color"] || "#888888",
    ParagraphColor: colorsObject["Paragraph color"] || "#888888",
    CTAColor: colorsObject["CTA color"] || "#888888",
    Shapes_Element_1: colorsObject["Decorative Elements color 1"] || "#888888",
    Shapes_Element_2: colorsObject["Decorative Elements color 2"] || "#888888",
    Shapes_Element_3: colorsObject["Decorative Elements color 3"] || "#888888",
  };
}
export function extractColorsFromGradient(gradient) {
  // Regular expression to match color codes in the gradient string
  const colorRegex = /#([0-9a-fA-F]{6}|[0-9a-fA-F]{3})\b/g;

  // Find all color codes in the gradient string
  const matches = gradient.match(colorRegex);

  // Return the array of color codes
  return matches || [];
}

export const BGRemover = async (url) => {
  // const apiUrl = process.env.REACT_APP_OPENAI_BASEURL;

  const body = { image: `${url}` };

  try {
    const response = await fetch(
      "https://us-central1-appydesigne-24e6c.cloudfunctions.net/widgets/bgremover",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      }
    );
    const result = await response.json();

    if (result.error) {
      throw new Error(result.error.message);
    } else {
      console.log("bgRemover Api  ", result);
      return result.data;
    }
  } catch (error) {
    console.error("Error removing Bg", error);
    throw error;
  }
};
export const checkNSFW = async (imageURL) => {
  try {
    const token = await auth.currentUser.getIdToken();
    var requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({ image: imageURL }),
    };

    let response = await fetch(
      "https://us-central1-appydesigne-24e6c.cloudfunctions.net/python_widget_auth/check_nsfw",
      requestOptions
    );
    const result = await response.json();
    if (result.error) {
      // return result.error;
      throw new Error(result.error);
    } else {
      return result.nsfw_detected;
    }
  } catch (error) {
    console.error("Error checking nsfw", error);
    throw error;
  }
};

export const playgroundSearch = async (searchContent, userId, apiDom) => {
  const raw = JSON.stringify({
    userId: userId,
    prompt: searchContent,
    threshold: 0.34,
  });

  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: raw,
    redirect: "follow",
  };
  try {
    const response = await fetch(
      `${Python_FUNCTION_URL}/${apiDom}`,
      requestOptions
    );
    if (!response.ok) {
      throw new Error(`Error: ${response.statusText}`);
    }
    const result = await response.json();
    return result;
  } catch (error) {
    throw error; // Rethrow the error to be caught in the calling function
  }
};

export const showToast = (message) => {
  toast.dismiss(); // Close the existing toast, if any
  // toast(message, {
  //   toastId: "customId",
  // }); // Show the new toast
  toast(message); // Show the new toast
};

export const fetchUnencryptedData = async (endPoint, data) => {
  try {
    const headers = {
      "Content-Type": "application/json",
    };
    const response = await axios.post(`${FUNCTION_URL}/${endPoint}`, data, {
      headers,
    });
    // const response = await axios.post(`http://localhost:9000/appydesigne-24e6c/us-central1/checkPreviousTrialActivation/`, data, { headers });

    return response?.data;
  } catch (error) {
    console.error("Error creating token:", error);
    throw error;
  }
};

export const migrateUserDataAPI = async (userId) => {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ userId }),
    redirect: "follow",
  };

  try {
    const response = await fetch(
      "https://us-central1-appydesigne-24e6c.cloudfunctions.net/widgetsnew/migrateData",
      requestOptions
    );

    if (!response.ok) {
      throw new Error(`Failed to migrate user data: ${response.status}`);
    }

    return { success: true, result: await response.text() };
  } catch (error) {
    return { success: false, error: error.message };
  }
};

export const getSamplePrompt = (samplePrompts) => {
  return samplePrompts[Math.floor(Math.random() * samplePrompts.length)]
    ?.promptValue;
};

export const fetchAiJsonData = async (url, options = {}) => {
  try {
    const response = await axios.get(url, options);
    if (response.status != 200) {
      return { status: false, error: "error fetching aiJson code is not 200" };
    }
    return { status: true, data: response.data };
  } catch (error) {
    console.log("error fetching aiJson");
    return { status: false, error };
  }
};

export const commonApi = async ({
  API_BASE_URL,
  apiEndpoint,
  method,
  queryParams,
  payload,
  options,
}) => {
  try {
    const response = await axios({
      url: `${API_BASE_URL}${apiEndpoint ? `/${apiEndpoint}` : ""}`,
      method,
      params: method.toLowerCase() === "get" ? queryParams : undefined,
      data: method.toLowerCase() !== "get" ? payload : undefined,
      headers: {
        // ...options?.cache === 'no-store' && { 'Cache-Control': 'no-store' },
      },
    });
    return { status: true, data: response.data };
  } catch (error) {
    return { status: false, error };
  }
};

export function convertDimensions(value, fromUnit, toUnit) {
  const conversionFactors = {
    px: 1,
    in: 72,
    mm: 72 / 25.4,
    cm: 72 / 2.54,
    ft: 72 * 12,
  };
  if (!conversionFactors[fromUnit] || !conversionFactors[toUnit]) {
    throw new Error("Unsupported unit");
  }

  // Convert width and height to pixels
  const widthInPixels = value.width * conversionFactors[fromUnit];
  const heightInPixels = value.height * conversionFactors[fromUnit];

  // Convert width and height from pixels to the target unit
  const convertedWidth = widthInPixels / conversionFactors[toUnit];
  const convertedHeight = heightInPixels / conversionFactors[toUnit];

  return {
    width: convertedWidth,
    height: convertedHeight,
    formattedWidth: Math.floor(convertedWidth * 100) / 100,
    formattedHeight: Math.floor(convertedHeight * 100) / 100,
  };
}
export const getDiscordDbPath = (path)=>{
  return `GptPluginGenerationRequestTemp/${path.split("_")[0]}/DataRequests/${path}`
}
export const AiGeneratorApiNew = async(payload)=>{
  try {
    const res = await commonApi({API_BASE_URL:"https://us-central1-appydesigne-24e6c.cloudfunctions.net/creditRevert",apiEndpoint:"api",method:"POST",payload:payload})
    // const res = await commonApi({API_BASE_URL:"http://localhost:9003/appydesigne-24e6c/us-central1/creditRevert",apiEndpoint:"api",method:"POST",payload:payload})
    if(!res.status){
      throw new Error(res.error.message||"Axios return false");
    }
    if(res.data.status!="success"){
      throw new Error(res.data.message||"creditRevert api return error");
    }
    return res.data.proxyResponse.data
  } catch (error) {
    console.error("error in AiGeneratorApiNew", error)
    return { error: true};
  }
}
export const removeImageMetadata = async (file) => {
  return new Promise(async(resolve, reject) => {
    if (!file) {
      return reject(new Error("No file found"));
    }
    if (!file.type.startsWith('image/')) {
      return reject(new Error("The file is not an image"));
    }
    if (file.type === "image/heic" ||file?.type === "image/HEIC" || file?.name?.endsWith(".heic")) {
      console.log('Converting HEIC to JPEG');
      file = await convertHeicToJpeg(file);
    }
    const reader = new FileReader();
    reader.onload = function (e) {
      const img = new Image();
      img.crossOrigin = 'Anonymous';
      img.onload = function () {
        const canvas = document.createElement("canvas");
        const ctx = canvas.getContext("2d");
        canvas.width = img.width;
        canvas.height = img.height;
        ctx.drawImage(img, 0, 0);
        canvas.toBlob((blob) => {
          if (blob) {
            resolve(blob);
          } else {
            reject(new Error("Failed to create Blob from canvas"));
          }
        }, file.type);
      };
      img.src = e.target.result;
    };
    reader.onerror = function (err) {
      reject(err);
    };
    reader.readAsDataURL(file);
  });
};

