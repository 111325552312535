import { initializeApp } from "firebase/app";
import { getStorage } from "firebase/storage";
import { getFirestore } from "firebase/firestore";
import axios from "axios";
import { verifyJwtToken } from "./utils/middleware";
import { FIREBASE_CONFIG_API_URL } from "./utils/AppConstant";

/**
 * Fetches Firebase configuration from the backend API.
 * @returns {Promise<string>} A base64 and JWT encoded Firebase configuration.
 */

const fetchFirebaseConfig = async () => {
  try {
    const response = await axios.get(FIREBASE_CONFIG_API_URL);
    if (response.status === 200) {
      return atob(response.data);
    } else {
      throw new Error("Failed to fetch Firebase configuration");
    }
  } catch (error) {
    throw error;
  }
};

/**
 * Initializes Firebase and returns Firestore and Storage instances.
 * @returns {Promise<{ db: Firestore, storage: Storage } | null>} Firebase services or null if an error occurs.
 */
const initializeFirebase = async () => {
  try {
    const firebaseConfig = await fetchFirebaseConfig();
    const key = await verifyJwtToken(firebaseConfig);
    const app = initializeApp(key);
    const db = getFirestore(app);
    const storage = getStorage(app);
    return { db, storage };
  } catch (error) {
    console.error("Error initializing Firebase:", error);
    // document.getElementById("root") && (document.getElementById("root").innerHTML = error);
    return null;
  }
};

/**
 * Entry point for accessing Firebase services.
 * @type {Promise<{ db: Firestore, storage: Storage } | null>}
 */

const firebaseServices = await initializeFirebase();

export default firebaseServices;
